import { StyledH2 } from '../../Titles';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../../Redux/Selectors/AuthSelectors';
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useCallback, useState } from 'react';
import CustomMarkerOld from '../../../../Static/icons/map/marker-old.png';
import CustomMarker from '../../../../Static/icons/map/marker.png';
import { Preloader } from '../../Preloader';
import { Button } from '../../Buttons';
import { StyledCustomError, StyledFormButtonBlock } from '../../Form/styled';
import { StyledMapContainer } from './styled';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { setUserData } from '../../../../Redux/Actions/AuthActions';
import { updateUser } from '../../../../API/User';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import L from './locale.json';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { GOOGLE_MAPS_API_KEY } from '../../../../Configs/constants';
import { useRef } from 'react';
// import Geocode from 'react-geocode';

// Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
// Geocode.setRegion('lt');
// Geocode.setLocationType('ROOFTOP');
//Geocode.enableDebug();

export const AddLocationMap = ({ onClose, type }) => {
  const { coordinates, companyAddress, _id } = useSelector(getUserData);
  const dispatch = useDispatch();
  const [locale] = useLocale(L);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const mapRef = useRef();

  const {
    messages: [updateUserDataMessages],
    loading: [updateUserDataLoading],
  } = useMessages(['updateUserData']);

  //useEffect(() => Geocode.setLanguage(lang), [lang]);

  const [markers, setMarkers] = useState([]);

  const handleChangeMarkerUser = () => {
    dispatch(
      rx(
        authRx,
        'updateUserData',
        (dispatch, { data }) => {
          dispatch(setUserData(data.user));
          onClose();
        },
        updateUser,
        {
          id: _id,
          body: {
            coordinates: {
              latitude: coordinates
                ? Number(markers[1].lat)
                : Number(markers[0].lat),
              longitude: coordinates
                ? Number(markers[1].lng)
                : Number(markers[0].lng),
            },
          },
        }
      )
    );
  };

  const onLoad = useCallback(
    async (map) => {
      if (coordinates) {
        map.setCenter({
          lat: coordinates.latitude,
          lng: coordinates.longitude,
        });
        setMarkers([{ lat: coordinates.latitude, lng: coordinates.longitude }]);
      } else {
        map.setCenter({ lat: 54.682889, lng: 25.234143 });
      }
      mapRef.current = map;
    },
    [coordinates, setMarkers]
  );

  const clickMap = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    // Geocode.fromLatLng(lat, lng).then(
    //   (...props) => console.log('OOOO', props),
    //   (e) => console.log(e)
    // );
    if (coordinates) {
      setMarkers([
        {
          lat: coordinates.latitude,
          lng: coordinates.longitude,
          rerender: true,
        },
        { lat, lng },
      ]);
    } else {
      setMarkers([{ lat, lng }]);
    }
    if (mapRef.current) {
      const mapCenter = mapRef.current.getCenter();
      mapRef.current.setCenter({ lat: mapCenter.lat(), lng: mapCenter.lng() });
    }
  };

  return isLoaded ? (
    <form>
      <StyledH2>{locale.title}</StyledH2>
      <StyledMapContainer>
        <GoogleMap
          options={{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
          }}
          onLoad={onLoad}
          onClick={clickMap}
          zoom={10}
        >
          {markers.map((marker, i) => {
            const markerIcon =
              markers.length > 1 && i === 0 ? CustomMarkerOld : CustomMarker;
            return (
              <MarkerF
                key={JSON.stringify(marker).toString()}
                icon={markerIcon}
                position={marker}
              >
                {i === 0 ? (
                  <InfoWindowF position={marker}>
                    <div>{companyAddress}</div>
                  </InfoWindowF>
                ) : null}
              </MarkerF>
            );
          })}
        </GoogleMap>
      </StyledMapContainer>
      {updateUserDataMessages.error ? (
        <StyledCustomError>{updateUserDataMessages.error}</StyledCustomError>
      ) : null}
      <StyledFormButtonBlock>
        <Button
          loading={updateUserDataLoading}
          kind={'green'}
          text={locale.submitBtns[type]}
          disabled={coordinates ? markers.length < 2 : markers.length < 1}
          fn={handleChangeMarkerUser}
        />
      </StyledFormButtonBlock>
    </form>
  ) : (
    <Preloader />
  );
};
