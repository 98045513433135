export const isMobile = () =>
  ((groups) => (groups ? Object.keys(groups)[0] : null))(
    (
      navigator.userAgent.match(
        /(?<Android>Android)|(?<BlackBerry>BlackBerry)|(?<IOS>iPhone|iPad|iPod)|(?<Opera>Opera Mini)|(?<Windows>IEMobile)/i
      ) || []
    ).groups
  );

export const getAvarageGrade = (reviews) =>
  reviews?.length
    ? (
        reviews.reduce((acc, { grade }) => (acc += grade), 0) / reviews.length
      ).toFixed(1)
    : 1;

export const getPlatform = () => {
  const clientStrings = [
    { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
    { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
    { s: 'Windows Vista', r: /Windows NT 6.0/ },
    { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
    { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
    { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
    { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
    { s: 'Windows 98', r: /(Windows 98|Win98)/ },
    { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
    { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: 'Windows CE', r: /Windows CE/ },
    { s: 'Windows 3.11', r: /Win16/ },
    { s: 'Android', r: /Android/ },
    { s: 'BlackBerry', r: /BlackBerry/ },
    { s: 'Open BSD', r: /OpenBSD/ },
    { s: 'Sun OS', r: /SunOS/ },
    { s: 'Chrome OS', r: /CrOS/ },
    { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    { s: 'Mac OS X', r: /Mac OS X/ },
    { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: 'QNX', r: /QNX/ },
    { s: 'UNIX', r: /UNIX/ },
    { s: 'BeOS', r: /BeOS/ },
    { s: 'OS/2', r: /OS\/2/ },
    {
      s: 'Search Bot',
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];
  for (const { s, r } of clientStrings) {
    if (r.test(window.navigator?.userAgent)) {
      return s;
    }
  }
  return (
    window.navigator?.userAgentData?.platform ||
    window.navigator?.platform ||
    '-'
  );
};

export const getDeviceInfo = () => ({
  mobile: !!isMobile(),
  platform: getPlatform(),
});

export const getCaretPosition = (obj) => {
  if (obj.selectionStart) return obj.selectionStart;
  else if (document.selection) {
    var sel = document.selection.createRange();
    var clone = sel.duplicate();
    sel.collapse(true);
    clone.moveToElementText(obj);
    clone.setEndPoint('EndToEnd', sel);
    return clone.text.length;
  }

  return 0;
};

export const setCaretPosition = (elem, caretPos) => {
  if (document.selection) {
    elem.focus();
    const range = document.selection.createRange();
    range.moveStart('character', -elem.value.length);
    range.moveStart('character', caretPos);
    range.moveEnd('character', 0);
    range.select();
  } else if (elem.selectionStart || elem.selectionStart === '0') {
    elem.selectionStart = caretPos;
    elem.selectionEnd = caretPos;
    elem.focus();
  }
};

export const measureScrollRails = (el) => {
  el = el || document.body;

  const scrollDiv = document.createElement('div');
  const position = el.style.position;
  el.style.position = 'relative';
  scrollDiv.setAttribute(
    'style',
    'width:100px;height:100px;overflow:scroll;position:absolute;top:-9999px;'
  );
  el.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  el.removeChild(scrollDiv);
  el.style.position = position;
  return scrollbarWidth;
};

const currencyConfig = {
  style: 'currency',
  currency: 'EUR',
};

export const toPrice = (num, lang) =>
  new Intl.NumberFormat(lang, currencyConfig).format(num || 0);

export const toDate = (date, lang) => {
  return date
    ? new Date(date).toLocaleDateString(lang, {
        year: 'numeric',
        day: '2-digit',
        month: 'long',
      })
    : '-';
};

export const toTime = (date) => {
  return date
    ? new Date(date).toLocaleTimeString('ru', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      })
    : '-';
};

export const toDateAndTime = (date, lang) => {
  return date ? `${toDate(date, lang)}, ${toTime(date)}` : '-';
};

export const decidePlural = (length = 1) => {
  if (length >= 10) return 'more';
  switch (length) {
    case 0:
      return 'no';
    case 1:
      return 'one';
    default:
      return 'lot';
  }
};

export const parseAddress = ({ city = '', street = '', house, flat }) =>
  `${city}${
    street
      ? ', Šv. ' +
        street +
        (house ? ', ' + house : '') +
        (flat ? ', apt. ' + flat : '')
      : ''
  }`;

const createBC = (ref) => new BroadcastChannel('payment_inclean_' + ref);

export const setBroadcastOnOrder = (payment_reference, fn) => {
  const bc = createBC(payment_reference);
  bc.onmessage = ({ data }) => {
    if (data === 'close me') bc.postMessage('close');
    fn();
    bc.close();
  };
};

export const setBroadcastOnLoad = () => {
  const payment_reference = new URL(document.location.href).searchParams.get(
    'payment_reference'
  );
  if (payment_reference) {
    const bc = createBC(payment_reference);
    bc.postMessage('close me');
    bc.onmessage = ({ data }) => {
      if (data === 'close') {
        bc.close();
        document.history.go(-document.history.length);
      }
    };
  }
};

export const onSwiperHelper = (
  swiper,
  navigationPrevRef,
  navigationNextRef
) => {
  // Delay execution for the refs to be defined
  setTimeout(() => {
    // Override prevEl & nextEl now that refs are defined
    if (!swiper.destroyed) {
      swiper.params.navigation.prevEl = navigationPrevRef.current;
      swiper.params.navigation.nextEl = navigationNextRef.current;

      // Re-init navigation
      swiper.navigation.destroy();
      swiper.navigation.init();
      swiper.navigation.update();
    }
  });
};

export const getIsMobile = () => window.innerWidth < 720;
