import { useDispatch, useSelector } from 'react-redux';
import { getLang } from '../../Redux/Selectors/LanguagesSelectors';
import { setLanguage } from '../../Redux/Actions/LanguagesActions';
import { StyledLanguagePickerItem } from './styled';
import { languages } from '../../Configs/constants';
import { useEffect, useRef } from 'react';

export const LanguagePicker = ({ language, fn }) => {
  const dispatch = useDispatch();
  const lang = useSelector(getLang);
  const brodacastChannelRef = useRef(false);

  const switchLang = (l) => () => {
    if (language && fn) {
      fn(l);
    } else {
      dispatch(setLanguage(l));
    }
  };

  useEffect(() => {
    if (!brodacastChannelRef.current) {
      brodacastChannelRef.current = new BroadcastChannel(
        'language-for-firebase-sw'
      );
      brodacastChannelRef.current.onmessage = (e) => {
        //console.log('MESSAGE from FB SW', e.data);
      };
    } else {
      brodacastChannelRef.current.postMessage({ lang });
    }
  }, [lang]);

  return (
    <>
      {languages.map((el) => {
        return (
          <StyledLanguagePickerItem
            onClick={switchLang(el)}
            key={el}
            className={(language || lang) === el ? 'is-active' : ''}
          >
            {el}
          </StyledLanguagePickerItem>
        );
      })}
    </>
  );
};
