import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';

const setLoading = createAction(types.SET_LOADING);

const setMessage = createAction(types.SET_MESSAGE);

const clearMessage = createAction(types.CLEAR_MESSAGE);

export { setLoading, setMessage, clearMessage };
