import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {
  StyledHeader,
  StyledHeaderRow,
  StyledHeaderContent,
  StyledSearchBar,
  StyledSearchForm,
  StyledSearchInput,
  StyledSearchIcon,
} from './styled';
import { UserPanel } from '../UserPanel';

import { ReactComponent as LogoIcon } from '../../Static/icons/logo.svg';
import { ReactComponent as SearchIcon } from '../../Static/icons/header/search.svg';
import { urls } from '../../Configs/constants';
import { LanguagePicker } from '../LanguagePicker';
import { StyledLanguagePicker } from '../LanguagePicker/styled';
import { useRef } from 'react';
import { BellNotifications } from '../Notifications/Bell';
import { useLocale } from '../../Utils/Hooks/useLocale';
import L from './locale.json';

const { MAIN } = urls;

const SearchPanel = () => {
  const [active, setActive] = useState(false);
  const handleFocus = () => setActive(true);
  const handleBlure = () => setActive(false);
  const [params, setParams] = useSearchParams();
  const [search, setSearch] = useState(params.get('search') || '');
  const [locale] = useLocale(L);

  const throttleRef = useRef(null);

  const handleInput = (e) => {
    const newSearch = {};
    for (let [key, val] of params.entries()) {
      if (key !== 'search' && val) newSearch[key] = val;
    }
    if (e.target.value) newSearch.search = e.target.value;

    setSearch(e.target.value);
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => setParams?.(newSearch), 500);
  };

  return (
    <StyledSearchBar>
      <StyledSearchForm active={active}>
        <StyledSearchInput
          placeholder={`${locale.search}...`}
          value={search}
          onInput={handleInput}
          onFocus={handleFocus}
          onBlur={handleBlure}
        />
        <StyledSearchIcon as={SearchIcon} />
      </StyledSearchForm>
    </StyledSearchBar>
  );
};

export const Header = ({ Auth }) => {
  return (
    <StyledHeader>
      <StyledHeaderRow>
        <StyledHeaderContent>
          <li className="nav logo">
            <Link to={MAIN[Auth]}>
              <LogoIcon />
            </Link>
          </li>
          <SearchPanel />
          <BellNotifications />
          <StyledLanguagePicker>
            <LanguagePicker />
          </StyledLanguagePicker>
          <UserPanel />
        </StyledHeaderContent>
      </StyledHeaderRow>
    </StyledHeader>
  );
};
