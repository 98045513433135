import { Routes, Route, Navigate } from 'react-router-dom';
import { authRoutes, routes } from './Routes/index';
import { Header } from './Components/Header';
import { MainWithSidebar } from './Components/Main';
import { useLocale } from './Utils/Hooks/useLocale';
import L from './Utils/generalLocale.json';
import { useSelector } from 'react-redux';
import { getUserData } from './Redux/Selectors/AuthSelectors';
import { isEmployeeAccessRoute } from './Utils/Helpers/isEmployeeAccessRoute';
import { useMemo } from 'react';
import { urls } from './Configs/constants';

const WithTitle = ({ children, name }) => {
  const [{ pages }] = useLocale(L);
  document.title = pages[name] || 'FairFix';
  return children;
};

const AuthRoute = ({ Auth, isProtected = false, children }) => {
  const path = useMemo(() => (Auth ? urls.MAIN[Auth] : '/login'), [Auth]);

  return !!Auth !== isProtected ? <Navigate to={path} /> : children;
};

export const AppRoutes = ({ Auth }) => {
  const userData = useSelector(getUserData);

  return (
    <Routes>
      {authRoutes.map(({ Elm, id, link, name, ...props }) => {
        return (
          <Route
            key={name}
            {...props}
            element={
              <WithTitle name={name}>
                <AuthRoute Auth={Auth}>
                  <Elm {...props} Auth={Auth} />
                </AuthRoute>
              </WithTitle>
            }
          />
        );
      })}
      <Route
        path="/"
        element={
          <>
            {Auth ? <Header Auth={Auth} /> : null}
            <MainWithSidebar Auth={Auth} />
          </>
        }
      >
        <Route
          index
          element={<AuthRoute Auth={Auth} isProtected={!Auth}></AuthRoute>}
        />
        {routes
          .filter(({ access, accessEmployee }) => {
            const accessMain = isEmployeeAccessRoute(
              access,
              accessEmployee,
              userData
            );

            return accessMain;
          })
          .map(({ Elm, subroutes, name, ...props }, i) => (
            <Route
              key={i}
              {...props}
              element={
                <WithTitle name={name}>
                  <AuthRoute Auth={Auth} isProtected>
                    <Elm {...props} Auth={Auth} />
                  </AuthRoute>
                </WithTitle>
              }
            >
              {subroutes
                ? subroutes
                    .filter(({ access, accessEmployee, name }) => {
                      if (accessEmployee) {
                        const accessMain = isEmployeeAccessRoute(
                          access,
                          accessEmployee,
                          userData
                        );

                        return accessMain;
                      } else {
                        return access
                          ? access.some((role) => role === Auth)
                          : true;
                      }
                    })
                    .map(({ Elm, subroutesInner, name, ...props }, j) => (
                      <Route
                        key={j}
                        {...props}
                        element={
                          <WithTitle name={name}>
                            <Elm {...props} Auth={Auth} />
                          </WithTitle>
                        }
                      >
                        {subroutesInner
                          ? subroutesInner
                              .filter(({ access }) =>
                                access
                                  ? access.some((role) => role === Auth)
                                  : true
                              )
                              .map(({ Elm, name, ...props }, k) => (
                                <Route
                                  key={k}
                                  {...props}
                                  element={
                                    <WithTitle name={name}>
                                      <Elm {...props} Auth={Auth} />
                                    </WithTitle>
                                  }
                                />
                              ))
                          : null}
                      </Route>
                    ))
                : null}
            </Route>
          ))}
      </Route>
    </Routes>
  );
};
