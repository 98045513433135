import { StyledCheckInputWrapper } from '../styled';

export const RadioInput = ({
  name,
  size = 'default',
  value,
  as,
  fn = () => null,
  errors = '',
  checked,
  placeholder = '',
  required = false,
  locale,
  ...props
}) => {
  return (
    <StyledCheckInputWrapper size={size} as={as}>
      <input
        {...props}
        name={name}
        value={value}
        checked={checked}
        type="radio"
        hidden={true}
        onChange={fn}
      />
      <span>
        {typeof placeholder === 'function'
          ? placeholder(locale)
          : placeholder || ''}{' '}
        {required && placeholder ? '*' : ''}
      </span>
    </StyledCheckInputWrapper>
  );
};
