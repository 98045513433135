import { Input } from '../../../Components/UI/Form/Input';

export const defaultVatNumberFormData = { vat: '' };

export const defaultVatNumberFormErrors = { vat: '' };

export const initialVatNumberFormState = {
  formData: defaultVatNumberFormData,
  errors: defaultVatNumberFormErrors,
};

export const setVatNumberFormConfig = [
  {
    Element: Input,
    props: {
      name: 'vat',
      placeholder: (l) => l.placeholders.vat,
      type: 'number',
      max: 9999999999,
      required: true,
    },
  },
];
