import styled from 'styled-components';
import S from '../../variables';

export const StyledColourPickerInput = styled.div`
  position: relative;

  & > .preview {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 16px;
    width: 26px;
    height: 26px;
    border-radius: 3px;
    transition: background-color ${S.trFast};
  }

  input {
    padding-left: 46px;
  }
`;

export const StyledColourPicker = styled.div`
  & > .sketch-picker {
    box-sizing: border-box !important;
    padding: 0 !important;
    box-shadow: none !important;

    .hue-horizontal {
      border-radius: 10px !important;

      & > div {
        width: 16px;
        height: 16px;
        top: 50%;
        transform: translateY(-50%);

        & > div {
          width: 14px !important;
          height: 14px !important;
          border-radius: 50% !important;
          border: 1px solid ${S.strokeGray};
          margin: 0 !important;
          box-shadow: none !important;
        }
      }
    }

    & > div {
      &:nth-child(1) {
        border-radius: 12px;
        overflow: hidden;
      }
    }

    & > .flexbox-fix {
      &:nth-child(3),
      &:nth-child(4) {
        display: none !important;
      }

      &:nth-child(2) {
        padding-top: 16px;

        & > div {
          &:nth-child(1) {
            padding: 0 !important;

            & > div {
              height: 5px !important;
              overflow: visible !important;
            }
          }

          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
`;
