export const CAR_PROPS = {
  string: 'string',
  number: 'number',
  date: 'date',
  check: 'check',
  bool: 'bool',
  array: 'array',
  price: 'price',
  odometer: 'odometer',
};

export const carDatesConfig = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export const priceDatesConfig = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
};

export const carConfig = {
  body: {
    type: 'string',
  },
  engineDisplacementCcm: {
    type: 'number',
    unit: 'centimeter',
  },
  enginePowerKw: {
    type: 'number',
  },
  transmission: {
    type: 'string',
  },
  fuelTypePrimary: {
    type: 'string',
  },
  odometer: {
    type: 'number',
  },
  checkDigit: {},
  validCheckDigit: {},
  manufacturer: {
    //type: 'string',
  },
  plantCountry: {
    //type: 'string',
  },
  productType: {
    type: 'string',
  },
  manufacturerAddress: {
    //type: 'string',
  },
  checkDigitValidity: {
    // type: 'bool',
    // checked: 'valid',
  },
  sequentialNumber: {},
  engineCylinders: {
    type: 'number',
  },
  numberOfDoors: {
    type: 'number',
  },
  engineModel: {
    type: 'string',
  },
  modelYear: {
    type: 'date',
    year: 'numeric',
  },
  plantCity: {
    type: 'string',
  },
  series: {
    type: 'string',
  },
  engineFull: {
    type: 'string',
  },
  numberOfSeats: {
    type: 'number',
  },
  engineCylindersPosition: {
    type: 'string',
  },
  drive: {
    type: 'string',
  },
  lengthMm: {
    type: 'number',
    unit: 'millimeter',
  },
  heightMm: {
    type: 'number',
    unit: 'millimeter',
  },
  widthMm: {
    type: 'number',
    unit: 'millimeter',
  },
  fuelSystem: {
    type: 'string',
  },
  engineTorque: {
    type: 'number',
  },
  wheelbaseMm: {
    type: 'number',
    unit: 'millimeter',
  },
  enginePosition: {
    type: 'string',
  },
  fuelCapacity: {
    //old from vindecode 3.1
    type: 'number',
    unit: 'liter',
  },
  fuelCapacityL: {
    type: 'number',
    unit: 'liter',
  },
  frontBreaks: {
    // old from vindecode 3.1
    type: 'string',
  },
  frontBrakes: {
    type: 'string',
  },
  rearBreaks: {
    // old from vindecode 3.1
    type: 'string',
  },
  rearBrakes: {
    type: 'string',
  },
  valvesPerCylinder: {
    type: 'number',
    unit: 'liter',
  },
  trackFrontMm: {
    type: 'number',
    unit: 'millimeter',
  },
  trackRearMm: {
    type: 'number',
    unit: 'millimeter',
  },
  maxSpeedKmH: {
    // type: 'number',
    // unit: 'kilometer-per-hour',
  },
  abs: {
    type: 'boolean',
  },
  wheelSize: {
    type: 'string',
  },
  acceleration0100KmH: {
    // old from vindecode 3.1
    type: 'number',
    unit: 'second',
  },
  acceleration0100KmHS: {
    type: 'number',
    unit: 'second',
  },
  engineCompressionRatio: {
    type: 'number',
  },
  steeringType: {
    type: 'string',
  },
  engineStroke: {
    // old from vindecode 3.1
    type: 'number',
    unit: 'millimeter',
  },
  engineStrokeMm: {
    // old from vindecode 3.1
    type: 'number',
    unit: 'millimeter',
  },
  engineCylinderBore: {
    // old from vindecode 3.1
    type: 'number',
    unit: 'millimeter',
  },
  engineCylinderBoreMm: {
    type: 'number',
    unit: 'millimeter',
  },
  minimumVolumeOfLuggageTrunk: {
    // old from vindecode 3.1
    type: 'number',
    unit: 'liter',
  },
  minimumTrunkCapacityL: {
    type: 'number',
    unit: 'liter',
  },
  maxWeightKg: {
    type: 'number',
    unit: 'kilogram',
  },
  productionStopped: {
    type: 'date',
    year: 'numeric',
  },
  productionStarted: {
    type: 'date',
    year: 'numeric',
  },
  frontSuspension: {
    type: 'string',
  },
  rearSuspension: {
    type: 'string',
  },
  fuelConsumptionL100kmUrban: {
    // old from vindecode 3.1
    type: 'number',
    unit: 'liter-per-kilometer',
  },
  fuelConsumptionUrbanL100km: {
    type: 'number',
    unit: 'liter-per-kilometer',
  },
  fuelConsumptionL100kmExtraUrban: {
    // old from vindecode 3.1
    type: 'number',
    unit: 'liter-per-kilometer',
  },
  fuelConsumptionExtraUrbanL100km: {
    type: 'number',
    unit: 'liter-per-kilometer',
  },
  fuelConsumptionL100kmCombined: {
    // old from vindecode 3.1
    type: 'number',
    unit: 'liter-per-kilometer',
  },
  fuelConsumptionCombinedL100km: {
    type: 'number',
    unit: 'liter-per-kilometer',
  },
  numberOfGears: {
    type: 'number',
  },
  valveTrain: {
    type: 'string',
  },
  powerSteering: {
    type: 'string',
  },
  maximumVolumeOfLuggageTrunk: {
    // old from vindecode 3.1
    type: 'number',
    unit: 'liter',
  },
  maximumTrunkCapacityL: {
    type: 'number',
    unit: 'liter',
  },
  wheelRimsSize: {
    //type: 'string',
  },
  engineTurbine: {
    type: 'string',
  },
  minimumTurningCircleTurningDiameter: {
    type: 'number',
    unit: 'meter',
  },
  emissionStandard: {
    type: 'string',
  },
  co2Emission: {
    type: 'number',
    unit: 'percent',
  },
  co2EmissionGKm: {
    type: 'number',
    unit: 'gram-per-kilometer',
  },
  dragCoefficient: {
    type: 'number',
  },
  permittedTrailerLoadWithoutBrakesKg: {
    type: 'number',
    unit: 'kilogram',
  },
  widthIncludingMirrorsMm: {
    type: 'number',
    unit: 'millimeter',
  },
  maxRoofLoadKg: {
    type: 'number',
    unit: 'kilogram',
  },
  permittedTowbarDownloadKg: {
    type: 'number',
    unit: 'kilogram',
  },
  rearOverhangMm: {
    type: 'number',
    unit: 'millimeter',
  },
  frontOverhangMm: {
    type: 'number',
    unit: 'millimeter',
  },
  permittedTrailerLoadWithBrakes8Kg: {
    type: 'number',
    unit: 'kilogram',
  },
  engineOilCapacityL: {
    type: 'number',
    unit: 'liter',
  },
  engineCoolantL: {
    type: 'number',
    unit: 'liter',
  },
  engineMaxSpeedRpm: {
    type: 'number',
  },
  wheelbaseArrayMm: {
    type: 'array',
    item: {
      type: 'number',
      unit: 'millimeter',
    },
  },
  wheelSizeArray: {
    type: 'array',
    item: {
      type: 'string',
    },
  },
  wheelRimsSizeArray: {
    // type: 'array',
    // item: {
    //   type: 'string',
    // },
  },
  price: {
    type: 'price',
  },
  created: {
    type: 'date',
    parse: true,
    ...carDatesConfig,
  },
  updated: {
    type: 'date',
    parse: true,
    ...carDatesConfig,
  },
  deleted: {
    //type: 'bool',
  },
  odometerKm: {
    type: 'odometer',
  },
};

const getValueByType = (
  { type, unit, checked, parse, item, ...rest },
  value,
  lang
) => {
  switch (type) {
    case CAR_PROPS.string:
      return value || 'skip';
    case CAR_PROPS.date: {
      if (!value) return 'skip';
      return new Intl.DateTimeFormat(lang, rest).format(
        parse ? Date.parse(value) : value
      );
    }
    case CAR_PROPS.number: {
      if (!value) return 'skip';
      return unit
        ? new Intl.NumberFormat(lang, {
            style: 'unit',
            unit,
            unitDisplay: 'short',
            // notation: 'compact',
          }).format(value)
        : value;
    }
    case CAR_PROPS.bool:
      return checked ? value === checked : !!value;
    case CAR_PROPS.price:
      return value?.length
        ? value
            .map(
              ({ date, price, currency }) =>
                `${new Intl.DateTimeFormat(lang, priceDatesConfig).format(
                  Date.parse(date)
                )}: ${new Intl.NumberFormat(lang, {
                  style: 'currency',
                  currency,
                }).format(price)}`
            )
            .join('\n')
        : 'skip';
    case CAR_PROPS.array: {
      return value.map((v) => getValueByType(item, v, lang)).join('\n');
    }
    case CAR_PROPS.odometer:
      return value?.length
        ? value
            .map(
              ({ date, odometer }) =>
                `${new Intl.DateTimeFormat(lang, priceDatesConfig).format(
                  Date.parse(date)
                )}: ${new Intl.NumberFormat(lang, {
                  style: 'unit',
                  unit: 'kilometer',
                }).format(odometer)}`
            )
            .join('\n')
        : 'skip';
    default:
      return 'skip';
  }
};

export const getCarDetails = (car, lang) => {
  return Object.entries(car).reduce((acc, [key, value]) => {
    const val = getValueByType(carConfig[key] || {}, value, lang);
    if (val !== 'skip') acc.push({ key, value: val });
    return acc;
  }, []);
};
