import styled from 'styled-components';
import S from '../../../UI/variables';
import { StyledOrderBlock } from '../../../Orders/ViewOrder/styled';
import { MaxContainerXl } from '../../../UI/Containers';
import { ReactComponent as TrashIcon } from '../../../../Static/icons/console/actions/delete.svg';

export const StyledSectionWrapper = styled(MaxContainerXl)`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;

  @media (min-width: ${S.tablet}) {
    flex-direction: row;
    gap: 40px;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const StyledServiceListWrapper = styled(StyledOrderBlock)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 20px;

  @media (min-width: ${S.tablet}) {
    padding: 40px;
  }
`;

export const StyledCategoryList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledCategoryListItem = styled.li`
  max-height: ${({ expanded }) => (expanded ? '250px' : '50px')};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
`;

export const StyledCategory = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  background-color: ${S.lightGray};
  border-radius: 6px;
  padding: 10px;
  font-size: 22px;
  font-weight: 500;
  &:after {
    content: '';
    display: inline-block;
    align-self: center;
    width: 10px;
    height: 10px;
    margin: 0 10px;
    border-right: 1px solid ${S.secondGray};
    border-bottom: 1px solid ${S.secondGray};

    transform: rotate(${({ expanded }) => (expanded ? 45 : -45)}deg);
    transition: transform ${S.trFast};
  }
`;

export const StyledCategoryCount = styled.span`
  font-size: 14px;
  padding: 4px;
  border-radius: 4px;
  margin-left: 8px;
  background-color: ${S.purpleLight};
`;

export const StyledListOfServices = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 190px;
  overflow-y: auto;
  margin-top: 12px;
  padding: 0 20px;
`;

export const StyledServiceNameSpan = styled.span``;

export const StyledServiceItem = styled.li`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  max-width: 540px;
  border: 1px solid transparent;
  ${({ selected }) => selected && `border-bottom-color: ${S.purpleLight};`}

  cursor: pointer;

  &:hover ${StyledServiceNameSpan} {
    color: ${S.purpleDark};
  }
`;

export const StyledServiceName = styled.span`
  overflow: hidden;
  width: 100%;
  flex-shrink: 2;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
`;

export const StyledCheck = styled.span`
  color: ${S.purpleDark};
  margin-left: 10px;
  min-width: 12px;
  flex-shrink: 0;
`;

export const StyledTime = styled(StyledCheck)`
  color: ${S.blueDark};
  font-size: 10px;
  min-width: 70px;
`;

export const StyledPrice = styled(StyledCheck)`
  color: ${S.green};
  min-width: 90px;
  text-align: right;
`;

export const StyledResults = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: ${S.tablet}) {
    max-width: 30%;
    min-width: 25%;
  }
`;

export const StyledCarWrapper = styled.div`
  order: 1;
  @media (min-width: ${S.tablet}) {
    order: 0;
  }
`;

export const StyledChosenServices = styled(StyledOrderBlock)``;

export const StyledChosenServicesTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  padding-right: 40px;
`;

export const StyledChosenServicesList = styled.ul`
  max-height: 200px;
  overflow-y: auto;
`;

export const StyledChosenServicesListItem = styled.li`
  height: 24px;
`;

export const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
  margin-left: 10px;
  width: 12px;
  height: 12px;
  fill: ${S.purpleDark};
  &:hover {
    fill: ${S.blueDark};
  }
`;
