import { StyledH2 } from '../../Titles';
import { Flex } from '../../Flex';
import { Button } from '../../Buttons';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { deleteConfigs } from '../../../../API/Configuration';
import { useDispatch, useSelector } from 'react-redux';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { StyledCustomError } from '../../Form/styled';
import { Preloader } from '../../Preloader';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { deletePromoCode } from '../../../../API/PromoCodes';
import { deleteStorePromoCode } from '../../../../Redux/Actions/PromoCodeActions';
import { deleteService } from '../../../../API/Services';
import {
  removeUserAdminServiceItem,
  setUserData,
} from '../../../../Redux/Actions/AuthActions';
import { addPartnerImages, deleteCard, updateUser } from '../../../../API/User';
import { getUserData } from '../../../../Redux/Selectors/AuthSelectors';
import { deleteComment, deleteReview } from '../../../../API/Reviews';
import { deleteList } from '../../../Settings/Moderation/queries';
import { InfoBlock } from '..';
import { deletePartnership } from '../../../../API/Partnership';
import {
  deletePartnersEmployee,
  deleteUserAccount,
  refetchUser,
  removeCar,
  undeleteCar,
} from '../../../queries';

export const ConfirmationDeleteContact = ({ fn, keyContact, id, onClose }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const {
    messages: [deleteContactMessages],
    loading: [deleteContactLoading],
  } = useMessages(['deleteContact']);

  const handleDelete = () => {
    dispatch(
      rx(
        authRx,
        'deleteContact',
        (_, { data: { config } }) => {
          fn && fn(config);
          onClose();
        },
        deleteConfigs,
        {
          key: keyContact,
          id,
        }
      )
    );
  };

  if (deleteContactLoading) {
    return <Preloader />;
  }

  return (
    <>
      <StyledH2>{locale[keyContact]?.title}</StyledH2>
      <div>
        <Flex
          flexDirection={'column'}
          spacer={{ left: '16px', bottom: '16px' }}
          full={true}
        >
          <div style={{ textAlign: 'center' }}>
            {locale[keyContact]?.description}
          </div>
          <div>
            <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
              <div>
                <Button
                  kind={'green'}
                  style={{ width: '100%' }}
                  fn={() => onClose()}
                  text={locale.no}
                />
              </div>
              <div>
                <Button
                  style={{ width: '100%' }}
                  fn={() => handleDelete()}
                  text={locale.yes}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
      {deleteContactMessages.error ? (
        <StyledCustomError>{deleteContactMessages}</StyledCustomError>
      ) : null}
    </>
  );
};

export const ConfirmationDeletePromoCode = ({ id, onClose }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const {
    messages: [deletePromoCodeMessages],
    loading: [deletePromoCodeLoading],
  } = useMessages(['deletePromoCode']);

  const handleDelete = () => {
    dispatch(
      rx(
        authRx,
        'deletePromoCode',
        (_, { data }) => {
          if (data.data) {
            dispatch(deleteStorePromoCode(id));
            onClose();
          }
        },
        deletePromoCode,
        {
          id,
        }
      )
    );
  };

  if (deletePromoCodeLoading) {
    return <Preloader />;
  }
  return (
    <>
      <StyledH2>{locale.promoCode?.title}</StyledH2>
      <div>
        <Flex
          flexDirection={'column'}
          spacer={{ left: '16px', bottom: '16px' }}
          full={true}
        >
          <div style={{ textAlign: 'center' }}>
            {locale.promoCode?.description}
          </div>
          <div>
            <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
              <div>
                <Button
                  kind={'green'}
                  style={{ width: '100%' }}
                  fn={() => onClose()}
                  text={locale.no}
                />
              </div>
              <div>
                <Button
                  style={{ width: '100%' }}
                  fn={() => handleDelete()}
                  text={locale.yes}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
      {deletePromoCodeMessages.error ? (
        <StyledCustomError>{deletePromoCodeMessages}</StyledCustomError>
      ) : null}
    </>
  );
};

export const ConfirmationDeleteService = ({ fnClose, id, onClose }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const {
    loading: [deleteServiceLoading],
  } = useMessages(['deleteService' + id]);

  const handleClose = () => {
    onClose();

    setTimeout(() => {
      fnClose();
    }, 300);
  };

  const handleDelete = () => {
    dispatch(
      rx(
        authRx,
        'deleteService' + id,
        (_, { data }) => {
          if (data?.data) {
            dispatch(removeUserAdminServiceItem(id));
            onClose();
          } else handleClose();
        },
        deleteService,
        {
          id,
        }
      )
    );
  };

  if (deleteServiceLoading) {
    return <Preloader />;
  }

  return (
    <>
      <StyledH2>{locale.service?.title}</StyledH2>
      <div>
        <Flex
          flexDirection={'column'}
          spacer={{ left: '16px', bottom: '16px' }}
          full={true}
        >
          <div style={{ textAlign: 'center' }}>
            {locale.service?.description}
          </div>
          <div>
            <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
              <div>
                <Button
                  kind={'green'}
                  style={{ width: '100%' }}
                  fn={handleClose}
                  text={locale.no}
                />
              </div>
              <div>
                <Button
                  style={{ width: '100%' }}
                  fn={handleDelete}
                  text={locale.yes}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
    </>
  );
};

export const ConfirmationDeleteLocation = ({ onClose }) => {
  const { _id } = useSelector(getUserData);
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const {
    messages: [updateUserDataMessages],
    loading: [updateUserDataLoading],
  } = useMessages(['updateUserData']);

  const handleDelete = () => {
    dispatch(
      rx(
        authRx,
        'updateUserData',
        (dispatch, { data }) => {
          dispatch(setUserData(data.user));
          onClose();
        },
        updateUser,
        {
          id: _id,
          body: {
            coordinates: null,
          },
        }
      )
    );
  };

  if (updateUserDataLoading) {
    return <Preloader />;
  }

  return (
    <>
      <StyledH2>{locale.location?.title}</StyledH2>
      <div>
        <Flex
          flexDirection={'column'}
          spacer={{ left: '16px', bottom: '16px' }}
          full={true}
        >
          <div style={{ textAlign: 'center' }}>
            {locale.location?.description}
          </div>
          <div>
            <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
              <div>
                <Button
                  kind={'green'}
                  style={{ width: '100%' }}
                  fn={() => onClose()}
                  text={locale.no}
                />
              </div>
              <div>
                <Button
                  style={{ width: '100%' }}
                  fn={() => handleDelete()}
                  text={locale.yes}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
      {updateUserDataMessages.error ? (
        <StyledCustomError>{updateUserDataMessages}</StyledCustomError>
      ) : null}
    </>
  );
};

export const ConfirmationDeletePaymentCard = ({ onClose, card }) => {
  const { _id } = useSelector(getUserData);
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const {
    messages: [updateUserDataMessages],
    loading: [updateUserDataLoading],
  } = useMessages(['updateUserData']);

  const handleDelete = () => {
    dispatch(
      rx(
        authRx,
        'updateUserData',
        (dispatch, { data }) => {
          dispatch(setUserData(data.user));
          onClose();
        },
        deleteCard,
        {
          userId: _id,
          cardId: card._id,
        }
      )
    );
  };

  if (updateUserDataLoading) {
    return <Preloader />;
  }

  return (
    <>
      <StyledH2>{locale.paymentCard?.title}</StyledH2>
      <div>
        <Flex
          flexDirection={'column'}
          spacer={{ left: '16px', bottom: '16px' }}
          full={true}
        >
          <div style={{ textAlign: 'center' }}>
            {locale.paymentCard?.description}
          </div>
          <div>
            <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
              <div>
                <Button
                  kind={'green'}
                  style={{ width: '100%' }}
                  fn={() => onClose()}
                  text={locale.no}
                />
              </div>
              <div>
                <Button
                  style={{ width: '100%' }}
                  fn={() => handleDelete()}
                  text={locale.yes}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
      {updateUserDataMessages.error ? (
        <StyledCustomError>{updateUserDataMessages}</StyledCustomError>
      ) : null}
    </>
  );
};

export const ConfirmationDeleteReview = ({ onClose, id, commentId, fn }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const {
    messages: [{ error }],
    loading: [loading],
  } = useMessages([`deleteReview_${id}`, `deleteComment_${commentId}`]);

  const handleDelete = () => {
    commentId
      ? dispatch(
          rx(
            authRx,
            `deleteComment_${commentId}`,
            (dispatch, { data }) => {
              if (data?.review) {
                fn((prevState) => {
                  return prevState.map((r) =>
                    r._id === data.review._id ? data.review : r
                  );
                });

                onClose();
              }
            },
            deleteComment,
            {
              id,
              commentId,
            }
          )
        )
      : dispatch(
          rx(
            authRx,
            `deleteReview_${id}`,
            (_, { data }) => {
              if (data) {
                fn((prevState) => {
                  return prevState.filter((r) => r._id !== id);
                });
                onClose();
              }
            },
            deleteReview,
            {
              id,
            }
          )
        );
  };

  return (
    <>
      <StyledH2>{locale[commentId ? 'comment' : 'review']?.title}</StyledH2>
      <div>
        <Flex
          flexDirection={'column'}
          spacer={{ left: '16px', bottom: '16px' }}
          full={true}
        >
          <div style={{ textAlign: 'center' }}>
            {locale[commentId ? 'comment' : 'review']?.description}
          </div>
          <div>
            <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
              <div>
                <Button
                  kind={'green'}
                  style={{ width: '100%' }}
                  fn={onClose}
                  text={locale.no}
                  disabled={loading}
                />
              </div>
              <div>
                <Button
                  style={{ width: '100%' }}
                  fn={handleDelete}
                  text={locale.yes}
                  disabled={loading}
                  loading={loading}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
      {error ? <StyledCustomError>{error}</StyledCustomError> : null}
    </>
  );
};

export const ConfirmationChangeActiveStatusUser = ({
  onClose,
  id,
  active,
  fn,
}) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const {
    messages: [deactivateUserMessages],
    loading: [deactivateUserLoading],
  } = useMessages(['deactivateUser']);

  const handleDeactivateUser = () => {
    dispatch(
      rx(
        authRx,
        'deactivateUser',
        (_, { data: { user } }) => {
          if (user) {
            fn && fn(user);
            onClose();
          }
        },
        updateUser,
        {
          id,
          body: {
            active,
          },
        }
      )
    );
  };

  if (deactivateUserLoading) {
    return <Preloader />;
  }

  return (
    <>
      <StyledH2>{locale['deactivateUser']?.title}</StyledH2>
      <div>
        <Flex
          flexDirection={'column'}
          spacer={{ left: '16px', bottom: '16px' }}
          full={true}
        >
          <div style={{ textAlign: 'center' }}>
            {locale['deactivateUser']?.description}
          </div>
          <div>
            <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
              <div>
                <Button
                  kind={'green'}
                  style={{ width: '100%' }}
                  fn={() => onClose()}
                  text={locale.no}
                />
              </div>
              <div>
                <Button
                  style={{ width: '100%' }}
                  fn={handleDeactivateUser}
                  text={locale.yes}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
      {deactivateUserMessages.error ? (
        <StyledCustomError>{deactivateUserMessages.error}</StyledCustomError>
      ) : null}
    </>
  );
};

export const ConfirmationDeleteSubscription = ({ onClose, id, fn }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const {
    messages: [deleteSubscriptionMessages],
    loading: [deleteSubscriptionLoading],
  } = useMessages(['deleteSubscription']);

  const handleDeactivateUser = () => {
    dispatch(
      rx(
        authRx,
        'deleteSubscription',
        (_, { data }) => {
          if (data) {
            fn && fn((prevState) => prevState.filter(({ _id }) => _id !== id));
            onClose();
          }
        },
        deletePartnership,
        {
          id,
        }
      )
    );
  };

  if (deleteSubscriptionLoading) {
    return <Preloader />;
  }

  return (
    <>
      <StyledH2>{locale['deleteSubscription']?.title}</StyledH2>
      <div>
        <Flex
          flexDirection={'column'}
          spacer={{ left: '16px', bottom: '16px' }}
          full={true}
        >
          <div>
            <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
              <div>
                <Button
                  kind={'green'}
                  style={{ width: '100%' }}
                  fn={() => onClose()}
                  text={locale.no}
                />
              </div>
              <div>
                <Button
                  style={{ width: '100%' }}
                  fn={handleDeactivateUser}
                  text={locale.yes}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
      {deleteSubscriptionMessages.error ? (
        <StyledCustomError>
          {deleteSubscriptionMessages.error}
        </StyledCustomError>
      ) : null}
    </>
  );
};

export const ConfirmationDeleteModerationList = ({ onClose, listData, fn }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const {
    messages: [deleteListMessage],
    loading: [loadingDeleteList],
  } = useMessages(['deleteModerationList']);

  const handleDelete = () => {
    deleteList(dispatch, listData.Id, (_, { data }) => {
      fn(data);
      data?.data && onClose();
    });
  };

  if (loadingDeleteList) {
    return <Preloader />;
  }

  return (
    <>
      <StyledH2>
        {locale.deleteModerationList.title + ' ' + listData.Name}
      </StyledH2>
      <div>
        <Flex
          flexDirection={'column'}
          spacer={{ left: '16px', bottom: '16px' }}
          full={true}
        >
          <div>
            <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
              <div>
                <Button
                  kind={'green'}
                  style={{ width: '100%' }}
                  fn={() => onClose()}
                  text={locale.no}
                />
              </div>
              <div>
                <Button
                  style={{ width: '100%' }}
                  fn={() => handleDelete()}
                  text={locale.yes}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
      <InfoBlock
        message={deleteListMessage.error || deleteListMessage.message}
        isError={deleteListMessage.error}
      />
    </>
  );
};

export const ConfirmationDeleteImagePartner = ({ onClose, formData }) => {
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const [locale] = useLocale(L);

  const {
    messages: [removePartnerImagesMessage],
    loading: [removePartnerImagesLoading],
  } = useMessages(['removePartnerImages']);

  const handleDeactivateUser = () => {
    dispatch(
      rx(
        authRx,
        'removePartnerImages',
        (_, { data }) => {
          if (data?.user?.images) {
            dispatch(setUserData({ ...userData, images: data.user.images }));
            onClose();
          }
        },
        addPartnerImages,
        {
          id: userData._id,
          body: formData,
        }
      )
    );
  };

  if (removePartnerImagesLoading) {
    return <Preloader />;
  }

  return (
    <>
      <StyledH2>{locale['deletePartnerImages']?.title}</StyledH2>
      <div>
        <Flex
          flexDirection={'column'}
          spacer={{ left: '16px', bottom: '16px' }}
          full={true}
        >
          <div>
            <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
              <div>
                <Button
                  kind={'green'}
                  style={{ width: '100%' }}
                  fn={() => onClose()}
                  text={locale.no}
                />
              </div>
              <div>
                <Button
                  style={{ width: '100%' }}
                  fn={handleDeactivateUser}
                  text={locale.yes}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
      {removePartnerImagesMessage.error ? (
        <StyledCustomError>
          {removePartnerImagesMessage.error}
        </StyledCustomError>
      ) : null}
    </>
  );
};

export const ConfirmationDeleteUser = ({ onClose, userId, isEmployee, fn }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const {
    messages: [deletedUserMessage],
    loading: [deleteUserLoading],
  } = useMessages([`deleteUser/${userId}`]);

  const handleDelete = () => {
    isEmployee
      ? deletePartnersEmployee(dispatch, userId, (...args) => {
          fn(...args);
          onClose();
        })
      : deleteUserAccount(dispatch, userId, (...args) => {
          fn(...args);
          onClose();
        });
  };

  const texts = locale[isEmployee ? 'deleteEmployee' : 'deleteUser'];

  return (
    <>
      <StyledH2>{texts.title}</StyledH2>
      <div>
        <Flex
          flexDirection={'column'}
          spacer={{ left: '16px', bottom: '16px' }}
          full={true}
        >
          <div style={{ textAlign: 'center' }}>{texts.description}</div>
          <div>
            <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
              <div>
                <Button
                  kind={'green'}
                  style={{ width: '100%' }}
                  fn={onClose}
                  text={locale.no}
                  disabled={deleteUserLoading}
                />
              </div>
              <div>
                <Button
                  style={{ width: '100%' }}
                  fn={() => handleDelete()}
                  text={locale.yes}
                  disabled={deleteUserLoading}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
      {deletedUserMessage.error ? (
        <StyledCustomError>{deletedUserMessage.error}</StyledCustomError>
      ) : null}
    </>
  );
};

export const ConfirmRestoreCarModal = ({ onClose, carId }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);
  const { _id: userId } = useSelector(getUserData);

  const {
    messages: [{ error }],
    loading: [loading],
  } = useMessages([`restoreCar${carId}`]);

  const handleRestore = () => {
    undeleteCar(dispatch, carId, async ({ car }) => {
      if (car) {
        refetchUser(dispatch, userId);
        onClose();
      }
    });
  };

  const texts = locale.restoreCar;

  return (
    <>
      <StyledH2>{texts.title}</StyledH2>
      <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
        <div>
          <Button
            kind={'green'}
            style={{ width: '100%' }}
            fn={onClose}
            text={locale.no}
            disabled={loading}
          />
        </div>
        <div>
          <Button
            style={{ width: '100%' }}
            fn={handleRestore}
            text={locale.yes}
            disabled={loading}
            loading={loading}
          />
        </div>
      </Flex>
      {error ? <StyledCustomError>{error}</StyledCustomError> : null}
    </>
  );
};

export const ConfirmDeleteCarModal = ({ onClose, carId }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);
  const { _id: userId } = useSelector(getUserData);

  const {
    messages: [{ error }],
    loading: [loading],
  } = useMessages([`deleteCar${carId}`]);

  const handleRestore = () => {
    removeCar(dispatch, carId, ({ car }) => {
      if (car) {
        refetchUser(dispatch, userId);
        onClose();
      }
    });
  };

  const texts = locale.deleteCar;

  return (
    <>
      <StyledH2>{texts.title}</StyledH2>
      <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
        <div>
          <Button
            kind={'green'}
            style={{ width: '100%' }}
            fn={onClose}
            text={locale.no}
            disabled={loading}
          />
        </div>
        <div>
          <Button
            style={{ width: '100%' }}
            fn={handleRestore}
            text={locale.yes}
            disabled={loading}
            loading={loading}
          />
        </div>
      </Flex>
      {error ? <StyledCustomError>{error}</StyledCustomError> : null}
    </>
  );
};
