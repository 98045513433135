import {
  StyledErrorsWrapper,
  StyledInputName,
  StyledTextarea,
} from '../styled';
import PropTypes from 'prop-types';

export const Textarea = ({
  name,
  value,
  fn = () => null,
  errors = '',
  placeholder = '',
  label,
  locale,
  ...props
}) => {
  const handleInput = (e) => {
    fn(e);
  };
  return (
    <>
      {label ? (
        typeof label === 'function' ? (
          <StyledInputName>
            {label(locale)}
            {props.required && '*'}
          </StyledInputName>
        ) : (
          <StyledInputName>
            {label}
            {props.required && '*'}
          </StyledInputName>
        )
      ) : null}
      <StyledTextarea
        as="div"
        className={errors ? 'has-error' : ''}
        readOnly={props.readOnly}
      >
        <textarea
          {...props}
          name={name}
          value={value}
          onInput={handleInput}
          placeholder={`${
            typeof placeholder === 'function'
              ? placeholder(locale)
              : placeholder
          } ${props.required ? '*' : ''}`}
        />
      </StyledTextarea>
      <StyledErrorsWrapper errors={errors}>
        <span>{errors}</span>
      </StyledErrorsWrapper>
    </>
  );
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  fn: PropTypes.func,
};
