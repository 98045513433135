import { cloneElement } from 'react';
import { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { StyledTooltip, StyledTriangle } from './styled';

export const useTooltip = ({ text, showOnOverflow = false, delay = 500 }) => {
  const [show, setShow] = useState(false);
  const [shown, setShown] = useState(false);

  const ref = useRef(null);
  const tooltipRef = useRef(null);
  const triangleRef = useRef(null);

  const timeoutRef = useRef(null);

  const onMouseEnter = useCallback(() => {
    clearTimeout(timeoutRef.current);
    if (
      (ref.current && ref.current.scrollWidth > ref.current.clientWidth) ||
      !showOnOverflow
    ) {
      setShow(true);
      timeoutRef.current = setTimeout(() => setShown(true), 0);
    }
  }, [showOnOverflow, setShow, setShown]);

  const onMouseLeave = useCallback(() => {
    clearTimeout(timeoutRef.current);
    setShown(false);
    timeoutRef.current = setTimeout(() => setShow(false), 0);
  }, [setShow]);

  useEffect(() => {
    if (ref.current && tooltipRef.current) {
      const { x, y, width } = ref.current.getBoundingClientRect();
      const { width: wd, height: ht } =
        tooltipRef.current.getBoundingClientRect();

      const centerX = x - wd / 2 + width / 2;
      const safeX = Math.max(
        Math.min(centerX, window.innerWidth - wd - 20),
        20
      );

      tooltipRef.current.style.transform = `translate(${safeX}px, ${
        y - ht - 4
      }px)`;
      triangleRef.current.style.left = centerX - safeX + wd / 2 + 'px';
    }
  }, [shown]);

  const tooltip = useMemo(
    () =>
      show &&
      text &&
      createPortal(
        <StyledTooltip ref={tooltipRef} show={shown} delay={delay} key={text}>
          <StyledTriangle ref={triangleRef}></StyledTriangle>
          <span>{text}</span>
        </StyledTooltip>,
        document.body
      ),
    [show, shown, text, delay]
  );

  return {
    tooltip,
    setShow,
    show,
    tooltipProps: { onMouseEnter, onMouseLeave, ref },
  };
};

export const WithTooltip = ({ children, text, showOnOverflow, delay }) => {
  const { tooltip, tooltipProps } = useTooltip({ text, showOnOverflow, delay });
  return (
    <>
      {tooltip}
      {typeof children === 'function'
        ? children(tooltipProps)
        : cloneElement(children, tooltipProps)}
    </>
  );
};
