import { StyledH2 } from '../../Titles';
import {
  StyledCustomError,
  StyledFormButtonBlock,
  StyledFormGroup,
} from '../../Form/styled';
import { Input } from '../../Form/Input';
import { Flex } from '../../Flex';
import {
  StyledCardFormWrapper,
  StyledPaymentCardsWrapper,
  StyledPaymentInformationIcon,
  StyledPaymentVariant,
  StyledPaymentVariantIcon,
  StyledPaymentVariantName,
  StyledPaymentVariantOtherIcon,
} from './styled';
import { Button } from '../../Buttons';
import { useState } from 'react';
import { validation } from '../../../../Utils/validation';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { InputMonthYearCard } from '../../Form/InputMonthYearCard';
import { useDispatch, useSelector } from 'react-redux';
import { addCard, payOtherMethod } from '../../../queries';
import { getUserData } from '../../../../Redux/Selectors/AuthSelectors';
import { InputNumberCard } from '../../Form/InputNumberCard';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import L from './locale.json';
import { RadioInput } from '../../Form/Radio';
import { ReactComponent as VisaImg } from '../../../../Static/icons/console/payment/visa.svg';
import { ReactComponent as MasterCardImg } from '../../../../Static/icons/console/payment/mastercard.svg';
import { ReactComponent as PlusIcon } from '../../../../Static/icons/console/add.svg';
import { ReactComponent as SuccessPaymentIcon } from '../../../../Static/icons/console/statuses/payment-success.svg';
import { ReactComponent as FailedPaymentIcon } from '../../../../Static/icons/console/statuses/payment-failed.svg';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { paySubscription } from '../../../../API/User';
import { setUserData } from '../../../../Redux/Actions/AuthActions';
import { closeModal, openModal } from '../../../../Redux/Actions/ModalActions';

export const PaymentCardModal = ({ onClose }) => {
  const { _id } = useSelector(getUserData);
  const dispatch = useDispatch();
  const [, lang] = useLocale();
  const [locale] = useLocale(L);
  const [inputs, setInputs] = useState({
    ccNumber: '',
    monthYear: '',
    holderName: '',
    cvc: '',
  });

  const [errors, setErrors] = useState({
    ccNumber: null,
    monthYear: null,
    holderName: null,
    cvc: null,
  });

  const {
    messages: [addCardMessages],
    loading: [addCardLoading],
  } = useMessages(['addCard']);

  const handleAddCard = (e) => {
    e.preventDefault();
    const [month, year] = inputs.monthYear.split('/');

    const dataCard = {
      cc_number: inputs.ccNumber,
      month,
      year: `20${year}`,
      holder_name: inputs.holderName,
      cvc: inputs.cvc,
    };

    addCard(dispatch, _id, dataCard, onClose);
  };

  const handleChangeInputs = (event) => {
    const { value, error, name, v } = validation(event.target, lang);

    setInputs((prevState) => ({
      ...prevState,
      [name]: name === 'monthYear' ? v : value,
    }));
    setErrors((prevState) => ({ ...prevState, [name]: error }));
  };

  return (
    <>
      <StyledH2>{locale.title}</StyledH2>
      <form noValidate={true} onSubmit={handleAddCard}>
        <StyledCardFormWrapper>
          <StyledFormGroup>
            <Input
              name={'holderName'}
              value={inputs.holderName}
              errors={errors.holderName}
              required={true}
              placeholder={locale.placeholders.holderName}
              fn={handleChangeInputs}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <InputNumberCard
              name={'ccNumber'}
              value={inputs.ccNumber}
              errors={errors.ccNumber}
              required={true}
              placeholder={'0000 0000 0000 0000'}
              fn={handleChangeInputs}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <Flex
              full={true}
              alignItems={'flex-start'}
              spacer={{ left: '8px', bottom: '8px' }}
            >
              <div>
                <InputMonthYearCard
                  name={'monthYear'}
                  value={inputs.monthYear}
                  errors={errors.monthYear}
                  required={true}
                  placeholder={'00/00'}
                  fn={handleChangeInputs}
                />
              </div>
              <div>
                <Input
                  name={'cvc'}
                  type={'number'}
                  value={inputs.cvc}
                  errors={errors.cvc}
                  minLength={3}
                  maxLength={3}
                  required={true}
                  placeholder={'CCV'}
                  fn={handleChangeInputs}
                />
              </div>
            </Flex>
          </StyledFormGroup>
        </StyledCardFormWrapper>
        {addCardMessages.error ? (
          <StyledCustomError>{addCardMessages.error}</StyledCustomError>
        ) : null}
        <StyledFormButtonBlock>
          <Button
            kind={'green'}
            text={locale.submitBtn}
            type={'submit'}
            loading={addCardLoading}
            disabled={Object.values(errors).some(
              (err) => err === null || err.length
            )}
          />
        </StyledFormButtonBlock>
      </form>
    </>
  );
};

export const PaymentModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { cards, activeCard, _id } = useSelector(getUserData);

  const {
    loading: [paySubscriptionLoading],
  } = useMessages(['paySubscription']);

  const [selectCard, setSelectCard] = useState(activeCard || 'other');

  const [locale] = useLocale(L);

  const handlePay = () => {
    if (selectCard === 'other') {
      payOtherMethod(dispatch, _id, onClose);
    } else {
      dispatch(
        rx(
          authRx,
          'paySubscription',
          (_, { data }) => {
            if (data?.user) {
              dispatch(setUserData(data.user));
            }

            dispatch(closeModal());

            setTimeout(() => {
              dispatch(
                openModal({
                  Comp: 'informationPaymentModal',
                  data: {
                    data,
                  },
                })
              );
            }, 300);
          },
          paySubscription,
          {
            id: _id,
            body: {
              cardId: selectCard,
            },
          }
        )
      );
    }
  };

  return (
    <form>
      <StyledH2>{locale.titlePaymentVariants}</StyledH2>
      <StyledPaymentCardsWrapper>
        <Flex
          spacer={{ left: '18px', bottom: '18px' }}
          flexDirection={'column'}
          full={true}
        >
          {cards?.length
            ? cards.map((card) => {
                return (
                  <div key={card._id}>
                    <StyledPaymentVariant>
                      <StyledPaymentVariantIcon>
                        {card.type === 'visa' ? <VisaImg /> : <MasterCardImg />}
                      </StyledPaymentVariantIcon>
                      <StyledPaymentVariantName>
                        {locale.typesCards[card.type]} {card.last_four_digits}
                      </StyledPaymentVariantName>
                      <RadioInput
                        as={'span'}
                        size={'small'}
                        name={'card'}
                        value={card._id}
                        fn={() => setSelectCard(card._id)}
                        checked={card._id === selectCard}
                      />
                    </StyledPaymentVariant>
                  </div>
                );
              })
            : null}
          <div>
            <StyledPaymentVariant>
              <StyledPaymentVariantOtherIcon as={PlusIcon} />
              <StyledPaymentVariantName>
                {locale.otherCard}
              </StyledPaymentVariantName>
              <RadioInput
                as={'span'}
                size={'small'}
                name={'card'}
                value={'other'}
                fn={() => setSelectCard('other')}
                checked={selectCard === 'other'}
              />
            </StyledPaymentVariant>
          </div>
        </Flex>
      </StyledPaymentCardsWrapper>
      <StyledFormButtonBlock>
        <Button
          kind={'green'}
          text={locale.payBtn}
          fn={handlePay}
          loading={paySubscriptionLoading}
        />
      </StyledFormButtonBlock>
    </form>
  );
};

export const InformationPaymentModal = ({ data, onClose }) => {
  const dispatch = useDispatch();

  const [locale] = useLocale(L);

  const handleChooseAnotherCard = () => {
    onClose();

    setTimeout(() => {
      dispatch(openModal({ Comp: 'paymentModal' }));
    }, 300);
  };

  if (data.status === 'failure') {
    return (
      <>
        <StyledPaymentInformationIcon as={FailedPaymentIcon} />
        <StyledH2>{locale.titlePaymentFailed}</StyledH2>
        <StyledFormButtonBlock>
          <Button
            text={locale.chooseAnotherCardBtn}
            full={true}
            fn={handleChooseAnotherCard}
          />
        </StyledFormButtonBlock>
      </>
    );
  } else {
    return (
      <>
        <StyledPaymentInformationIcon as={SuccessPaymentIcon} />
        <StyledH2>{locale.titlePaymentSuccess}</StyledH2>
      </>
    );
  }
};
