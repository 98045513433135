import styled, { css } from 'styled-components';
import S from '../variables';

export const StyledPopUp = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  max-width: 340px;

  bottom: 40px;
  padding: 12px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: ${S.calendarShade};
  & > svg {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    margin-right: 16px;
  }
  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 16px;
  }
  & > button {
    flex-shrink: 0;
  }
  transition: transform ${S.trSlow}, opacity ${S.trSlow},
    z-index 0s linear ${({ show }) => (show ? 0 : 1)}s;
  ${({ show }) =>
    show
      ? css`
          transform: translateY(0px);
          opacity: 1;
          z-index: 1001;
        `
      : css`
          z-index: -1;
          transform: translateY(40px);
          opacity: 0;
        `}

  @media screen and (min-width: ${S.tablet}) {
    max-width: 500px;
  }
`;
