import styled from 'styled-components';
import S from '../../UI/variables';

export const StyledSettingBoost = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px 8px 16px;
  border-radius: 10px;
  background-color: ${S.lightGray};
  width: 100%;
`;

export const StyledSettingBoostName = styled.div`
  font-weight: 600;
`;
