import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';

const setNewOrderData = createAction(types.SET_NEW_ORDER);

const setNewOrderStep = createAction(types.SET_NEW_ORDER_STEP);

const resetNewOrderData = createAction(types.RESET_NEW_ORDER);

const addOrderServiceItem = createAction(types.ADD_SERVICE_NEW_ORDER);

const removeOrderServiceItem = createAction(types.REMOVE_SERVICE_NEW_ORDER);

export {
  setNewOrderData,
  resetNewOrderData,
  addOrderServiceItem,
  removeOrderServiceItem,
  setNewOrderStep,
};
