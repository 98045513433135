import styled, { keyframes } from 'styled-components';
import S from '../variables';

const rotating = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledPreloader = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${S.white};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 20px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
  }
`;

export const StyledPreloaderSpin = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  font-size: 22px;
  box-sizing: border-box;
  border-top: 3px dotted ${S.purpleDark};
  border-radius: 50%;
  animation: ${rotating} 2s linear infinite;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    border-top: 3px dotted ${S.greenDark};
    transform: translate(-50%, -50%) rotate(120deg);
  }

  ::after {
    border-top: 3px dotted ${S.greenLight};
    transform: translate(-50%, -50%) rotate(240deg);
  }
`;
