import styled from 'styled-components';
import S from '../../UI/variables';
import { rgba } from 'polished';

export const StyledBannersWrapper = styled.div`
  position: relative;
  padding: 0 88px;

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: color ${S.trFast};
    color: ${S.textPrimary};

    &:hover {
      color: ${S.purpleDark};
    }

    svg {
      width: 26px;
      height: 26px;
      fill: currentColor;
    }

    &:nth-child(1) {
      left: 0;
    }

    &:nth-child(2) {
      right: 0;
    }
  }
`;

export const StyledHuePickerWrapper = styled.div`
  & > div {
    position: relative;
    width: 100%;
    height: 5px;
  }
  background: #f00;
  margin-top: 16px;
  width: 100%;
  border-radius: 8px;
  padding: 0 5px 0 7px;
`;

export const StyledHueWrapper = styled.div`
  margin: 0 auto;
  width: 200px;
  @media screen and (min-width: ${S.tablet}) {
    width: 379px;
  }
`;

export const StyledHuePickerHandle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #d2d6e4;
  background: #fff;
  transform: translate(-50%, -4px);
`;

export const StyledFlexBanners = styled.div`
  display: flex;
  margin-left: -12px;
  margin-bottom: -12px;
  flex-wrap: wrap;

  & > div {
    padding-left: 12px;
    margin-bottom: 12px;
    width: calc(100% / 3);
  }
`;

export const StyledFlexBannerLabel = styled.div`
  border-radius: 15px;
  cursor: pointer;
  transition: transform ${S.trFast}, box-shadow ${S.trFast};
  transform-origin: center center;
  transform: ${({ active }) => (active ? 'scale(0.9)' : 'none')};
  box-shadow: ${({ active }) => (active ? S.mainShade : 'none')};

  &:hover {
    transform: scale(0.9);
    box-shadow: ${S.mainShade};
  }

  & > input {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }
`;

export const StyledPeriodSecond = styled.label`
  display: flex;
  position: relative;
  border-radius: 12px;
  background-color: ${({ active }) => (active ? S.purple : S.lightGray)};
  font-size: 14px;
  transition: background-color ${S.trFast};
  cursor: pointer;
  aspect-ratio: 1;

  &:hover {
    background-color: ${({ active }) =>
      active ? S.purple : rgba(S.purple, 0.4)};
  }

  & > span {
    margin: auto;
    text-align: center;
    padding: 12px;
  }

  & > input {
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }
`;
