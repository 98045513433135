export const filterServiceForRequest = (services) => {
  return services.reduce(
    (acc, s) => [
      ...acc,
      {
        service: { _id: s.service._id },
        price: Number(s.price),
        time: Number(s.time),
      },
    ],
    []
  );
};
