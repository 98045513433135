import styled, { css } from 'styled-components';
import S from '../variables';

export const StyledSelectedTile = styled.div`
  height: 100%;
  padding: 36px 16px 40px;
  background-color: ${S.lightGray};
  border-radius: 10px;

  ${({ isAction }) =>
    isAction &&
    css`
      cursor: pointer;
      transition: background-color ${S.trFast}, color ${S.trFast};

      &:hover {
        background-color: ${S.yellow};
      }
    `}

  & > div {
    &:first-child {
      font-weight: 600;
      color: ${S.textPrimary};
    }
    &:not(:first-child) {
      margin-top: 10px;
      color: ${S.textSecondary};
    }
  }
`;

export const StyledBenefits = styled.ul`
  margin-top: 16px;
  & > li {
    & > svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;
