import { Flex } from '../../../UI/Flex';
import { Button } from '../../../UI/Buttons';
import { useState } from 'react';
import L from './locale.json';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { StyledH2 } from '../../../UI/Titles';
import { fieldDataCollection } from '../../../../Utils/Helpers/stypByStep';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { useDispatch } from 'react-redux';
import { MaxContainer } from '../../../UI/Containers';
import { StyledFormGroup } from '../../../UI/Form/styled';
import { Input } from '../../../UI/Form/Input';
import { StyledFormGroupTitle } from './styled';
import { Textarea } from '../../../UI/Form/Textarea';

const initialState = {
  name: {
    en: '',
    lt: '',
  },
  description: {
    en: '',
    lt: '',
  },
  benefits: {
    en: '',
    lt: '',
  },
};

const initialStateErrors = {
  name: null,
  description: null,
  benefits: null,
};

const xform = ({ benefits, ...rest }) => {
  const split = [
    { lang: 'lt', opts: benefits.lt.split(/\n+/g).filter(Boolean) },
    { lang: 'en', opts: benefits.en.split(/\n+/g).filter(Boolean) },
  ].sort(({ opts: a }, { opts: b }) => b.length - a.length);
  return {
    ...rest,
    benefits: split[0].opts.map((benefit, i) => ({
      [split[0].lang]: benefit,
      [split[1].lang]: split[1].opts[i],
    })),
  };
};

export const ConfigForm = ({ fn, config, onClose, submit }) => {
  const dispatch = useDispatch();
  const [locale, lang] = useLocale(L);

  const [inputs, setInputs] = useState(() =>
    config
      ? {
          name: config.name,
          description: config.description,
          benefits: config.benefits.reduce((acc, { en, lt }) => ({
            en: acc.en + `\n${en}`,
            lt: acc.lt + `\n${lt}`,
          })),
        }
      : initialState
  );
  const [errors, setErrors] = useState(initialStateErrors);

  const handleChange = (key) => (event) => {
    const { error, name, value } = fieldDataCollection(event, lang);
    setInputs((prev) => ({ ...prev, [key]: { ...prev[key], [name]: value } }));
    setErrors((prev) => ({ ...prev, [key]: error }));
  };

  const handleResponse = (_, { data }) => {
    if (data?.data) {
      fn(data);
      onClose();
    }
    if (data?.conflict && data.conflict in initialStateErrors) {
      setErrors((prev) => ({ ...prev, [data.conflict]: data.message }));
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    submit(dispatch, xform(inputs), handleResponse);
  };

  const {
    loading: [loadingEdit],
  } = useMessages(['updateConfig']);

  return (
    <Flex
      alignItems={'stretch'}
      flexDirection={'column'}
      spacer={{ bottom: '24px' }}
    >
      <div>
        <StyledH2>{locale.title}</StyledH2>
      </div>
      <div>
        <form>
          <MaxContainer>
            <StyledFormGroupTitle>{locale.name.title}</StyledFormGroupTitle>
            <StyledFormGroup>
              <Input
                name={'en'}
                value={inputs.name.en}
                fn={handleChange('name')}
                errors={errors.name}
                placeholder={locale.name.placeholder}
                label={locale.labelEn}
                required={true}
              />
            </StyledFormGroup>
            <StyledFormGroup>
              <Input
                name={'lt'}
                value={inputs.name.lt}
                fn={handleChange('name')}
                errors={errors.name}
                placeholder={locale.name.placeholder}
                label={locale.labelLt}
                required={true}
              />
            </StyledFormGroup>
            <StyledFormGroupTitle>
              {locale.description.title}
            </StyledFormGroupTitle>
            <StyledFormGroup>
              <Textarea
                name={'en'}
                value={inputs.description.en}
                fn={handleChange('description')}
                errors={errors.description}
                placeholder={locale.description.placeholder}
                label={locale.labelEn}
                required={true}
              />
            </StyledFormGroup>
            <StyledFormGroup>
              <Textarea
                name={'lt'}
                value={inputs.description.lt}
                fn={handleChange('description')}
                errors={errors.description}
                placeholder={locale.description.placeholder}
                label={locale.labelLt}
                required={true}
              />
            </StyledFormGroup>
            <StyledFormGroupTitle>
              {locale.description.title}
            </StyledFormGroupTitle>
            <StyledFormGroup>
              <Textarea
                name={'en'}
                value={inputs.benefits.en}
                fn={handleChange('benefits')}
                errors={errors.description}
                placeholder={locale.description.placeholder}
                label={locale.labelEn}
              />
            </StyledFormGroup>
            <StyledFormGroup>
              <Textarea
                name={'lt'}
                value={inputs.benefits.lt}
                fn={handleChange('benefits')}
                errors={errors.benefits}
                placeholder={locale.description.placeholder}
                label={locale.labelLt}
              />
            </StyledFormGroup>
          </MaxContainer>
        </form>
      </div>
      <div>
        <Flex
          justifyContent={'center'}
          spacer={{ left: '12px', bottom: '12px' }}
        >
          <div>
            <Button
              kind={'green'}
              disabled={loadingEdit || Object.values(errors).some(Boolean)}
              loading={loadingEdit}
              text={locale.save}
              fn={handleSubmitForm}
            />
          </div>
        </Flex>
      </div>
    </Flex>
  );
};
