import { createReducer } from '@reduxjs/toolkit';

import {
  resetNewOrderData,
  setNewOrderData,
  addOrderServiceItem,
  removeOrderServiceItem,
  setNewOrderStep,
} from '../../Actions/CreateOrderActions';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'newOrder',
  storage,
};

export const defaultOrderState = {
  client: null,
  phone: null,
  email: null,
  employees: [],
  start: new Date().toISOString(),
  number: null,
  vin: null,
  year: null,
  services: [],
  timeShift: 0,
  companyComment: null,
  companyImages: [],
  step: 0,
  errors: {
    client: '',
    phone: '',
    email: '',
    executor: '',
    date: '',
  },
};

const newOrderReducer = createReducer(defaultOrderState, (builder) => {
  builder
    .addCase(setNewOrderStep, (state, action) => {
      const { step, errors = {} } = action.payload;
      return {
        ...state,
        errors: { ...state.errors, ...errors },
        step,
      };
    })
    .addCase(setNewOrderData, (state, action) => {
      return {
        ...state,
        errors: { ...state.errors, ...(action.payload.errors || {}) },
        ...action.payload,
      };
    })
    .addCase(resetNewOrderData, () => {
      return defaultOrderState;
    })
    .addCase(addOrderServiceItem, (state, action) => {
      return {
        ...state,
        services: [...state.services, action.payload],
      };
    })
    .addCase(removeOrderServiceItem, (state, action) => {
      const { _id } = action.payload.service;
      const newList = state.services?.filter(
        (service) => service.service._id !== _id
      );
      return {
        ...state,
        services: newList,
      };
    });
});

const newOrder = persistReducer(persistConfig, newOrderReducer);

export default newOrder;
