import {
  getModerationLists,
  addModerationList,
  updateModerationList,
  deleteModerationList,
  getModerationListTerms,
  updateModerationListTerms,
} from '../../../API/Moderation';
import { rx } from '../../../Redux/Actions';
import { authRx } from '../../../Redux/Actions/AuthActions/thunk';

export const getLists = (dispatch, fn) =>
  dispatch(rx(authRx, 'getModerationLists', fn, getModerationLists));

export const addList = (dispatch, body, fn) =>
  dispatch(rx(authRx, 'addModerationList', fn, addModerationList, { body }));

export const updateList = (dispatch, id, body, fn) =>
  dispatch(
    rx(authRx, 'updateModerationList', fn, updateModerationList, { body, id })
  );

export const deleteList = (dispatch, id, fn) =>
  dispatch(
    rx(authRx, 'deleteModerationList', fn, deleteModerationList, { id })
  );

export const getListTerms = (dispatch, id, lang, fn) =>
  dispatch(
    rx(authRx, 'getModerationListTerms', fn, getModerationListTerms, {
      id,
      controls: { params: { lang } },
    })
  );

export const updatetListTerms = (dispatch, id, body, lang, fn) =>
  dispatch(
    rx(authRx, 'updateModerationListTerms', fn, updateModerationListTerms, {
      id,
      body,
      controls: { params: { lang } },
    })
  );
