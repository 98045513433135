import { setDateObject, setDateOfMonth } from '../../Utils/Dates';
import { defaultSchedule } from '../../Configs/constants';
import {
  START_TIME,
  END_TIME,
  DURATION_DAY,
} from '../../Configs/consoleConfig';
import { getDaysInMonth } from '../../Utils/Dates';
import { clearDate } from '../../Utils/Dates';

import { statusIcons } from '../UI/StatusIcons';

export const getStatusIcon = ({ start, end, ended }) => {
  let status = ended ? 'settled' : 'done';
  if (!ended && Date.parse(start) < Date.now()) {
    if (Date.parse(end) > Date.now()) status = 'pending';
    else status = 'rejected';
  }
  return statusIcons[status] || statusIcons.done;
};

export const getEmployeeDay = (
  date,
  data = [],
  id,
  schedule = defaultSchedule[0]
) => {
  const dummyDate = new Date(date);
  //const startWorkDate = dummyDate.setHours(START_TIME);
  const startDayString = schedule.length
    ? new Date(
        new Date(date).setHours(new Date(schedule[0]).getHours())
      ).toISOString()
    : new Date(dummyDate.setHours(START_TIME)).toISOString();

  const startDay = schedule.length
    ? new Date(date).setHours(new Date(schedule[0]).getHours())
    : Date.parse(dummyDate);
  const endDayString = schedule.length
    ? new Date(
        new Date(date).setHours(new Date(schedule[1]).getHours())
      ).toISOString()
    : new Date(dummyDate.setHours(END_TIME)).toISOString();
  const durationDay = schedule.length
    ? new Date(schedule[1]) - new Date(schedule[0])
    : DURATION_DAY;

  const parsedData = data
    .filter(({ employee }) => employee?._id === id)
    .reduce((acc, { start, end, ...rest }, i, _data) => {
      const s = Date.parse(start) - startDay;
      const e = Date.parse(end) - startDay;

      let res = [
        {
          duration: e - s,
          start,
          end,
          ...rest,
        },
      ];

      if (!_data[i - 1] && s > 0) {
        res = [{ duration: s, start: startDayString, end: start }, ...res];
      }

      if (!_data[i + 1] && e < durationDay) {
        res = [
          ...res,
          {
            duration: durationDay - e,
            start: end,
            end: endDayString,
          },
        ];
      }

      let testNext;
      if (
        _data[i + 1] &&
        (testNext = Date.parse(_data[i + 1].start) - startDay) > e
      ) {
        res = [
          ...res,
          {
            duration: testNext - e,
            start: end,
            end: _data[i + 1].start,
          },
        ];
      }

      return [...acc, ...res];
    }, []);
  return parsedData.length
    ? parsedData
    : [
        {
          start: startDayString,
          end: endDayString,
          duration: durationDay,
          id,
        },
      ];
};

export const calculateTimeAndPriceServices = (services, timeShift = 0) => {
  let price = 0;
  let time = 0;

  services.forEach((s) => {
    price = price + (Number(s.price) || 0);
    time = time + (Number(s.time) || 0);
  });

  return { price, time: Math.round(time * 60000 + timeShift) };
};

export const splitTimeShort = (datestring) => {
  return new Date(datestring).toLocaleTimeString('ru', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const getTimeString = (duration, lang = 'en', unitDisplay = 'short') => {
  const hours = Math.floor(Math.abs(duration) / 3_600_000);
  const minutes = Math.floor(Math.abs(duration) / 60_000) - hours * 60;

  return (
    (duration < 0 ? '-' : '') +
    (hours
      ? new Intl.NumberFormat(lang, {
          style: 'unit',
          unit: 'hour',
          unitDisplay,
          notation: 'compact',
        }).format(hours)
      : '') +
    (minutes
      ? ' ' +
        new Intl.NumberFormat(lang, {
          style: 'unit',
          unit: 'minute',
          unitDisplay,
          notation: 'compact',
        }).format(minutes) +
        ' '
      : '')
  );
};

export const getDurationString = (start, end) => {
  return start && end
    ? splitTimeShort(start) + ' - ' + splitTimeShort(end)
    : '';
};

export const getStatusIndex = (employee, duration, end) => {
  return employee
    ? end < new Date().toISOString()
      ? 3
      : 1
    : duration < 3_600_000
    ? 0
    : 2;
};

export const getMinMaxDayHours = (scheddule) =>
  scheddule.reduce(
    ([min, max], day) =>
      day.length
        ? [
            Math.min(new Date(day[0]).getHours(), min),
            Math.max(new Date(day.at(-1)).getHours(), max),
          ]
        : [min, max],
    [23, 0]
  );

export const getEmployeesOrders = (orders, { _id }) =>
  orders.filter(
    ({ employees }) =>
      employees.some((employee) => employee._id === _id) ||
      (_id === 'unassigned' && !employees?.length)
  );

export const filterOrders = (orders = [], [s, e] = [null, null], employee) => {
  s = s || setDateOfMonth(new Date());
  e = e || setDateOfMonth(new Date(s), new Date(s).getDate() + 1);

  return orders.filter(({ start, employees }) => {
    return (
      start < e &&
      start > s &&
      (!employee || employees.some(({ _id }) => _id === employee._id))
    );
  });
};

export const CARDS_WIDTH = 228;
export const CARDS_HEIGHT = 120;

export const getTimeStats = (orders, date, daySchedule, min, max) => {
  if (min > max) {
    [min, max] = [max, min];
  }
  const orderDates = orders.map(({ start }) => Date.parse(start));
  const dayStart = Math.min(
    new Date(date).setHours(
      min || min === 0 ? min : new Date(daySchedule[0]).getHours(),
      0,
      0,
      0
    ),
    ...orderDates
  );
  const dayEnd =
    Math.max(
      new Date(date).setHours(
        max || max === 0 ? max : new Date(daySchedule.at(-1)).getHours(),
        0,
        0,
        0
      ),
      ...orderDates
    ) +
    60 * 60 * 1000;

  const isDayOff = !daySchedule.length;

  if (isDayOff) {
    return [dayStart, dayEnd, dayStart, dayEnd, dayEnd, dayEnd, isDayOff];
  }

  const actualDayStart = new Date(date).setHours(
    new Date(daySchedule[0]).getHours(),
    new Date(daySchedule[0]).getMinutes(),
    0,
    0
  );

  const actualDayEnd = new Date(date).setHours(
    new Date(daySchedule.at(-1)).getHours(),
    new Date(daySchedule.at(-1)).getMinutes(),
    0,
    0
  );

  let actualBrakeStart = actualDayEnd;
  let actualBrakeEnd = actualDayEnd;

  if (daySchedule.length === 4) {
    actualBrakeStart = new Date(date).setHours(
      new Date(daySchedule[1]).getHours(),
      new Date(daySchedule[1]).getMinutes(),
      0,
      0
    );

    actualBrakeEnd = new Date(date).setHours(
      new Date(daySchedule[2]).getHours(),
      new Date(daySchedule[2]).getMinutes(),
      0,
      0
    );
  }
  return [
    dayStart,
    dayEnd,
    actualDayStart,
    actualDayEnd,
    actualBrakeStart,
    actualBrakeEnd,
    isDayOff,
  ];
};

const getEmployeeTimeStats = (
  date,
  empDaySchedule,
  actualDayStart,
  actualDayEnd,
  actualBrakeStart,
  actualBrakeEnd,
  isDayOff
) => {
  let employeeDayStart = actualDayStart;
  let employeeDayEnd = actualDayEnd;
  let isEmployeeDayOff = isDayOff;
  let employeeBrakeStart = actualBrakeStart;
  let employeeBrakeEnd = actualBrakeEnd;

  if (empDaySchedule) {
    if (empDaySchedule.length) {
      isEmployeeDayOff = false;
      employeeDayStart = new Date(date).setHours(
        new Date(empDaySchedule[0]).getHours(),
        new Date(empDaySchedule[0]).getMinutes(),
        0,
        0
      );

      employeeDayEnd = new Date(date).setHours(
        new Date(empDaySchedule.at(-1)).getHours(),
        new Date(empDaySchedule.at(-1)).getMinutes(),
        0,
        0
      );
      employeeBrakeStart = employeeDayEnd;
      employeeBrakeEnd = employeeDayEnd;

      if (empDaySchedule.length === 4) {
        employeeBrakeStart = new Date(date).setHours(
          new Date(empDaySchedule[1]).getHours(),
          new Date(empDaySchedule[1]).getMinutes(),
          0,
          0
        );

        employeeBrakeEnd = new Date(date).setHours(
          new Date(empDaySchedule[2]).getHours(),
          new Date(empDaySchedule[2]).getMinutes(),
          0,
          0
        );
      }
    } else {
      isEmployeeDayOff = true;
    }
  }
  return [
    employeeDayStart,
    employeeDayEnd,
    employeeBrakeStart,
    employeeBrakeEnd,
    isEmployeeDayOff,
  ];
};

export const getDayLayout = (
  data,
  employees,
  daySchedule,
  date,
  weekday,
  minHour,
  maxHour
) => {
  const [
    dayStart,
    dayEnd,
    actualDayStart,
    actualDayEnd,
    actualBrakeStart,
    actualBrakeEnd,
    isDayOff,
  ] = getTimeStats(data, date, daySchedule, minHour, maxHour);

  return {
    layout: employees.map((emp, i) => {
      const orders = getEmployeesOrders(data, emp);
      const { schedule = [] } = emp;
      const [
        employeeDayStart,
        employeeDayEnd,
        employeeBrakeStart,
        employeeBrakeEnd,
        isEmployeeDayOff,
      ] = getEmployeeTimeStats(
        date,
        schedule[weekday],
        actualDayStart,
        actualDayEnd,
        actualBrakeStart,
        actualBrakeEnd,
        isDayOff
      );

      return {
        employee: {
          ...emp,
          employeeDayStart,
          employeeDayEnd,
          employeeBrakeStart,
          employeeBrakeEnd,
          isEmployeeDayOff,
        },
        orders: orders.map((order) => {
          const { start, end } = order;
          const orderStart = Date.parse(start);
          const orderEnd = Date.parse(end);
          const duration = orderEnd - orderStart;
          const height = (duration / (1000 * 3600)) * CARDS_HEIGHT;

          const initialPosition = {
            x: i * CARDS_WIDTH,
            y: ((orderStart - dayStart) / (1000 * 3600)) * CARDS_HEIGHT,
          };

          return {
            ...order,
            column: i,
            orderStart,
            orderEnd,
            duration,
            height,
            initialPosition,
            dayStart,
            dayEnd,
            actualDayStart,
            actualDayEnd,
            actualBrakeStart,
            actualBrakeEnd,
          };
        }),
      };
    }),
    dayStart,
    dayEnd,
    isDayOff,
    actualDayStart,
    actualDayEnd,
  };
};

const isIntersected = (s1, e1, s2, e2) =>
  s1 === s2 || e1 === e2 || (s1 > s2 ? s1 < e2 : e1 > s2);

export const getWeekDayLayout = (data, daySchedule, date, minHour, maxHour) => {
  const [
    dayStart,
    dayEnd,
    actualDayStart,
    actualDayEnd,
    actualBrakeStart,
    actualBrakeEnd,
    isDayOff,
  ] = getTimeStats(data, date, daySchedule, minHour, maxHour);

  const sortedOrders = [...data].sort(
    ({ start: a }, { start: b }) => Date.parse(a) - Date.parse(b)
  );

  let int = [];
  let countedOrders = [];

  sortedOrders.forEach((order, i) => {
    const { start, end } = order;
    if (!int.length) {
      int = [{ start, end, x: 0, count: 1, width: 1 }];
    } else {
      const noIntersections = [];
      const splitCols = int.reduce((acc, { start, end, x }) => {
        const inCol = acc.find((el) => el.x === x);
        if (inCol) inCol.end = end > inCol.end ? end : inCol.end;
        else acc.push({ start, end, x });
        return acc;
      }, []);
      const intersections = splitCols.filter(({ start: s, end: e }, j) => {
        const intersected = isIntersected(start, end, s, e);
        if (intersected) {
          return true;
        } else noIntersections.push(j);
        return false;
      });

      if (!intersections.length) {
        countedOrders = [...countedOrders, ...int];
        int = [{ start, end, x: 0, count: 1, width: 1 }];
      } else {
        if (noIntersections.length) {
          int.push({
            start,
            end,
            x: noIntersections[0],
            width: 1,
            count: int[0].count,
          });
        } else {
          int.push({
            start,
            end,
            x: splitCols.length,
            width: 1,
            count: int[0].count,
          });
          int.forEach((el) => (el.count = splitCols.length + 1));
        }
      }
    }
  });

  countedOrders = [...countedOrders, ...int];

  for (let i = countedOrders.length - 1; i >= 0; i--) {
    const el = countedOrders[i];
    const { start, end, x, count } = el;
    const intersections = countedOrders.filter(
      ({ start: s, end: e, x: x1 }, j) =>
        j !== i && x1 > x && isIntersected(start, end, s, e)
    );
    if (intersections.length) {
      const closestImmutable = intersections
        .filter(({ width }) => width === 1)
        .sort(({ x: a }, { x: b }) => a - b)?.[0];
      const closestMutable = intersections
        .filter(({ width }) => width > 1)
        .sort(({ x: a }, { x: b }) => a - b)?.[0];
      const maxCanBeWidedTo = closestImmutable?.x || count - 1;
      const minCanBeWidedTo = closestMutable?.x || count - 1;
      if (maxCanBeWidedTo === minCanBeWidedTo || !closestMutable) {
        el.width = maxCanBeWidedTo - x;
        continue;
      }
      if (closestMutable.width > 1) {
        const avarageSharedWidth = Math.min(
          maxCanBeWidedTo,
          Math.ceil((closestMutable.width + 1) / 2)
        );
        closestMutable.width -= avarageSharedWidth - 1;
        closestMutable.x += avarageSharedWidth - 1;
        el.width = avarageSharedWidth;
      }
    } else if (x + 1 < count) {
      el.width = count - x;
    }
  }

  return {
    dayStart,
    dayEnd,
    actualDayStart,
    actualDayEnd,
    actualBrakeStart,
    actualBrakeEnd,
    isDayOff,
    layout: sortedOrders.map((order, i) => {
      const { start, end } = order;
      const orderStart = Date.parse(start);
      const orderEnd = Date.parse(end);
      const duration = orderEnd - orderStart;
      const height = (duration / (1000 * 3600)) * CARDS_HEIGHT;
      const { count, x, width: wd } = countedOrders[i];
      const width = ((CARDS_WIDTH - 16) / count) * wd;

      const initialPosition = {
        x: 8 + ((CARDS_WIDTH - 16) / count) * x,
        y: ((orderStart - dayStart) / (1000 * 3600)) * CARDS_HEIGHT,
      };

      return {
        ...order,
        column: i,
        orderStart,
        orderEnd,
        duration,
        height,
        width,
        initialPosition,
      };
    }),
  };
};

export const getDragPayload = (data, card, layout) => {
  const stickyX = Math.min(
    layout.length * CARDS_WIDTH,
    Math.max(0, Math.round(data.x / CARDS_WIDTH) * CARDS_WIDTH)
  );
  const newColumn = stickyX / CARDS_WIDTH;

  const stickyY = (Math.floor((data.y / CARDS_HEIGHT) * 4) * CARDS_HEIGHT) / 4;

  const { orders = [], employee = {} } = layout[newColumn] || {};
  const newStart = card.dayStart + (stickyY / CARDS_HEIGHT) * 60 * 60 * 1000;
  const availableDropZones =
    employee.isEmployeeDayOff || newStart < Date.now()
      ? []
      : [
          [employee.employeeDayStart, employee.employeeDayStart],
          [employee.employeeDayEnd, employee.employeeDayEnd],
          [employee.employeeBrakeStart, employee.employeeBrakeEnd],
          ...(stickyX ? orders : [])
            .filter(({ _id }) => _id !== card._id)
            .map(({ orderStart, orderEnd }) => [orderStart, orderEnd]),
        ].sort(([a], [b]) => a - b);

  const status = availableDropZones.some(([_, e], i, arr) => {
    return (
      e <= newStart && arr[i + 1] && arr[i + 1][0] >= newStart + card.duration
    );
  })
    ? 'settled'
    : 'rejected';

  return {
    x: data.x,
    stickyX,
    y: data.y,
    stickyY,
    status,
    newStart,
    newColumn,
    card,
  };
};

export const isValidDate = (date) => /^\d{4}-\d{2}-\d{2}$/.test(date);

export const validateView = (view) =>
  ['month', 'week', 'day'].find((v) => v === view) || 'month';

export const setWeekPeriod = (dateObj) => {
  const weekDate1 = new Date(dateObj);
  const weekDate2 = new Date(dateObj);
  const day = dateObj.getDate();
  const weekDay = (dateObj.getDay() || 7) - 1;
  weekDate1.setDate(day - weekDay);
  const startWeek = weekDate1.toISOString();
  weekDate2.setDate(day - weekDay + 7);
  const endWeek = weekDate2.toISOString();
  return [startWeek, endWeek];
};

export const calendarParams = ['month', 'week', 'day'];

export const checkCurrentMonth = (chosenDate, currentDate) => {
  const result =
    chosenDate < currentDate
      ? new Date(chosenDate).setHours(0, 0, 0, 0) ===
          new Date(currentDate).setHours(0, 0, 0, 0) &&
        currentDate.getHours() < END_TIME
        ? true
        : false
      : true;
  return result;
};

export const checkFreeOrders = (employees, orders, isoDate, weekday, time) => {
  return employees.some(({ _id, schedule }) => {
    const scheduleClean = schedule || defaultSchedule;
    const employeeOrders = getEmployeesOrders(orders, { _id });
    if (!employeeOrders.length) return true;

    const employeeStart = new Date(isoDate).setHours(
      new Date(scheduleClean[weekday][0]).getHours()
    );
    const employeeEnd = new Date(isoDate).setHours(
      new Date(scheduleClean[weekday][1]).getHours()
    );

    return employeeOrders.some(({ start, end }, i, a) => {
      const firstFreeTime = Date.parse(start) - employeeStart >= time;
      const lastFreeTime = employeeEnd - Date.parse(end) >= time;
      if (a.length === 1) return firstFreeTime || lastFreeTime;
      if (i === 0) return firstFreeTime;
      if (i === a.length - 1) return lastFreeTime;

      return Date.parse(a[i + 1].start) - Date.parse(end) >= time;
    });
  });
};

export const splitOnlyDate = (iso) => iso.split('T')[0];

export const splitYnM = (iso) => splitOnlyDate(iso).slice(0, -3);

export const getScheduledEmployees = (weekday, employees) =>
  employees.filter(({ schedule }) => {
    return (schedule || defaultSchedule)[weekday].length;
  });

export const getDayOrders = (isoDate, orders, employees) =>
  orders.filter(({ start, employee }) => {
    return (
      start &&
      employee &&
      splitOnlyDate(start) === splitOnlyDate(isoDate) &&
      employees.some(({ _id }) => _id === employee._id)
    );
  });

export const checkFreeTime = (isoDate, weekday, orders, employees, time) => {
  const scheduledEmployees = getScheduledEmployees(weekday, employees);
  if (!scheduledEmployees.length) return false;
  if (!orders.length && scheduledEmployees.length) return true;
  const dayOrders = getDayOrders(isoDate, orders, employees);
  if (!dayOrders.length) return true;
  return checkFreeOrders(scheduledEmployees, dayOrders, isoDate, weekday, time);
};

export const checkDayAvailable =
  (year, month, orders, employees, time) => (_, i) => {
    const day = i + 1;
    const date = new Date(year, month, day);
    if (
      new Date(new Date().getFullYear(), new Date().getMonth()) >
      new Date(year, month)
    )
      return false;
    return (
      checkCurrentMonth(date, new Date()) &&
      checkFreeTime(
        date.toISOString(),
        (date.getDay() || 7) - 1,
        orders,
        employees,
        time
      )
    );
  };

export const getAvailableDates = (
  year,
  month,
  orders = [],
  employees = [],
  time = 3_600_000
) => {
  const monthOrders = orders.filter(({ start }) => {
    return (
      start &&
      splitYnM(start) ===
        splitYnM(new Date(new Date().setFullYear(year, month)).toISOString())
    );
  });
  return Array.from(
    { length: getDaysInMonth(new Date(year, month)) },
    checkDayAvailable(year, month, monthOrders, employees, time)
  );
};
////////TIME

export const parseTime =
  (isoDate, weekday, orders, employees, time) => (_, i) => {
    if (!employees.length) return [false, false, false, false];
    const hour = START_TIME + i;
    const ordersByEmployee = employees.map(({ _id }) =>
      getEmployeesOrders(orders, { _id })
    );
    return [0, 15, 30, 45].map((min, i) => {
      const T = new Date(isoDate).setHours(hour, min, 0, 0);
      if (T - 3_600_000 < Date.now()) return false;

      return employees.some(({ schedule }, k) => {
        const scheduleClean = schedule || defaultSchedule;
        const employeeOrders = ordersByEmployee[k];

        const employeeStart = new Date(isoDate).setHours(
          new Date(scheduleClean[weekday][0]).getHours()
        );
        const employeeEnd = new Date(isoDate).setHours(
          new Date(scheduleClean[weekday][1]).getHours()
        );

        if (T < employeeStart || T + time > employeeEnd) return false;
        if (!employeeOrders.length) return true;
        return employeeOrders.some(({ start, end }, j, a) => {
          const one = a[j - 1] ? Date.parse(a[j - 1].end) : employeeStart;
          const two = a[j + 1] ? Date.parse(a[j + 1].start) : employeeEnd;
          return (
            (one <= T && T + time <= Date.parse(start)) ||
            (Date.parse(end) <= T && T + time <= two)
          );
        });
      });
    });
  };

export const getAvailableTime = (
  date,
  orders = [],
  employees = [],
  time = 3_600_000
) => {
  const dayOrders = getDayOrders(date, orders, employees);

  const weekday = (new Date(new Date(date).setHours(0)).getDay() || 7) - 1;
  const scheduledEmployees = getScheduledEmployees(weekday, employees);
  return Array.from(
    { length: END_TIME - START_TIME },
    parseTime(date, weekday, dayOrders, scheduledEmployees, time)
  );
};

export const fillDate = (date) => {
  date = date ? new Date(date) : new Date();
  if (!date.valueOf()) date = new Date();
  return setDateObject(date);
};

export const cropDate = (iso) => {
  return clearDate(iso).split('T')[0];
};

export const getAvailableEmployees = (employees = [], data, date) => {
  const weekday = (date.getDay() || 7) - 1;
  const past = Date.now() > new Date(date).setHours(24);

  const availableEmployees = employees?.filter(
    ({ _id, schedule = defaultSchedule }) =>
      (!!schedule[weekday][0] && !past) ||
      data.some(({ employees }) =>
        employees.some((employee) => employee?._id === _id)
      )
  );
  const unassignedOrders = data.filter(({ employees }) => !employees.length);
  return { weekday, past, availableEmployees, unassignedOrders };
};

export const getAvailableHours = (employees, data, date) => {
  const { weekday, availableEmployees, unassignedOrders, past } =
    getAvailableEmployees(employees, data, date);

  const isDayOff = employees.every(({ schedule }) => !schedule[weekday].length);

  const engagedEmployees = data
    .filter(({ employees }) => !!employees?.length)
    .reduce(
      (acc, { employees }) => ({
        ...acc,
        ...employees.reduce(
          (employee) => ({
            [employee._id]: 0,
          }),
          {}
        ),
      }),
      {}
    );

  const allHours = availableEmployees.reduce(
    (acc, { schedule = defaultSchedule }) =>
      acc +
      (schedule[weekday][0]
        ? (Date.parse(schedule[weekday].at(-1)) -
            Date.parse(schedule[weekday][0])) /
          3_600_000
        : 0) -
      (schedule[weekday].length === 4
        ? (Date.parse(schedule[weekday][2]) -
            Date.parse(schedule[weekday][1])) /
          3_600_000
        : 0),
    0
  );

  const engagedHours = Object.values(
    data
      .filter(({ employees }) => !!employees?.length)
      .reduce((acc, { start, end, employees }) => {
        return {
          ...acc,
          ...employees.reduce(
            (employee) => ({
              [employee._id]:
                acc[employee._id] +
                Math.round((Date.parse(end) - Date.parse(start)) / 3_600_000),
            }),
            {}
          ),
        };
      }, engagedEmployees)
  ).reduce((acc, hours) => {
    return (acc += hours);
  }, 0);

  const numberOfClients = data.reduce(
    (acc, { client }) => acc.add(client?._id || client),
    new Set()
  ).size;

  return {
    allHours,
    engagedHours,
    hours: allHours - engagedHours,
    weekday,
    availableEmployees,
    employeesEngaged: Object.keys(engagedEmployees).length,
    numberOfClients,
    unassignedOrders,
    past,
    isDayOff,
  };
};

class DragListener {
  #_data = {
    x: 0,
    y: 0,
    status: 'settled',
    card: {},
  };
  constructor() {
    this.handlers = [];
  }

  subscribe(id, fn) {
    const subscribed = this.handlers.find((h) => h.id === id);
    if (subscribed) subscribed.fn = fn;
    else this.handlers.push({ id, fn });
  }

  unsubscribe(id) {
    this.handlers = this.handlers.filter((h) => h.id !== id);
  }

  next(data = {}) {
    this.#_data = { ...this.#_data, ...data };
    this.handlers.forEach(({ fn }) => fn(this.#_data));
  }

  get data() {
    return this.#_data;
  }
}

export const createDragListener = () => new DragListener();
