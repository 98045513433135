import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { rx } from '../../Redux/Actions';
import { authRx } from '../../Redux/Actions/AuthActions/thunk';
import { getPartnerships } from '../../API/Partnership';
import {
  StyledSubscriptionsButtons,
  StyledSubscriptionsControls,
  StyledSubscriptionsDescription,
  StyledSubscriptionsItem,
  StyledSubscriptionsName,
  StyledSubscriptionsPrice,
} from './styled';
import { MaxContainerDf } from '../UI/Containers';
import { useLocale } from '../../Utils/Hooks/useLocale';
import { TabsIndividual } from '../UI/Tabs/TabsIndividual';
import { Flex } from '../UI/Flex';
import { Button } from '../UI/Buttons';
import { ReactComponent as IconEdit } from '../../Static/icons/console/actions/patch.svg';
import { ReactComponent as IconDelete } from '../../Static/icons/console/actions/delete.svg';
import { ReactComponent as IconAdd } from '../../Static/icons/console/add.svg';
import { StyledH2 } from '../UI/Titles';
import { openModal } from '../../Redux/Actions/ModalActions';
import L from './locale.json';
import { getPlural } from '../../Utils/Helpers/plural';
import { toPrice } from '../../Utils/Helpers';

const typesSubscriptions = (l) => [
  {
    name: l?.partner || 'Partner',
    type: 'partner',
  },
  {
    name: l?.users || 'Users',
    type: 'users',
  },
];

export const getUnitTypes = (l) => ({
  car: { name: l.unitTypes.car, type: 'car' },
  employee: { name: l.unitTypes.employee, type: 'employee' },
});

export const Subscriptions = () => {
  const dispatch = useDispatch();
  const [locale, lang] = useLocale(L);
  const [partnerships, setPartnerships] = useState([]);
  const [typePartnership, setTypePartnership] = useState('partner');

  const listSubscriptions = useMemo(() => {
    return partnerships.filter(({ userRole }) =>
      typePartnership === 'partner'
        ? userRole === typePartnership
        : userRole !== 'partner'
    );
  }, [partnerships, typePartnership]);

  const units = useMemo(() => getUnitTypes(locale), [locale]);

  const handleOpenSubscriptionModal = (edit = null, subscription = null) => {
    dispatch(
      openModal({
        data: {
          edit,
          onChange: setPartnerships,
          subscription,
        },
        Comp: 'subscriptionModal',
      })
    );
  };

  const handleOpenSubscriptionDelete = (id) => {
    dispatch(
      openModal({
        data: {
          id,
          fn: setPartnerships,
        },
        Comp: 'confirmationDeleteSubscription',
      })
    );
  };

  useEffect(() => {
    dispatch(
      rx(
        authRx,
        'getPartnerships',
        (_, { data }) => {
          if (data.partnerships) {
            setPartnerships(data.partnerships);
          }
        },
        getPartnerships
      )
    );
  }, [setPartnerships, dispatch]);

  return (
    <Flex
      flexDirection={'column'}
      spacer={{ left: '20px', bottom: '20px' }}
      full={true}
    >
      <div>
        <Flex
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          spacer={{ left: '20px', bottom: '20px' }}
        >
          <div>
            <TabsIndividual
              buttons={typesSubscriptions(locale.tabs)}
              activeTab={typePartnership}
              callbackChange={(e, type) => setTypePartnership(type)}
            />
          </div>
          <div>
            <Button
              Icon={IconAdd}
              kind={'green'}
              text={locale.btnAdd}
              fn={() => handleOpenSubscriptionModal(null)}
            />
          </div>
        </Flex>
      </div>
      <div>
        <StyledH2>{locale.title}</StyledH2>
      </div>
      <div>
        <MaxContainerDf>
          {listSubscriptions?.length ? (
            listSubscriptions.map((subscription) => {
              return (
                <StyledSubscriptionsItem key={subscription._id}>
                  {subscription?.name && subscription?.name[lang] ? (
                    <StyledSubscriptionsName>
                      {subscription?.name[lang]}
                    </StyledSubscriptionsName>
                  ) : null}
                  {subscription?.description &&
                  subscription?.description[lang] ? (
                    <StyledSubscriptionsDescription>
                      {subscription?.description[lang]}
                    </StyledSubscriptionsDescription>
                  ) : null}
                  <StyledSubscriptionsControls>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <StyledSubscriptionsPrice>
                        {`${toPrice(subscription.pricePerMonth, lang)} / ${
                          locale.perMonth
                        }`}
                      </StyledSubscriptionsPrice>
                      <StyledSubscriptionsPrice>
                        {`${toPrice(
                          subscription.pricePerUnit,
                          lang
                        )} / ${getPlural(1, units[subscription.unit]?.name)}`}
                      </StyledSubscriptionsPrice>
                      <StyledSubscriptionsPrice>
                        {`${locale.free}: ${
                          subscription.freeUnitsAmount
                        } ${getPlural(
                          subscription.freeUnitsAmount,
                          units[subscription.unit]?.name
                        )}`}
                      </StyledSubscriptionsPrice>
                    </div>
                    <StyledSubscriptionsButtons>
                      <Flex spacer={{ left: '8px', bottom: '8px' }}>
                        <div>
                          <Button
                            kind={'circle'}
                            Icon={IconEdit}
                            fn={() =>
                              handleOpenSubscriptionModal(true, subscription)
                            }
                          />
                        </div>
                        <div>
                          <Button
                            kind={'circle'}
                            Icon={IconDelete}
                            fn={() =>
                              handleOpenSubscriptionDelete(subscription._id)
                            }
                          />
                        </div>
                      </Flex>
                    </StyledSubscriptionsButtons>
                  </StyledSubscriptionsControls>
                </StyledSubscriptionsItem>
              );
            })
          ) : (
            <div>{locale.notFound}</div>
          )}
        </MaxContainerDf>
      </div>
    </Flex>
  );
};
