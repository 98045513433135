import styled, { css, keyframes } from 'styled-components';

import S from '../variables';
import { StyledH1, StyledH2 } from '../Titles';
import { StyledMainButton } from '../Buttons/styled';
import { StyledPreloader } from '../Preloader/styled';
import { rgba } from 'polished';

const show = keyframes`
 from {
   opacity: 0;
   transform: translateX(20px);
 }
 to {
   opacity: 1;
   transform: translateX(0);
 }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;
  min-height: 24px;

  & > button {
    position: absolute;
    top: 16px;
    right: 12px;
  }
`;

export const StyledModalSubtitle = styled.div`
  font-size: 15px;
  line-height: 1.333333;
  text-align: ${({ align }) => align || 'center'};
`;

export const StyledModalContainer = styled.div`
  width: 100%;
`;

export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  background: ${S.white};
  position: relative;
  margin: auto;
  border-radius: 7px;
  box-shadow: ${S.mainShade};
  transition: transform ${S.trFast};
  width: 100%;
  padding: 16px 20px 32px;

  ${({ size }) => {
    switch (size) {
      case 'lightbox':
        return css`
          padding: 0;
          width: fit-content;
          background: unset;
          height: calc(100vh - 80px);
        `;
      case 'full':
        return css`
          max-width: 1280px;
          padding: 16px 20px 24px;

          @media screen and (min-width: ${S.tablet}) {
            padding: 16px 45px 32px;
          }
        `;

      case 'big':
        return css`
          max-width: 780px;
          padding: 16px 20px 24px;

          @media screen and (min-width: ${S.tablet}) {
            padding: 16px 45px 32px;
          }
        `;
      case 'medium':
        return css`
          max-width: 660px;
        `;
      case 'car':
        return css`
          max-width: 520px;
          padding: 16px 20px 24px;

          @media screen and (min-width: ${S.tablet}) {
            padding: 16px 62px 24px;
          }
        `;
      case 'small':
        return css`
          max-width: 365px;
          padding: 16px 20px 32px;
        `;
      default:
        return css`
          max-width: 365px;
        `;
    }
  }}
`;

export const StyledModalWrapper = styled.div`
  opacity: ${({ opened }) => (opened ? 1 : 0)};
  pointer-events: ${({ opened }) => (opened ? 'auto' : 'none')};
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  transition: opacity ${S.trFast};
  transform-origin: center center;
  flex-shrink: 0;

  @media screen and (min-width: ${S.tablet}) {
    padding: 40px;
  }

  ${StyledModalBody} {
    transform: scale(${({ opened }) => (opened ? 1 : 0)});
  }
`;

export const StyledComponentWrapper = styled.div`
  height: 100%;
  ${StyledH1} {
    text-align: center;
  }

  & form {
    position: relative;

    ${StyledMainButton} {
      min-width: 174px;
    }

    & > ${StyledPreloader} {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      width: auto;
      height: auto;
      background: ${rgba(S.white, 0.6)};
    }
  }

  ${StyledH2} {
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

export const StyledInfoBlock = styled.div`
  margin-top: 4px;
  text-align: center;
  color: ${({ isError }) => (isError ? S.rejected : S.settled)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  animation: ${show} ${S.trFast} forwards;
`;
