import { useMemo } from 'react';
import { StyledFormGroup } from '../../UI/Form/styled';
import { useDependentState } from '../../../Utils/Hooks/useDependentState';
import { OrderStartSelect } from '../../UI/Form/OrderStartSelect';

export const OrderDateForm = ({ onChange, orderData, errors }) => {
  const [start, setStart] = useDependentState(orderData?.start, (date) => {
    onChange('start', date);
  });

  const duration = useMemo(() => {
    return orderData?.services?.reduce((acc, { time }) => {
      return acc + time * 60 * 1000;
    }, 0);
  }, [orderData?.services]);

  return (
    <StyledFormGroup>
      <OrderStartSelect
        start={start}
        duration={duration}
        schedule={orderData.company.schedule}
        errors={errors?.start}
        onChange={setStart}
        openTop={false}
      />
    </StyledFormGroup>
  );
};
