import { StyledErrorsWrapper, StyledFormGroup } from '../../UI/Form/styled';
import { useSelector } from 'react-redux';
import { AddCarCard } from '../../Vehicles/AddCarCard';
import { OrderParams } from '../ViewOrder';
import { NumberPlate } from '../../Vehicles/NumberPlate';
import { getUserData } from '../../../Redux/Selectors/AuthSelectors';
import { StyledCarRadio } from './styled';
import { useDependentState } from '../../../Utils/Hooks/useDependentState';

const CarItem = ({ car, selected, onSelect, errors }) => {
  const { make, model, number } = car;
  const handleClick = () => {
    onSelect(car);
  };
  return (
    <StyledCarRadio onClick={handleClick} selected={selected}>
      <OrderParams
        value={make?.name + ' ' + model?.name}
        image={make?.image}
        noMargin
      />
      <NumberPlate number={number} />
      <StyledErrorsWrapper errors={!!errors}>
        <span>{errors}</span>
      </StyledErrorsWrapper>
    </StyledCarRadio>
  );
};

export const SelectCarForm = ({ onChange, orderData, errors }) => {
  const { cars } = useSelector(getUserData);
  const [selectedCar, setSelectedCar] = useDependentState(
    orderData?.car,
    (car) => onChange('car', car)
  );

  return (
    <>
      <StyledFormGroup>
        {cars
          ?.filter(({ deleted }) => !deleted)
          .map((car) => {
            const isSelected = selectedCar?._id === car._id;
            return (
              <CarItem
                car={car}
                key={car._id}
                selected={isSelected}
                onSelect={setSelectedCar}
                errors={isSelected && errors?.car}
              />
            );
          })}
      </StyledFormGroup>
      <StyledFormGroup>
        <AddCarCard />
      </StyledFormGroup>
    </>
  );
};
