import { StyledH2 } from '../../Titles';
import { editQAFormConfig } from '../../../../Configs/formConfigs/qa';
import { Form } from '../../Form';
import { useDispatch } from 'react-redux';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { updateConfigs } from '../../../../API/Configuration';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';

export const QAModal = ({ question, answer, id, onClose, fn }) => {
  const [locale] = useLocale(L);
  const dispatch = useDispatch();

  const {
    messages: [updateConfigurationMassages],
    loading: [updateConfigurationLoading],
  } = useMessages(['updateConfiguration']);

  const handleSubmitQA = (e, formData) => {
    const formFieldObj = {
      question: {
        en: formData.questionEn,
        lt: formData.questionLt,
      },
      answer: {
        en: formData.answerEn,
        lt: formData.answerLt,
      },
    };

    dispatch(
      rx(
        authRx,
        'updateConfiguration',
        (_, { data: { config } }) => {
          if (config) {
            fn && fn(config);
            onClose();
          }
        },
        updateConfigs,
        {
          key: 'qa',
          body: id ? { ...formFieldObj, id } : formFieldObj,
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{locale.title}</StyledH2>
      <Form
        {...editQAFormConfig(question, answer)}
        buttonProps={{
          kind: 'green',
          text: locale.submitBtn,
        }}
        locale={locale}
        loading={updateConfigurationLoading}
        customError={updateConfigurationMassages.error}
        onSubmit={handleSubmitQA}
      />
    </>
  );
};
