import { addService, addServiceAuth } from '../../../../API/Services';
import { updateUser } from '../../../../API/User';
import { rx } from '../../../../Redux/Actions';
import {
  addUserServiceItem,
  setUserData,
} from '../../../../Redux/Actions/AuthActions';
import { authRx, unauthRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { closeModal } from '../../../../Redux/Actions/ModalActions';
import { filterServiceForRequest } from '../../../../Utils/Helpers/services';

export const editServicesByPartner = (dispatch, userId, services) => {
  dispatch(
    rx(
      authRx,
      'updateUserServices',
      (dispatch, { data }) => {
        dispatch(setUserData(data.user));
        dispatch(closeModal({}));
      },
      updateUser,
      {
        id: userId,
        body: {
          services: filterServiceForRequest(services),
        },
      }
    )
  );
};

const addCreatedService = (
  dispatch,
  _id,
  { serviceToAdd, price, time },
  services
) => {
  dispatch(
    rx(
      authRx,
      'updateUserServices',
      (dispatch, { data }) => {
        dispatch(setUserData(data.user));
        dispatch(closeModal({}));
      },
      updateUser,
      {
        id: _id,
        body: {
          services: filterServiceForRequest([
            ...services,
            {
              service: serviceToAdd,
              price,
              time,
            },
          ]),
        },
      }
    )
  );
};

export const createServiceByPartner = (
  dispatch,
  _id,
  { serviceToAdd, price, time },
  services,
  lang
) => {
  dispatch(
    rx(
      authRx,
      'addService',
      (_, { data: { service } }) => {
        addCreatedService(
          dispatch,
          _id,
          {
            serviceToAdd: {
              _id: service._id,
              name: service.name[lang],
              type: service.type,
              approved: false,
            },
            price,
            time,
          },
          services
        );
      },
      addServiceAuth,
      serviceToAdd
    )
  );
};

export const createServiceOnSignup = (
  dispatch,
  { serviceToAdd, price, time },
  lang
) => {
  dispatch(
    rx(
      unauthRx,
      'addService',
      (_, { data: { service } }) => {
        dispatch(
          addUserServiceItem({
            service: {
              _id: service._id,
              name: service.name[lang],
              type: service.type,
              approved: false,
            },
            price,
            time,
          })
        );

        dispatch(closeModal({}));
      },
      addService,
      serviceToAdd
    )
  );
};
