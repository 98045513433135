import { StyledH2 } from '../../Titles';
import { Form } from '../../Form';
import { tcaFormConfig } from '../../../../Configs/formConfigs/tca';
import { useDispatch } from 'react-redux';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { putTerms } from '../../../../API/Configuration';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';

export const EditTCA = ({ terms, onClose, fn }) => {
  const [locale] = useLocale(L);
  const dispatch = useDispatch();

  const {
    messages: [updateTermsMassages],
    loading: [updateTermsLoading],
  } = useMessages(['updateTerms']);

  const handleEditTCA = (e, formData) => {
    dispatch(
      rx(
        authRx,
        'updateTerms',
        (_, { data: { config } }) => {
          if (config) {
            fn && fn(config);
            onClose();
          }
        },
        putTerms,
        {
          body: {
            en: formData.termsEn,
            lt: formData.termsLt,
          },
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{locale.title}</StyledH2>
      <Form
        {...{
          ...tcaFormConfig,
          initialState: {
            ...tcaFormConfig.initialState,
            formData: {
              ...tcaFormConfig.initialState.formData,
              termsLt: terms.lt || '',
              termsEn: terms.en || '',
            },
          },
        }}
        buttonProps={{
          kind: 'green',
          text: locale.submitBtn,
        }}
        locale={locale}
        loading={updateTermsLoading}
        customError={updateTermsMassages.error}
        onSubmit={handleEditTCA}
      />
    </>
  );
};
