import styled from 'styled-components';
import S from '../UI/variables';

export const StyledLanguagePicker = styled.li`
  display: flex;
  margin-left: 0;
`;

export const StyledLanguagePickerItem = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  text-transform: uppercase;
  transition: color ${S.trFast}, background-color ${S.trFast};

  &:hover,
  &.is-active:hover {
    color: ${S.purple};
  }

  &:not(:last-child) {
    margin: 0 12px 0 0;
  }

  &.is-active {
    color: ${S.purple};
  }
`;
