import styled from 'styled-components';
import { StyledErrorsWrapper } from '../../Form/styled';

export const StyledPeriodRow = styled.div`
  position: relative;

  ${StyledErrorsWrapper} {
    position: absolute;
    top: 100%;
    width: max-content;
  }
`;
