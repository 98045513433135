import styled from 'styled-components';
import S from '../UI/variables';
import { StyledCreateOrderBox } from '../Orders/CreateOrder/styled';

export const ServiceGrid = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: -24px;
  margin-bottom: -24px;
  word-break: break-word;

  & > div {
    width: 100%;
    padding-left: 24px;
    margin-bottom: 24px;

    @media screen and (min-width: 556px) {
      width: 50%;
    }

    & > div {
      @media screen and (min-width: 556px) {
        margin: 0 auto;

        ${StyledCreateOrderBox} & {
          max-width: 100%;
        }
      }

      svg {
        width: 24px;
        height: 24px;
        fill: #ccd2e3;
      }
    }
  }

  .price {
    white-space: nowrap;
    padding-left: 24px;
  }
`;

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 2px;
`;

export const StyledServiceItemActions = styled.div`
  overflow: hidden;
  display: flex;
  margin-top: 8px;

  input {
    line-height: 36px;
    font-size: 12px;
  }
`;

export const StyledServiceItemActionsCell = styled.div`
  width: 120px;
`;

export const StyledServiceFilter = styled.div`
  & > div {
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: ${S.tablet}) {
      flex-direction: row;
      align-items: center;
    }
  }

  input {
    background-color: ${S.lightGray};

    @media screen and (min-width: ${S.tabletLandscape}) {
      min-width: 382px;
    }
  }
`;

export const StyledServiceFilterSort = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    &:first-child {
      color: #ccd2e3;
      width: 24px;
      height: 24px;
      margin-right: 8px;

      svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
      }
    }

    &:last-child {
      text-decoration: underline;
    }
  }
`;

export const StyledHourlyRateWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  gap: 24px;
  margin-bottom: 24px;
  & label {
    flex-shrink: 2;
    min-width: unset;
    max-width: unset;
  }
`;
