import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import {
  StyledBannersWrapper,
  StyledFlexBannerLabel,
  StyledFlexBanners,
} from '../styled';
import { useRef } from 'react';
import { StyledErrorsWrapper } from '../../../UI/Form/styled';

import { ReactComponent as IconArrowLeft } from '../../../../Static/icons/chevron-left.svg';
import { ReactComponent as IconArrowRight } from '../../../../Static/icons/chevron-right.svg';
import { onSwiperHelper } from '../../../../Utils/Helpers';
import { Navigation } from 'swiper';
import { useMemo } from 'react';
import { BannerImage } from '../../Partner/ToolItem';

const divideArray = (arr) => {
  return arr.reduce(
    (p, c) => {
      if (p[p.length - 1].length === 6) {
        p.push([]);
      }

      p[p.length - 1].push(c);
      return p;
    },
    [[]]
  );
};

const Slide = ({ _id, image, text, hue, templateId, handleChange }) => {
  const radioRef = useRef(null);
  return (
    <div>
      <StyledFlexBannerLabel
        active={templateId === _id}
        onClick={() => radioRef.current.click()}
      >
        <BannerImage image={image} text={text} hue={hue} />
        <input
          ref={radioRef}
          type={'radio'}
          name={'templateId'}
          value={_id}
          onChange={handleChange}
          checked={templateId === _id}
          required={true}
        />
      </StyledFlexBannerLabel>
    </div>
  );
};

export const Step3 = ({ inputs, errors, handleChange, templates }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const templatesSlides = useMemo(() => divideArray(templates), [templates]);

  return (
    <StyledBannersWrapper>
      {templatesSlides.length ? (
        <>
          <button ref={navigationPrevRef}>
            <IconArrowLeft />
          </button>
          <button ref={navigationNextRef}>
            <IconArrowRight />
          </button>
          <Swiper
            spaceBetween={12}
            slidesPerView={1}
            allowTouchMove={false}
            modules={[Navigation]}
            onSwiper={(swiper) =>
              onSwiperHelper(swiper, navigationPrevRef, navigationNextRef)
            }
          >
            {templatesSlides.map((arr, index) => {
              return (
                <SwiperSlide key={index}>
                  <StyledFlexBanners>
                    {arr.map((item) => {
                      return (
                        <Slide
                          key={item._id}
                          {...item}
                          {...inputs}
                          handleChange={handleChange}
                        />
                      );
                    })}
                  </StyledFlexBanners>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </>
      ) : null}
      <StyledErrorsWrapper errors={errors.bannerView}>
        <span>{errors.bannerView}</span>
      </StyledErrorsWrapper>
    </StyledBannersWrapper>
  );
};
