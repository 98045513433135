import { API } from '../index';

export const endpoints = {
  ADD_SERVICE: '/service',
  ADD_SERVICE_ADMIN: '/service/add',
  UPDATE_SERVICE: '/service',
  DELETE_SERVICE: '/service',
  GET_SERVICE_ADMIN: '/service',
  GET_SERVICES: '/service/list',
};

export const addService = async (config, body) =>
  await API.post(endpoints.ADD_SERVICE, body, config);

export const addServiceAuth = async (config, body) =>
  await API.post(endpoints.ADD_SERVICE_ADMIN, body, config);

export const getServices = async (config) =>
  await API.get(endpoints.GET_SERVICE_ADMIN, config);

export const getPopularServices = async (config) =>
  await API.get(endpoints.GET_SERVICES, config);

export const updateService = async (config, { body, id }) =>
  await API.patch(endpoints.UPDATE_SERVICE + '/' + id, body, config);

export const deleteService = async (config, { id }) =>
  await API.delete(endpoints.DELETE_SERVICE + '/' + id, config);
