import { StyledH2 } from '../../Titles';
import { Form } from '../../Form';
import { editCompanyFormConfig } from '../../../../Configs/formConfigs/editCompany';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../../Redux/Selectors/AuthSelectors';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { setUserData } from '../../../../Redux/Actions/AuthActions';
import { updateUser } from '../../../../API/User';
import { closeModal } from '../../../../Redux/Actions/ModalActions';
import { getLoading } from '../../../../Redux/Selectors/MessageSelectors';
import { useMemo } from 'react';

export const EditCompany = () => {
  const { _id, companyName, companyAddress } = useSelector(getUserData);
  const loading = useSelector(getLoading('updateCompanyData'));
  const [locale] = useLocale(L);
  const dispatch = useDispatch();

  const initialState = useMemo(() => {
    return { formData: { companyName, companyAddress } };
  }, [companyName, companyAddress]);

  const handleSubmitEditCompany = (e, formData) => {
    dispatch(
      rx(
        authRx,
        'updateCompanyData',
        (dispatch, { data }) => {
          dispatch(setUserData(data.user));
          dispatch(closeModal({}));
        },
        updateUser,
        {
          id: _id,
          body: {
            ...formData,
          },
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{locale.title}</StyledH2>
      <Form
        initialState={{
          ...editCompanyFormConfig.initialState,
          ...initialState,
        }}
        formConfig={editCompanyFormConfig.formConfig}
        buttonProps={{
          kind: 'green',
          text: locale.submit,
        }}
        loading={loading}
        locale={locale}
        onSubmit={handleSubmitEditCompany}
      />
    </>
  );
};
