import { StyledMainButton } from './styled';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Preloader } from '../Preloader';
import { NavLink } from 'react-router-dom';

export const Button = forwardRef(
  (
    {
      text = null,
      Icon = null,
      label = null,
      style = {},
      type = 'button',
      kind = 'stroke',
      size = 'medium',
      active = false,
      classes = [],
      fn = () => null,
      full = null,
      disabled = false,
      showDisabled = true,
      loading = false,
      link = null,
      className,
    },
    ref
  ) => {
    const handleClick = (e) => fn(e);

    const classNames = [
      active ? 'is-active' : '',
      ...classes,
      loading ? 'is-loading' : '',
      className,
    ];

    return (
      <StyledMainButton
        as={link ? NavLink : 'button'}
        to={link || ''}
        style={style}
        kind={kind}
        full={full ? full.toString() : undefined}
        className={[...classNames]}
        onClick={handleClick}
        disabled={disabled}
        $showDisabled={showDisabled}
        type={type}
        size={size}
        ref={ref}
      >
        {Icon && !loading ? (
          <span className="icon">
            <Icon />
          </span>
        ) : null}
        {text ? <span className="text">{text}</span> : null}
        {loading ? <Preloader /> : null}
        {label}
      </StyledMainButton>
    );
  }
);

Button.propTypes = {
  text: PropTypes.string,
  Icon: PropTypes.object,
  style: PropTypes.object,
  type: PropTypes.oneOf(['button', 'submit']),
  kind: PropTypes.oneOf([
    'green',
    'stroke',
    'pink',
    'circle',
    'icon',
    'step',
    'square',
    'square-grey',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  classes: PropTypes.array,
  fn: PropTypes.func,
  disabled: PropTypes.bool,
};
