import { API } from '../index';

export const endpoints = {
  ORDER: '/order',
  GET_CAR_HISTORY: '/order/car',
  CANCEL_ORDER: '/order/cancel',
  FINISH_ORDER: '/order/finish',
  UPDATE_ORDER_IMAGES: '/order/images',
};

export const getOrders = async (config) =>
  await API.get(endpoints.ORDER, config);

export const getOrder = async (config, { id }) =>
  await API.get(endpoints.ORDER + '/' + id, config);

export const getCarHistory = async (config, { id }) =>
  await API.get(endpoints.GET_CAR_HISTORY + '/' + id, config);

export const addOrder = async (config, { body }) =>
  await API.post(endpoints.ORDER, body, config);

export const updateOrder = async (config, { body, id }) => {
  return await API.patch(endpoints.ORDER + '/' + id, body, config);
};

export const cancelOrder = async (config, { body, id }) => {
  return await API.patch(endpoints.CANCEL_ORDER + '/' + id, body, config);
};

export const finishOrder = async (config, { body, id }) => {
  return await API.patch(endpoints.FINISH_ORDER + '/' + id, body, config);
};

export const deleteOrder = async (config, { id }) =>
  await API.delete(endpoints.ORDER + '/' + id, config);

export const updateOrderImages = async (config, { id, body }) =>
  await API.patch(endpoints.UPDATE_ORDER_IMAGES + '/' + id, body, {
    ...config,
    headers: { ...config.headers, 'content-type': 'multipart/form-data' },
  });
