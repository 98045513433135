import { StyledPreloader, StyledPreloaderSpin } from './styled';

import { ReactComponent as Logo } from '../../../Static/icons/logo.svg';

export const Preloader = ({ className }) => {
  return (
    <StyledPreloader className={className}>
      <Logo />
      <StyledPreloaderSpin />
    </StyledPreloader>
  );
};
