import { ImageInputMultiple } from '../../../UI/Form/MultipleImage';
import { useDispatch, useSelector } from 'react-redux';
import { setNewOrderData } from '../../../../Redux/Actions/CreateOrderActions';
import { getNewOrderData } from '../../../../Redux/Selectors/CreateOrderSelectors';
import { useState } from 'react';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from '../locale.json';
import { closeModal } from '../../../../Redux/Actions/ModalActions';
import { fetchUpdateOrderImages } from '../../queries';
import {
  StyledCustomError,
  StyledFormButtonBlock,
  StyledFormGroup,
} from '../../../UI/Form/styled';
import { Button } from '../../../UI/Buttons';

export const PhotoForm = ({ order, onChange, isCreate, isModal }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const { companyImages } = useSelector(getNewOrderData);

  const [photosState, setPhotosState] = useState(
    isCreate ? order?.companyImages || [] : []
  );
  const [photosRemote, setPhotosRemote] = useState(
    isCreate ? [] : order?.companyImages || []
  );

  const {
    messages: [updateOrderImagesMessage],
    loading: [updateOrderImagesLoading],
  } = useMessages([`updateOrderImages_${order._id}`]);

  const handleSubmitForm = () => {
    if (isCreate) {
      dispatch(
        setNewOrderData({
          companyImages: photosState,
        })
      );
      dispatch(closeModal({}));
    } else {
      const imagesToDelete = order.companyImages.filter(
        (i) => photosRemote.indexOf(i) < 0
      );

      let formDataDelete = null;
      let formDataUpdate = null;

      if (imagesToDelete?.length) {
        formDataDelete = new FormData();

        imagesToDelete.forEach((file) => {
          formDataDelete.append('imagesToDelete', file);
        });
      }

      if (photosState?.length) {
        formDataUpdate = new FormData();
        const files = Array.from(photosState);

        files.forEach((file) => {
          formDataUpdate.append('images', file);
        });
      }

      if (formDataDelete) {
        fetchUpdateOrderImages(
          dispatch,
          formDataDelete,
          onChange,
          order._id
        ).then((data) => {
          if (formDataUpdate) {
            fetchUpdateOrderImages(
              dispatch,
              formDataUpdate,
              onChange,
              order._id
            ).then(() => {
              dispatch(closeModal({}));
            });
          } else {
            dispatch(closeModal({}));
          }
        });
      } else if (formDataUpdate) {
        fetchUpdateOrderImages(
          dispatch,
          formDataUpdate,
          onChange,
          order._id
        ).then(() => {
          dispatch(closeModal({}));
        });
      }
    }
  };

  const handleDeleteRemoteFiles = (file) => {
    setPhotosRemote((prevState) => prevState.filter((image) => image !== file));
  };

  const handleUpdateOrder = (files) => {
    if (isCreate && !isModal) {
      dispatch(
        setNewOrderData({
          companyImages: files,
        })
      );
    } else {
      setPhotosState(files);
    }
  };

  return (
    <>
      <StyledFormGroup>
        <ImageInputMultiple
          disabled={updateOrderImagesLoading}
          files={isModal ? photosState : companyImages}
          remoteFiles={photosRemote}
          handleDeleteRemoteFiles={handleDeleteRemoteFiles}
          fn={handleUpdateOrder}
        />
      </StyledFormGroup>
      {isModal ? (
        <StyledFormButtonBlock>
          <Button
            kind={'green'}
            fn={handleSubmitForm}
            text={locale.saveBtn}
            loading={updateOrderImagesLoading}
            disabled={
              !isCreate
                ? order.companyImages === photosRemote && !photosState.length
                : photosState === order.companyImages
            }
          />
        </StyledFormButtonBlock>
      ) : null}
      {updateOrderImagesMessage.error ? (
        <StyledCustomError>{updateOrderImagesMessage.error}</StyledCustomError>
      ) : null}
    </>
  );
};
