import styled from 'styled-components';

import S from '../../UI/variables';

import {
  StyledSelectDropdown,
  StyledSelectLabel,
} from '../../UI/Form/Select/styled';

export const StyledUserDropdown = styled.div`
  ${StyledSelectLabel} {
    border: none;
    padding: 4px;

    &:hover {
      background-color: ${S.purpleLight};
      color: ${S.textPrimary};
    }
  }
`;

export const PersonLogoWrapper = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: 8px;

  & > div > div {
    width: 30px;
    height: 30px;
  }

  @media screen and (min-width: ${S.phone}) {
    width: 48px;
    height: 48px;

    & > div > div {
      width: 48px;
      height: 48px;
    }
  }
`;

export const StyledLogoutItem = styled.li`
  width: 100%;
  border-top: 1px solid ${S.strokeGray};
`;

export const StyledPanelDropdown = styled(StyledSelectDropdown)`
  max-height: none;
  overflow: hidden;
  width: fit-content;
  margin-top: 20px;
`;
