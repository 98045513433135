import { useState, useEffect, useRef } from 'react';
import {
  ArrowButton,
  ArrowContainer,
  CloseButton,
  Image,
  LightboxContainer,
  Loader,
  Picture,
} from './styled';

const StaleLoader = ({ loading }) => {
  const [show, setShow] = useState(false);
  const timeout = useRef(null);
  useEffect(() => {
    clearTimeout(timeout.current);
    if (loading) {
      timeout.current = setTimeout(() => {
        setShow(true);
      }, 1000);
    } else {
      setShow(false);
    }
    return () => clearTimeout(timeout.current);
  }, [loading]);
  return <Loader shown={show} />;
};

export const Lightbox = ({ images = [], startIndex, onClose }) => {
  const [imageToShow, setImageToShow] = useState(
    startIndex < images.length ? startIndex || 0 : 0
  );
  //const [errorImage, setErrorImage] = useState(null);
  const [slided, setSlided] = useState(true);

  const handlePrevImage = () => {
    // setErrorImage(null);
    setImageToShow((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
    setSlided(true);
  };

  const handleNextImage = () => {
    // setErrorImage(null);
    setImageToShow((prevImage) =>
      prevImage === images.length - 1 ? 0 : prevImage + 1
    );
    setSlided(true);
  };

  const image = images[imageToShow];

  const onLoad = () => {
    setSlided(false);
  };

  const imageProps = {
    onLoad,
    slided,
  };

  return (
    <LightboxContainer>
      <StaleLoader loading={slided} />
      <CloseButton onClick={onClose}>x</CloseButton>
      {images.length > 1 ? (
        <ArrowContainer>
          <ArrowButton onClick={handlePrevImage}>{'<'}</ArrowButton>
          <ArrowButton onClick={handleNextImage}>{'>'}</ArrowButton>
        </ArrowContainer>
      ) : null}
      <Picture
        key={image}
        src={image}
        ImageComponent={Image}
        imageProps={imageProps}
      />
    </LightboxContainer>
  );
};
