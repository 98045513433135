import { useMemo } from 'react';
import { BannerImage } from '../../Partner/ToolItem';
import { Hue } from 'react-color/lib/components/common';
import {
  StyledHuePickerHandle,
  StyledHuePickerWrapper,
  StyledHueWrapper,
} from '../styled';

export const Step4 = ({ inputs, handleChange, templates }) => {
  const { templateId, hue, text } = inputs;

  const { image } = useMemo(
    () => templates.find(({ _id }) => _id === templateId) || {},
    [templates, templateId]
  );

  const onChange = ({ h }) =>
    handleChange({ target: { value: Math.round(h) || 0, name: 'hue' } });

  return (
    <>
      <StyledHueWrapper>
        <BannerImage image={image} text={text} hue={hue} full />
        <StyledHuePickerWrapper>
          <div>
            <Hue
              hsl={{ h: hue }}
              pointer={StyledHuePickerHandle}
              onChange={onChange}
            />
          </div>
        </StyledHuePickerWrapper>
      </StyledHueWrapper>
    </>
  );
};
