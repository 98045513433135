import { API } from '../index';

export const endpoints = {
  GET_BANNERS: '/marketing/banners',
  GET_ALL: '/marketing',
  GET_CONFIG: '/marketing/config',
  GET_OWN: '/marketing',
  UPDATE_BANNERS_CONFIG: '/marketing/banners',
  UPDATE_GEARS_CONFIG: '/marketing/gears',
  ADD_GEAR: '/marketing/gears',
  ADD_PERIOD: '/marketing/banners/period',
  ADD_TEMPLATE: '/marketing/banners/template',
  UPDATE_GEAR: '/marketing/gears',
  UPDATE_PERIOD: '/marketing/banners/period',
  UPDATE_TEMPLATE: '/marketing/banners/template',
  DELETE_GEAR: '/marketing/gears',
  DELETE_PERIOD: '/marketing/banners/period',
  DELETE_TEMPLATE: '/marketing/banners/template',
  ORDER_GEAR: '/marketing/gears',
  ORDER_BANNER: '/marketing/banners',
  APPROVE_BANNER: '/marketing/banners/approve',
  PAUSE_BANNER: '/marketing/banners/pause',
};

export const getBanners = async (config) =>
  await API.get(endpoints.GET_BANNERS, config);

export const getMarketingToolsConfig = async (config) =>
  await API.get(endpoints.GET_CONFIG, config);

export const getAllMarketingTools = async (config) =>
  await API.get(endpoints.GET_ALL, config);

export const getOwnMarketingTools = async (config, { id }) =>
  await API.get(endpoints.GET_OWN + '/' + id, config);

export const updateBannersConfig = async (config, { body }) =>
  await API.put(endpoints.UPDATE_BANNERS_CONFIG, body, config);

export const updateGearsConfig = async (config, { body }) =>
  await API.put(endpoints.UPDATE_GEARS_CONFIG, body, config);

export const addGearToConfig = async (config, { body }) =>
  await API.patch(endpoints.ADD_GEAR, body, config);

export const addPeriodToConfig = async (config, { body }) =>
  await API.patch(endpoints.ADD_PERIOD, body, config);

export const addTemplateToConfig = async (config, { body }) =>
  await API.patch(endpoints.ADD_TEMPLATE, body, config);

export const updateGearInConfig = async (config, { id, body }) =>
  await API.patch(endpoints.UPDATE_GEAR + '/' + id, body, config);

export const updatePeriodInConfig = async (config, { id, body }) =>
  await API.patch(endpoints.UPDATE_PERIOD + '/' + id, body, config);

export const updateTemplateInConfig = async (config, { id, body }) =>
  await API.patch(endpoints.UPDATE_TEMPLATE + '/' + id, body, config);

export const deleteGearFromConfig = async (config, { id }) =>
  await API.delete(endpoints.DELETE_GEAR + '/' + id, config);

export const deletePeriodFromConfig = async (config, { id }) =>
  await API.delete(endpoints.DELETE_PERIOD + '/' + id, config);

export const deleteTemplateFromConfig = async (config, { id }) =>
  await API.delete(endpoints.DELETE_TEMPLATE + '/' + id, config);

export const orderBanner = async (config, { id, body }) =>
  await API.post(endpoints.ORDER_BANNER + '/' + id, body, config);

export const orderGear = async (config, { id, body }) =>
  await API.post(endpoints.ORDER_GEAR + '/' + id, body, config);

export const approveBanner = async (config, { id }) =>
  await API.patch(endpoints.APPROVE_BANNER + '/' + id, {}, config);

export const pauseBanner = async (config, { id, body }) =>
  await API.patch(endpoints.PAUSE_BANNER + '/' + id, body, config);
