import { StyledCalcGroup, StyledCalcGroupCount } from './styled';
import { useEffect, useState } from 'react';
import { Button } from '../Buttons';

import { ReactComponent as IconPlus } from '../../../Static/icons/calc/plus.svg';
import { ReactComponent as IconMinus } from '../../../Static/icons/calc/minus.svg';
import { useMemo } from 'react';

export const CalcGroup = ({
  value: valueProp,
  fn = () => null,
  minValue,
  maxValue,
  options,
}) => {
  const { min, max, countOptions } = useMemo(() => {
    const min = options
      ? Math.min(...options.map(({ value }) => value))
      : minValue || 0;
    const max = options
      ? Math.max(...options.map(({ value }) => value))
      : maxValue || 10000;
    const countOptions =
      options ||
      Array.from({ length: max - min }, (_, i) => ({
        value: min + i,
        id: min + i,
      }));
    return { min, max, countOptions };
  }, [minValue, maxValue, options]);

  const [count, setCount] = useState(
    () =>
      countOptions?.find(({ id }) => id === valueProp)?.value || minValue || 0,
    []
  );

  const handlePlus = () => {
    setCount((prevState) => (prevState !== max ? prevState + 1 : prevState));
  };

  const handleMinus = () => {
    setCount((prevState) => (prevState !== min ? prevState - 1 : prevState));
  };

  useEffect(() => {
    const nextValue = countOptions.find(({ value }) => value === count)?.id;
    fn(nextValue);
  }, [count, countOptions, fn]);

  return (
    <StyledCalcGroup>
      <Button
        kind={'square'}
        disabled={count === min}
        Icon={IconMinus}
        fn={handleMinus}
      />
      <StyledCalcGroupCount>{count}</StyledCalcGroupCount>
      <Button
        kind={'square'}
        disabled={count === max}
        Icon={IconPlus}
        fn={handlePlus}
      />
    </StyledCalcGroup>
  );
};
