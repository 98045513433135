import { Input } from '../../../Components/UI/Form/Input';
import { Select } from '../../../Components/UI/Form/Select';
import { usersTypesConfig } from '../../usersTypesConfig';
import { PhoneInput } from '../../../Components/UI/Form/Phone';

// TODO perhaps there will be a functionality for adding users

export const defaultAddUserFormData = {
  userType: 0,
  name: '',
  phone: '',
  email: '',
};

export const defaultAddUserFormErrors = {
  userType: '',
  name: '',
  phone: '',
  email: '',
};

export const initialAddUserFormState = {
  formData: defaultAddUserFormData,
  errors: defaultAddUserFormErrors,
};

export const setAddUserFormConfig = [
  {
    Element: Select,
    props: {
      options: usersTypesConfig,
      kind: 'form',
      name: 'userType',
      // placeholder: (l) => l.placeholders.usersTypesConfig,
      required: true,
    },
  },
  {
    Element: Input,
    props: {
      name: 'name',
      // label: (l) => l.placeholders.name,
      // placeholder: (l) => l.placeholders.name,
      type: 'text',
      required: true,
    },
  },
  {
    Element: PhoneInput,
    props: {
      name: 'phone',
      // label: (l) => l.placeholders.phone,
      // placeholder: (l) => l.placeholders.phone,
      type: 'tel',
      required: true,
    },
  },
  {
    Element: Input,
    props: {
      name: 'email',
      // label: (l) => l.placeholders.email,
      // placeholder: (l) => l.placeholders.email,
      type: 'email',
      required: true,
    },
  },
];
