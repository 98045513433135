import { StyledH2 } from '../../Titles';
import { Flex } from '../../Flex';
import { RadioInput } from '../../Form/Radio';
import { useState } from 'react';
import { Form } from '../../Form';
import { phoneFormConfig } from '../../../../Configs/formConfigs/phone';
import { emailFormConfig } from '../../../../Configs/formConfigs/email';
import { socialLinkFormConfig } from '../../../../Configs/formConfigs/social';
import { Login } from '../../../Auth/Login';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { setUserData } from '../../../../Redux/Actions/AuthActions';
import { updateEmployee, updateUser } from '../../../../API/User';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../../Redux/Selectors/AuthSelectors';
import { closeModal } from '../../../../Redux/Actions/ModalActions';
import { registrationCodeFormConfig } from '../../../../Configs/formConfigs/registrationCode';
import { vatNumberFormConfig } from '../../../../Configs/formConfigs/vatNumber';
import {
  getLoading,
  useMessages,
} from '../../../../Redux/Selectors/MessageSelectors';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { updateConfigs } from '../../../../API/Configuration';
import { isUser, roles } from '../../../../Configs/constants';
import { phoneReplacedCode } from '../../../../Utils/Helpers/phone';
import { socialsTypesConfig } from '../../../../Configs/socials-config';

const AddNewPhoneFrom = (props) => {
  return <Form {...props} />;
};

const AddNewEmailFrom = (props) => {
  return <Form {...props} />;
};

export const AddNewContacts = ({ fn, onClose }) => {
  const [type, setType] = useState('phone');
  const [{ addNewContacts, submit }] = useLocale(L);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setType(e.currentTarget.value);
  };

  const {
    messages: [updateConfigurationMassages],
    loading: [updateConfigurationLoading],
  } = useMessages(['updateConfiguration']);

  const handleSubmitPhone = (e, formData) => {
    dispatch(
      rx(
        authRx,
        'updateConfiguration',
        (_, { data: { config } }) => {
          if (config) {
            fn && fn(config);
            onClose();
          }
        },
        updateConfigs,
        {
          key: 'phones',
          body: {
            phone: `+370${formData.phone}`,
          },
        }
      )
    );
  };

  const handleSubmitEmail = (e, formData) => {
    dispatch(
      rx(
        authRx,
        'updateConfiguration',
        (_, { data: { config } }) => {
          if (config) {
            fn && fn(config);
            onClose();
          }
        },
        updateConfigs,
        {
          key: 'emails',
          body: {
            email: formData.email,
          },
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{addNewContacts.title}</StyledH2>
      <Flex
        flexDirection={'column'}
        full={true}
        spacer={{
          left: '24px',
          bottom: '22px',
        }}
      >
        <div>
          <Flex
            spacer={{
              left: '24px',
              bottom: '12px',
            }}
            justifyContent={'center'}
          >
            <div>
              <RadioInput
                placeholder={addNewContacts.placeholders.phone}
                name={'typeContact'}
                value={'phone'}
                checked={type === 'phone'}
                fn={handleChange}
              />
            </div>
            <div>
              <RadioInput
                placeholder={addNewContacts.placeholders.email}
                name={'typeContact'}
                value={'email'}
                checked={type === 'email'}
                fn={handleChange}
              />
            </div>
          </Flex>
        </div>
        <div>
          {type === 'phone' ? (
            <AddNewPhoneFrom
              {...phoneFormConfig}
              buttonProps={{
                kind: 'green',
                text: submit,
              }}
              customError={updateConfigurationMassages.error}
              loading={updateConfigurationLoading}
              onSubmit={handleSubmitPhone}
            />
          ) : (
            <AddNewEmailFrom
              {...emailFormConfig}
              buttonProps={{
                kind: 'green',
                text: submit,
              }}
              customError={updateConfigurationMassages.error}
              loading={updateConfigurationLoading}
              locale={addNewContacts}
              onSubmit={handleSubmitEmail}
            />
          )}
        </div>
      </Flex>
    </>
  );
};

export const EditPhone = ({ role, phone, id, onClose, fn, main }) => {
  const dispatch = useDispatch();
  const phoneReplaced = phoneReplacedCode(phone);
  const [{ editPhone, submit }] = useLocale(L);

  const {
    messages: [updateConfigurationMassages],
    loading: [updateConfigurationLoading],
  } = useMessages(['updateConfiguration']);

  const handleSubmitAdminForm = (e, formData) => {
    dispatch(
      rx(
        authRx,
        'updateConfiguration',
        (_, { data: { config } }) => {
          if (config) {
            fn && fn(config);
            onClose();
          }
        },
        updateConfigs,
        {
          key: 'phones',
          body: {
            id,
            phone: `+370${formData.phone}`,
          },
        }
      )
    );
  };

  if (
    role === roles.PARTNER ||
    isUser(role) ||
    (role === roles.ADMIN && main)
  ) {
    return (
      <>
        <StyledH2>{editPhone.title}</StyledH2>
        <Login updatingUserPhone={true} outerPhone={phoneReplaced} />
      </>
    );
  }

  if (role === roles.ADMIN) {
    return (
      <>
        <StyledH2>{editPhone.title}</StyledH2>
        <Form
          {...{
            ...phoneFormConfig,
            initialState: {
              ...phoneFormConfig.initialState,
              formData: {
                ...phoneFormConfig.initialState.formData,
                phone: phoneReplaced,
              },
            },
          }}
          onSubmit={handleSubmitAdminForm}
          loading={updateConfigurationLoading}
          customError={updateConfigurationMassages.error}
          buttonProps={{
            kind: 'green',
            text: submit,
          }}
        />
      </>
    );
  }
};

export const EditPhoneEmployee = ({ employee, onChange, onClose }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const {
    messages: [updateEmployeePhoneMassages],
    loading: [updateEmployeePhoneLoading],
  } = useMessages(['updateEmployeePhone']);

  const handleSubmitForm = (e, formData) => {
    dispatch(
      rx(
        authRx,
        'updateEmployeePhone',
        (_, { data }) => {
          if (data?.employee) {
            onChange(data.employee);
            onClose();
          }
        },
        updateEmployee,
        {
          id: employee._id,
          body: {
            phone: `+370${formData.phone}`,
          },
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{locale.editPhone.title}</StyledH2>
      <Form
        {...{
          ...phoneFormConfig,
          initialState: {
            ...phoneFormConfig.initialState,
            formData: {
              ...phoneFormConfig.initialState.formData,
              phone: employee.phone
                ? phoneReplacedCode(employee.phone) || ''
                : '',
            },
          },
        }}
        onSubmit={handleSubmitForm}
        loading={updateEmployeePhoneLoading}
        customError={updateEmployeePhoneMassages.error}
        buttonProps={{
          kind: 'green',
          text: locale.submit,
        }}
      />
    </>
  );
};

export const EditEmailEmployee = ({ employee, onChange, onClose }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const {
    messages: [updateEmployeeEmailMassages],
    loading: [updateEmployeeEmailLoading],
  } = useMessages(['updateEmployeeEmail']);

  const handleSubmitForm = (e, formData) => {
    dispatch(
      rx(
        authRx,
        'updateEmployeeEmail',
        (_, { data }) => {
          if (data?.employee) {
            onChange(data.employee);
            onClose();
          }
        },
        updateEmployee,
        {
          id: employee._id,
          body: formData,
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{locale.editEmail.title}</StyledH2>
      <Form
        {...{
          ...emailFormConfig,
          initialState: {
            ...emailFormConfig.initialState,
            formData: {
              ...emailFormConfig.initialState.formData,
              email: employee.email,
            },
          },
        }}
        locale={locale.editEmail}
        onSubmit={handleSubmitForm}
        loading={updateEmployeeEmailLoading}
        customError={updateEmployeeEmailMassages.error}
        buttonProps={{
          kind: 'green',
          text: locale.submit,
        }}
      />
    </>
  );
};

export const EditEmail = ({ email }) => {
  const [{ editEmail, submit }] = useLocale(L);
  const { _id } = useSelector(getUserData);
  const loading = useSelector(getLoading('updateUserEmail'));
  const dispatch = useDispatch();

  const handleEditEmail = (e, formData) => {
    dispatch(
      rx(
        authRx,
        'updateUserEmail',
        (dispatch, { data }) => {
          dispatch(setUserData(data.user));
          dispatch(closeModal({}));
        },
        updateUser,
        {
          id: _id,
          body: {
            email: formData.email,
          },
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{editEmail.title}</StyledH2>
      <Form
        {...{
          ...emailFormConfig,
          initialState: {
            ...emailFormConfig.initialState,
            formData: { ...emailFormConfig.initialState.formData, email },
          },
        }}
        buttonProps={{
          kind: 'green',
          text: submit,
        }}
        locale={editEmail}
        loading={loading}
        onSubmit={handleEditEmail}
      />
    </>
  );
};

export const EditRegistrationCode = ({ registrationCode }) => {
  const [{ editRegistrationCode, submit }] = useLocale(L);
  const { _id } = useSelector(getUserData);
  const loading = useSelector(getLoading('updateRegistrationCode'));
  const dispatch = useDispatch();

  const handleEditRegistrationCode = (e, formData) => {
    dispatch(
      rx(
        authRx,
        'updateRegistrationCode',
        (dispatch, { data }) => {
          dispatch(setUserData(data.user));
          dispatch(closeModal({}));
        },
        updateUser,
        {
          id: _id,
          body: {
            registrationCode: formData.registrationCode,
          },
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{editRegistrationCode.title}</StyledH2>
      <Form
        {...{
          ...registrationCodeFormConfig,
          initialState: {
            ...registrationCodeFormConfig.initialState,
            formData: {
              ...registrationCodeFormConfig.initialState.formData,
              registrationCode,
            },
          },
        }}
        buttonProps={{
          kind: 'green',
          text: submit,
        }}
        locale={editRegistrationCode}
        loading={loading}
        onSubmit={handleEditRegistrationCode}
      />
    </>
  );
};

export const EditVatNumber = ({ vat }) => {
  const [{ editVatNumber, submit }] = useLocale(L);
  const { _id } = useSelector(getUserData);
  const loading = useSelector(getLoading('updateVatNumber'));
  const dispatch = useDispatch();

  const handleEditVatNumber = (e, formData) => {
    dispatch(
      rx(
        authRx,
        'updateVatNumber',
        (dispatch, { data }) => {
          dispatch(setUserData(data.user));
          dispatch(closeModal({}));
        },
        updateUser,
        {
          id: _id,
          body: {
            vat: formData.vat,
          },
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{editVatNumber.title}</StyledH2>
      <Form
        {...{
          ...vatNumberFormConfig,
          initialState: {
            ...vatNumberFormConfig.initialState,
            formData: { ...vatNumberFormConfig.initialState.formData, vat },
          },
        }}
        buttonProps={{
          kind: 'green',
          text: submit,
        }}
        locale={editVatNumber}
        loading={loading}
        onSubmit={handleEditVatNumber}
      />
    </>
  );
};

export const EditSocialLink = ({
  socialLink,
  socialType,
  socialName,
  fn,
  onClose,
  id,
}) => {
  const [{ editSocialLink, submit }] = useLocale(L);
  const dispatch = useDispatch();

  const {
    messages: [updateConfigurationMassages],
    loading: [updateConfigurationLoading],
  } = useMessages(['updateConfiguration']);

  const handleEditSocialLink = (e, formData) => {
    dispatch(
      rx(
        authRx,
        'updateConfiguration',
        (_, { data: { config } }) => {
          if (config) {
            fn && fn(config);
            onClose();
          }
        },
        updateConfigs,
        {
          key: 'socials',
          body: {
            id,
            social: socialsTypesConfig[formData.social],
            name: formData.name,
            link: formData.link,
          },
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{editSocialLink.title}</StyledH2>
      <Form
        {...{
          ...socialLinkFormConfig,
          initialState: {
            ...socialLinkFormConfig.initialState,
            formData: {
              ...socialLinkFormConfig.initialState.formData,
              social: socialsTypesConfig.indexOf(socialType),
              name: socialName,
              link: socialLink,
            },
          },
        }}
        buttonProps={{
          kind: 'green',
          text: submit,
        }}
        locale={editSocialLink}
        loading={updateConfigurationLoading}
        customError={updateConfigurationMassages.error}
        onSubmit={handleEditSocialLink}
      />
    </>
  );
};

export const AddSocialLink = ({ fn, onClose }) => {
  const [{ addSocialLink, submit }] = useLocale(L);
  const dispatch = useDispatch();

  const {
    messages: [updateConfigurationMassages],
    loading: [updateConfigurationLoading],
  } = useMessages(['updateConfiguration']);

  const handleEditSocialLink = (e, formData) => {
    dispatch(
      rx(
        authRx,
        'updateConfiguration',
        (_, { data: { config } }) => {
          if (config) {
            fn && fn(config);
            onClose();
          }
        },
        updateConfigs,
        {
          key: 'socials',
          body: {
            social: socialsTypesConfig[formData.social],
            name: formData.name,
            link: formData.link,
          },
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{addSocialLink.title}</StyledH2>
      <Form
        {...socialLinkFormConfig}
        buttonProps={{
          kind: 'green',
          text: submit,
        }}
        locale={addSocialLink}
        loading={updateConfigurationLoading}
        customError={updateConfigurationMassages.error}
        onSubmit={handleEditSocialLink}
      />
    </>
  );
};
