import { useCallback, useEffect, useRef, useState } from 'react';
import { DatePickerButton } from '../../Buttons/DatePicker';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { SelectComponent } from '../Select';
import S from '../../variables';
import { splitTimeShort } from '../../../Calendar/helpers';
import { ReactComponent as TimeIcon } from '../../../../Static/icons/console/calendar/time.svg';
import { StyledParts, StyledWrapper } from './styled';
import { StyledErrorsWrapper } from '../styled';
import { useDependentState } from '../../../../Utils/Hooks/useDependentState';

const defaultOptions = Array.from({ length: 24 * 4 }, (_, i) => {
  const minutes = [0, 15, 30, 45][i % 4];
  const hours = Math.floor(i / 4);
  const value = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0'
  )}`;
  return {
    value,
    label: value,
    hours,
    minutes,
  };
});

const getTimeOptions = (schedule, date, duration = 0, initialDate) => {
  const day = new Date(date).getDay();
  const weekday = day === 0 ? 6 : day - 1;
  const daySchedule = schedule?.[weekday];

  if (!date || !daySchedule) {
    return defaultOptions;
  }

  // to be able to reselect initial date and time after it was changed
  // like reverting changes
  const dateIsInitial =
    initialDate &&
    new Date(date).toDateString() === new Date(initialDate).toDateString();

  const initialHours = dateIsInitial && new Date(initialDate).getHours();
  const initialMinutes = dateIsInitial && new Date(initialDate).getMinutes();

  if (!daySchedule.length) {
    return dateIsInitial
      ? defaultOptions.map((opt) => {
          if (opt.hours === initialHours && opt.minutes === initialMinutes) {
            return opt;
          }
          return { ...opt, disabled: true };
        })
      : [];
  }

  const dayStart = new Date(date).setHours(
    new Date(daySchedule[0]).getHours(),
    new Date(daySchedule[0]).getMinutes(),
    0,
    0
  );

  const dayEnd = new Date(date).setHours(
    new Date(daySchedule.at(-1)).getHours(),
    new Date(daySchedule.at(-1)).getMinutes(),
    0,
    0
  );

  let brakeStart = dayEnd;
  let brakeEnd = dayEnd;

  if (daySchedule.length === 4) {
    brakeStart = new Date(date).setHours(
      new Date(daySchedule[1]).getHours(),
      new Date(daySchedule[1]).getMinutes(),
      0,
      0
    );

    brakeEnd = new Date(date).setHours(
      new Date(daySchedule[2]).getHours(),
      new Date(daySchedule[2]).getMinutes(),
      0,
      0
    );
  }

  return defaultOptions.reduce((acc, opt) => {
    const { hours, minutes } = opt;
    const time = new Date(date).setHours(hours, minutes, 0, 0);
    const timeEnd = time + duration;
    if (
      (time >= dayStart && timeEnd <= brakeStart) ||
      (time >= brakeEnd && timeEnd <= dayEnd) ||
      (opt.hours === initialHours && opt.minutes === initialMinutes)
    ) {
      acc.push(opt);
      return acc;
    } else {
      acc.push({ ...opt, disabled: true });
    }
    return acc;
  }, []);
};

const TimeOption = ({ label, disabled }) => {
  return <div style={disabled ? { color: S.secondGray } : null}>{label}</div>;
};

export const OrderStartSelect = ({
  start,
  duration,
  schedule,
  onChange,
  errors,
  disabled,
  openTop = true,
}) => {
  const [locale] = useLocale(L);
  const [value, setValue] = useDependentState(start);
  const [initialValue] = useState(start);
  const [dateError, setDateError] = useDependentState(errors);
  const [timeOptions, setTimeOptions] = useState(() =>
    getTimeOptions(schedule, value, duration, initialValue)
  );

  const handleChangeDate = useCallback(
    (date) => {
      if (date) {
        setDateError(null);
        const timeOptions = getTimeOptions(
          schedule,
          date,
          duration,
          initialValue
        );

        if (!timeOptions.length) {
          setDateError(locale.errors.noTimeAvailable);
          setValue(null);
          onChange?.('');
          return timeOptions;
        }
        const selectedTimeOption = timeOptions.find(
          ({ hours, minutes, disabled }) =>
            !disabled &&
            new Date(date).getHours() === hours &&
            new Date(date).getMinutes() === minutes
        );

        if (selectedTimeOption) {
          setValue(date);
          onChange?.(date);
          return timeOptions;
        }

        const firstAvailableTimeOption = timeOptions.find(
          (opt) => !opt.disabled
        );

        if (firstAvailableTimeOption) {
          const nextDate = new Date(
            new Date(date).setHours(
              firstAvailableTimeOption.hours,
              firstAvailableTimeOption.minutes,
              0,
              0
            )
          ).toISOString();
          setValue(nextDate);
          onChange?.(nextDate);
          return timeOptions;
        }
      }

      setValue(null);
      onChange?.('');
      return defaultOptions;
    },
    [duration, schedule, onChange, initialValue, locale, setValue, setDateError]
  );

  const valueRef = useRef(value);

  useEffect(() => {
    if (valueRef.current !== value) {
      setTimeOptions(handleChangeDate(value));
      valueRef.current = value;
    }
  }, [value, handleChangeDate]);

  const handleChangeTime = ({ hours, minutes }) => {
    const newStart = new Date(
      new Date(start).setHours(hours, minutes, 0, 0)
    ).toISOString();
    onChange(newStart);
  };

  return (
    <div>
      <StyledWrapper>
        <StyledParts>
          <DatePickerButton
            kind={'button'}
            placeholder={locale.placeholders.date}
            label={locale.labels.date}
            showDoubleView={false}
            fn={handleChangeDate}
            minDate={new Date()}
            selectedDate={value && new Date(value)}
            errors={!!dateError}
            disabled={disabled}
            openTop={openTop}
          />
        </StyledParts>
        <StyledParts>
          <SelectComponent
            kind="button"
            name={'time'}
            icon={TimeIcon}
            options={timeOptions}
            valueComponent={TimeOption}
            placeholder={locale.placeholders.time}
            label={locale.labels.time}
            value={value && splitTimeShort(value)}
            required
            onChange={handleChangeTime}
            disabled={disabled || !value}
            errors={!!dateError}
            openTop={openTop}
            align="left"
          />
        </StyledParts>
      </StyledWrapper>
      {dateError && typeof dateError === 'string' ? (
        <StyledErrorsWrapper errors>
          <span className="error">{dateError}</span>
        </StyledErrorsWrapper>
      ) : null}
    </div>
  );
};
