export const checkRequired = (formData, required) => {
  return Object.keys(formData).reduce(
    (acc, key) => (required[key] && !formData[key] ? [...acc, key] : acc),
    []
  );
};

export const FormReducer = (state, action) => {
  if (action.type.includes('error/')) {
    return {
      ...state,
      errors: { ...state.errors, [action.name]: action.payload },
    };
  } else if (action.type.includes('clear')) {
    return action.payload;
  } else {
    return {
      ...state,
      formData: { ...state.formData, [action.name]: action.payload },
    };
  }
};
