export const parseMilliseconds = (mss) => {
  if (!mss) {
    return [0, 0];
  }

  if (mss === 0) {
    return [0, 0];
  }

  const year = Math.floor(mss / (1000 * 60 * 60 * 24 * 30 * 12));
  const month = Math.floor(mss / (1000 * 60 * 60 * 24 * 30));
  const days = Math.floor(mss / (1000 * 60 * 60 * 24));
  const hours = Math.floor(mss / (1000 * 60 * 60));

  return year > 0
    ? [year, 3]
    : month > 0
    ? [month, 2]
    : days > 0
    ? [days, 1]
    : hours > 0
    ? [hours, 0]
    : [0, 0];
};
