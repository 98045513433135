import styled from 'styled-components';

export const StyledCalcGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCalcGroupCount = styled.div`
  width: 22px;
  margin: 0 16px;
  text-align: center;
`;
