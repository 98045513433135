import styled from 'styled-components';
import { StyledCard } from '../UI/Card/styled';
import S from '../UI/variables';
import { StyledTextarea } from '../UI/Form/styled';
import { StyledPreloader } from '../UI/Preloader/styled';
import { rgba } from 'polished';

export const StyledReviewsList = styled.ul`
  padding-top: 8px;
`;

export const StyledReviewsListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const StyledReviewAdd = styled(StyledCard)`
  padding: 16px;
  box-shadow: ${S.mainShade};
  background-color: ${S.white};
  margin-top: 16px;

  ${StyledTextarea} {
    border-radius: 4px;
    margin-bottom: 16px;
  }
`;

export const StyledReviewAddBody = styled.div`
  position: relative;

  ${StyledPreloader} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: ${rgba(S.white, 0.6)};
  }
`;

export const StyledReviewAddLabel = styled.div`
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 16px;
`;
