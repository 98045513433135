import styled from 'styled-components';
import S from '../../variables';

export const StyledStepsWrapper = styled.div`
  padding: 24px 0 12px;

  &:first-child {
    padding: 0 0 8px;
  }

  @media screen and (min-width: ${S.tablet}) {
    padding: 32px 0 16px;

    &:first-child {
      padding: 0 0 16px;
    }
  }
`;
