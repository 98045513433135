import styled, { css } from 'styled-components';

import S from '../variables';

export const StyledTooltip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  max-width: calc(100vw - 40px);
  background: ${S.white};
  filter: drop-shadow(0px 4px 4px rgba(140, 140, 140, 0.24))
    drop-shadow(0px 4px 11px rgba(140, 140, 140, 0.18));
  border-radius: 12px;
  padding: 13px 16px;
  color: ${S.textSecondary};
  font-size: 12px;
  ${({ show }) =>
    show
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          width: 1px;
          height: 1px;
          margin: -1px;
          border: 0;
          padding: 0;
          white-space: nowrap;
          clip-path: inset(100%);
          clip: rect(0 0 0 0);
          overflow: hidden;
        `};
  transition: opacity ${S.trFast} ${({ delay }) => delay}ms;
  pointer-events: none;

  & > span {
    display: block;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledTriangle = styled.div`
  position: absolute;
  bottom: -3px;
  left: 50%;
  width: 6px;
  height: 6px;
  background: ${S.white};
  transform: translate(-50%) rotate(45deg);
`;
