import styled from 'styled-components';
import S from '../variables';

export const StyledCalendar = styled.div`
  .react-calendar {
    border-color: ${S.strokeGray};
    border-radius: 12px;
    font-family: 'Roboto', sans-serif;
    padding: 8px 16px 10px;
    font-size: 12px;
    width: 300px;
    margin: 0 auto;

    & * {
      font-size: 1em;
    }

    &__tile {
      line-height: 1;
      padding: 0;

      &:nth-child(1),
      &:nth-child(8),
      &:nth-child(15),
      &:nth-child(22),
      &:nth-child(29),
      &:nth-child(36) {
        border-radius: 20px 0 0 20px;
      }

      &:nth-child(7),
      &:nth-child(14),
      &:nth-child(21),
      &:nth-child(28),
      &:nth-child(35),
      &:nth-child(42) {
        border-radius: 0 20px 20px 0;
      }

      &.react-calendar__decade-view__years__year,
      &.react-calendar__year-view__months__month abbr {
        padding: 10px;
      }

      &--hasActive.react-calendar__tile--now,
      &--hasActive {
        background-color: ${S.purple};
        border-radius: 20px;
        color: ${S.textPrimary};
      }

      &--now {
        background-color: transparent;

        abbr {
          box-shadow: inset 0 0 0 1px ${S.textPrimary};
        }
      }

      &--active {
        background-color: ${S.purpleLight};
        color: ${S.textPrimary};
      }

      &--active:enabled:hover,
      &--active:enabled:focus {
        background-color: ${S.purpleLight};

        abbr {
          box-shadow: inset 0 0 0 1px ${S.textPrimary};
        }
      }

      &:not(&--active):enabled:hover,
      &:not(&--active):enabled:focus {
        background-color: ${S.white};

        abbr {
          box-shadow: inset 0 0 0 1px ${S.textPrimary};
        }
      }

      &--rangeStart {
        border-radius: 20px 0 0 20px;

        abbr {
          background-color: ${S.purple};
          border-radius: 20px;
        }
      }

      &--rangeEnd {
        border-radius: 0 20px 20px 0;

        abbr {
          background-color: ${S.purple};
          border-radius: 20px;
        }
      }

      &--rangeStart.react-calendar__tile--rangeEnd {
        border-radius: 20px;
      }

      abbr {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 6px;
        border-radius: 20px;
      }

      &:disabled {
        background-color: transparent;
        color: ${S.secondGray};
      }
    }

    & abbr {
      text-decoration: none;
      font-weight: 400;
    }

    &--doubleView {
      width: 550px;

      .react-calendar__viewContainer {
        margin: 0;

        & > * {
          margin: 0;

          &:nth-child(2) {
            margin-left: 24px;
          }
        }
      }

      .react-calendar__navigation__label {
        padding: 0 20px;

        &__divider {
          display: ${({ selectRange }) => (selectRange ? 'unset' : 'none')};
        }
      }
    }

    &__navigation {
      margin: 0;
      height: 36px;
      font-size: 14px;

      &__label,
      &__arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
      }

      &__arrow {
        justify-content: center;
      }

      button {
        transition: background-color ${S.trFast};

        svg {
          width: 16px;
          height: 16px;
        }
      }

      button:enabled:hover,
      button:enabled:focus {
        background-color: ${S.lightGray};
      }

      &__arrow {
        &.react-calendar__navigation__next2-button,
        &.react-calendar__navigation__prev2-button {
          display: none;
        }
      }
    }

    &__month-view {
      & button {
        margin-bottom: 4px;
      }

      &__days {
        margin-bottom: -4px;
      }

      &__weekdays {
        &__weekday {
          color: ${S.blueDark};
        }
      }
    }
  }
`;
