import { Component } from 'react';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: '' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, message: error.message };
  }

  componentDidCatch(error, errorInfo) {
    const component = (
      errorInfo.componentStack.match(/(?<comp>at\s\w+)\s/) || {
        groups: { comp: '' },
      }
    ).groups.comp;
    console.warn(component, ': ', error.message);
  }

  render() {
    return this.state.hasError ? this.props.fallback : this.props.children;
  }
}

const defaultFallback = <h2>Ooops</h2>;

ErrorBoundary.defaultProps = {
  fallback: defaultFallback,
};
