import { useSelector } from 'react-redux';

import { getUserValues, checkEqual } from '../../Redux/Selectors/AuthSelectors';

import { StyledPanel } from './styled';
import { UserDropdown } from './userDropdown';

const values = ['companyName', 'name', 'gravatar', 'avatarURL', 'role'];

export const UserPanel = () => {
  const user = useSelector(getUserValues(...values), checkEqual(...values));
  return (
    <StyledPanel>
      <UserDropdown {...user} />
    </StyledPanel>
  );
};
