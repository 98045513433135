import { Flex } from '../../UI/Flex';
import { Button } from '../../UI/Buttons';
import { useMemo, useState } from 'react';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import L from './locale.json';
import { useLocale } from '../../../Utils/Hooks/useLocale';
import { StyledH2 } from '../../UI/Titles';
import { fieldDataCollection } from '../../../Utils/Helpers/stypByStep';
import { StepsForm } from '../../UI/Form/Steps';
import { useMessages } from '../../../Redux/Selectors/MessageSelectors';
import { doOrderBanner } from '../queries';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../Redux/Selectors/AuthSelectors';
import { toPrice } from '../../../Utils/Helpers';

const initialState = {
  text: '',
  description: '',
  note: '',
  periodId: '',
  start: '',
  days: 0,
  templateId: '',
  hue: 0,
};

const initialStateErrors = {
  text: null,
  periodId: null,
  start: null,
  days: null,
  templateId: null,
};

const stepFormConfig = [
  ['text', 'textColor', 'description', 'note', 'periodId'],
  ['start', 'days'],
  ['templateId'],
  ['hue'],
];

export const AddBannerForm = ({ config, fn, onClose, isAdmin }) => {
  const dispatch = useDispatch();
  const { _id } = useSelector(getUserData);
  const [{ titles, buttonNext, buttonAdd, placeholders }, lang] = useLocale(L);
  const { periods, templates } = config;

  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState(initialStateErrors);
  const [step, setStep] = useState(0);

  const switchStep = (step, event) => {
    event.preventDefault();
    setStep(step);
  };

  const disabledStep = useMemo(() => {
    return stepFormConfig[step]?.some(
      (name) => errors[name] || errors[name] === null
    );
  }, [step, errors]);

  const handleChange = (event) => {
    const { error, name, value } = fieldDataCollection(event, lang);
    setInputs((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    doOrderBanner(dispatch, _id, inputs, (_, { data }) => {
      if (data?.data) {
        fn(data.banner);
        onClose();
      }
    });
  };

  const {
    loading: [loading],
  } = useMessages(['orderBanner']);

  const price = useMemo(() => {
    const pricePerDay =
      periods.find(({ _id }) => _id === inputs.periodId)?.pricePerDay || 0;
    const basePrice =
      templates.find(({ _id }) => _id === inputs.templateId)?.basePrice || 0;

    return toPrice(basePrice + pricePerDay * inputs.days, lang);
  }, [periods, templates, inputs, lang]);

  return (
    <>
      <StepsForm
        config={stepFormConfig}
        step={step}
        disabledStep={disabledStep}
        switchStep={switchStep}
      />

      <div>
        <Flex
          alignItems={'stretch'}
          flexDirection={'column'}
          spacer={{ bottom: '24px' }}
        >
          <div>
            <StyledH2>{titles[step]}</StyledH2>
          </div>
          <div>
            <form>
              {step === 0 ? (
                <Step1
                  inputs={inputs}
                  errors={errors}
                  periods={periods}
                  placeholders={placeholders}
                  templates={templates}
                  handleChange={handleChange}
                  isAdmin={isAdmin}
                />
              ) : step === 1 ? (
                <Step2
                  inputs={inputs}
                  errors={errors}
                  handleChange={handleChange}
                />
              ) : step === 2 ? (
                <Step3
                  inputs={inputs}
                  errors={errors}
                  handleChange={handleChange}
                  templates={templates}
                />
              ) : step === 3 ? (
                <Step4
                  inputs={inputs}
                  errors={errors}
                  templates={templates}
                  handleChange={handleChange}
                />
              ) : null}
            </form>
          </div>
          <div>
            <Flex
              justifyContent={'center'}
              spacer={{ left: '12px', bottom: '12px' }}
            >
              <div>
                <Button
                  kind={'green'}
                  disabled={disabledStep || loading}
                  loading={loading}
                  text={
                    step === 3
                      ? `${buttonAdd} ${isAdmin ? '' : price}`
                      : `${buttonNext} ${isAdmin ? '' : price}`
                  }
                  fn={(e) => {
                    step === 3 ? handleSubmitForm(e) : switchStep(step + 1, e);
                  }}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
    </>
  );
};
