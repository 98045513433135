import { createReducer } from '@reduxjs/toolkit';

import {
  addUserServiceItem,
  addUserServiceItemAdmin,
  changeUserAdminServiceItem,
  changeUserServiceItem,
  removeUserAdminServiceItem,
  removeUserServiceItem,
  setUserData,
  setUserServices,
  userAddNotifications,
  userLogout,
  userSetNotifications,
} from '../../Actions/AuthActions';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'auth',
  storage,
};

export const defaultUserState = {
  role: null,
  phone: null,
  name: null,
  avatarURL: null,
  email: null,
  active: null,
  tokenAccess: null,
  tokenRefresh: null,
  services: [],
  notifications: 0,
  hourlyRate: null,
};

const authReducer = createReducer(defaultUserState, (builder) => {
  builder
    .addCase(userSetNotifications, (state, action) => {
      return {
        ...state,
        notifications: action.payload,
      };
    })
    .addCase(userAddNotifications, (state, action) => {
      const { type } = action.payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [type]: (state.notifications[type] || 0) + 1,
          all: state.notifications.all + 1,
        },
      };
    })
    .addCase(setUserData, (state, action) => {
      return { ...state, ...action.payload };
    })
    .addCase(setUserServices, (state, action) => {
      return { ...state, services: action.payload };
    })
    .addCase(addUserServiceItem, (state, action) => {
      return {
        ...state,
        services: [...state.services, action.payload],
      };
    })
    .addCase(addUserServiceItemAdmin, (state, action) => {
      return {
        ...state,
        services: [action.payload, ...state.services],
      };
    })
    .addCase(changeUserServiceItem, (state, action) => {
      const {
        service: { _id },
        time,
        price,
      } = action.payload;
      const addedIndex = state.services.findIndex((s) => s.service._id === _id);

      if (addedIndex >= 0) {
        if (time && price) {
          state.services[addedIndex] = {
            ...state.services[addedIndex],
            price,
            time,
          };
        } else {
          state.services.splice(addedIndex, 1);
        }
      } else {
        state.services.push(action.payload);
      }
    })
    .addCase(changeUserAdminServiceItem, (state, action) => {
      return {
        ...state,
        services: state.services.map((s) =>
          s._id === action.payload._id ? action.payload : s
        ),
      };
    })
    .addCase(removeUserServiceItem, (state, action) => {
      const { _id } = action.payload;
      const newList = state.services?.filter(
        (service) => service.service._id !== _id
      );
      return {
        ...state,
        services: newList,
      };
    })
    .addCase(removeUserAdminServiceItem, (state, action) => {
      const newList = state.services?.filter(
        (service) => service._id !== action.payload
      );
      return {
        ...state,
        services: newList,
      };
    })
    .addCase(userLogout, () => {
      return defaultUserState;
    });
});

const auth = persistReducer(persistConfig, authReducer);

export default auth;
