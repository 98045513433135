import styled from 'styled-components';

import S from '../UI/variables';

export const StyledMain = styled.main`
  max-width: 100%;
  overflow: hidden;
`;

export const StyledMainWithSidebar = styled.div`
  position: relative;
  max-width: 100vw;
  display: flex;
  height: calc(100vh - 70px);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;

  @media screen and (min-width: ${S.tablet}) {
    height: calc(100vh - 85px);
  }
`;

export const StyledConsoleMain = styled.main`
  width: 100%;
  height: 100%;
  overflow: auto;
  color: ${S.textPrimary};
`;

export const StyledMainShade = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(rgba(114, 120, 130, 0.25) -40%, transparent 60%);
`;
