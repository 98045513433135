import { StyledH2 } from '../../Titles';
import { Form } from '../../Form';
import { userNameFormConfig } from '../../../../Configs/formConfigs/userName';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { setUserData } from '../../../../Redux/Actions/AuthActions';
import { updateEmployee, updateUser } from '../../../../API/User';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../../Redux/Selectors/AuthSelectors';

export const EditUserName = ({
  onClose,
  employee = null,
  onChange = () => null,
}) => {
  const { _id, name } = useSelector(getUserData);
  const [locale] = useLocale(L);
  const dispatch = useDispatch();

  const {
    messages: [updateUserMessages],
    loading: [updateUserLoading],
  } = useMessages(['updateUser']);

  const handleEditUserName = (e, formData) => {
    if (employee) {
      dispatch(
        rx(
          authRx,
          'updateUser',
          (dispatch, { data }) => {
            if (data?.employee) {
              onChange(data.employee);
              onClose();
            }
          },
          updateEmployee,
          {
            id: employee._id,
            body: {
              ...formData,
            },
          }
        )
      );
    } else {
      dispatch(
        rx(
          authRx,
          'updateUser',
          (dispatch, { data }) => {
            if (data?.user) {
              dispatch(setUserData(data.user));
              onClose();
            }
          },
          updateUser,
          {
            id: _id,
            body: {
              ...formData,
            },
          }
        )
      );
    }
  };

  return (
    <>
      <StyledH2>{employee ? locale.titleEmployee : locale.title}</StyledH2>
      <Form
        {...{
          ...userNameFormConfig,
          initialState: {
            ...userNameFormConfig.initialState,
            formData: {
              ...userNameFormConfig.initialState.formData,
              name: employee?.name || name || 'New User',
            },
          },
        }}
        onSubmit={handleEditUserName}
        loading={updateUserLoading}
        locale={locale}
        customError={updateUserMessages.error}
        buttonProps={{
          kind: 'green',
          text: locale.submit,
        }}
      />
    </>
  );
};
