import { StyledFlex } from './styled';

export const Flex = ({
  children,
  full = false,
  flexDirection = 'row',
  flexWrap = 'no-wrap',
  spacer = null,
  justifyContent = null,
  alignItems = null,
  as = 'div',
}) => {
  return (
    <StyledFlex
      as={as}
      full={full}
      flexDirection={flexDirection}
      spacer={spacer}
      flexWrap={flexWrap}
      justifyContent={justifyContent}
      alignItems={alignItems}
    >
      {children}
    </StyledFlex>
  );
};
