import styled, { css } from 'styled-components';

import S from '../variables';
import { StyledPreloader } from '../Preloader/styled';

export const StyledAvatarInput = styled.input`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
`;

export const StyledPersonWrapper = styled.div`
  position: relative;
  z-index: 0;

  label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: ${S.white};
    opacity: 0;
    visibility: hidden;
    transition: opacity ${S.trFast}, visibility ${S.trFast};
    border-radius: 50%;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }

  ${StyledPreloader} {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    padding: 0;

    & > div {
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    label {
      opacity: 1;
      visibility: visible;
    }
  }

  button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    height: 16px;
    padding: 0;

    & > .icon > svg {
      width: 8px;
      height: 8px;
    }
  }
`;

export const StyledPersonLogo = styled.div`
  display: block;
  aspect-ratio: 1;
  width: ${({ wd }) => wd || 48}px;
  height: ${({ wd }) => wd || 48}px;
  border-radius: 50%;
  overflow: hidden;

  ${({ outline }) =>
    outline
      ? css`
          box-shadow: inset 0 0 0 3px ${S.white}, 0 0 0 1px ${S.greenDark};
        `
      : null};

  ${({ color }) =>
    color
      ? css`
          box-shadow: inset 0 0 0 2px ${color};
        `
      : null};
`;

export const StyledPicture = styled.img`
  position: relative;
  z-index: -1;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
