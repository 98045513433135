import styled from 'styled-components';
import S from '../../variables';
import { Preloader } from '../../Preloader';
import { ImageView } from '../../ImageView';

export const LightboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: calc(100vw - 80px);
  position: relative;
`;

export const Picture = styled(ImageView)`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scale(${({ slided }) => (slided ? 0.6 : 1)});
  opacity: ${({ slided }) => (slided ? 0 : 1)};
  transition: transform ${S.trSlow}, opacity ${S.trSlow};
`;

export const ArrowContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  justify-content: space-between;
`;

export const ArrowButton = styled.button`
  background: rgba(0, 0, 0, 0.2);
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  margin: 0 20px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &:active {
    background: rgba(0, 0, 0, 0.6);
  }
`;

export const CloseButton = styled(ArrowButton)`
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 0;
  padding-bottom: 4px;
`;

export const Loader = styled(Preloader)`
  background: transparent;
  position: absolute;
  pointer-events: none;
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  transition: opacity ${S.trSlow};
`;
