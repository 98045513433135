import { useNavigate } from 'react-router-dom';
import { StyledNewUserIcon, StyledUserCell } from '../styled';
import { Avatar } from '../../../UI/Avatar';
import { Button } from '../../../UI/Buttons';
import { ReactComponent as IconEdit } from '../../../../Static/icons/console/actions/patch.svg';
import { Flex } from '../../../UI/Flex';
import { useMemo } from 'react';
import { WithTooltip } from '../../../UI/Tooltip';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from '../../locale.json';

export const UserCell = ({ data }) => {
  const [locale] = useLocale(L);
  const navigate = useNavigate();

  const newUser = useMemo(
    () =>
      data?.created
        ? new Date() - new Date(data.created) < 8 * 24 * 3600 * 1000
        : false,
    [data]
  );

  const handleNavigate = (e) => {
    if (data._id) {
      e.stopPropagation();
      navigate((data?.path ? data.path : '/users/') + data._id);
    }
  };

  if (data?._id) {
    return (
      <StyledUserCell
        onClick={data?.navigateAction ? handleNavigate : () => null}
      >
        <Flex spacer={{ left: '12px', bottom: '12px' }}>
          {newUser ? (
            <div>
              <WithTooltip text={locale.newUser}>
                <StyledNewUserIcon />
              </WithTooltip>
            </div>
          ) : null}
          {data?.img !== false && data._id !== true ? (
            <div>
              <Avatar {...data} wd={24} />
            </div>
          ) : null}
          <div>
            <span className={'name'}>
              {data?.companyName || data?.name || ' - '}
            </span>
          </div>
          {data?.action ? (
            data?.action.type === 'edit' ? (
              <div className={'action'}>
                <Button
                  kind={'icon'}
                  Icon={IconEdit}
                  fn={(e) => data?.action.fn(e, data._id)}
                />
              </div>
            ) : null
          ) : null}
        </Flex>
      </StyledUserCell>
    );
  } else {
    return (
      <StyledUserCell>
        <Flex spacer={{ left: '12px', bottom: '12px' }}>
          <div>
            <span className={'name'}>{' - '}</span>
          </div>
          {data?.action ? (
            data?.action.type === 'edit' ? (
              <div className={'action'}>
                <Button
                  kind={'icon'}
                  Icon={IconEdit}
                  fn={(e) => data?.action.fn(e, data._id)}
                />
              </div>
            ) : null
          ) : null}
        </Flex>
      </StyledUserCell>
    );
  }
};
