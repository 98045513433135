import { useDispatch, useSelector } from 'react-redux';
import {
  checkToUpdateSelect,
  getSelect,
} from '../../../../Redux/Selectors/SelectSelectors';
import {
  closeSelect,
  openSelect,
} from '../../../../Redux/Actions/SelectActions';
import { ReactComponent as DateIcon } from '../../../../Static/icons/console/calendar/calendar.svg';
import { DatePicker } from '../../DatePicker';
import {
  StyledPlaceholder,
  StyledSelect,
  StyledSelectDropdownDate,
  StyledSelectIcon,
  StyledSelectLabel,
} from '../../Form/Select/styled';
import {
  StyledErrorsWrapper,
  StyledInputLabel,
  StyledInputName,
} from '../../Form/styled';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { useEffect, useState } from 'react';

export const DatePickerButton = ({
  id = '',
  fn = () => null,
  selectRange = false,
  showDoubleView = true,
  view,
  defaultView = 'month',
  maxDate = null,
  minDate = null,
  selectedDate: selectedDateProp = null,
  label = null,
  kind = 'default',
  onClickMonth = null,
  onClickDay = null,
  onClickYear = null,
  inputRef,
  placeholder,
  errors,
  disabled,
  openTop = false,
  align,
}) => {
  const [, lang] = useLocale();
  const open = useSelector(
    getSelect,
    checkToUpdateSelect(['datePicker_' + id])
  );
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState(selectedDateProp);

  useEffect(() => {
    setSelectedDate((prev) => {
      if (!selectedDateProp) {
        return selectedDateProp;
      }
      if (
        Array.isArray(selectedDateProp) &&
        (selectedDateProp[0] !== prev?.[0] || selectedDateProp[1] !== prev?.[1])
      ) {
        return selectedDateProp;
      }
      if ((Date.parse(selectedDateProp) || 0) !== (Date.parse(prev) || 0)) {
        return selectedDateProp;
      } else {
        return prev;
      }
    });
  }, [selectedDateProp]);

  const onSelect = (data) => {
    fn(data);
    setSelectedDate(
      Array.isArray(data)
        ? data.map((el) => new Date(el))
        : data
        ? new Date(data)
        : data
    );
    dispatch(closeSelect());
  };

  const valueDate = selectedDate
    ? Array.isArray(selectedDate)
      ? `${new Date(selectedDate[0]).toLocaleDateString(lang, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })} - ${new Date(selectedDate[1]).toLocaleDateString(lang, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}`
      : new Date(selectedDate).toLocaleDateString(lang, {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        })
    : '';

  const isOpen = open === 'datePicker_' + id;

  return (
    <StyledInputLabel disabled={disabled}>
      {label ? <StyledInputName>{label}</StyledInputName> : null}
      <StyledSelect
        onClick={(e) => e.stopPropagation()}
        className={isOpen ? 'open' : ''}
      >
        <StyledSelectLabel
          kind={kind}
          onClick={(e) => {
            if (!isOpen) {
              e.stopPropagation();
              dispatch(openSelect('datePicker_' + id));
            } else dispatch(closeSelect());
          }}
          className={`${isOpen ? 'open' : ''} 
          ${errors ? 'has-error' : ''}`}
        >
          <input readOnly type={'hidden'} value={valueDate} />
          <StyledSelectIcon as={DateIcon} />
          {valueDate ? (
            <span>{valueDate}</span>
          ) : (
            <StyledPlaceholder>{placeholder || ''}</StyledPlaceholder>
          )}
        </StyledSelectLabel>

        <StyledSelectDropdownDate
          className={isOpen ? 'open' : ''}
          openTop={openTop}
          align={align}
        >
          <DatePicker
            inputRef={inputRef}
            fn={onSelect}
            selectRange={selectRange}
            showDoubleView={showDoubleView}
            view={view}
            defaultView={defaultView}
            maxDate={maxDate}
            minDate={minDate}
            selectedDate={selectedDate}
            onClickMonth={onClickMonth}
            onClickYear={onClickYear}
            onClickDay={onClickDay}
          />
        </StyledSelectDropdownDate>
      </StyledSelect>
      {errors && typeof errors === 'string' ? (
        <StyledErrorsWrapper errors>
          <span className="error">{errors}</span>
        </StyledErrorsWrapper>
      ) : null}
    </StyledInputLabel>
  );
};
