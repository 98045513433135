import { StyledBell, StyledBellItem } from './styled';
import { ReactComponent as BellIcon } from '../../../Static/icons/console/bell.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../Redux/Selectors/AuthSelectors';

export const BellNotifications = () => {
  const { notifications } = useSelector(getUserData);

  return (
    <StyledBellItem>
      <StyledBell as={Link} to={'notifications/'}>
        <span>
          <BellIcon />
          {Number(notifications?.all) > 0 ? (
            <span>{notifications.all}</span>
          ) : null}
        </span>
      </StyledBell>
    </StyledBellItem>
  );
};
