import { API } from '../index';

export const endpoints = {
  GET_LISTS: '/moderation',
  ADD_LIST: '/moderation',
  UPDATE_LIST: '/moderation',
  DELETE_LIST: '/moderation',
  UPDATE_LIST_TERMS: '/moderation/:id/terms',
  GET_LIST_TERMS: '/moderation/:id/terms',
};

export const getModerationLists = async (config) =>
  await API.get(endpoints.GET_LISTS, config);

export const addModerationList = async (config, { body }) =>
  await API.post(endpoints.ADD_LIST, body, config);

export const updateModerationList = async (config, { body, id }) =>
  await API.put(endpoints.UPDATE_LIST + '/' + id, body, config);

export const deleteModerationList = async (config, { id }) =>
  await API.delete(endpoints.DELETE_LIST + '/' + id, config);

export const getModerationListTerms = async (config, { id }) =>
  await API.get(endpoints.GET_LIST_TERMS.replace(':id', id), config);

export const updateModerationListTerms = async (config, { id, body }) =>
  await API.patch(endpoints.UPDATE_LIST_TERMS.replace(':id', id), body, config);
