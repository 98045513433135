import {
  StyledStatusWrapper,
  StyledTemplateImage,
  StyledTool,
  StyledToolData,
  StyledToolDate,
  StyledToolFlex,
  StyledToolInfo,
  StyledToolPicture,
  StyledToolTitle,
} from '../styled';
import { useId, useMemo, useLayoutEffect, useEffect } from 'react';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { BASE_URL } from '../../../../Configs/constants';
import { statusIcons } from '../../../UI/StatusIcons';
import { WithTooltip } from '../../../UI/Tooltip';
import { useWindowSize } from '../../../../Utils/Hooks/useWindowSize';
import { useRef } from 'react';
import L from './locale.json';
import { Button } from '../../../UI/Buttons';
import { ReactComponent as IconPause } from '../../../../Static/icons/console/actions/pause.svg';
import { ReactComponent as IconPlay } from '../../../../Static/icons/console/actions/play.svg';
import { pauseOrUnpauseBanner } from '../../queries';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Preloader } from '../../../UI/Preloader';
import { GearIndicator } from '../../GearIndicator';
import defaultBannerImage from '../../../../Static/default-banner.png';

export const TemplatePicture = ({ image, preview, full, onLoad }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [image, preview]);

  const handleOnLoad = (e) => {
    setLoading(false);
    onLoad?.(e);
  };

  return (
    <>
      {loading ? <Preloader /> : null}
      {preview || error ? (
        <StyledTemplateImage
          onLoad={handleOnLoad}
          onLoadStart={() => setLoading(true)}
          src={error ? defaultBannerImage : preview}
          onError={handleOnLoad}
          alt=""
        />
      ) : (
        <picture>
          <source
            srcSet={`${BASE_URL}/${image}${full ? '' : '_thmb'}.webp`}
            type="image/webp"
          />
          <source
            srcSet={`${BASE_URL}/${image}${full ? '' : '_thmb'}.png`}
            type="image/png"
          />
          <StyledTemplateImage
            onLoadStart={() => setLoading(true)}
            onLoad={handleOnLoad}
            onError={(e) => {
              setError(true);
              handleOnLoad(e);
            }}
            src={`${BASE_URL}/${image}${full ? '' : '_thmb'}.png`}
            alt=""
          />
        </picture>
      )}
    </>
  );
};

export const BannerImage = ({
  image,
  text,
  hue,
  full = false,
  size,
  margin,
  onLoad,
}) => {
  const ref = useRef(null);
  const trottleRef = useRef(Date.now());
  const timeouRef = useRef(null);
  const [stagedHue, setStagedHue] = useState(hue);
  const { width } = useWindowSize(ref, [142, 379], 0);
  const fontSize = useMemo(() => {
    return width / 10;
  }, [width]);

  useLayoutEffect(() => {
    clearTimeout(timeouRef.current);
    if (Date.now() - trottleRef.current > 200) {
      setStagedHue(hue);
      trottleRef.current = Date.now();
    } else {
      timeouRef.current = setTimeout(() => setStagedHue(hue), 200);
    }
  }, [hue]);

  return (
    <>
      <StyledToolPicture
        ref={ref}
        fontSize={fontSize}
        hue={stagedHue}
        full={full}
        size={size}
        margin={margin}
      >
        {image ? (
          <TemplatePicture image={image} full={full} onLoad={onLoad} />
        ) : null}
        <span>{text}</span>
      </StyledToolPicture>
    </>
  );
};

const GearImage = ({ power, max }) => {
  const id = useId();
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" fill="none">
        <defs>
          <clipPath clipPathUnits="objectBoundingBox" id={id}>
            <path
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.531595 0C0.570428 0 0.602943 0.0294281 0.606807 0.0680682C0.612807 0.12807 0.683206 0.157235 0.729877 0.11905C0.75993 0.0944614 0.803726 0.0966458 0.831183 0.124103L0.875864 0.168784C0.903325 0.196245 0.90551 0.240047 0.880918 0.270104C0.842728 0.316781 0.871904 0.38719 0.931914 0.393191C0.970562 0.397056 1 0.429579 1 0.46842V0.531609C1 0.570434 0.97058 0.602942 0.931948 0.606805C0.871955 0.612804 0.842812 0.683207 0.880991 0.729871C0.905581 0.759926 0.903403 0.803731 0.875944 0.83119L0.831279 0.875854C0.80381 0.903323 0.759996 0.905509 0.72993 0.880909C0.68324 0.842708 0.612812 0.871882 0.606809 0.931909C0.602944 0.970563 0.570417 1 0.531569 1H0.468418C0.429578 1 0.397057 0.970569 0.393192 0.931921C0.38719 0.871905 0.31677 0.842741 0.270089 0.880935C0.240026 0.905532 0.196215 0.903348 0.168749 0.875883L0.124085 0.831218C0.0966227 0.803756 0.0944418 0.759948 0.119035 0.72989C0.157221 0.683218 0.128068 0.612807 0.0680642 0.606806C0.0294249 0.602942 0 0.570428 0 0.531596V0.468432C0 0.429584 0.0294463 0.397055 0.0681016 0.39319C0.128118 0.387188 0.157305 0.31677 0.11911 0.270088C0.094515 0.240027 0.0966998 0.19622 0.124164 0.168756L0.168845 0.124075C0.196299 0.0966207 0.24009 0.0944369 0.27014 0.119023C0.316805 0.157203 0.387195 0.128047 0.393195 0.0680532C0.397058 0.0294201 0.429567 0 0.468393 0H0.531595ZM0.5 0.7C0.610457 0.7 0.7 0.610457 0.7 0.5C0.7 0.389543 0.610457 0.3 0.5 0.3C0.389543 0.3 0.3 0.389543 0.3 0.5C0.3 0.610457 0.389543 0.7 0.5 0.7Z"
            />
          </clipPath>
        </defs>
      </svg>
      <StyledToolPicture
        clipPath={`url(#${id})`}
        power={(power / max) * 100}
      ></StyledToolPicture>
    </>
  );
};

export const BannerItem = ({
  _id,
  active,
  paused: pausedProp,
  toolName,
  description,
  created,
  approved,
  start,
  expires,
  image,
  hue,
  text,
  power,
  min,
  max,
}) => {
  const [locale, lang] = useLocale(L);
  const [paused, setPaused] = useState(pausedProp);
  const dispatch = useDispatch();
  const { dates, StatusIcon, tooltip } = useMemo(() => {
    const startDate = Math.max(
      Date.parse(created),
      Date.parse(start || 0),
      approved ? Date.parse(approved) : 0
    );
    const endDate = Date.parse(expires);

    return {
      dates: new Intl.DateTimeFormat(lang, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).formatRange(startDate, endDate),
      StatusIcon:
        statusIcons[approved ? 'settled' : active ? 'pending' : 'rejected'],
      tooltip: approved
        ? locale.approved
        : active
        ? locale.pending
        : locale.notActive,
    };
  }, [locale, lang, approved, created, expires, start, active]);
  return (
    <StyledTool>
      <StyledToolFlex>
        {image ? (
          <BannerImage image={image} text={text} hue={hue} />
        ) : (
          <GearImage power={power} min={min} max={max} />
        )}
        <StyledToolData>
          <StyledToolTitle>{toolName}</StyledToolTitle>
          <StyledToolDate as="div">
            <WithTooltip text={tooltip}>
              <StatusIcon />
            </WithTooltip>
            {dates}
          </StyledToolDate>
          <StyledToolInfo as="div">
            {power !== undefined ? (
              <>
                <StyledToolTitle>{locale.gears}:</StyledToolTitle>
                <GearIndicator power={power} maxPower={max} />
              </>
            ) : (
              <>
                <StyledToolTitle>{locale.description}:</StyledToolTitle>
                {description}
              </>
            )}
          </StyledToolInfo>
        </StyledToolData>
        {paused !== undefined ? (
          <StyledStatusWrapper>
            <div>
              <Button
                Icon={paused ? IconPlay : IconPause}
                kind={'circle'}
                fn={() => {
                  const newPaused = !paused;
                  setPaused(newPaused);
                  pauseOrUnpauseBanner(
                    dispatch,
                    _id,
                    newPaused,
                    (_, { data }) =>
                      setPaused(data?.banner ? data.banner.paused : !newPaused)
                  );
                }}
              />
            </div>
          </StyledStatusWrapper>
        ) : null}
      </StyledToolFlex>
    </StyledTool>
  );
};

export const SearchBoostItem = ({ gears, ...props }) => {
  const { min, max } = useMemo(() => {
    const gearsPower = gears.map(({ power }) => power);
    return { min: Math.min(...gearsPower), max: Math.max(...gearsPower) };
  }, [gears]);
  return (
    <BannerItem
      {...props}
      approved={props.active ? props.created : null}
      text=""
      min={min}
      max={max}
    />
  );
};
