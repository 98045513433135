import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import {
  addCommentToReview,
  addReview,
  editComment,
  editReview,
  getPartnerReviews,
} from '../../../../API/Reviews';
import { useDispatch } from 'react-redux';
import { StyledH2 } from '../../Titles';
import { Textarea } from '../../Form/Textarea';
import { useState } from 'react';
import { Button } from '../../Buttons';
import {
  StyledCustomError,
  StyledFormButtonBlock,
  StyledFormGroup,
  StyledInputName,
} from '../../Form/styled';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { Grade } from '../../../Grade';
import { Flex } from '../../Flex';
import { useEffect } from 'react';
import { Preloader } from '../../Preloader';
import { ReviewItem } from '../../../Reviews/ReviewItem';
import { StyledReviewList } from './styled';
import { roles } from '../../../../Configs/constants';

const ReviewBlock = ({ comment, setComment, grade, setGrade, error }) => {
  const [locale] = useLocale(L);
  return (
    <>
      {setGrade ? (
        <StyledFormGroup>
          <Flex spacer={{ left: '24px', bottom: '24px' }} alignItems={'center'}>
            <div>
              <StyledInputName>{locale.grade}</StyledInputName>
            </div>
            <div>
              <Grade grade={grade} ht={20} fn={setGrade} showValue />
            </div>
          </Flex>
        </StyledFormGroup>
      ) : null}
      <StyledFormGroup>
        <Textarea
          label={locale.massage}
          name={'review'}
          value={comment}
          fn={(e) => setComment(e.target.value)}
        />
      </StyledFormGroup>
      {error ? <StyledCustomError>{error}</StyledCustomError> : null}
    </>
  );
};

export const EditReview = ({
  id,
  commentId,
  grade,
  onClose,
  text = '',
  fn,
}) => {
  const dispatch = useDispatch();
  const [reviewText, setReviewText] = useState(text);
  const [gradeState, setGradeState] = useState(grade);
  const [locale] = useLocale(L);

  const {
    messages: [editReviewMessages],
    loading: [editReviewLoading],
  } = useMessages(['editReview']);

  const handleEdit = (e) => {
    e.preventDefault();

    commentId
      ? dispatch(
          rx(
            authRx,
            'editReview',
            (dispatch, { data }) => {
              if (data?.review) {
                fn((prevState) => {
                  return prevState.map((r) =>
                    r._id === data.review._id ? data.review : r
                  );
                });

                onClose();
              }
            },
            editComment,
            {
              id: id,
              commentId,
              body: {
                comment: reviewText,
              },
            }
          )
        )
      : dispatch(
          rx(
            authRx,
            'editReview',
            (dispatch, { data }) => {
              if (data?.review) {
                fn((prevState) => {
                  return prevState.map((r) =>
                    r._id === data.review._id ? data.review : r
                  );
                });

                onClose();
              }
            },
            editReview,
            {
              id: id,
              body: {
                grade: gradeState,
                comment: reviewText,
              },
            }
          )
        );
  };

  return (
    <>
      <StyledH2>{commentId ? locale.titleComment : locale.title}</StyledH2>
      <form noValidate={true} onSubmit={handleEdit}>
        <ReviewBlock
          grade={gradeState}
          comment={reviewText}
          setGrade={!commentId && setGradeState}
          setComment={setReviewText}
          error={editReviewMessages.error}
        />
        <StyledFormButtonBlock>
          <Button
            type={'submit'}
            kind={'green'}
            text={locale.submit}
            loading={editReviewLoading}
          />
        </StyledFormButtonBlock>
      </form>
    </>
  );
};

export const AddComment = ({ id, onClose, fn }) => {
  const [comment, setComment] = useState('');
  const [locale] = useLocale(L);
  const dispatch = useDispatch();

  const {
    loading: [loading],
    messages: [{ error }],
  } = useMessages([`addCommentToReview_${id}`]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (comment?.length > 0) {
      dispatch(
        rx(
          authRx,
          `addCommentToReview_${id}`,
          (_, { data }) => {
            if (data?.review) {
              fn(data?.review);
              onClose();
            }
          },
          addCommentToReview,
          {
            id,
            body: {
              comment,
            },
          }
        )
      );
    }
  };

  return (
    <>
      <StyledH2>{locale.titleAddCommentToReview}</StyledH2>
      <form noValidate={true} onSubmit={handleSubmit}>
        <ReviewBlock comment={comment} setComment={setComment} error={error} />
        <StyledFormButtonBlock>
          <Button
            type={'submit'}
            kind={'green'}
            text={locale.submitAdd}
            loading={loading}
            disabled={!comment.length || loading}
          />
        </StyledFormButtonBlock>
      </form>
    </>
  );
};

export const PartnerReviews = ({ companyId, grade = 0 }) => {
  const dispatch = useDispatch();
  const [reviewText, setReviewText] = useState('');

  const [gradeState, setGradeState] = useState(grade);
  const [reviews, setReviews] = useState([]);
  const [locale] = useLocale(L);

  const {
    messages: [{ error }],
    loading: [loading, getReviewsLoading],
  } = useMessages(['addReview', `getReviews_${companyId}`]);

  useEffect(() => {
    dispatch(
      rx(
        authRx,
        `getReviews_${companyId}`,
        (_, { data }) => {
          if (data?.reviews) {
            setReviews(data.reviews);
          }
        },
        getPartnerReviews,
        { id: companyId }
      )
    );
  }, [dispatch, companyId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      rx(
        authRx,
        'addReview',
        (_, { data }) => {
          if (data?.review) {
            setReviews((prevState) => {
              return [data.review, ...prevState];
            });
          }
        },
        addReview,
        {
          body: {
            grade: gradeState,
            comment: reviewText,
            company: companyId,
          },
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{locale.titleAdd}</StyledH2>
      <form noValidate={true} onSubmit={handleSubmit}>
        <ReviewBlock
          grade={gradeState}
          comment={reviewText}
          setGrade={setGradeState}
          setComment={setReviewText}
          error={error}
        />
        <StyledFormButtonBlock>
          <Button
            type={'submit'}
            kind={'green'}
            text={locale.submitAdd}
            loading={loading}
          />
        </StyledFormButtonBlock>
      </form>
      {getReviewsLoading ? (
        <Preloader />
      ) : (
        <StyledReviewList>
          {reviews.map((review) => (
            <ReviewItem
              review={review}
              key={review._id}
              actionsReview={false}
              userRole={roles.PARTNER}
            />
          ))}
        </StyledReviewList>
      )}
    </>
  );
};
