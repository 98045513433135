import { Input } from '../../../Components/UI/Form/Input';

export const defaultUserNameFormData = { name: '' };

export const defaultUserNameFormErrors = { name: '' };

export const initialUserNameFormState = {
  formData: defaultUserNameFormData,
  errors: defaultUserNameFormErrors,
};

export const setUserNameFormConfig = [
  {
    Element: Input,
    props: {
      name: 'name',
      placeholder: (l) => l.placeholders.name,
      type: 'text',
      required: true,
    },
  },
];
