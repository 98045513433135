import { useState } from 'react';

import { NavLink } from 'react-router-dom';

import { routes } from '../../Routes';

import { useDispatch, useSelector } from 'react-redux';

import L from './locale.json';

import {
  StyledSidebar,
  StyledSidebarList,
  StyledSidebarLink,
  StyledSidebarLogoutItem,
  StyledSidebarToggle,
  StyledSidebarToggleButton,
  StyledSidebarScroll,
  StyledAside,
} from './styled';

import { ReactComponent as LogoutIcon } from '../../Static/icons/sidebar/logout.svg';
import { useLocale } from '../../Utils/Hooks/useLocale';
import { openModal } from '../../Redux/Actions/ModalActions';
import { ReactComponent as Icon } from '../../Static/icons/console/actions/double-arrow-left.svg';
import { getUserData } from '../../Redux/Selectors/AuthSelectors';
import { isEmployeeAccessRoute } from '../../Utils/Helpers/isEmployeeAccessRoute';
import { WithTooltip } from '../UI/Tooltip';

const SidebarToggle = ({ expanded, onClick }) => {
  const handleClick = (e) => {
    e.currentTarget.blur();
    onClick(e);
  };
  return (
    <StyledSidebarToggle>
      <StyledSidebarToggleButton expanded={expanded} onClick={handleClick}>
        <Icon />
      </StyledSidebarToggleButton>
    </StyledSidebarToggle>
  );
};

const LogoutTab = () => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const handleClickLogout = () => {
    dispatch(openModal({ Comp: 'logoutModal' }));
  };

  return (
    <WithTooltip text={locale.logout} showOnOverflow>
      <StyledSidebarLogoutItem onClick={handleClickLogout}>
        <LogoutIcon />
        <span>{locale.logout}</span>
      </StyledSidebarLogoutItem>
    </WithTooltip>
  );
};

export const Sidebar = ({ Auth }) => {
  const userData = useSelector(getUserData);
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => setExpanded(!expanded);

  const [locale] = useLocale(L);

  return (
    <StyledAside>
      <StyledSidebar expanded={expanded}>
        <StyledSidebarScroll>
          <StyledSidebarList>
            <SidebarToggle expanded={expanded} onClick={handleExpand} />
            {routes.map(({ access, accessEmployee, Icon, path, name }) =>
              isEmployeeAccessRoute(access, accessEmployee, userData, true) ? (
                <WithTooltip
                  key={name}
                  text={locale[name]}
                  showOnOverflow={expanded}
                >
                  <li>
                    <StyledSidebarLink as={NavLink} to={path}>
                      {Icon ? <Icon /> : null}
                      <span>{name ? locale[name] : 'Not found name link'}</span>
                    </StyledSidebarLink>
                  </li>
                </WithTooltip>
              ) : null
            )}
            <LogoutTab />
          </StyledSidebarList>
        </StyledSidebarScroll>
      </StyledSidebar>
    </StyledAside>
  );
};
