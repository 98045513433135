import { StyledFormGroup, StyledInputName } from '../../../UI/Form/styled';
import { Textarea } from '../../../UI/Form/Textarea';
import { Flex } from '../../../UI/Flex';
import { StyledPeriodSecond } from '../styled';
import { MaxContainerXs } from '../../../UI/Containers';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { BannerImage } from '../../Partner/ToolItem';
import { useCallback, useMemo } from 'react';
import { PartnerSelect } from '../../Partner/PartnerSelect';

export const BannerPeriods = ({ periods, selected, onSelect, onAdd }) => {
  const [, lang] = useLocale();
  return (
    <Flex
      full={true}
      spacer={{
        left: '14px',
        bottom: '14px',
      }}
    >
      {periods.map((period) => {
        const { _id, time } = period;
        return (
          <div key={_id}>
            <StyledPeriodSecond active={selected === _id}>
              <span>
                {new Intl.NumberFormat(lang, {
                  style: 'unit',
                  unit: 'second',
                  notation: 'compact',
                  compactDisplay: 'long',
                  unitDisplay: 'long',
                }).format(time / 1000)}
              </span>
              <input
                type={'radio'}
                name={'periodId'}
                value={_id}
                checked={selected === _id}
                required={true}
                onChange={(e) => onSelect(e, period)}
              />
            </StyledPeriodSecond>
          </div>
        );
      })}
      {onAdd ? (
        <div>
          <StyledPeriodSecond onClick={onAdd}>
            <span>+</span>
          </StyledPeriodSecond>
        </div>
      ) : null}
    </Flex>
  );
};

export const Step1 = ({
  inputs,
  placeholders,
  templates,
  errors,
  periods,
  handleChange,
  isAdmin,
}) => {
  const { text, hue, templateId } = inputs;

  const { image } = useMemo(
    () => templates.find(({ _id }) => _id === templateId) || {},
    [templates, templateId]
  );
  const setCompany = useCallback(
    (v) => {
      handleChange({ target: { name: 'company', value: v || undefined } });
    },
    [handleChange]
  );

  return (
    <MaxContainerXs>
      <StyledFormGroup>
        <BannerImage text={text} image={image} hue={hue} full size={144} />
      </StyledFormGroup>
      {isAdmin ? (
        <StyledFormGroup>
          <PartnerSelect onChange={setCompany} />
        </StyledFormGroup>
      ) : null}
      <StyledFormGroup>
        <Textarea
          name={'text'}
          value={inputs.text}
          fn={handleChange}
          errors={errors.header}
          placeholder={placeholders.header}
          label={placeholders.header}
          required={true}
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <Textarea
          name={'description'}
          value={inputs.description}
          fn={handleChange}
          errors={errors.offerDescription}
          placeholder={placeholders.offerDescription}
          label={placeholders.offerDescription}
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <Textarea
          name={'note'}
          value={inputs.note}
          fn={handleChange}
          errors={errors.note}
          placeholder={placeholders.note}
          label={placeholders.note}
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <StyledInputName>
          {placeholders.periodId}
          {'*'}
        </StyledInputName>
        <BannerPeriods
          periods={periods}
          onSelect={handleChange}
          selected={inputs.periodId}
        />
      </StyledFormGroup>
    </MaxContainerXs>
  );
};
