import {
  StyledFormButtonBlock,
  StyledFormGroup,
} from '../../../UI/Form/styled';
import { Input } from '../../../UI/Form/Input';
import { MaxContainerXs } from '../../../UI/Containers';
import L from './locale.json';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { useState } from 'react';
import { validation } from '../../../../Utils/validation';
import { CarDetails } from '../../../Orders/ViewOrder';
import { Button } from '../../Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCarByVIN, refetchUser, submitAddCar } from '../../../queries';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { getUserData } from '../../../../Redux/Selectors/AuthSelectors';

export const AddCarModal = ({ onClose }) => {
  const [{ vin, number }, setState] = useState({ vin: '', number: '' });
  const [errors, setErrors] = useState({});
  const [locale, lang] = useLocale(L);
  const dispatch = useDispatch();
  const { _id: userId } = useSelector(getUserData);

  const [car, setCar] = useState(null);

  const {
    loading: [loadingCarData, loadingAddCar],
  } = useMessages(['checkVIN', 'addCar']);

  const handleChange = ({ target }) => {
    const { value, error, name } = validation(target, lang);
    setState((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: error }));
  };

  const handleCheck = () => {
    fetchCarByVIN(dispatch, { vin, number }, ({ field, conflict, car }) => {
      if (car) {
        setCar(car);
      } else {
        setCar(null);
        setErrors((prev) => ({
          ...prev,
          vin:
            field === 'vin'
              ? locale.errors.invalidVIN
              : conflict === 'vin'
              ? locale.errors.conflictVIN
              : conflict === 'number'
              ? null
              : 'Something went wrong',
          number: conflict === 'number' ? locale.errors.conflictNumber : null,
        }));
      }
    });
  };

  const handleReset = () => {
    setCar(null);
  };

  const handleSubmit = () => {
    submitAddCar(dispatch, car._id, ({ field, conflict, car }) => {
      if (car) {
        refetchUser(dispatch, userId);
        onClose();
      } else {
        setCar(null);
        setErrors((prev) => ({
          ...prev,
          vin:
            field === 'vin'
              ? locale.errors.invalidVIN
              : conflict === 'vin'
              ? locale.errors.conflictVIN
              : conflict === 'number'
              ? null
              : 'Something went wrong',
          number: conflict === 'number' ? locale.errors.conflictNumber : null,
        }));
      }
    });
  };

  return (
    <MaxContainerXs>
      {car ? (
        <CarDetails {...car} isViewOnly />
      ) : (
        <>
          <StyledFormGroup>
            <Input
              label={locale.vinTitle}
              name={'vin'}
              type={'text'}
              value={vin}
              fn={handleChange}
              placeholder={locale.vinPlaceholder}
              required
              errors={errors?.vin || null}
              disabled={loadingCarData}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <Input
              label={locale.plateTitle}
              name={'number'}
              type={'text'}
              value={number}
              fn={handleChange}
              placeholder={locale.platePlaceholder}
              required
              errors={errors?.number || null}
              disabled={loadingCarData}
            />
          </StyledFormGroup>
        </>
      )}
      <StyledFormButtonBlock style={{ gap: '20px' }}>
        {car ? (
          <>
            <Button
              text={locale.cancelButton}
              fn={handleReset}
              disabled={loadingAddCar}
            />
            <Button
              kind={'green'}
              text={locale.submitButton}
              fn={handleSubmit}
              disabled={loadingAddCar}
              loading={loadingAddCar}
            />
          </>
        ) : (
          <Button
            text={locale.nextButton}
            fn={handleCheck}
            disabled={
              loadingCarData || !vin || !number || errors?.vin || errors?.number
            }
            loading={loadingCarData}
          />
        )}
      </StyledFormButtonBlock>
    </MaxContainerXs>
  );
};
