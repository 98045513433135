import styled from 'styled-components';

import S from '../../UI/variables';
import { StyledCheckInputWrapper } from '../../UI/Form/styled';

const StyledCell = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const StyledBannerCell = styled(StyledCell)`
  cursor: pointer;
  & > * {
    flex-shrink: 0;
    &:nth-child(1) {
      margin-right: 8px;
    }
  }
`;

export const StyledUserCell = styled(StyledCell)`
  margin-right: 24px;
  padding: 4px;
  cursor: pointer;

  & > div {
    width: calc(100% + 12px);
  }

  & > ${StyledCheckInputWrapper} {
    flex-shrink: 0;
    pointer-events: none;
  }

  & span.name {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .action {
    margin-left: auto;
    opacity: 0;
    visibility: hidden;
    transition: opacity ${S.trFast}, visibility ${S.trFast};
  }

  &:hover {
    & .action {
      opacity: 1;
      visibility: visible;
    }
  }

  & button {
    margin-left: auto;
  }

  ${({ vertical }) =>
    vertical ? 'display: flex; flex-direction: column;' : ''}
`;

export const StyledNewUserIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${S.greenLight};
`;

export const StyledAlignCell = styled(StyledCell)`
  position: relative;
  color: transparent;
  pointer-events: none;
  width: fit-content;
  font-weight: 500;
`;

export const StyledAlignedCell = styled(StyledCell)`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  color: initial;
  justify-content: flex-end;
  pointer-events: auto;
  font-weight: 400;
`;

export const StyledRealPrice = styled.span`
  white-space: nowrap;
  color: ${S.accentRed};
  text-decoration: line-through ${S.negative} 2px;
  margin-right: 8px;
`;

export const StyledDiscount = styled.sup`
  font-size: 10px;
  margin-left: 4px;
  color: ${S.negative};
`;

export const StyledBannerText = styled.div`
  max-width: 132px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: underline;
`;
