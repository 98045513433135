import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';

const initOrderState = createAction(types.INIT_ORDER_STATE);
const setVehicle = createAction(types.SET_VEHICLE);
const setOrderStart = createAction(types.SET_START);
const selectService = createAction(types.SELECT_SERVICE);
const deselectService = createAction(types.DESELECT_SERVICE);
const toggleService = createAction(types.TOGGLE_SERVICE);
const setSelectedServices = createAction(types.SET_SERVICES);
const setOrderCompany = createAction(types.SET_COMPANY);
const setClientComment = createAction(types.SET_COMMENT);
const setClientImages = createAction(types.SET_IMAGES);

export {
  initOrderState,
  setVehicle,
  setOrderStart,
  selectService,
  deselectService,
  setSelectedServices,
  toggleService,
  setOrderCompany,
  setClientComment,
  setClientImages,
};
