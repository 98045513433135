import { validation } from '../validation';

export const checkStep = (index, step, disabledStep, canNavigate) => {
  let classes = [];

  if (index === step) {
    classes.push('is-use');
  }

  if (index < step || (index === step && !disabledStep)) {
    classes.push('is-ready');
  } else if (
    canNavigate ||
    index === step ||
    (index === step + 1 && !disabledStep)
  ) {
    classes.push('is-enabled');
  }

  return classes;
};

export const fieldDataCollection = (event, lang = 'en') => {
  const name = event.target.name;
  const { value, error } = validation(event.target, lang);

  return { error, value, name };
};
