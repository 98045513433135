import { createGlobalStyle } from 'styled-components';
import S from './Components/UI/variables';

export const GlobalStyle = createGlobalStyle`
    
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: ${S.textPrimary};
  font-size: 16px;
  font-weight: 400;
  min-width: 320px;
  overflow-x: hidden;
  
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${S.white};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    border: 4px solid #fff;
    background-color: ${S.purpleDark};
    cursor: pointer;
  }

  & *::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  & *::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 4px;
  }

  & *::-webkit-scrollbar-thumb {
    border-radius: 6px;
    border: 4px solid #fff;
    background-color: ${S.purpleDark};
    cursor: pointer;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
  & footer {
    margin-top: auto;
  }
  
  @media print {
    & header, & footer, & aside {
      display: none;
    }
    & .main-with-sidebar {
      height: auto;
    }
  }
}

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

*::selection {
  background: ${S.purple};
  color: ${S.black};
}

:root {
  -moz-tab-size: 4;
  tab-size: 4;
}
a {
  text-decoration: none;
}
html {
  line-height: 1.2;
  -webkit-text-size-adjust: 100%;
}
hr {
  height: 0;
}
abbr[title] {
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  user-select: auto;
}
button,
select {
  text-transform: none;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  padding: 0;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
button {
  outline: none;
  background: none;
  border: none;
}
ul {
  list-style-type: none;
}
input {
  user-select: text;
  
  &::selection {
    background: ${S.greenDark}; 
    color: ${S.greenLight};
  }
}

`;
