import styled, { css } from 'styled-components';

import S from '../variables';

import { rgba } from 'polished';
import { StyledMainButton } from '../Buttons/styled';

export const StyledForm = styled.form`
  box-shadow: none;
`;

export const StyledFormButtonBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export const StyledCustomError = styled.div`
  text-align: center;
  margin: 16px 0;
  font-weight: 500;
  color: ${S.red};
`;

export const StyledInputLabel = styled.label`
  display: block;
  position: relative;
  width: fit-content;
  min-width: 100%;
  max-width: 100%;

  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          opacity: 0.7;
        `
      : ``}
`;

export const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledFormGroup = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const StyledInputName = styled.div`
  font-size: 16px;
  color: ${S.textPrimary};

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  ${StyledFormGroup}:not(:first-child) & {
    padding-top: 8px;
  }
`;

export const StyledInput = styled.input`
  display: block;
  appearance: none;
  box-shadow: none;
  outline: none;
  border: 1px solid ${S.strokeGray};
  line-height: 46px;
  font-size: 17px;
  padding: 0 16px;
  border-radius: 15px;
  background-color: ${S.white};
  transition: box-shadow ${S.trFast}, opacity ${S.trFast},
    border-color ${S.trFast};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.has-error {
    border-color: ${S.error};
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:not(.has-error).has-value {
    border-color: ${S.purpleDark};
  }

  &:hover:not(.has-error):not(:disabled):not(:focus):not(:focus-within) {
    border-color: ${S.purpleDark};
  }

  &:focus:not(.has-error) {
    border-color: ${S.textPrimary};
  }

  &[disabled]:not([readonly]) {
    background-color: ${S.lightGray};
    color: ${S.textSecondary};
  }

  &:-webkit-autofill {
    box-shadow: none !important;
  }

  &::placeholder {
    color: ${S.textSecondary};
  }
`;

export const StyledCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  & ${StyledInput} {
    padding: 12px;
    width: 70px;
    height: 80px;
    text-align: center;
  }
`;

export const StyledTextarea = styled(StyledInput)`
  height: 120px;
  line-height: 1.2;
  white-space: normal;
  padding: 0;
  &:focus-within:not(.has-error) {
    border-color: ${S.textPrimary};
  }
  & > textarea {
    width: 100%;
    padding: 16px;
    resize: none;
    border: none;
    outline: none;
    height: 100%;
  }

  ${({ readOnly }) => (readOnly ? `pointer-events: none;` : '')}
`;

export const StyledCodeInput = styled(StyledInput)`
  width: 48px;
`;

export const StyledErrorsWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  height: ${({ errors }) => (errors ? 'auto' : 0)}px;
  opacity: ${({ errors }) => (errors ? 1 : 0)};
  transition: opacity ${S.trFast};

  & > span {
    display: block;
    width: 100%;
    color: ${S.error};
    font-size: 10px;
    overflow: hidden;
    transform: translateY(${({ errors }) => (errors ? '0' : '-100%')});
    transition: transform ${S.trFast},
      opacity ${S.trFast}${({ errors }) => (errors ? ' 100ms' : '')};
  }
`;

export const StyledPhonePrefix = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 14px 16px;
  border-right: 1px solid ${S.strokeGray};
  color: ${S.textSecondary};
`;

export const StyledPhoneInput = styled(StyledInput)`
  padding-left: 86px;
`;

export const StyledImageLabelWrap = styled.span`
  position: relative;
  z-index: 1;
  border: 1px dashed ${S.strokeGray};
  border-radius: 15px;
  padding: 16px;
  overflow: hidden;
  transition: border-color ${S.trFast};

  img {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledImageLabelWrapDefault = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${S.strokeGray};
  font-weight: 600;
  transition: color ${S.trFast};

  svg {
    width: 12px;
    height: 12px;
    fill: currentColor;

    &:not(:last-child) {
      margin-right: 7px;
    }
  }
`;

export const StyledImageInputContainer = styled.div`
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;

export const StyledImageLabel = styled.label`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover ${StyledImageLabelWrap} {
    border-color: ${S.purpleLanding};
  }

  &:hover ${StyledImageLabelWrapDefault} {
    color: ${S.purpleLanding};
  }
`;

export const StyledImagesGroup = styled.div`
  padding-top: 16px;
`;

export const StyledImagesGroupItem = styled.div`
  width: ${({ actions }) =>
    actions ? 'calc(100% / 3) !important' : 'calc(100% / 4) !important'};
`;

export const StyledImagesGroupRatio = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  padding-top: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledImageLabelDeleteImage = styled.span`
  background-color: ${rgba('#676767', 0.5)};
  transition: background-color ${S.trFast};
  color: ${S.white};
  font-weight: 600;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity ${S.trFast};

  &:hover {
    opacity: 1;
  }

  ${StyledMainButton} {
    &:hover {
      transform: scale(1.1);
      color: ${S.purpleDark};
      background-color: ${S.purpleLight};
      border-color: ${S.purpleLight};
    }
  }
`;

export const StyledImageLabelNote = styled.span`
  padding-top: 4px;
  font-size: 12px;
  color: ${S.textSecondary};
`;

const checkSvg = `data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7017 0.287516C12.0952 0.675048 12.1 1.30819 11.7125 1.70169L5.09091 8.42513L0.287516 3.54784C-0.100015 3.15435 -0.0951824 2.5212 0.298312 2.13367C0.691806 1.74614 1.32495 1.75097 1.71248 2.14447L5.09091 5.57487L10.2875 0.298312C10.675 -0.0951824 11.3082 -0.100015 11.7017 0.287516Z' fill='%231A434E'/%3E%3C/svg%3E%0A`;

export const StyledCheckInputWrapper = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    height: 1px;
    width: 1px;

    &:checked ~ span {
      &::after {
        transform: scale(1);
      }

      &::before {
        background-color: ${S.greenDark};
      }
    }

    &:focus-visible ~ span {
      &::after {
        transform: scale(0.5);
      }
    }

    &[disabled],
    &[readonly] {
      ~ span {
        cursor: default;
        user-select: none;
      }
    }

    &[disabled]:not([readonly]) ~ span {
      color: ${S.textSecondary};

      &::after {
        background-color: ${S.textSecondary};
      }

      &::before {
        background-color: ${S.strokeGray};
        border-color: ${S.strokeGray};
      }
    }

    &.has-error {
      ~ span {
        color: ${S.error};

        &::before {
          background-color: transparent;
          border-color: ${S.error};
        }

        &::after {
          background-color: ${S.error};
        }
      }
    }
  }

  span {
    display: flex;
    align-items: flex-start;
    color: ${S.black};
    transition: color ${S.trFast};

    &::after {
      content: '';
      transform: scale(0);
      background: ${S.greenLight}
        ${({ checkedType }) =>
          checkedType ? `url("${checkSvg}") no-repeat center center` : ''};
      border-radius: ${({ checkedType }) => (checkedType ? '4px' : '50%')};
      height: ${({ checkedType, size }) =>
        checkedType ? '22px' : size === 'small' ? '8px' : '12px'};
      width: ${({ checkedType, size }) =>
        checkedType ? '22px' : size === 'small' ? '8px' : '12px'};
      position: absolute;
      top: ${({ checkedType, size }) =>
        checkedType ? '-1px' : size === 'small' ? '4px' : '6px'};
      left: ${({ checkedType, size }) =>
        checkedType ? '-1px' : size === 'small' ? '4px' : '6px'};
      transition: transform ${S.trFast};
      z-index: 1;
      transform-origin: center center;
    }

    &::before {
      content: '';
      border-radius: ${({ checkedType }) => (checkedType ? '4px' : '50%')};
      border: 1px solid ${S.greenDark};
      height: ${({ checkedType, size }) =>
        checkedType ? '20px' : size === 'small' ? '16px' : '24px'};
      width: ${({ checkedType, size }) =>
        checkedType ? '20px' : size === 'small' ? '16px' : '24px'};
      position: relative;
      flex-shrink: 0;
      margin-right: 8px;
      transition: background-color ${S.trFast};
    }

    a {
      text-decoration: none;
    }
  }

  &:hover {
    input:not([disabled]):not([readonly]) {
      ~ span {
        color: ${S.greenDark};

        &::after {
          border-color: ${S.greenDark};
        }
      }
    }
  }
`;
