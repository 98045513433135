import { ReactComponent as GearIcon } from '../../../Static/icons/gear.svg';
import { StyledGearIndicator, StyledGear } from './styled';

export const GearIndicator = ({ maxPower, power }) => {
  return (
    <StyledGearIndicator>
      {Array.from({ length: maxPower }, (_, i) => (
        <li key={i}>
          <StyledGear as={GearIcon} $activeGear={power > i} />
        </li>
      ))}
    </StyledGearIndicator>
  );
};
