import { rx } from '../../Redux/Actions';
import { authRx } from '../../Redux/Actions/AuthActions/thunk';
import {
  addOrder,
  cancelOrder,
  deleteOrder,
  finishOrder,
  getOrder,
  updateOrder,
  updateOrderImages,
} from '../../API/Orders';

export const fetchOrder = (dispatch, id, fn) =>
  dispatch(
    rx(
      authRx,
      'getOrder',
      (_, { data }) => {
        if (data?.order) {
          fn(data.order);
        }
      },
      getOrder,
      { id }
    )
  );

export const fetchUpdateOrderImages = (dispatch, formData, fn, id) =>
  dispatch(
    rx(
      authRx,
      `updateOrderImages_${id}`,
      (_, { data }) => {
        if (data?.order) {
          fn && fn(data.order);
        }
      },
      updateOrderImages,
      {
        id,
        body: formData,
      }
    )
  );

export const fetchUpdateOrderData = (dispatch, body, fn, id) =>
  dispatch(
    rx(
      authRx,
      'updateOrder',
      (_, { data }) => {
        if (data?.order) {
          fn(data.order);
        }
      },
      updateOrder,
      {
        id,
        body,
      }
    )
  );

export const fetchCreateOrderData = (dispatch, body) =>
  new Promise((resolve) =>
    dispatch(
      rx(
        authRx,
        'createOrder',
        (_, { data }) => {
          if (data?.order) {
            resolve(data.order);
          }
        },
        addOrder,
        {
          body,
        }
      )
    )
  );

export const fetchCancelOrder = (dispatch, id, fn) =>
  dispatch(
    rx(
      authRx,
      `cancelOrder_${id}`,
      (_, { data }) => {
        if (data?.data) {
          fn(data.order);
        }
      },
      cancelOrder,
      {
        id,
      }
    )
  );

export const fetchDeleteOrder = (dispatch, id, fn) =>
  dispatch(
    rx(
      authRx,
      `deleteOrder_${id}`,
      (_, { data }) => {
        if (data?.data) {
          fn(data.order);
        }
      },
      deleteOrder,
      {
        id,
      }
    )
  );

export const fetchFinishOrder = (dispatch, id, fn) =>
  dispatch(
    rx(
      authRx,
      `finishOrder_${id}`,
      (_, { data }) => {
        if (data?.data) {
          fn(data.order);
        }
      },
      finishOrder,
      {
        id,
      }
    )
  );
