import { API } from '../index';

export const endpoints = {
  CAR: '/car',
  CHCEK_VIN: '/car/vin',
  UNDELETE: '/car/revoke',
};

export const getCars = async (config) => await API.get(endpoints.CAR, config);

export const checkVIN = async (config, { body }) =>
  await API.post(endpoints.CHCEK_VIN, body, config);

export const patchCar = async (config, { carId, body }) =>
  await API.patch(`${endpoints.CAR}/${carId}`, body, config);

export const deleteCar = async (config, { carId }) =>
  await API.delete(`${endpoints.CAR}/${carId}`, config);

export const restoreCar = async (config, { carId }) =>
  await API.patch(`${endpoints.UNDELETE}/${carId}`, {}, config);
