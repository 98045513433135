import styled, { css } from 'styled-components';

export const StyledFlex = styled.div`
  display: flex;
  flex-wrap: ${({ flexWrap }) => flexWrap || 'no-wrap'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};

  ${({ spacer, full }) => {
    return spacer
      ? css`
          margin-left: ${`-${spacer.left}`};
          margin-bottom: ${`-${spacer.bottom}`};

          & > * {
            padding-left: ${spacer.left};
            margin-bottom: ${spacer.bottom};
            width: ${full ? '100%' : 'auto'};
          }
        `
      : css`
          & > * {
            width: ${full ? '100%' : 'auto'};
          }
        `;
  }}
`;
