import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { fieldDataCollection } from '../../../../Utils/Helpers/stypByStep';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { Button } from '../../../UI/Buttons';
import { MaxContainer } from '../../../UI/Containers';
import { Flex } from '../../../UI/Flex';
import { Input } from '../../../UI/Form/Input';
import { StyledFormGroup } from '../../../UI/Form/styled';
import { StyledH2 } from '../../../UI/Titles';
import { TemplatePicture } from '../../Partner/ToolItem';
import { addTemplate, deleteTemplate, editTemplate } from '../../queries';
import L from './locale.json';
import { StyledAdd, StyledTemplate } from './styled';

const initialStateErrors = {
  basePrice: null,
};

export const BannerTemplateForm = ({ fn, template, onClose }) => {
  const dispatch = useDispatch();
  const [locale, lang] = useLocale(L);

  const [price, setPrice] = useState(template?.basePrice || 0);
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState(initialStateErrors);

  const fileRef = useRef(null);

  const {
    loading: [loadingDelete, loadingEdit, loadingAdd],
  } = useMessages([
    'deleteTemplateFromConfig',
    'updateTemplateInConfig',
    'addTemplateToConfig',
  ]);

  const handleChangePrice = (event) => {
    const { error, value } = fieldDataCollection(event, lang);
    setPrice(value);
    setErrors((prev) => ({ ...prev, basePrice: error }));
  };

  const handleResult = (_, { data }) => {
    if (data?.conflict && data.conflict in initialStateErrors) {
      setErrors((prev) => ({ ...prev, [data.conflict]: data.message }));
    } else if (data.bannersConfig) {
      fn(data);
      onClose();
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const file = fileRef.current?.files?.[0];
    if (file) formData.append('image', file);
    if (price) formData.append('basePrice', price);
    const handler = template ? editTemplate : addTemplate;
    handler(dispatch, formData, handleResult, template?._id);
  };

  const handleSubmitDelete = (e) => {
    e.preventDefault();
    deleteTemplate(dispatch, template?._id, handleResult);
  };

  const onPickFile = ({ target }) => {
    const file = target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const onOpenFilePicker = () => fileRef.current.click();

  const img = image || template?.image;

  return (
    <Flex
      alignItems={'stretch'}
      flexDirection={'column'}
      spacer={{ bottom: '24px' }}
    >
      <div>
        <StyledH2>{locale.title}</StyledH2>
      </div>
      <div>
        <StyledTemplate empty={!img} onClick={onOpenFilePicker}>
          {img ? (
            <TemplatePicture image={template?.image} preview={image} full />
          ) : null}
          <StyledAdd>{img ? locale.change : locale.add}</StyledAdd>
          <input type="file" ref={fileRef} onChange={onPickFile} />
        </StyledTemplate>
      </div>
      <div>
        <form>
          <MaxContainer>
            <StyledFormGroup>
              <Input
                name={'basePrice'}
                value={price}
                fn={handleChangePrice}
                errors={errors.basePrice}
                placeholder={locale.basePrice.placeholder}
                label={locale.basePrice.title}
              />
            </StyledFormGroup>
          </MaxContainer>
        </form>
      </div>
      <div>
        <Flex
          justifyContent={'center'}
          spacer={{ left: '12px', bottom: '12px' }}
        >
          <div>
            <Button
              kind={'green'}
              disabled={
                loadingEdit ||
                loadingAdd ||
                Object.values(errors).some(Boolean) ||
                (!image && !template?.image)
              }
              loading={loadingEdit || loadingAdd}
              text={locale.save}
              fn={handleSubmitForm}
            />
          </div>
          {template ? (
            <div>
              <Button
                disabled={loadingEdit || loadingDelete || loadingAdd}
                loading={loadingDelete}
                text={locale.delete}
                fn={handleSubmitDelete}
              />
            </div>
          ) : null}
        </Flex>
      </div>
    </Flex>
  );
};
