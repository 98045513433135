import { StyledSettingBoost, StyledSettingBoostName } from './styled';
import { CalcGroup } from '../../UI/CalcGroup';
import { Flex } from '../../UI/Flex';
import { Button } from '../../UI/Buttons';
import L from './locale.json';
import { useLocale } from '../../../Utils/Hooks/useLocale';
import { useMemo, useState } from 'react';
import { useCallback } from 'react';
import { orderSearchBoost } from '../queries';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../Redux/Selectors/AuthSelectors';
import { useMessages } from '../../../Redux/Selectors/MessageSelectors';
import { PartnerSelect } from '../Partner/PartnerSelect';
import { toPrice } from '../../../Utils/Helpers';

export const SearchBoost = ({ onSubmit, isAdmin, config }) => {
  const [{ gears, days, pay }, lang] = useLocale(L);
  const dispatch = useDispatch();
  const { _id } = useSelector(getUserData);

  const {
    loading: [loading],
  } = useMessages(['orderGear']);

  const gearOptions = useMemo(
    () =>
      config.gears
        .map(({ _id, power }) => ({
          id: _id,
          value: power,
        }))
        .sort(({ value: a }, { value: b }) => a - b),
    [config.gears]
  );

  const [formData, setFormData] = useState({
    gearId: gearOptions[0].id,
    days: 1,
  });

  const price = useMemo(() => {
    const { pricePerDay, basePrice } =
      config.gears.find(({ _id }) => _id === formData.gearId) || {};
    return toPrice(basePrice + pricePerDay * formData.days, lang);
  }, [config.gears, formData, lang]);

  const setCompany = useCallback((v) => {
    setFormData((prev) => ({ ...prev, company: v || undefined }));
  }, []);

  const setGear = useCallback((v) => {
    setFormData((prev) => ({ ...prev, gearId: v }));
  }, []);

  const setDays = useCallback((v) => {
    setFormData((prev) => ({ ...prev, days: v }));
  }, []);

  const handleSubmit = () => {
    orderSearchBoost(dispatch, _id, formData, (_, { data }) => {
      if (data?.data) {
        onSubmit(data.searchBoost);
      }
    });
  };

  return (
    <>
      <Flex
        flexDirection={'column'}
        alignItems={'stretch'}
        spacer={{ left: '8px', bottom: '8px' }}
      >
        {isAdmin ? (
          <div>
            <StyledSettingBoost>
              <PartnerSelect onChange={setCompany} />
            </StyledSettingBoost>
          </div>
        ) : null}
        <div>
          <StyledSettingBoost>
            <StyledSettingBoostName>{gears}</StyledSettingBoostName>
            <CalcGroup options={gearOptions} fn={setGear} minValue={1} />
          </StyledSettingBoost>
        </div>
        <div>
          <StyledSettingBoost>
            <StyledSettingBoostName>{days}</StyledSettingBoostName>
            <CalcGroup fn={setDays} minValue={1} />
          </StyledSettingBoost>
        </div>
        <div>
          <Flex justifyContent={'center'}>
            <Button
              kind={'green'}
              text={`${pay} ${isAdmin ? '' : price}`}
              fn={handleSubmit}
              disabled={loading || (isAdmin && !formData.company)}
              loading={loading}
            />
          </Flex>
        </div>
      </Flex>
    </>
  );
};
