import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSelect } from './Redux/Actions/SelectActions';
import { GlobalStyle } from './styledApp';
import { Modal } from './Components/UI/Modal';
import { AppRoutes } from './appRoutes';
import { getAuthState, getUserData } from './Redux/Selectors/AuthSelectors';
import { useConnectNotifications, useNotifications } from './Socket';
import {
  DEFAULT_EVENTS,
  INCOMMING_MESSAGES,
  MESSAGES,
} from './Socket/constants';
import {
  userAddNotifications,
  userSetNotifications,
} from './Redux/Actions/AuthActions';
import { useRef } from 'react';
import { query } from './API';
import { incomplete } from './API/User';
import { FirebaseMessages } from './Components/FirebaseMessages';
import { Reauth } from './Components/Reauth';
import { hotjar } from 'react-hotjar';
import { ENV } from './Configs/constants';
import { useLocation } from 'react-router';

const HotJarManager = () => {
  const location = useLocation();
  const { _id, companyName, name, email, phone, role } =
    useSelector(getUserData);
  useEffect(() => {
    if (ENV === 'production') {
      hotjar.initialize({ id: 3922044, sv: 6, debug: true });
    }
  }, []);

  useEffect(() => {
    if (hotjar.initialized()) {
      hotjar.stateChange(location.pathname.replace(/^\//, ''));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (_id && hotjar.initialized()) {
      hotjar.identify(_id, {
        name: companyName || name,
        email,
        phone,
        role,
      });
    }
  }, [_id, companyName, name, email, phone, role]);

  return null;
};

const SocketConnection = () => {
  useConnectNotifications();
  const dispatch = useDispatch();

  const onceRef = useRef(false);

  const { subscribe, emit, unsubscribe } = useNotifications();

  useEffect(() => {
    subscribe({
      event: INCOMMING_MESSAGES.all,
      id: 'app_initial_request',
      cb: (notifications) => {
        onceRef.current = true;
        dispatch(userSetNotifications(notifications));
      },
    });
    subscribe({
      event: DEFAULT_EVENTS.connect,
      id: 'app_connected',
      cb: () => {
        setTimeout(() => {
          if (!onceRef.current) {
            emit(MESSAGES.getAll);
          }
        }, 1000);
      },
    });
    subscribe({
      event: INCOMMING_MESSAGES.single,
      id: 'app_request_for_every_message_example',
      cb: (notification) => {
        dispatch(userAddNotifications(notification));
      },
    });

    return () => {
      unsubscribe('app_initial_request');
      unsubscribe('app_request_for_every_message_example');
      unsubscribe('app_connected');
    };
  }, [subscribe, emit, unsubscribe, dispatch]);
};

const IncompleteRegistration = () => {
  const user = useSelector(getUserData);
  const stagedUser = useRef({});
  stagedUser.current = user;
  useEffect(
    () =>
      (window.onbeforeunload = () => {
        const { tokenAccess, phone, email } = stagedUser.current;
        !tokenAccess &&
          (phone || email) &&
          query(incomplete, {
            phone: phone || '',
            email: email || '',
            role: 'partner',
          });
      }),
    []
  );
  return null;
};

function App({ isMobile }) {
  const dispatch = useDispatch();
  const handleClickAll = useCallback(() => dispatch(closeSelect()), [dispatch]);

  const Auth = useSelector(getAuthState, (p, n) => {
    return p === n;
  });

  useEffect(() => {
    document.addEventListener('click', handleClickAll);
    return () => document.removeEventListener('click', handleClickAll);
  }, [handleClickAll]);

  return (
    <>
      <Reauth />
      <GlobalStyle />
      <Modal />
      <IncompleteRegistration />
      {Auth ? <SocketConnection /> : null}
      <HotJarManager />
      <FirebaseMessages Auth={Auth} />
      <AppRoutes Auth={Auth} isMobile={isMobile} />
    </>
  );
}

export default App;
