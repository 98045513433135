export const parsers = {
  phone: (v) => (v.replace(/\D/g, '').match(/\d+/g) || []).join(''),
  price: (v) => (v.replace(/\D/g, '').match(/\d+/g) || []).join(''),
  time: (v) => (v.replace(/\D/g, '').match(/\d+/g) || []).join(''),
  monthYear: (v) =>
    (v.replace(/\D/g, '').match(/\d+/g) || []).join('').slice(0, 4),
  cvc: (v) => (v.replace(/\D/g, '').match(/\d+/g) || []).join('').slice(0, 3),
  ccNumber: (v) =>
    (v.replace(/\D+/g, '').match(/\d+/g) || []).join('').slice(0, 16),
  vin: (v) =>
    (v.match(/\w+/g) || [])
      .map((char) => char.toUpperCase())
      .join('')
      .slice(0, 17),
  number: (v) =>
    (v.match(/\w+/g) || [])
      .map((char) => char.toUpperCase())
      .join('')
      .slice(0, 6),
};
