import {
  StyledFormButtonBlock,
  StyledFormGroup,
} from '../../../UI/Form/styled';
import { Input } from '../../../UI/Form/Input';
import { useDispatch, useSelector } from 'react-redux';
import { getNewOrderData } from '../../../../Redux/Selectors/CreateOrderSelectors';
import { useEffect, useState } from 'react';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from '../locale.json';
import { validation } from '../../../../Utils/validation';
import { setNewOrderData } from '../../../../Redux/Actions/CreateOrderActions';
import { closeModal } from '../../../../Redux/Actions/ModalActions';
import { fetchUpdateOrderData } from '../../queries';
import { Button } from '../../../UI/Buttons';

export const AboutCarForm = ({
  onChange,
  order,
  isCreate,
  isModal,
  globalErrors,
  setGlobalErrors,
}) => {
  const dispatch = useDispatch();
  const { year, vin, number } = useSelector(getNewOrderData);
  const [inputs, setInputs] = useState({
    year: order?.year || '',
    vin: order?.vin || '',
    number: order?.number || '',
  });

  const [errors, setErrors] = useState(
    globalErrors || {
      year: '',
      vin: '',
      number: '',
    }
  );

  useEffect(() => {
    if (isCreate && !isModal) {
      if (
        globalErrors.year !== errors.year ||
        globalErrors.vin !== errors.vin ||
        globalErrors.number !== errors.number
      ) {
        setErrors(globalErrors);
      }
    }
  }, [
    isCreate,
    isModal,
    setErrors,
    errors.year,
    errors.vin,
    errors.number,
    globalErrors,
  ]);

  const [locale, lang] = useLocale(L);

  const handleChangeInput = (event) => {
    const { value, error, name } = validation(event.target, lang);

    if (isCreate && !isModal) {
      dispatch(
        setNewOrderData({
          [name]: value,
          errors: {
            [name]: error,
          },
        })
      );
      setGlobalErrors((prevState) => ({ ...prevState, [name]: error }));
    } else {
      setInputs((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
    }
  };

  const handleSubmitForm = () => {
    if (isCreate) {
      dispatch(
        setNewOrderData({
          ...inputs,
        })
      );
      dispatch(closeModal({}));
    } else {
      fetchUpdateOrderData(
        dispatch,
        { ...inputs },
        (newOrder) => {
          onChange(newOrder);
          dispatch(closeModal({}));
        },
        order._id
      );
    }
  };

  return (
    <>
      <StyledFormGroup>
        <Input
          name={'year'}
          type={'number'}
          placeholder={locale.placeholders.year}
          label={locale.placeholders.year}
          value={isModal ? inputs.year || '' : year || ''}
          errors={errors.year}
          fn={handleChangeInput}
          required={true}
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <Input
          name={'vin'}
          placeholder={locale.placeholders.vin}
          label={locale.placeholders.vin}
          value={isModal ? inputs.vin || '' : vin || ''}
          errors={errors.vin}
          fn={handleChangeInput}
          required={true}
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <Input
          name={'number'}
          placeholder={locale.placeholders.number}
          label={locale.placeholders.number}
          value={isModal ? inputs.number || '' : number || ''}
          errors={errors.number}
          fn={handleChangeInput}
          required={true}
        />
      </StyledFormGroup>
      {isModal ? (
        <StyledFormButtonBlock>
          <Button
            kind={'green'}
            fn={handleSubmitForm}
            text={locale.saveBtn}
            disabled={Object.values(errors).some((err) => err.length)}
          />
        </StyledFormButtonBlock>
      ) : null}
    </>
  );
};
