import { StyledH2 } from '../../Titles';
import { useMemo, useState } from 'react';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import LangWeekdaysLocale from '../../DatePicker/locale.json';
import L from '../../../ScheduleList/locale.json';
import { Input } from '../../Form/Input';
import { Flex } from '../../Flex';
import { MaxContainerXs } from '../../Containers';
import {
  StyledWorkingDay,
  StyledWorkingDayBody,
  StyledWorkingDayHeader,
} from './styled';
import { CheckboxInput } from '../../Form/Checkbox';
import { Button } from '../../Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { updateEmployee, updateUser } from '../../../../API/User';
import { closeModal } from '../../../../Redux/Actions/ModalActions';
import { getLoading } from '../../../../Redux/Selectors/MessageSelectors';
import { getUserData } from '../../../../Redux/Selectors/AuthSelectors';
import { roles } from '../../../../Configs/constants';
import { splitTimeShort } from '../../../Calendar/helpers';

const EditScheduleItem = ({ dayName, day, setScheduleState }) => {
  const [{ workingHours, breakTitle }] = useLocale(L);

  const handleChange = (e) => {
    const { name, valueAsDate } = e.target;
    const ISODate = valueAsDate
      ? new Date(
          new Date('2023-01-01T00:00:00.000Z').setHours(
            valueAsDate.getUTCHours(),
            !valueAsDate.getUTCHours()
              ? valueAsDate.getUTCMinutes() || 1
              : valueAsDate.getUTCMinutes(),
            0,
            0
          )
        ).toISOString()
      : null;

    if (
      valueAsDate.getUTCHours() === 0 &&
      (name === 'hoursFrom' || name === 'breakFrom')
    ) {
    }
    setScheduleState((prevState) => {
      if (name === 'hoursFrom' && !valueAsDate) {
        return {
          ...prevState,
          [dayName]: {
            ...prevState[dayName],
            [name]: null,
            hoursTo: null,
            breakFrom: null,
            breakTo: null,
          },
        };
      }

      if (name === 'hoursTo' && !valueAsDate) {
        return {
          ...prevState,
          [dayName]: {
            ...prevState[dayName],
            [name]: null,
            breakFrom: null,
            breakTo: null,
          },
        };
      }

      if (name === 'breakFrom' && !valueAsDate) {
        return {
          ...prevState,
          [dayName]: {
            ...prevState[dayName],
            [name]: null,
            breakTo: null,
          },
        };
      }

      return {
        ...prevState,
        [dayName]: {
          ...prevState[dayName],
          [name]: ISODate,
        },
      };
    });
  };

  const handleChangeChecked = (e) => {
    const { checked } = e.target;

    setScheduleState((prevState) => {
      return {
        ...prevState,
        [dayName]: {
          ...prevState[dayName],
          checked,
        },
      };
    });
  };

  return (
    <StyledWorkingDay checked={day.checked}>
      <StyledWorkingDayHeader checked={day.checked}>
        <CheckboxInput
          placeholder={dayName}
          checked={day.checked}
          name={'dayChecked'}
          fn={handleChangeChecked}
        />
      </StyledWorkingDayHeader>
      <StyledWorkingDayBody checked={day.checked}>
        <Flex
          justifyContent={'space-between'}
          spacer={{ left: '18px', bottom: '18px' }}
        >
          <div>
            <span>{workingHours}:</span>
            <Flex spacer={{ left: '8px', bottom: '8px' }}>
              <div>
                <Input
                  name={'hoursFrom'}
                  type={'time'}
                  value={day.hoursFrom ? splitTimeShort(day.hoursFrom) : ''}
                  min="00:01"
                  fn={handleChange}
                />
              </div>
              <div>
                <Input
                  name={'hoursTo'}
                  type={'time'}
                  disabled={!day.hoursFrom}
                  value={
                    day.hoursTo && day.hoursFrom
                      ? splitTimeShort(day.hoursTo)
                      : ''
                  }
                  fn={handleChange}
                />
              </div>
            </Flex>
          </div>
          <div>
            <span>{breakTitle}:</span>
            <Flex spacer={{ left: '8px', bottom: '8px' }}>
              <div>
                <Input
                  name={'breakFrom'}
                  type={'time'}
                  disabled={!(day.hoursTo && day.hoursFrom)}
                  value={
                    day.breakFrom && day.hoursTo
                      ? splitTimeShort(day.breakFrom)
                      : ''
                  }
                  fn={handleChange}
                />
              </div>
              <div>
                <Input
                  name={'breakTo'}
                  type={'time'}
                  disabled={!(day.breakFrom && day.hoursTo && day.hoursFrom)}
                  value={
                    day.breakTo && day.breakFrom && day.hoursTo && day.hoursFrom
                      ? splitTimeShort(day.breakTo)
                      : ''
                  }
                  fn={handleChange}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </StyledWorkingDayBody>
    </StyledWorkingDay>
  );
};

const parseSchedule = (schedule) => {
  const schArray = [];

  Object.values(schedule).forEach(
    ({ hoursFrom, hoursTo, breakFrom, breakTo, checked }) => {
      const arr = [];

      if (checked) {
        if (hoursFrom?.length) {
          arr.push(hoursFrom);
        }

        if (hoursTo?.length && hoursFrom?.length) {
          arr.push(hoursTo);
        }

        if (breakFrom?.length && hoursTo?.length && hoursFrom?.length) {
          arr.push(breakFrom);
        }

        if (
          breakTo?.length &&
          breakFrom?.length &&
          hoursTo?.length &&
          hoursFrom?.length
        ) {
          arr.push(breakTo);
        }
      }

      schArray.push(arr.sort());
    }
  );

  return schArray;
};

const SubmitSchedule = ({ scheduleState, _id, onChange }) => {
  const [{ submit }] = useLocale(L);
  const dispatch = useDispatch();
  const loading = useSelector(getLoading('updateUserData'));
  const { role, _id: ID } = useSelector(getUserData);

  const submitSchedule = () => {
    dispatch(
      rx(
        authRx,
        'updateUserData',
        (dispatch, { data }) => {
          onChange && onChange(data.user || data.employee);
          dispatch(closeModal({}));
        },
        role === roles.PARTNER && _id !== ID ? updateEmployee : updateUser,
        {
          id: _id,
          body: {
            schedule: parseSchedule(scheduleState),
          },
        }
      )
    );
  };

  const disabledSubmit = useMemo(() => {
    return (
      parseSchedule(scheduleState).some((day) => day.length % 2 !== 0) ||
      Object.values(scheduleState).some((day) => {
        if (day.checked) {
          if (day.hoursFrom === null || day.hoursTo === null) {
            return true;
          } else if (day.hoursFrom >= day.hoursTo) {
            return true;
          } else if (day.breakFrom !== null || day.breakTo !== null) {
            if (
              day.breakFrom >= day.breakTo ||
              day.breakFrom <= day.hoursFrom ||
              day.breakFrom >= day.hoursTo ||
              day.breakTo <= day.hoursFrom ||
              day.breakTo >= day.hoursTo
            ) {
              return true;
            }
          } else {
            return false;
          }
        }
        return false;
      })
    );
  }, [scheduleState]);

  return (
    <Button
      loading={loading}
      text={submit}
      kind={'green'}
      disabled={disabledSubmit}
      fn={submitSchedule}
    />
  );
};

export const EditSchedule = ({ schedule, _id, onChange }) => {
  const [{ weekdaysLong }] = useLocale(LangWeekdaysLocale);
  const [{ title }] = useLocale(L);

  const [scheduleState, setScheduleState] = useState(() =>
    schedule.reduce((acc, v, i) => {
      return {
        ...acc,
        [weekdaysLong[i]]: {
          hoursFrom: v[0] || null,
          hoursTo: v.length > 2 ? v[3] : v[1] || null,
          breakFrom: v.length > 2 ? v[1] : null,
          breakTo: v.length > 2 ? v[2] : null,
          checked: v.length > 0,
        },
      };
    }, {})
  );

  return (
    <>
      <StyledH2>{title}</StyledH2>
      <MaxContainerXs>
        <form noValidate={true}>
          <Flex
            spacer={{ left: '16px', bottom: '16px' }}
            full={true}
            justifyContent={'center'}
            flexDirection={'column'}
          >
            <div>
              {Object.keys(scheduleState).map((day, i) => {
                return (
                  <EditScheduleItem
                    key={i}
                    index={i}
                    day={scheduleState[day]}
                    dayName={day}
                    setScheduleState={setScheduleState}
                  />
                );
              })}
            </div>
            <div>
              <Flex justifyContent={'center'}>
                <div>
                  <SubmitSchedule
                    scheduleState={scheduleState}
                    _id={_id}
                    onChange={onChange}
                  />
                </div>
              </Flex>
            </div>
          </Flex>
        </form>
      </MaxContainerXs>
    </>
  );
};
