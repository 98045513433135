import { setLoading, setMessage } from './MessageActions/';

export const rx =
  (fnRx, type, ...args) =>
  async (dispatch) => {
    try {
      dispatch(setMessage({ [type]: { error: '', success: '' } }));
      dispatch(setLoading(type));
      const result = await fnRx(dispatch, ...args);
      const message = {
        error: result?.data?.message || '',
        success: result?.data?.data || '',
        code: result?.data?.code || null,
        conflict: result?.data?.conflict || null,
      };
      dispatch(setMessage({ [type]: message }));
    } catch (err) {
      dispatch(setMessage({ [type]: { error: err.message, success: '' } }));
    } finally {
      dispatch(setLoading(type));
    }
  };
