import styled from 'styled-components';
import S from '../../../UI/variables';
import { StyledPreloader } from '../../../UI/Preloader/styled';
import { rgba } from 'polished';

export const StyledPromoCodeItem = styled.span`
  position: relative;
  display: block;
  padding: 16px;
  border-radius: 16px;
  box-shadow: ${S.mainShade};

  ${StyledPreloader} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: ${rgba(S.white, 0.5)};
    border-radius: 16px;
  }
`;

export const StyledPromoCodeItemLeft = styled.span`
  display: flex;
  flex-direction: column;
  padding-right: 24px;
`;

export const StyledPromoCodeItemType = styled.span`
  color: ${S.textSecondary};
  margin-bottom: 4px;
  text-transform: capitalize;
`;

export const StyledPromoCodeItemDate = styled.span`
  font-weight: 600;
  color: ${S.greenDark};
  margin-bottom: 4px;
  font-size: 12px;

  @media screen and (min-width: ${S.phone}) {
    font-size: 14px;
  }

  @media screen and (min-width: ${S.tablet}) {
    font-size: 16px;
  }
`;

export const StyledPromoCodeItemName = styled.span`
  color: ${S.textPrimary};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;

  @media screen and (min-width: ${S.phone}) {
    font-size: 20px;
  }

  @media screen and (min-width: ${S.tablet}) {
    font-size: 24px;
    line-height: 1.7084;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const StyledPromoCodeItemInfo = styled.span`
  font-size: 14px;

  @media screen and (min-width: ${S.tablet}) {
    font-size: 16px;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
