import { lazy } from 'react';
import WithSuspense from '../Utils/HOC/withSuspense';

import { ReactComponent as StatsIcon } from '../Static/icons/sidebar/stats.svg';
import { ReactComponent as SettingsIcon } from '../Static/icons/sidebar/setings.svg';
import { ReactComponent as MarketingToolsIcon } from '../Static/icons/sidebar/marketing-tools.svg';
import { ReactComponent as ReviewsIcon } from '../Static/icons/sidebar/review.svg';
import { ReactComponent as CalendarIcon } from '../Static/icons/sidebar/calendar.svg';
import { ReactComponent as HomeIcon } from '../Static/icons/sidebar/home.svg';
import { ReactComponent as VehicleIcon } from '../Static/icons/sidebar/vehicle.svg';
import { ReactComponent as ClientsIcon } from '../Static/icons/sidebar/clients.svg';
import { ReactComponent as NotificationIcon } from '../Static/icons/sidebar/notification.svg';
import { ReactComponent as ProfileIcon } from '../Static/icons/sidebar/profile.svg';
import { ReactComponent as OrdersIcon } from '../Static/icons/sidebar/orders.svg';
import { ReactComponent as WalletIcon } from '../Static/icons/sidebar/wallet.svg';
import { ReactComponent as SubscriptionIcon } from '../Static/icons/sidebar/subscription.svg';
import { roles } from '../Configs/constants';

export const statisticRoutes = [
  {
    index: true,
    link: '',
    name: 'statisticsDashboard',
    Elm: WithSuspense(lazy(() => import('../Pages/Statistics/Dashboard'))),
    end: true,
  },
  {
    path: 'revenue',
    link: 'revenue',
    name: 'statisticsRevenue',
    Elm: WithSuspense(lazy(() => import('../Pages/Statistics/Revenue'))),
  },
];

export const usersRoutes = [
  {
    index: true,
    link: '',
    name: 'usersPrivate',
    Elm: WithSuspense(lazy(() => import('../Pages/Users/PrivateUser'))),
    end: true,
  },
  {
    path: 'business',
    link: 'business',
    name: 'usersBusiness',
    Elm: WithSuspense(lazy(() => import('../Pages/Users/BusinessUser'))),
  },
  {
    path: 'partners',
    link: 'partners',
    name: 'usersServicesPartners',
    Elm: WithSuspense(lazy(() => import('../Pages/Users/ServicesPartners'))),
  },
  {
    path: 'admin',
    link: 'admin',
    name: 'usersAdmin',
    Elm: WithSuspense(lazy(() => import('../Pages/Users/Admin'))),
  },
  {
    path: ':id',
    name: 'userProfile',
    Elm: WithSuspense(lazy(() => import('../Pages/Users/UserProfile'))),
  },
  {
    path: 'statistics/:id',
    name: 'userProfileStatistics',
    access: [roles.ADMIN],
    Elm: WithSuspense(
      lazy(() => import('../Pages/Users/UserProfileStatistics'))
    ),
  },
];

export const employeeRoutes = [
  {
    index: true,
    link: '',
    name: 'employee',
    Elm: WithSuspense(lazy(() => import('../Pages/Employee/EmployeeList'))),
    end: true,
  },
  {
    path: ':id',
    name: 'employeeProfile',
    Elm: WithSuspense(lazy(() => import('../Pages/Employee/EmployeeProfile'))),
  },
];

export const paymentsRoutes = [
  {
    index: true,
    link: '',
    name: 'paymentsUsers',
    Elm: WithSuspense(lazy(() => import('../Pages/Payments/PaymentsList'))),
    end: true,
  },
  {
    path: ':id',
    name: 'paymentsUser',
    Elm: WithSuspense(lazy(() => import('../Pages/Payments/PaymentsUser'))),
  },
];

export const marketingToolsRoutes = [
  {
    index: true,
    link: '',
    name: 'marketingToolsBanners',
    Elm: WithSuspense(
      lazy(() => import('../Pages/MarketingTools/Admin/Banner'))
    ),
    end: true,
  },
  {
    path: 'gears',
    link: 'gears',
    name: 'marketingToolsGears',
    Elm: WithSuspense(
      lazy(() => import('../Pages/MarketingTools/Admin/Gears'))
    ),
  },
  {
    path: 'requests-banners',
    link: 'requests-banners',
    name: 'bannersRequests',
    Elm: WithSuspense(
      lazy(() => import('../Pages/MarketingTools/Admin/BannerRequests'))
    ),
  },
  {
    path: 'requests-gears',
    link: 'requests-gears',
    name: 'searchBoostRequests',
    Elm: WithSuspense(
      lazy(() => import('../Pages/MarketingTools/Admin/GearRequests'))
    ),
  },
];

export const settingsRoutes = [
  {
    index: true,
    link: '',
    name: 'settings',
    Elm: WithSuspense(lazy(() => import('../Pages/Settings/Main'))),
    end: true,
  },
  {
    path: 'support',
    link: 'support',
    name: 'settingsSupportDetails',
    Elm: WithSuspense(lazy(() => import('../Pages/Settings/SupportDetails'))),
  },
  {
    path: 'promo',
    link: 'promo',
    name: 'settingsPromoCode',
    Elm: WithSuspense(lazy(() => import('../Pages/Settings/PromoCode'))),
  },
  {
    path: 'services',
    link: 'services',
    name: 'services',
    Elm: WithSuspense(lazy(() => import('../Pages/Settings/ServicesList'))),
  },
  {
    path: 'moderation',
    link: 'moderation',
    name: 'moderation',
    Elm: WithSuspense(lazy(() => import('../Pages/Settings/Moderation'))),
  },
];

export const ordersRoutes = [
  {
    index: true,
    link: '',
    name: 'tableOrders',
    access: [
      roles.EMPLOYEE,
      roles.PARTNER,
      roles.PRIVATE_USER,
      roles.BUSINESS_USER,
    ],
    Elm: WithSuspense(lazy(() => import('../Pages/Orders/Orders'))),
    end: true,
  },
  {
    path: 'create',
    name: 'createOrder',
    link: 'create',
    access: [roles.PARTNER, roles.EMPLOYEE],
    accessEmployee: ['full'],
    Elm: WithSuspense(lazy(() => import('../Pages/Orders/Create'))),
  },
  {
    path: 'create',
    name: 'createOrder',
    link: 'create',
    access: [roles.PRIVATE_USER, roles.BUSINESS_USER],
    Elm: WithSuspense(lazy(() => import('../Pages/Orders/UserCreate'))),
  },
  {
    path: 'view/:id',
    name: 'orderItem',
    access: [
      roles.PARTNER,
      roles.EMPLOYEE,
      roles.ADMIN,
      roles.PRIVATE_USER,
      roles.BUSINESS_USER,
    ],
    accessEmployee: ['full', 'limited'],
    Elm: WithSuspense(lazy(() => import('../Pages/Orders/Order'))),
  },
  {
    path: 'view/car-history/:id',
    name: 'orderCarHistory',
    access: [
      roles.PARTNER,
      roles.EMPLOYEE,
      roles.ADMIN,
      roles.PRIVATE_USER,
      roles.BUSINESS_USER,
    ],
    accessEmployee: ['full', 'limited'],
    Elm: WithSuspense(lazy(() => import('../Pages/Orders/CarHistory'))),
  },
];

export const profileSupportRoutes = [
  {
    index: true,
    link: '',
    name: 'profileSupportContacts',
    Elm: WithSuspense(lazy(() => import('../Pages/Profile/Support/Contacts'))),
    end: true,
  },
  {
    path: 'faq',
    link: 'faq',
    name: 'profileSupportFaq',
    Elm: WithSuspense(lazy(() => import('../Pages/Profile/Support/Faq'))),
  },
  {
    path: 'tc',
    link: 'tc',
    name: 'profileSupportTC',
    Elm: WithSuspense(lazy(() => import('../Pages/Profile/Support/TC'))),
  },
];

export const profileRoutes = [
  {
    index: true,
    link: '',
    name: 'profile',
    Elm: WithSuspense(lazy(() => import('../Pages/Profile/Main'))),
    end: true,
  },
  {
    path: 'settings',
    link: 'settings',
    name: 'profileAccountSettings',
    Elm: WithSuspense(lazy(() => import('../Pages/Profile/Settings'))),
  },
  {
    path: 'working-hours',
    link: 'working-hours',
    name: 'profileWorkingHours',
    access: [roles.PARTNER, roles.EMPLOYEE],
    accessEmployee: ['full'],
    Elm: WithSuspense(lazy(() => import('../Pages/Profile/WorkingHours'))),
  },
  {
    path: 'services',
    link: 'services',
    name: 'profileServicesList',
    access: [roles.PARTNER],
    Elm: WithSuspense(lazy(() => import('../Pages/Profile/ServicesList'))),
  },
  {
    path: 'address',
    link: 'address',
    name: 'profileAddress',
    access: [roles.PARTNER, roles.PRIVATE_USER, roles.BUSINESS_USER],
    Elm: WithSuspense(lazy(() => import('../Pages/Profile/Address'))),
  },
  {
    path: 'support',
    link: 'support',
    name: 'profileSupport',
    access: [roles.PARTNER, roles.PRIVATE_USER, roles.BUSINESS_USER],
    subroutesInner: profileSupportRoutes,
    Elm: WithSuspense(lazy(() => import('../Pages/Profile/Support'))),
  },
  {
    path: 'payments',
    link: 'payments',
    name: 'profilePayments',
    access: [roles.PARTNER, roles.PRIVATE_USER, roles.BUSINESS_USER],
    Elm: WithSuspense(lazy(() => import('../Pages/Profile/Payments'))),
  },
  {
    path: 'pictures',
    link: 'pictures',
    name: 'profilePictures',
    access: [roles.PARTNER],
    Elm: WithSuspense(lazy(() => import('../Pages/Profile/Pictures'))),
  },
];

export const routes = [
  {
    path: '*',
    name: 'error',
    Elm: WithSuspense(lazy(() => import('../Pages/404'))),
    accessEmployee: ['full', 'limited'],
    access: ['notFound'],
  },
  {
    path: 'home',
    name: 'home',
    Icon: HomeIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Home'))),
    access: [roles.PRIVATE_USER, roles.BUSINESS_USER],
  },
  {
    path: 'vehicles',
    name: 'vehicles',
    Icon: VehicleIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Vehicles'))),
    access: [roles.PRIVATE_USER, roles.BUSINESS_USER],
  },
  {
    path: 'calendar',
    name: 'calendar',
    Icon: CalendarIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Calendar'))),
    access: [roles.PARTNER, roles.EMPLOYEE],
    accessEmployee: ['limited', 'full'],
  },
  {
    path: 'statistics',
    name: 'statistics',
    Icon: StatsIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Statistics'))),
    subroutes: statisticRoutes,
    access: [roles.ADMIN],
  },
  {
    path: 'users',
    name: 'users',
    Icon: ClientsIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Users'))),
    subroutes: usersRoutes,
    access: [roles.ADMIN],
  },
  {
    path: 'notifications',
    name: 'notifications',
    Icon: NotificationIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Notifications'))),
    accessEmployee: ['full', 'limited'],
    access: [
      roles.ADMIN,
      roles.PARTNER,
      roles.EMPLOYEE,
      roles.PRIVATE_USER,
      roles.BUSINESS_USER,
    ],
  },
  {
    path: 'payments',
    name: 'payments',
    Icon: WalletIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Payments'))),
    subroutes: paymentsRoutes,
    access: [roles.ADMIN],
  },
  {
    path: 'subscriptions',
    name: 'subscriptions',
    Icon: SubscriptionIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Subscription'))),
    access: [roles.ADMIN],
  },
  {
    path: 'settings',
    name: 'settings',
    Icon: SettingsIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Settings'))),
    subroutes: settingsRoutes,
    access: [roles.ADMIN],
  },
  {
    path: 'marketing-tools',
    name: 'marketing',
    Icon: MarketingToolsIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/MarketingTools/Partner'))),
    accessEmployee: ['full'],
    access: [roles.PARTNER, roles.EMPLOYEE],
  },
  {
    path: 'marketing-tools',
    name: 'marketing',
    Icon: MarketingToolsIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/MarketingTools/Admin'))),
    subroutes: marketingToolsRoutes,
    access: [roles.ADMIN],
  },
  {
    path: 'orders',
    name: 'orders',
    Icon: OrdersIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Orders'))),
    subroutes: ordersRoutes,
    accessEmployee: ['full', 'limited'],
    access: [
      roles.PARTNER,
      roles.EMPLOYEE,
      roles.PRIVATE_USER,
      roles.BUSINESS_USER,
    ],
  },
  {
    path: 'employee',
    name: 'employee',
    Icon: ClientsIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Employee'))),
    access: [roles.PARTNER, roles.EMPLOYEE],
    accessEmployee: ['full'],
    subroutes: employeeRoutes,
  },
  {
    path: 'profile',
    name: 'profile',
    Icon: ProfileIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Profile'))),
    access: [
      roles.PARTNER,
      roles.ADMIN,
      roles.EMPLOYEE,
      roles.PRIVATE_USER,
      roles.BUSINESS_USER,
    ],
    accessEmployee: ['full', 'limited'],
    subroutes: profileRoutes,
  },
  {
    path: 'reviews',
    name: 'reviews',
    Icon: ReviewsIcon,
    Elm: WithSuspense(lazy(() => import('../Pages/Review'))),
    accessEmployee: ['full'],
    access: [
      roles.PARTNER,
      roles.EMPLOYEE,
      roles.PRIVATE_USER,
      roles.BUSINESS_USER,
    ],
  },
  {
    path: 'workshop/:id',
    name: 'workshop',
    Elm: WithSuspense(lazy(() => import('../Pages/Workshop'))),
    access: [roles.PRIVATE_USER, roles.BUSINESS_USER],
  },
];

export const authRoutes = [
  {
    path: 'registration',
    link: '/registration',
    name: 'registration',
    Elm: WithSuspense(lazy(() => import('../Pages/Auth/Registration'))),
  },
  {
    path: 'onboarding',
    name: 'onboarding',
    link: '/onboarding',
    Elm: WithSuspense(lazy(() => import('../Pages/Auth/Onboarding'))),
  },
  {
    path: 'login',
    link: '/login',
    name: 'login',
    Elm: WithSuspense(lazy(() => import('../Pages/Auth/Login'))),
  },
];
