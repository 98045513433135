import styled from 'styled-components';
import S from '../../variables';

export const StyledWorkingDayHeader = styled.div`
  padding-bottom: ${({ checked }) => (checked ? `12px` : '0')};
  margin-bottom: ${({ checked }) => (checked ? `12px` : '0')};
  border-bottom: ${({ checked }) =>
    checked ? `1px solid ${S.strokeGray}` : 'none'};
`;

export const StyledWorkingDayBody = styled.div`
  display: ${({ checked }) => (checked ? `block` : 'none')};

  span {
    display: block;
    margin-bottom: 8px;
  }
`;

export const StyledWorkingDay = styled.div`
  padding: ${({ checked }) => (checked ? `16px 16px 32px 16px` : '16px')};
  border-radius: 15px;
  background-color: ${S.lightGray};

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  input[type='time'] {
    line-height: 36px;
    padding: 0 12px;
    width: 70px;
    font-size: 16px;

    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
`;
