import { initialQAFormState, setAddQAFormConfig } from './config';

export const QAFormConfig = {
  initialState: initialQAFormState,
  formConfig: setAddQAFormConfig,
};

export const editQAFormConfig = (question, answer) => ({
  initialState: {
    ...initialQAFormState,
    formData: {
      questionLt: question?.lt || '',
      questionEn: question?.en || '',
      answerLt: answer?.lt || '',
      answerEn: answer?.en || '',
    },
  },
  formConfig: setAddQAFormConfig,
});
