import { CodeInput } from '../../../Components/UI/Form/Code';

export const defaultCodeFormData = { code: '' };

export const defaultCodeFormErrors = { code: '' };

export const initialCodeFormState = {
  formData: defaultCodeFormData,
  errors: defaultCodeFormErrors,
};

export const setCodeFormConfig = [
  {
    Element: CodeInput,
    props: {
      name: 'code',
      type: 'text',
      required: true,
    },
  },
];
