export const DEFAULT_EVENTS = {
  connect: 'connect',
  error: 'connect_error',
  disconnect: 'disconnect',
};

export const DISCONNECT_REASONS = {
  server: 'io server disconnect',
  client: 'io client disconnect',
  timeout: 'ping timeout',
  close: 'transport close',
  error: 'transport error',
};

export const INCOMMING_MESSAGES = {
  all: 'notifications',
  single: 'notification',
  reauth: 'reauth',
};

export const MESSAGES = {
  getAll: 'get_notifications',
};
