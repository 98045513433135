import { ReactComponent as Inst } from '../Static/icons/social/instCircle.svg';
import { ReactComponent as FB } from '../Static/icons/social/fbCircle.svg';
import { ReactComponent as Linked } from '../Static/icons/social/linkedCircle.svg';

export const socialsIconConfig = {
  Instagram: Inst,
  Facebook: FB,
  LinkedIn: Linked,
};

export const socialsTypesConfig = ['Instagram', 'Facebook', 'LinkedIn'];
