import { useState, useRef, useEffect, useCallback } from 'react';

const checkToUpdate = (prev, next, bp) => {
  if (bp?.length)
    return bp.some(
      (bp) =>
        (prev.width >= bp && next.width < bp) ||
        (prev.width < bp && next.width >= bp)
    );
  else return prev.width !== next.width || prev.height !== next.height;
};

export const useWindowSize = (
  element = { current: null },
  bp = [],
  throttle = 100
) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const timeout = useRef(null);

  const handleResize = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      const next = {
        width: element.current
          ? element.current.offsetWidth
          : window.innerWidth,
        height: element.current
          ? element.current.offsetHeight
          : window.innerHeight,
      };
      checkToUpdate(windowSize, next, bp) && setWindowSize(next);
    }, [throttle]);
  }, [element, windowSize, bp, throttle]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    element.current &&
      setWindowSize({
        width: element.current.offsetWidth,
        height: element.current.offsetHeight,
      });
  }, [element]);

  return windowSize;
};
