import { useMemo } from 'react';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLastMessage } from '../../../Redux/Selectors/MessageSelectors';
import { CloseButton } from '../Buttons/CloseButton';
import { statusIcons } from '../StatusIcons';
import { StyledPopUp } from './styled';

export const MessagePopUp = () => {
  const lastMessage = useSelector(getLastMessage);
  const { success, error } = lastMessage;
  const [show, setShow] = useState(false);
  const timeoutRef = useRef(null);

  const Icon = useMemo(
    () => statusIcons[error ? 'rejected' : 'settled'],
    [error]
  );

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (error || (success && success !== 'Success' && success !== 'Sėkmė')) {
      setShow(true);
      timeoutRef.current = setTimeout(() => {
        setShow(false);
      }, 10000);
    } else setShow(false);
  }, [lastMessage, error, success]);

  return (
    <StyledPopUp
      show={show}
      onMouseEnter={() => {
        clearTimeout(timeoutRef.current);
      }}
      onMouseLeave={() => {
        timeoutRef.current = setTimeout(() => {
          setShow(false);
        }, 5000);
      }}
    >
      <Icon />
      <span>{error || success}</span>
      <CloseButton fn={() => setShow(false)} />
    </StyledPopUp>
  );
};
