import {
  StyledExpandedReview,
  StyledReviewItem,
  StyledReviewItemComment,
  StyledReviewItemDate,
  StyledReviewItemInfo,
  StyledReviewItemRow,
  StyledReviewItemRowActions,
  StyledReviewItemText,
  StyledReviewItemUserName,
} from './styled';
import { Grade } from '../../Grade';
import { Button } from '../../UI/Buttons';
import { ReactComponent as IconDelete } from '../../../Static/icons/console/actions/delete.svg';
import { ReactComponent as IconEdit } from '../../../Static/icons/console/actions/patch.svg';
import { ReactComponent as IconComments } from '../../../Static/icons/console/comments.svg';
import { Flex } from '../../UI/Flex';
import { isUser, roles } from '../../../Configs/constants';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../Redux/Actions/ModalActions';
import { useLocale } from '../../../Utils/Hooks/useLocale';
import L from '../locale.json';
import { getUserData } from '../../../Redux/Selectors/AuthSelectors';
import { rx } from '../../../Redux/Actions';
import { authRx } from '../../../Redux/Actions/AuthActions/thunk';
import { addCommentToReview } from '../../../API/Reviews';
import {
  StyledReviewAdd,
  StyledReviewAddBody,
  StyledReviewAddLabel,
} from '../styled';
import { Textarea } from '../../UI/Form/Textarea';
import { useMessages } from '../../../Redux/Selectors/MessageSelectors';
import { Preloader } from '../../UI/Preloader';
import { useActiveSubscribe } from '../../../Utils/Hooks/useActiveSubscribe';
import { UserCell } from '../../Table/Cells/UserCell';
import { useEffect } from 'react';

const ReviewComment = ({ reviewId, comment, setReviews, actionsReview }) => {
  const dispatch = useDispatch();
  const { _id: userId, role } = useSelector(getUserData);

  const isActiveSubscribe = useActiveSubscribe();

  const {
    loading: [addReviewLoading, editReviewLoading, deletedReviewLoading],
  } = useMessages(['addReview', 'editReview', 'deletedReview']);

  const handleOpenConfirmationDeleteComment = () => {
    if (isActiveSubscribe) {
      dispatch(
        openModal({
          data: {
            fn: setReviews,
            id: reviewId,
            commentId: comment._id,
          },
          Comp: 'confirmationDeleteReview',
        })
      );
    }
  };

  const handleOpenEditComment = () => {
    if (isActiveSubscribe) {
      dispatch(
        openModal({
          data: {
            fn: setReviews,
            id: reviewId,
            commentId: comment._id,
            text: comment.comment,
          },
          Comp: 'editReview',
        })
      );
    }
  };

  return (
    <>
      <StyledReviewItem
        expanded
        loadingReview={
          addReviewLoading || editReviewLoading || deletedReviewLoading
        }
      >
        <StyledReviewItemRow>
          <StyledReviewItemInfo>
            <StyledReviewItemDate>
              {new Date(comment.updated || comment.created).toLocaleDateString(
                'ru',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }
              )}
            </StyledReviewItemDate>
          </StyledReviewItemInfo>
        </StyledReviewItemRow>
        <StyledReviewItemRow>
          <StyledReviewItemUserName>
            <UserCell data={comment.user} />
          </StyledReviewItemUserName>
        </StyledReviewItemRow>
        <StyledReviewItemRow>
          <StyledReviewItemComment>{comment.comment}</StyledReviewItemComment>
        </StyledReviewItemRow>
        <StyledReviewItemRowActions>
          <Flex spacer={{ left: '12px', bottom: '12px' }}>
            {actionsReview &&
            (userId === comment.user?._id || role === roles.ADMIN) ? (
              <>
                <div>
                  <Button
                    kind={'circle'}
                    Icon={IconEdit}
                    disabled={!isActiveSubscribe}
                    fn={handleOpenEditComment}
                  />
                </div>
                <div>
                  <Button
                    kind={'circle'}
                    Icon={IconDelete}
                    disabled={!isActiveSubscribe}
                    fn={handleOpenConfirmationDeleteComment}
                  />
                </div>
              </>
            ) : null}
          </Flex>
        </StyledReviewItemRowActions>
      </StyledReviewItem>
    </>
  );
};

const AddCommentBlock = ({ expanded, reviewId, onAddComment }) => {
  const dispatch = useDispatch();
  const [locale] = useLocale(L);
  const [comment, setComment] = useState('');
  const {
    loading: [loading],
  } = useMessages([`addCommentToReview_${reviewId}`]);

  const handleAddCommentToReview = () => {
    dispatch(
      rx(
        authRx,
        `addCommentToReview_${reviewId}`,
        (dispatch, { data }) => {
          if (data?.review) {
            onAddComment(data?.review);
            setComment('');
          }
        },
        addCommentToReview,
        {
          id: reviewId,
          body: {
            comment,
          },
        }
      )
    );
  };

  const onChangeComment = ({ target: { value } }) => setComment(value);

  return (
    <StyledReviewAdd expanded={expanded}>
      <StyledReviewAddLabel>{locale.sendNewCommentLabel}</StyledReviewAddLabel>
      <StyledReviewAddBody>
        <Textarea name={'newReview'} value={comment} fn={onChangeComment} />
        {loading ? <Preloader /> : null}
      </StyledReviewAddBody>
      <Button
        kind={'green'}
        text={locale.sendNewComment}
        loading={loading}
        disabled={!comment.length || Boolean(loading)}
        fn={handleAddCommentToReview}
      />
    </StyledReviewAdd>
  );
};

export const ReviewItem = ({
  review,
  setReviews,
  actionsReview = true,
  addReview = false,
  userRole,
}) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [bodyHeight, setBodyHeight] = useState(false);
  const refComments = useRef(null);
  const [locale] = useLocale(L);
  const { _id: userId, role } = useSelector(getUserData);

  const isActiveSubscribe = useActiveSubscribe();

  const {
    loading: [addReviewLoading, editReviewLoading, deletedReviewLoading],
  } = useMessages(['addReview', 'editReview', 'deletedReview']);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const onAddComment = (review) => {
    setReviews((prevState) => {
      return prevState.map((r) => (r._id === review._id ? review : r));
    });
    setExpanded(true);
  };

  const comments = review.comments || [];

  useEffect(() => {
    if (expanded) {
      setBodyHeight(refComments.current.clientHeight);
    }
  }, [expanded, comments?.length]);

  const handleOpenAddComment = () => {
    dispatch(
      openModal({
        data: {
          fn: onAddComment,
          id: review._id,
        },
        Comp: 'addComment',
      })
    );
  };

  const handleOpenConfirmationDeleteReview = () => {
    if (isActiveSubscribe) {
      dispatch(
        openModal({
          data: {
            fn: setReviews,
            id: review._id,
          },
          Comp: 'confirmationDeleteReview',
        })
      );
    }
  };

  const handleOpenEditReview = () => {
    if (isActiveSubscribe) {
      dispatch(
        openModal({
          data: {
            fn: setReviews,
            id: review._id,
            text: review.comment,
            grade: review.grade,
          },
          Comp: 'editReview',
        })
      );
    }
  };

  return (
    <>
      <StyledReviewItem
        expanded={expanded}
        loadingReview={
          addReviewLoading || editReviewLoading || deletedReviewLoading
        }
      >
        <StyledReviewItemRow>
          <StyledReviewItemInfo>
            <StyledReviewItemDate>
              {new Date(review.updated || review.created).toLocaleDateString(
                'ru',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }
              )}
            </StyledReviewItemDate>
            <Grade grade={review.grade} showValue />
          </StyledReviewItemInfo>
        </StyledReviewItemRow>
        {review.user && !isUser(userRole || role) ? (
          <StyledReviewItemRow>
            <StyledReviewItemUserName>
              <UserCell data={review.user} />
            </StyledReviewItemUserName>
          </StyledReviewItemRow>
        ) : null}
        {review.company && isUser(userRole || role) ? (
          <StyledReviewItemRow>
            <StyledReviewItemUserName>
              <UserCell data={review.company} />
            </StyledReviewItemUserName>
          </StyledReviewItemRow>
        ) : null}
        {review.employee && (userRole || role) !== roles.EMPLOYEE ? (
          <StyledReviewItemRow>
            <StyledReviewItemText>{locale.employee}: </StyledReviewItemText>
            <UserCell data={review.employee} />
          </StyledReviewItemRow>
        ) : null}
        {review.order ? (
          <StyledReviewItemRow>
            <StyledReviewItemText>
              {locale.order}: {review.order.number}
            </StyledReviewItemText>
          </StyledReviewItemRow>
        ) : null}
        <StyledReviewItemRow>
          <StyledReviewItemComment>{review.comment}</StyledReviewItemComment>
        </StyledReviewItemRow>
        <StyledReviewItemRowActions>
          <Flex spacer={{ left: '12px', bottom: '12px' }}>
            {comments.length ? (
              <div>
                <Button
                  kind={'circle'}
                  active={expanded}
                  text={String(comments.length)}
                  Icon={IconComments}
                  fn={handleToggle}
                />
              </div>
            ) : null}
            {role === roles.ADMIN ? (
              <div>
                <Button
                  kind={'circle'}
                  Icon={IconComments}
                  text={'+'}
                  fn={handleOpenAddComment}
                />
              </div>
            ) : null}
            {actionsReview &&
            (userId === review.user?._id || role === roles.ADMIN) ? (
              <>
                <div>
                  <Button
                    kind={'circle'}
                    Icon={IconEdit}
                    disabled={!isActiveSubscribe}
                    fn={handleOpenEditReview}
                  />
                </div>
                <div>
                  <Button
                    kind={'circle'}
                    Icon={IconDelete}
                    disabled={!isActiveSubscribe}
                    fn={handleOpenConfirmationDeleteReview}
                  />
                </div>
              </>
            ) : null}
          </Flex>
        </StyledReviewItemRowActions>
        <StyledExpandedReview
          active={expanded && comments.length}
          bodyHeight={bodyHeight}
        >
          <div ref={refComments}>
            {comments.map((c) => {
              return (
                <ReviewComment
                  key={c._id}
                  comment={c}
                  reviewId={review._id}
                  setReviews={setReviews}
                  actionsReview={actionsReview}
                />
              );
            })}
          </div>
        </StyledExpandedReview>
      </StyledReviewItem>
      {addReview && isActiveSubscribe ? (
        <AddCommentBlock
          reviewId={review._id}
          expanded={expanded}
          onAddComment={onAddComment}
        />
      ) : null}
    </>
  );
};
