import { NavLink } from 'react-router-dom';

import { Avatar } from '../../UI/Avatar';
import { useSelector, useDispatch } from 'react-redux';

import { openSelect, closeSelect } from '../../../Redux/Actions/SelectActions';
import {
  getSelect,
  checkToUpdateSelect,
} from '../../../Redux/Selectors/SelectSelectors';

import {
  PersonLogoWrapper,
  StyledLogoutItem,
  StyledPanelDropdown,
  StyledUserDropdown,
} from './styled';

import { StyledSidebarLink } from '../../Sidebar/styled';
import { ReactComponent as LogoutIcon } from '../../../Static/icons/sidebar/logout.svg';
import { routes } from '../../../Routes/index';
import L from '../../Sidebar/locale.json';
import { openModal } from '../../../Redux/Actions/ModalActions';
import { useLocale } from '../../../Utils/Hooks/useLocale';
import {
  StyledSelect,
  StyledSelectArrow,
  StyledSelectLabel,
} from '../../UI/Form/Select/styled';
import { ReactComponent as SelectArrow } from '../../../Static/icons/chevron-down.svg';

export const UserDropdown = ({
  companyName,
  name,
  gravatar,
  avatarURL,
  role: Auth,
}) => {
  const open = useSelector(getSelect, checkToUpdateSelect(['user-dropdown']));
  const dispatch = useDispatch();
  const [locale] = useLocale(L);
  const handleOpen = (e) => {
    e.stopPropagation();
    open === 'user-dropdown'
      ? dispatch(closeSelect())
      : dispatch(openSelect('user-dropdown'));
  };

  const handleClickLogout = () => {
    dispatch(openModal({ Comp: 'logoutModal' }));
  };

  return (
    <StyledUserDropdown>
      <StyledSelect
        onClick={handleOpen}
        className={open === 'user-dropdown' ? 'open' : ''}
      >
        <StyledSelectLabel>
          <PersonLogoWrapper>
            <Avatar avatarURL={avatarURL} gravatar={gravatar} outline={true} />
          </PersonLogoWrapper>
          <span className="user-name">{companyName || name || 'New User'}</span>
          <StyledSelectArrow
            as={SelectArrow}
            className={open === 'user-dropdown' ? 'open' : ''}
          />
        </StyledSelectLabel>
        <StyledPanelDropdown
          as="ul"
          className={open === 'user-dropdown' ? 'open' : ''}
        >
          {routes
            .filter(({ access }) => access.some((role) => role === Auth))
            .map(({ Elm, Icon, title, path, name }, i) => (
              <li key={i}>
                <StyledSidebarLink as={NavLink} to={path}>
                  {Icon ? <Icon /> : null}
                  <span>{name ? locale[name] : 'Not found name link'}</span>
                </StyledSidebarLink>
              </li>
            ))}
          <StyledLogoutItem>
            <StyledSidebarLink as="button" onClick={handleClickLogout}>
              <LogoutIcon />
              <span>{locale.logout}</span>
            </StyledSidebarLink>
          </StyledLogoutItem>
        </StyledPanelDropdown>
      </StyledSelect>
    </StyledUserDropdown>
  );
};
