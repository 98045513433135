import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { getUserData } from '../../../../Redux/Selectors/AuthSelectors';
import { ServiceGrid } from '../../../ServicesList/styled';
import { Flex } from '../../../UI/Flex';
import { CheckboxInput } from '../../../UI/Form/Checkbox';
import { getPartnerships } from '../../../../API/Partnership';
import { rx } from '../../../../Redux/Actions';
import { unauthRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { TabsIndividual } from '../../../UI/Tabs/TabsIndividual';
import {
  addOrderServiceItem,
  removeOrderServiceItem,
  setNewOrderData,
} from '../../../../Redux/Actions/CreateOrderActions';
import { getNewOrderData } from '../../../../Redux/Selectors/CreateOrderSelectors';
import { roles } from '../../../../Configs/constants';
import { StyledFormButtonBlock } from '../../../UI/Form/styled';
import { Button } from '../../../UI/Buttons';
import { closeModal } from '../../../../Redux/Actions/ModalActions';
import { fetchUpdateOrderData } from '../../queries';

export const ServicesForm = ({ isCreate, onChange, order, isModal }) => {
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const newOrderData = useSelector(getNewOrderData);
  const [servicesUser, setServicesUser] = useState([]);
  const [servicesOrder, setServicesOrder] = useState(order?.services || []);
  const firstMount = useRef(true);
  const [tab, setTab] = useState('');
  const [partnerships, setPartnerships] = useState([]);
  const [, lang] = useLocale();

  useEffect(() => {
    if (userData.role === roles.EMPLOYEE) {
      setServicesUser(userData.company.services);
    } else {
      setServicesUser(userData.services);
    }
  }, [userData]);

  useEffect(() => {
    isCreate && setServicesOrder(newOrderData.services);
  }, [newOrderData, isCreate]);

  useEffect(() => {
    if (firstMount.current) {
      dispatch(
        rx(
          unauthRx,
          'getPartnerships',
          (_, { data }) => {
            if (data?.partnerships) {
              setPartnerships(data.partnerships);
              setTab(data.partnerships[0].type);
            }
          },
          getPartnerships,
          {
            controls: {
              params: {
                userRole: roles.PARTNER,
              },
            },
          }
        )
      );

      firstMount.current = false;
    }
  }, [dispatch]);

  const handleChangeTab = (e, type) => {
    setTab(type);
  };

  const handleCheckedService = (e, service) => {
    const { checked } = e.target;

    if (checked) {
      if (isCreate && !isModal) {
        dispatch(
          addOrderServiceItem({
            ...service,
            checked,
          })
        );
      } else {
        setServicesOrder((prevState) => [...prevState, service]);
      }
    } else {
      if (isCreate && !isModal) {
        dispatch(
          removeOrderServiceItem({
            ...service,
            checked,
          })
        );
      } else {
        setServicesOrder((prevState) =>
          prevState.filter((s) => s.service._id !== service.service._id)
        );
      }
    }
  };

  const partnershipsTabs = useMemo(() => {
    return partnerships.map((p) => ({
      type: p.type,
      name: p.name[lang],
      disabled: !servicesUser.some(({ service }) => service.type === p.type),
    }));
  }, [partnerships, lang, servicesUser]);

  const servicesList = useMemo(() => {
    return Object.values({
      ...servicesUser.reduce(
        (acc, s) => ({
          ...acc,
          [s.service._id]: {
            ...s,
            checked: false,
          },
        }),
        {}
      ),
      ...servicesOrder.reduce(
        (acc, s) => ({
          ...acc,
          [s.service._id]: {
            ...s,
            checked: true,
          },
        }),
        {}
      ),
    });
  }, [servicesUser, servicesOrder]);

  const disabledSubmit = useMemo(
    () => servicesList.filter((s) => s.checked).length < 1,
    [servicesList]
  );

  const handleSubmitForm = () => {
    const finishServices = servicesList.filter((s) => s.checked);

    if (isCreate) {
      dispatch(
        setNewOrderData({
          services: finishServices,
        })
      );
      dispatch(closeModal({}));
    } else {
      fetchUpdateOrderData(
        dispatch,
        { services: finishServices },
        (newOrder) => {
          onChange(newOrder);
          dispatch(closeModal({}));
        },
        order._id
      );
    }
  };

  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      spacer={{ left: '24px', bottom: '24px' }}
      full={true}
    >
      <div>
        <Flex justifyContent={'center'}>
          <div>
            <TabsIndividual
              buttons={partnershipsTabs}
              activeTab={tab}
              callbackChange={handleChangeTab}
            />
          </div>
        </Flex>
      </div>
      <div>
        <ServiceGrid>
          {servicesList?.length
            ? servicesList
                .filter(({ service: { type } }) => type === tab)
                .map((s) => {
                  if (s.service?.approved) {
                    return (
                      <div key={s.service._id}>
                        <div>
                          <Flex
                            justifyContent={'space-between'}
                            spacer={{ left: '8px', bottom: '8px' }}
                            alignItems={'flex-start'}
                            full={true}
                          >
                            <div>
                              <CheckboxInput
                                placeholder={
                                  s.service.name[lang] || s.service.name
                                }
                                checked={s.checked || false}
                                value={s.service._id}
                                fn={(e) => handleCheckedService(e, s)}
                              />
                            </div>
                            <div>
                              <div className={'price'}>€{s.price}</div>
                            </div>
                          </Flex>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })
            : null}
        </ServiceGrid>
      </div>
      {!isCreate || isModal ? (
        <div>
          <Flex justifyContent={'center'}>
            <div>
              <StyledFormButtonBlock>
                <Button
                  kind={'green'}
                  fn={handleSubmitForm}
                  text={'Save'}
                  disabled={disabledSubmit}
                />
              </StyledFormButtonBlock>
            </div>
          </Flex>
        </div>
      ) : null}
    </Flex>
  );
};
