import { useLocale } from '../../../Utils/Hooks/useLocale';
import { statusIcons } from '../StatusIcons';
import { StyledBenefits, StyledSelectedTile } from './styled';

const Icon = statusIcons.settled;

export const SelectedTile = ({ title, description, benefits, fn }) => {
  const [, lang] = useLocale();

  return (
    <StyledSelectedTile isAction={fn} onClick={fn ? fn : () => null}>
      {title ? <div>{title[lang]}</div> : null}
      {description ? <div>{description[lang]}</div> : null}
      {benefits ? (
        <StyledBenefits>
          {benefits.map((name) => (
            <li key={name[lang]}>
              <Icon />
              {name[lang]}
            </li>
          ))}
        </StyledBenefits>
      ) : null}
    </StyledSelectedTile>
  );
};
