import styled from 'styled-components';
import S from '../UI/variables';
import { StyledOrderParams } from '../Orders/ViewOrder/styled';
import { Button } from '../UI/Buttons';

export const StyledNumberPlate = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #000;
  box-shadow: 0 0 0 1px #fff;
  width: 150px;
  height: 33px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  font-size: 24px;
  font-weight: 500;
  white-space: pre;
  &:before {
    content: 'LT';
    position: absolute;
    background-color: #033dab;
    width: 15px;
    height: 100%;
    top: 0;
    left: 0;
    color: #fff;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 2px;
  }
  &:after {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 1.2px dotted #f4c904;
    top: 4px;
    left: 2px;
  }
`;

export const StyledCardset = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  & > li {
    width: 100%;
    @media (min-width: ${S.tablet}) {
      max-width: 400px;
    }
    @media (min-width: ${S.desktop}) {
      max-width: 500px;
    }
  }
`;

export const StyledCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  & > ${StyledOrderParams} {
    width: calc(100% - 170px);
  }
`;

export const StyledCarCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid ${S.purpleLight};
  padding: 12px;
  @media (min-width: ${S.tablet}) {
    padding: 18px;
  }
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  transition: border-color ${S.trFast};
  &:hover {
    border: 1px solid ${S.purpleDark};
  }
`;

export const StyledCarDetails = styled(StyledCarCard)`
  background-color: ${({ disabled }) =>
    disabled ? S.lightGray : S.purpleLight};
`;

export const StyledDeletedRow = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  & > span {
    color: ${S.rejected};
  }
`;

export const StyledAddCard = styled(StyledCarCard)`
  &:hover button {
    color: ${S.purpleDark};
  }
`;

export const StyledAddCardTitle = styled.h3`
  line-height: 33px;
  margin-bottom: 32px;
`;

export const StyledAddCarSubtitle = styled.p`
  color: ${S.textSecondary};
  margin-bottom: 22px;
`;

export const AddCarButton = styled(Button)`
  margin-top: auto;
  color: ${S.purpleSecond};
  font-weight: 500;
`;

export const StyledCarContent = styled.ul`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 14px;
  & > li {
    width: 100%;
  }
  @media (min-width: ${S.phone}) {
    gap: 0;
    flex-wrap: nowrap;
    & > li {
      &:first-of-type {
        border-right: 1px solid ${S.purpleDark};
        padding-right: 20px;
      }
      &:last-of-type {
        padding-left: 20px;
      }
      width: 50%;
    }
  }
`;

export const StyledContentList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const StyledPillLabel = styled.h3`
  font-size: 12px;
  margin-bottom: 5px;
`;

export const StyledPill = styled.div`
  font-size: 12px;
  background-color: #fff;
  border: 1px solid ${S.purpleLight};
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
`;

export const StyledPillName = styled.span`
  color: ${({ $isError }) => ($isError ? S.rejected : S.purpleDark)};
  padding-right: 5px;
  border-right: 1px solid
    ${({ $isError }) => ($isError ? S.rejected : S.purpleDark)};
`;

export const StyledPillValue = styled.span`
  padding-left: 5px;
  color: ${({ $isError }) => ($isError ? S.rejected : S.textPrimary)};
`;
