import { Input } from '../../../Components/UI/Form/Input';

export const defaultEmailFormData = { email: '' };

export const defaultEmailFormErrors = { email: '' };

export const initialEmailFormState = {
  formData: defaultEmailFormData,
  errors: defaultEmailFormErrors,
};

export const setEmailFormConfig = [
  {
    Element: Input,
    props: {
      name: 'email',
      placeholder: (l) => l.placeholders.email,
      type: 'email',
      required: true,
    },
  },
];
