export const roles = {
  ADMIN: 'admin',
  PARTNER: 'partner',
  PRIVATE_USER: 'privateUser',
  BUSINESS_USER: 'businessUser',
  EMPLOYEE: 'employee',
};

export const isUser = (role) =>
  [roles.PRIVATE_USER, roles.BUSINESS_USER].includes(role);

export const urls = {
  MAIN: {
    [roles.PARTNER]: '/orders',
    [roles.ADMIN]: '/statistics',
    [roles.EMPLOYEE]: '/calendar',
    [roles.PRIVATE_USER]: '/home',
    [roles.BUSINESS_USER]: '/home',
  },

  LOGIN: '/login',
  REGISTRATION: '/registration',

  STATISTICS: '/statistics',
  STATISTICS_REVENUE: '/revenue',

  USERS: '/users',
  USERS_BUSINESS: '/business',
  USERS_PRIVATE: '/private',
  USERS_PARTNER: '/partners',
  USERS_ADMIN: '/admin',

  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_INCOMPLETE_REGISTRATION: '/incomplete-registration',
  NOTIFICATIONS_NEW_CLIENTS: '/new-clients',
  NOTIFICATIONS_REVIEW: '/review',

  PAYMENTS: '/payments',

  SETTINGS: '/settings',

  CALENDAR: '/calendar',
};

export const languages = ['en', 'lt'];

//
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const ENV = process.env.REACT_APP_ENV;

export const defaultDay = [
  '1970-01-01T06:00:00.000Z',
  '1970-01-01T09:15:00.000Z',
  '1970-01-01T10:00:00.000Z',
  '1970-01-01T14:00:00.000Z',
];
export const weekendDay = [
  '1970-01-01T06:00:00.000Z',
  '1970-01-01T11:00:00.000Z',
];

export const defaultSchedule = [
  defaultDay,
  defaultDay,
  defaultDay,
  defaultDay,
  defaultDay,
  weekendDay,
  [],
];

export const NOTIFICATION_TYPES = {
  payment: {
    type: 'payment',
  },
  user: {
    type: 'user',
  },
  incomplete: {
    type: 'incomplete',
  },
  order: {
    type: 'order',
  },
  review: {
    type: 'review',
  },
  service: {
    type: 'service',
  },
  marketing: {
    type: 'marketing',
  },
  car: {
    type: 'car',
  },
};

export const NOTIFICATION_STATUSES = {
  success: {
    type: 'success',
  },
  failure: {
    type: 'failure',
  },
  warning: {
    type: 'warning',
  },
};

export const NOTIFICATION_KEYS = {
  [NOTIFICATION_TYPES.user.type]: {
    registeredPartner: 'USER_PARTNER_REGISTERED', //
    registeredPrivate: 'USER_PRIVATE_REGISTERED', //
    registeredBusiness: 'USER_BUSINESS_REGISTERED', //
    created: 'USER_CREATED', //
    employeeAdded: 'EMPLOYEE_ADDED', //
    updated: 'USER_UPDATED', //
    updatedByPartner: 'USER_UPDATED_BY_PARTNER', //
    activated: 'USER_ACTIVATED', //
    deactivated: 'USER_DEACTIVATED', //
    accessGrandted: 'USER_ACCESS_GRANTED', //
    appDeleted: 'USER_APP_DELETED', //
  },
  [NOTIFICATION_TYPES.incomplete.type]: {
    incomplete: 'INCOMPLETE_REGISTRATION', //
  },
  [NOTIFICATION_TYPES.payment.type]: {
    method_added: 'PAYMENT_METHOD_ADDED', //
    succeededManual: 'PAYMENT_SUCCEEDED_MANUAL', //
    rejectedManual: 'PAYMENT_REJECTED_MANUAL', //
    succeededAuto: 'PAYMENT_SUCCEEDED_AUTO', //
    warningAuto: 'PAYMENT_WARNING_AUTO', //
    rejectedAuto: 'PAYMENT_REJECTED_AUTO', //
    notifyToBeCharged: 'PAYMENT_NOTIFY_TO_BE_CHARGED', //
    promocodeApplied: 'PAYMENT_PROMOCODE_APPLIED', //
    promocodeExpired: 'PAYMENT_PROMOCODE_EXPIRED', //
  },
  [NOTIFICATION_TYPES.marketing.type]: {
    bannerOrdered: 'MARKETING_BANNER_ORDERED', //
    gearsOrdered: 'MARKETING_GEARS_ORDERED', //
  },
  [NOTIFICATION_TYPES.service.type]: {
    added: 'SERVICE_ADDED', //
    approved: 'SERVICE_APPROVED', //
    disapproved: 'SERVICE_DISAPPROVED', //
    deleted: 'SERVICE_DELETED', //
  },
  [NOTIFICATION_TYPES.review.type]: {
    added: 'REVIEW_ADDED', //
    updated: 'REVIEW_UPDATED', //
    deleted: 'REVIEW_DELETED', //
    commentAdded: 'REVIEW_COMMENT_ADDED', //
    commentUpdated: 'REVIEW_COMMENT_UPDATED', //
    commentDeleted: 'REVIEW_COMMENT_DELETED', //
  },
  [NOTIFICATION_TYPES.order.type]: {
    createdUser: 'ORDER_CREATED_USER', //
    created: 'ORDER_CREATED', //
    updated: 'ORDER_UPDATED', //
    deleted: 'ORDER_DELETED', //
    declined: 'ORDER_DECLINED', //
    dateUpdated: 'ORDER_DATE_UPDATED', //
    finshed: 'ORDER_FINISHED', //
    postponed: 'ORDER_POSTPONED', //
    lated: 'ORDER_LATED', //
  },
  [NOTIFICATION_TYPES.car.type]: {
    carAdded: 'CAR_ADDED', //
    tyreChangeWinter: 'CAR_TYRE_WINTER', //
    tyreChangeWinterOne: 'CAR_TYRE_WINTER_ONE', //
    tyreChangeWinterPartner: 'CAR_TYRE_WINTER_PARTNER', //
    tyreChangeSummer: 'CAR_TYRE_SUMMER', //
    tyreChangeSummerOne: 'CAR_TYRE_SUMMER_ONE', //
    tyreChangeSummerPartner: 'CAR_TYRE_SUMMER_PARTNER', //
    speedLimitWinter: 'CAR_SPEED_LIMIT_WINTER', //
    speedLimitSummer: 'CAR_SPEED_LIMIT_SUMMER', //
    mileageUpdate: 'CAR_MILEAGE_UPDATE', //
    periodicInsurance: 'CAR_PERIODIC_INSURANCE', //
    periodicInsuranceExpired: 'CAR_PERIODIC_INSURANCE_EXPIRED', //
    periodicMot: 'CAR_PERIODIC_MOT', //
    periodicMotExpired: 'CAR_PERIODIC_MOT_EXPIRED', //
    periodicOil: 'CAR_PERIODIC_OIL', //
    periodicOilExpired: 'CAR_PERIODIC_OIL_EXPIRED', //
    periodicAirFilter: 'CAR_PERIODIC_AIR_FILTER', //
    periodicAirFilterExpired: 'CAR_PERIODIC_AIR_FILTER_EXPIRED', //
    periodicBrakes: 'CAR_PERIODIC_BRAKES', //
    periodicBrakesExpired: 'CAR_PERIODIC_BRAKES_EXPIRED', //
  },
};
