import { useState } from 'react';
import L from './locale.json';
import { useLocale } from '../../../Utils/Hooks/useLocale';
import Calendar from 'react-calendar';
import { ReactComponent as IconLeft } from '../../../Static/icons/chevron-left.svg';
import { ReactComponent as IconRight } from '../../../Static/icons/chevron-right.svg';
import 'react-calendar/dist/Calendar.css';
import { StyledCalendar } from './styled';

export const DatePicker = ({
  fn = () => null,
  selectRange = false,
  showDoubleView = true,
  view,
  defaultView = 'month',
  maxDate = null,
  minDate = null,
  selectedDate,
  onClickMonth = null,
  onClickYear = null,
  onClickDay = null,
  inputRef,
}) => {
  const [locale, lang] = useLocale(L);

  const weekdays = locale.weekdays;
  const months = locale.months;

  const [value, setValue] = useState(
    selectedDate
      ? selectedDate
      : selectRange
      ? [new Date(), new Date()]
      : new Date()
  );

  const onChange = (val) => {
    setValue(val);
    if (Array.isArray(val)) {
      fn([val[0].toISOString(), val[1].toISOString()]);
    } else {
      if (selectedDate) {
        fn(
          new Date(
            new Date(val).setHours(
              selectedDate.getHours(),
              !selectedDate.getHours()
                ? selectedDate.getMinutes() || 1
                : selectedDate.getMinutes(),
              0,
              0
            )
          ).toISOString()
        );
      } else {
        fn(val.toISOString());
      }
    }
  };

  return (
    <StyledCalendar selectRange={selectRange}>
      <Calendar
        inputRef={inputRef}
        onClickDay={onClickDay}
        onClickMonth={onClickMonth}
        onClickYear={onClickYear}
        selectRange={selectRange}
        locale={lang}
        showDoubleView={showDoubleView}
        view={view}
        defaultView={defaultView}
        value={value}
        onChange={onChange}
        maxDate={maxDate}
        minDate={minDate}
        nextLabel={<IconRight />}
        prevLabel={<IconLeft />}
        formatShortWeekday={(locale, value) => weekdays[value.getDay()]}
        formatMonth={(locale, value) => months[value.getMonth()]}
      />
    </StyledCalendar>
  );
};
