import styled from 'styled-components';
import S from '../../variables';

export const StyledTilesMarketing = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: -12px;
  margin-bottom: -12px;

  & > * {
    padding-left: 12px;
    margin-bottom: 12px;
    width: 100%;
  }

  @media screen and (min-width: ${S.tablet}) {
    flex-direction: row;
  }
`;
