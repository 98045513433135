import { useLocale } from '../../../Utils/Hooks/useLocale';
import { Input } from '../Form/Input';
import { StyledH3 } from '../Titles';
import {
  InputWrapper,
  StyledHour,
  StyledIntervalPad,
  StyledIntervalWrapper,
  StyledNameWrapper,
  StyledPriceWrapper,
  StyledTimeWrapper,
  StyledWrapper,
} from './styled';
import L from './locale.json';
import { useState } from 'react';
import { toPrice } from '../../../Utils/Helpers';
import { getTimeString } from '../../Calendar/helpers';

const setValue = ({ name, time, hourlyRate }) => ({
  name,
  time,
  price: Math.round(hourlyRate * (time / 60) * 100) / 100,
});

const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const minutes = [15, 30, 45, 60];

export const LinearTimePicker = ({ time, setTime }) => {
  const [locale] = useLocale(L);
  const selectedHour = Math.floor(time / 60);
  const selectedMinute = time % 60;
  const [hoveredTime, setHoveredTime] = useState(0);
  const hoveredHour = Math.floor(hoveredTime / 60);
  const hoveredMinute = hoveredTime % 60;

  const onMouseEnter = (hoveredTime) => {
    setHoveredTime(hoveredTime);
  };
  const omMouseLeave = () => {
    setHoveredTime(0);
  };

  return (
    <StyledTimeWrapper onMouseLeave={omMouseLeave}>
      {hours.map((h) => (
        <li key={h}>
          <StyledHour>{`${h + 1}${locale.hourShort}`}</StyledHour>
          <StyledIntervalWrapper selected={h < selectedHour}>
            {minutes.map((m) => {
              const selected =
                h <= selectedHour && (h < selectedHour || m <= selectedMinute);
              const hovered =
                h <= hoveredHour && (h < hoveredHour || m <= hoveredMinute);
              const time = h * 60 + m;
              return (
                <StyledIntervalPad
                  key={m}
                  onMouseEnter={() => onMouseEnter(time)}
                  onClick={() => setTime(time)}
                  selected={hoveredTime ? hovered && selected : selected}
                  hovered={selected || hovered}
                />
              );
            })}
          </StyledIntervalWrapper>
        </li>
      ))}
    </StyledTimeWrapper>
  );
};

export const TimePriceChooser = ({
  name,
  time,
  price,
  hourlyRate,
  onChange,
  nameEditable,
}) => {
  const [locale, lang] = useLocale(L);
  const setName = ({ target }) => {
    onChange(setValue({ name: target.value, time, hourlyRate }));
  };
  const setTime = (time) => {
    onChange(setValue({ name, time, hourlyRate }));
  };

  return (
    <StyledWrapper>
      <StyledNameWrapper>
        <InputWrapper>
          <Input
            value={name}
            name="name"
            fn={setName}
            placeholder={locale.namePlaceholder}
            readOnly={!nameEditable}
            disabled={!nameEditable}
          />
        </InputWrapper>
        <StyledPriceWrapper>
          <StyledH3>{locale.time}:</StyledH3>
          <Input
            value={getTimeString((time || 0) * 60000, lang)}
            name="time"
            placeholder={`${0}${locale.hourShort}`}
            readOnly
            disabled
          />
        </StyledPriceWrapper>
        <StyledPriceWrapper>
          <StyledH3>{locale.price}:</StyledH3>
          <Input
            value={toPrice(price, lang)}
            name="price"
            placeholder={toPrice(0, lang)}
            readOnly
            disabled
          />
        </StyledPriceWrapper>
      </StyledNameWrapper>
      <LinearTimePicker time={time} setTime={setTime} />
    </StyledWrapper>
  );
};
