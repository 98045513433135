import styled, { css } from 'styled-components';

import S from '../UI/variables';

export const StyledAside = styled.aside`
  pisition: relative;
  z-index: 999;
  width: 63px;
  flex-shrink: 0;
  height: 100%;
  display: none;
  @media screen and (min-width: ${S.tablet}) {
    display: block;
  }
`;

export const StyledSidebar = styled.div`
  pisition: absolute;

  background: ${S.lightGray};
  height: 100%;
  transition: width ${S.trFast};
  border-right: 1px solid ${S.strokeGray};

  @media screen and (min-width: ${S.tablet}) {
    ${({ expanded }) =>
      expanded
        ? css`
            width: 280px;
            box-shadow: 6px 3px 8px rgba(0, 0, 0, 0.1);
          `
        : css`
            width: 64px;
          `}
  }
`;

export const StyledSidebarScroll = styled.div`
  overflow: auto;
  height: 100%;
`;

export const StyledSidebarList = styled.ul`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  & > li {
    overflow: hidden;
    flex-shrink: 0;
  }
`;

export const StyledSidebarLink = styled.a`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  color: ${S.textPrimary};
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  transition: color ${S.trFast}, background ${S.trFast};
  width: 100%;

  & svg {
    fill: currentColor;
    margin-right: 16px;
    transition: fill ${S.trFast};
    width: 24px;
    flex-shrink: 0;
  }

  &.active {
    background: ${S.purpleSecond};
  }

  &:not(.active):hover {
    background: ${S.purpleLight};
  }
`;

export const StyledSidebarLogoutItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding: 0 24px;
  height: 112px;
  color: ${S.textSecondary};
  border-top: 1px solid ${S.strokeGray};
  cursor: pointer;
  transition: color ${S.trFast};

  &:hover {
    color: ${S.purpleDark};
  }

  & svg {
    fill: currentColor;
    margin-right: 16px;
    flex-shrink: 0;
  }
`;

export const StyledSidebarToggle = styled.li`
  display: flex;
  padding: 0 22px;
`;

export const StyledSidebarToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 50%;
  flex-shrink: 0;
  outline: none;
  background: ${S.white};
  box-shadow: ${S.mainShade};
  margin-bottom: 20px;
  padding: 3px;
  cursor: pointer;

  svg {
    fill: currentColor;
    transform: scale(${({ expanded }) => (expanded ? 1 : -1)});
    transition: transform ${S.trFast};
  }
`;
