import { StyledH2 } from '../../Titles';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { addPartnership, updatePartnership } from '../../../../API/Partnership';
import {
  StyledCustomError,
  StyledFormButtonBlock,
  StyledFormGroup,
  StyledInputName,
} from '../../Form/styled';
import { Select } from '../../Form/Select';
import {
  partnershipsRolesConfig,
  partnershipsTypesPartnerConfig,
} from '../../../../Configs/partnershipsTypesConfig';
import { Input } from '../../Form/Input';
import { Textarea } from '../../Form/Textarea';
import { Button } from '../../Buttons';
import { validation } from '../../../../Utils/validation';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { useState } from 'react';
import { roles } from '../../../../Configs/constants';
import L from './locale.json';
import Ls from '../../../Subscriptions/locale.json';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { getUnitTypes } from '../../../Subscriptions';

export const SubscriptionModal = ({
  edit,
  subscription,
  onChange,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [locale, lang] = useLocale(L);
  const [localeS] = useLocale(Ls);
  const units = useMemo(() => Object.values(getUnitTypes(localeS)), [localeS]);
  const [inputs, setInputs] = useState({
    subscriptionRole: subscription
      ? subscription.userRole === roles.PARTNER
        ? 0
        : subscription.userRole === roles.BUSINESS_USER
        ? 1
        : 2
      : 0,
    subscriptionType: subscription
      ? subscription.type === partnershipsTypesPartnerConfig[0]
        ? 0
        : subscription.type === partnershipsTypesPartnerConfig[1]
        ? 1
        : subscription.type === partnershipsTypesPartnerConfig[2]
        ? 2
        : subscription.type === 'private'
        ? 3
        : 4
      : 0,
    nameEn: subscription?.name?.en || '',
    nameLt: subscription?.name?.lt || '',
    descriptionEn: subscription?.description?.en || '',
    descriptionLt: subscription?.description?.lt || '',
    pricePerMonth: subscription?.pricePerMonth
      ? String(subscription.pricePerMonth) || ''
      : '',
    pricePerUnit: String(subscription?.pricePerUnit || 0),
    freeUnitsAmount: String(subscription?.freeUnitsAmount || 0),
    unit: units.findIndex(({ type }) => type === subscription?.unit) || 0,
  });

  const [errors, setErrors] = useState({
    nameEn: subscription?.name?.en ? '' : null,
    nameLt: subscription?.name?.lt ? '' : null,
    descriptionEn: subscription?.description?.en ? '' : null,
    descriptionLt: subscription?.description?.lt ? '' : null,
    pricePerMonth:
      typeof subscription?.pricePerMonth !== 'undefined' ? '' : null,
    pricePerUnit: typeof subscription?.pricePerUnit !== 'undefined' ? '' : null,
    freeUnitsAmount:
      typeof subscription?.freeUnitsAmount !== 'undefined' ? '' : null,
  });

  const {
    messages: [fetchSubscriptionMessages],
    loading: [fetchSubscriptionLoading],
  } = useMessages(['fetchSubscription']);

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      type:
        inputs.subscriptionRole === 0
          ? partnershipsTypesPartnerConfig[inputs.subscriptionType]
          : inputs.subscriptionRole === 1
          ? 'business'
          : 'private',
      userRole:
        inputs.subscriptionRole === 0
          ? roles.PARTNER
          : inputs.subscriptionRole === 1
          ? roles.BUSINESS_USER
          : roles.PRIVATE_USER,
      name: {
        en: inputs.nameEn,
        lt: inputs.nameLt,
      },
      description: {
        en: inputs.descriptionEn,
        lt: inputs.descriptionLt,
      },
      pricePerMonth: inputs.pricePerMonth,
      pricePerUnit: inputs.pricePerUnit,
      freeUnitsAmount: inputs.freeUnitsAmount,
      unit: units[inputs.unit].type,
    };

    if (edit) {
      dispatch(
        rx(
          authRx,
          'fetchSubscription',
          (dispatch, { data }) => {
            if (data?.partnership) {
              onChange &&
                onChange((prevState) =>
                  prevState.map((p) =>
                    p._id === data.partnership._id ? data.partnership : p
                  )
                );
              onClose();
            }
          },
          updatePartnership,
          {
            id: subscription._id,
            body,
          }
        )
      );
    } else {
      dispatch(
        rx(
          authRx,
          'fetchSubscription',
          (dispatch, { data }) => {
            if (data?.partnership) {
              onChange &&
                onChange((prevState) => [...prevState, data.partnership]);
              onClose();
            }
          },
          addPartnership,
          {
            body,
          }
        )
      );
    }
  };

  const handleChangeInput = (e) => {
    const { value, error, name } = validation(e.target, lang);

    setErrors((prevState) => ({ ...prevState, [name]: error }));
    if (name === 'subscriptionRole') {
      switch (value) {
        case 1:
          setInputs((prevState) => ({
            ...prevState,
            [name]: value,
            subscriptionType: 3,
          }));
          break;
        case 2:
          setInputs((prevState) => ({
            ...prevState,
            [name]: value,
            subscriptionType: 4,
          }));
          break;
        default:
          setInputs((prevState) => ({
            ...prevState,
            [name]: value,
            subscriptionType:
              prevState.subscriptionType > 2 ? 0 : prevState.subscriptionType,
          }));
      }
    } else {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <StyledH2>{edit ? locale.titles.edit : locale.titles.add}</StyledH2>
      <form noValidate={true}>
        <StyledFormGroup>
          <StyledInputName>
            {locale.placeholders.subscriptionRole}
          </StyledInputName>
          <Select
            kind={'form'}
            name={'subscriptionRole'}
            value={inputs.subscriptionRole}
            options={partnershipsRolesConfig}
            fn={handleChangeInput}
          />
        </StyledFormGroup>
        {inputs.subscriptionRole === 0 ? (
          <StyledFormGroup>
            <StyledInputName>
              {locale.placeholders.subscriptionType}
            </StyledInputName>
            <Select
              kind={'form'}
              name={'subscriptionType'}
              value={inputs.subscriptionType}
              options={partnershipsTypesPartnerConfig}
              fn={handleChangeInput}
            />
          </StyledFormGroup>
        ) : null}
        <StyledFormGroup>
          <StyledInputName>{locale.placeholders.nameEn}</StyledInputName>
          <Input
            name={'nameEn'}
            required={true}
            placeholder={locale.placeholders.nameEn}
            errors={errors.nameEn}
            value={inputs.nameEn}
            fn={handleChangeInput}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <StyledInputName>{locale.placeholders.nameLt}</StyledInputName>
          <Input
            name={'nameLt'}
            required={true}
            placeholder={locale.placeholders.nameLt}
            errors={errors.nameLt}
            value={inputs.nameLt}
            fn={handleChangeInput}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <StyledInputName>{locale.placeholders.descriptionEn}</StyledInputName>
          <Textarea
            name={'descriptionEn'}
            required={true}
            placeholder={locale.placeholders.descriptionEn}
            errors={errors.descriptionEn}
            value={inputs.descriptionEn}
            fn={handleChangeInput}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <StyledInputName>{locale.placeholders.descriptionLt}</StyledInputName>
          <Textarea
            name={'descriptionLt'}
            required={true}
            placeholder={locale.placeholders.descriptionLt}
            errors={errors.descriptionLt}
            value={inputs.descriptionLt}
            fn={handleChangeInput}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <StyledInputName>{locale.placeholders.pricePerMonth}</StyledInputName>
          <Input
            name={'pricePerMonth'}
            required={true}
            placeholder={locale.placeholders.pricePerMonth}
            errors={errors.pricePerMonth}
            value={inputs.pricePerMonth}
            fn={handleChangeInput}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <StyledInputName>{locale.placeholders.unit}</StyledInputName>
          <Select
            kind={'form'}
            name={'unit'}
            value={inputs.unit}
            options={units.map(({ name }) => name.one)}
            fn={handleChangeInput}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <StyledInputName>{locale.placeholders.pricePerUnit}</StyledInputName>
          <Input
            name={'pricePerUnit'}
            required={true}
            placeholder={locale.placeholders.pricePerUnit}
            errors={errors.pricePerUnit}
            value={inputs.pricePerUnit}
            fn={handleChangeInput}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <StyledInputName>
            {locale.placeholders.freeUnitsAmount}
          </StyledInputName>
          <Input
            name={'freeUnitsAmount'}
            required={true}
            placeholder={locale.placeholders.freeUnitsAmount}
            errors={errors.freeUnitsAmount}
            value={inputs.freeUnitsAmount}
            fn={handleChangeInput}
          />
        </StyledFormGroup>
        {fetchSubscriptionMessages.error ? (
          <StyledCustomError>{fetchSubscriptionMessages}</StyledCustomError>
        ) : null}
        <StyledFormButtonBlock>
          <Button
            text={edit ? locale.submits.edit : locale.submits.add}
            kind={'green'}
            loading={fetchSubscriptionLoading}
            fn={handleSubmit}
          />
        </StyledFormButtonBlock>
      </form>
    </>
  );
};
