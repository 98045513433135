import { Input } from '../../../Components/UI/Form/Input';
import { Select } from '../../../Components/UI/Form/Select';
import { socialsTypesConfig } from '../../socials-config';

export const defaultSocialLinkFormData = { social: 0, name: '', link: '' };

export const defaultSocialLinkFormErrors = { social: '', name: '', link: '' };

export const initialSocialLinkFormState = {
  formData: defaultSocialLinkFormData,
  errors: defaultSocialLinkFormErrors,
};

export const setSocialLinkFormConfig = [
  {
    Element: Select,
    props: {
      options: socialsTypesConfig,
      kind: 'form',
      name: 'social',
      placeholder: (l) => l.placeholders.social,
      required: true,
    },
  },
  {
    Element: Input,
    props: {
      name: 'name',
      type: 'text',
      placeholder: (l) => l.placeholders.name,
      required: true,
    },
  },
  {
    Element: Input,
    props: {
      name: 'link',
      type: 'url',
      placeholder: (l) => l.placeholders.link,
      required: true,
    },
  },
];
