import styled from 'styled-components';
import { StyledCard } from '../UI/Card/styled';
import S from '../UI/variables';

export const StyledSubscriptionsItem = styled(StyledCard)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${S.white};
  box-shadow: 0 0 7px #ebebeb;

  @media (min-width: ${S.tabletLandscape}) {
    flex-direction: row;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const StyledSubscriptionsName = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 24px;
  width: 200px;
  flex-shrink: 0;

  @media (min-width: ${S.tabletLandscape}) {
    margin-right: 24px;
    margin-bottom: 0;
    width: 200px;
  }
`;

export const StyledSubscriptionsDescription = styled.div`
  font-size: 14px;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;

  @media (min-width: ${S.tabletLandscape}) {
    max-width: 447px;
    margin-right: auto;
  }
`;

export const StyledSubscriptionsControls = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 32px;

  @media (min-width: ${S.tabletLandscape}) {
    margin-top: 0;
    margin-left: 75px;
  }
`;

export const StyledSubscriptionsPrice = styled.div`
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  margin-right: 28px;
`;

export const StyledSubscriptionsButtons = styled.div`
  margin-left: auto;
`;
