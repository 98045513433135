import { roles } from '../../Configs/constants';

export const isEmployeeAccessRoute = (
  accessRoles,
  accessEmployee,
  userData,
  isSidebar = false
) => {
  const accessMain = accessRoles.some((role) =>
    !isSidebar
      ? userData.role === role || role === 'notFound'
      : userData.role === role
  );

  if (userData.role === roles.EMPLOYEE && accessMain) {
    const accessEmpl = accessEmployee?.some((a) => a === userData.access);

    return accessMain && accessEmpl;
  }

  return accessMain;
};

export const haveAccess = (role, access, isActive = true) => {
  return !!isActive && (role !== roles.EMPLOYEE || access === 'full');
};
