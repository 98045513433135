import { MultipleImagePicker } from '../../UI/Form/MultipleImage';
import { useSelector } from 'react-redux';
import { StyledFormGroup } from '../../UI/Form/styled';
import { getUserData } from '../../../Redux/Selectors/AuthSelectors';
import { useDependentState } from '../../../Utils/Hooks/useDependentState';
import { isUser } from '../../../Configs/constants';

export const ImagesForm = ({ onChange, orderData, disabled }) => {
  const { role } = useSelector(getUserData);

  const imagesProp = isUser(role) ? 'clientImages' : 'companyImages';

  const [photosState, setPhotosState] = useDependentState(
    orderData[imagesProp] || [],
    (images) => {
      console.log('images', images);
      onChange(imagesProp, images);
    }
  );

  return (
    <>
      <StyledFormGroup>
        <MultipleImagePicker
          disabled={disabled}
          images={photosState}
          onChange={setPhotosState}
        />
      </StyledFormGroup>
    </>
  );
};
