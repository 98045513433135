import { useMemo, useState } from 'react';
import {
  StyledCarImage,
  StyledOrderBlock,
  StyledOrderBlockEdit,
  StyledOrderBlockTitle,
  StyledOrderContainer,
  StyledOrderParams,
  StyledOrderTotal,
  StyledViewComment,
} from './styled';
import { Flex } from '../../UI/Flex';
import { ReactComponent as IconEdit } from '../../../Static/icons/console/actions/pancel.svg';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../Redux/Actions/ModalActions';
import {
  ImageInputMultiple,
  MultipleImagePicker,
} from '../../UI/Form/MultipleImage';
import { Button } from '../../UI/Buttons';
import { StyledH2 } from '../../UI/Titles';
import { useLocale } from '../../../Utils/Hooks/useLocale';
import L from './locale.json';
import {
  calculateTimeAndPriceServices,
  getTimeString,
} from '../../Calendar/helpers';
import { getUserData } from '../../../Redux/Selectors/AuthSelectors';
import { useMessages } from '../../../Redux/Selectors/MessageSelectors';
import { Preloader } from '../../UI/Preloader';
import { StyledCustomError } from '../../UI/Form/styled';
import {
  fetchCancelOrder,
  fetchCreateOrderData,
  fetchDeleteOrder,
  fetchFinishOrder,
  fetchUpdateOrderImages,
} from '../queries';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetNewOrderData } from '../../../Redux/Actions/CreateOrderActions';
import { isUser, roles } from '../../../Configs/constants';
import { getCarDetails } from '../carConfig';
import { WithTooltip } from '../../UI/Tooltip';
import { CheckboxInput } from '../../UI/Form/Checkbox';
import { toDateAndTime, toPrice } from '../../../Utils/Helpers';
import { setDateObject } from '../../../Utils/Dates';
import { useAccess } from '../../../Utils/Hooks/useAccess';
import { Avatar } from '../../UI/Avatar';
import { CompanyAddress } from '../../CompanyAddress';
import { NumberPlate } from '../../Vehicles/NumberPlate';

export const OrderParams = ({
  title = '',
  value = '-',
  user,
  image,
  type,
  noMargin,
  link,
}) => {
  return (
    <StyledOrderParams
      alignCenter={!!user || !!image || typeof value == 'boolean'}
      type={type}
      $noMargin={noMargin}
    >
      {title ? (
        <span>
          {title}
          {user || value || typeof value == 'boolean' ? ':' : ''}&nbsp;
        </span>
      ) : null}
      {user ? (
        <>
          <Avatar {...user} wd="24" />
          &nbsp;
        </>
      ) : null}
      {image ? (
        <>
          <StyledCarImage
            src={image}
            alt={value}
            onError={(e) => e?.target?.remove()}
          />
          &nbsp;
        </>
      ) : null}
      {typeof value == 'boolean' ? (
        <CheckboxInput readOnly checked={value} />
      ) : (
        <WithTooltip text={value} showOnOverflow>
          {link ? <a href={link}>{value}</a> : <span>{value}</span>}
        </WithTooltip>
      )}
    </StyledOrderParams>
  );
};

export const CarDetails = ({
  canEdit,
  isViewOnly,
  isCreate,
  openPatchModal,
  make,
  model,
  number,
  year,
  vin,
  ...restCar
}) => {
  const [locale, lang] = useLocale(L);
  const [expanded, setExpanded] = useState(false);
  const { role } = useSelector(getUserData);
  const navigate = useNavigate();

  const details = useMemo(() => getCarDetails(restCar, lang), [restCar, lang]);
  return (
    <StyledOrderBlock>
      <StyledOrderBlockTitle>{locale.aboutCar.title}</StyledOrderBlockTitle>
      {!isCreate ? (
        <>
          <OrderParams
            title={locale.aboutCar.car}
            value={make.name}
            image={make.image}
          />
          <OrderParams title={locale.aboutCar.model} value={model.name} />
        </>
      ) : null}
      <StyledOrderParams>
        <NumberPlate number={number} />
      </StyledOrderParams>
      <OrderParams title={locale.aboutCar.vin} value={vin} />
      <OrderParams title={locale.aboutCar.year} value={year} />
      {(isUser(role) || isCreate) && canEdit && role !== roles.ADMIN ? (
        <StyledOrderBlockEdit onClick={openPatchModal}>
          <IconEdit />
        </StyledOrderBlockEdit>
      ) : null}
      {!isCreate && expanded
        ? details.map(({ key, type, value, image }) => (
            <OrderParams
              key={key}
              title={locale.aboutCar[key]}
              type={type}
              value={value}
              image={image}
            />
          ))
        : null}
      {!isCreate && !isViewOnly ? (
        <Flex
          justifyContent={'flex-end'}
          spacer={{ left: '10px', bottom: '10px' }}
          flexWrap={'wrap'}
        >
          <div>
            <Button
              kind={'green'}
              text={locale.viewHistoryCar}
              fn={() => navigate(`/orders/view/car-history/${restCar._id}`)}
            />
          </div>
          {details.length ? (
            <div>
              <Button
                text={expanded ? locale.viewLess : locale.viewMore}
                fn={() => setExpanded(!expanded)}
              />
            </div>
          ) : null}
        </Flex>
      ) : null}
    </StyledOrderBlock>
  );
};

export const getOrderData = (orderData, patch, isCreate, search) => {
  let start = orderData.start;
  if (!start && isCreate && search) {
    const date = new URLSearchParams(search).get('date');
    if (date)
      start = new Date(
        setDateObject(date).setHours(
          new Date().getHours(),
          new Date().getMinutes(),
          0,
          0
        )
      ).toISOString();
  }
  return { ...orderData, start, ...patch };
};

export const ViewOrder = ({ isCreate, orderData = {} }) => {
  const { role } = useSelector(getUserData);
  const canEdit = useAccess({ checkActive: true });
  const dispatch = useDispatch();
  const [locale, lang] = useLocale(L);
  const navigate = useNavigate();
  const location = useLocation();
  const [patch, setPatch] = useState({});

  const order = useMemo(() => {
    return getOrderData(
      orderData,
      patch,
      isCreate,
      location.state?.from?.search
    );
  }, [orderData, patch, isCreate, location.state?.from?.search]);

  const {
    messages: [
      updateOrderImagesMessage,
      updateOrderMessage,
      createOrderMessage,
    ],
    loading: [
      updateOrderImagesLoading,
      updateOrderLoading,
      createOrderLoading,
      cancelOrderLoading,
      finishOrderLoading,
    ],
  } = useMessages([
    `updateOrderImages_${order._id}`,
    `updateOrder`,
    'createOrder',
    `cancelOrder_${order._id}`,
    `finishOrder_${order._id}`,
  ]);

  const { price: totalPrice, time: estimatedDurationOfWork } = useMemo(() => {
    return order.services?.length
      ? calculateTimeAndPriceServices(order.services)
      : { price: 0, time: 0 };
  }, [order]);

  const openPatchModal =
    (Comp, size = 'small') =>
    () => {
      dispatch(
        openModal({
          data: {
            order,
            isCreate,
            onChange: setPatch,
          },
          size,
          Comp,
        })
      );
    };

  const handleCreateOrder = () => {
    const homePath =
      location.state?.from?.pathname + location.state?.from?.search ||
      '/orders';
    const { companyImages, ...restOrder } = order;
    fetchCreateOrderData(dispatch, restOrder).then((newOrder) => {
      if (order.companyImages?.length) {
        const formData = new FormData();
        const files = Array.from(order.companyImages);

        files.forEach((file) => {
          formData.append('images', file);
        });
        fetchUpdateOrderImages(
          dispatch,
          formData,
          () => {
            navigate(homePath);
            dispatch(resetNewOrderData());
          },
          newOrder._id
        );
      } else {
        navigate(homePath);
        dispatch(resetNewOrderData());
      }
    });
  };

  const decideToCLose = () => {
    navigate('/orders');
  };

  const handleCancelOrder = () =>
    fetchCancelOrder(dispatch, order._id, decideToCLose);

  const handleFinishOrder = () =>
    fetchFinishOrder(dispatch, order._id, decideToCLose);

  const isLoading =
    updateOrderLoading ||
    createOrderLoading ||
    updateOrderImagesLoading ||
    cancelOrderLoading ||
    finishOrderLoading;

  return (
    <StyledOrderContainer isLoading={isLoading}>
      <Flex
        flexDirection={'column'}
        spacer={{ left: '16px', bottom: '16px' }}
        full
      >
        <div>
          <StyledH2>
            {isCreate
              ? locale.titleOrder.new
              : `${locale.titleOrder.number} ${order.number || 0}`}
          </StyledH2>
        </div>

        <div>
          <StyledOrderBlock>
            <StyledOrderBlockTitle>
              {locale.generalInformation.title}
            </StyledOrderBlockTitle>
            {order.client ? (
              <>
                <OrderParams
                  title={locale.generalInformation.client}
                  value={order.client.name}
                  user={order.client}
                />
                <OrderParams
                  title={locale.generalInformation.phone}
                  value={order.client.phone}
                  link={`tel:${order.client.phone}`}
                />
                {order.client.email ? (
                  <OrderParams
                    title={locale.generalInformation.email}
                    value={order.client.email}
                    link={`mailto:${order.client.email}`}
                  />
                ) : null}
              </>
            ) : null}
            <StyledOrderParams>
              <span>{locale.generalInformation.dateAndTime}: &nbsp;</span>
              <span>{toDateAndTime(order.start, lang)}</span>
            </StyledOrderParams>
            {order.employees?.length
              ? order.employees.map((employee) => {
                  const { _id, name, phone } = employee;
                  return (
                    <OrderParams
                      key={_id}
                      title={locale.generalInformation.executor}
                      value={name || phone}
                      user={employee}
                    />
                  );
                })
              : null}
            {((Date.parse(order.start) > Date.now() && role !== roles.ADMIN) ||
              isCreate) &&
            canEdit ? (
              <StyledOrderBlockEdit
                onClick={openPatchModal('changeGeneralInformationOrderModal')}
              >
                <IconEdit />
              </StyledOrderBlockEdit>
            ) : null}
          </StyledOrderBlock>
        </div>
        <div>
          {order.car ? (
            <CarDetails
              {...order.car}
              isCreate={isCreate}
              canEdit={canEdit}
              openPatchModal={openPatchModal('changeCarInformationOrderModal')}
            />
          ) : null}
        </div>
        <div>
          <StyledOrderBlock>
            <StyledOrderBlockTitle>
              {locale.services.title}
            </StyledOrderBlockTitle>
            {order.services?.length
              ? order.services.map((service) => {
                  return (
                    <OrderParams
                      key={service.service._id}
                      title={
                        isCreate
                          ? service.service.name
                          : service.service.name[lang]
                      }
                      value={toPrice(service.price, lang)}
                      type="space-between"
                    />
                  );
                })
              : null}
            {canEdit &&
            role !== roles.ADMIN &&
            Date.parse(order.start) > Date.now() ? (
              <StyledOrderBlockEdit
                onClick={openPatchModal('changeServicesOrderModal', 'big')}
              >
                <IconEdit />
              </StyledOrderBlockEdit>
            ) : null}
          </StyledOrderBlock>
        </div>
        <div>
          <StyledOrderBlock>
            <StyledOrderBlockTitle>{`${locale.time.title}: ${getTimeString(
              (Math.floor(Date.parse(order.end) / 60000) -
                Math.floor(Date.parse(order.start) / 60000)) *
                60000,
              lang
            )}`}</StyledOrderBlockTitle>
            <OrderParams
              title={locale.time.estimatedDurationOfWork}
              value={getTimeString(estimatedDurationOfWork)}
              type="space-between"
            />
            <OrderParams
              title={locale.time.editDurationOfWorkTo}
              value={
                order.timeShift ? getTimeString(order.timeShift, lang) : '-'
              }
              type="space-between"
            />
            {role !== roles.ADMIN &&
            Date.parse(order.start) > Date.now() &&
            canEdit ? (
              <StyledOrderBlockEdit
                onClick={openPatchModal('changeTimeOrderModal')}
              >
                <IconEdit />
              </StyledOrderBlockEdit>
            ) : null}
          </StyledOrderBlock>
        </div>
        {!isCreate ? (
          <>
            <div>
              <StyledOrderBlock>
                <StyledOrderBlockTitle>
                  {locale.photoClient.title}
                </StyledOrderBlockTitle>
                <ImageInputMultiple
                  remoteFiles={order.clientImages || []}
                  actions={false}
                />
              </StyledOrderBlock>
            </div>
            <div>
              <StyledOrderBlock>
                <StyledOrderBlockTitle>
                  {locale.commentClient.title}
                </StyledOrderBlockTitle>
                <StyledViewComment>
                  {order.clientComment || '-'}
                </StyledViewComment>
              </StyledOrderBlock>
            </div>
          </>
        ) : null}
        <div>
          <StyledOrderBlock>
            {updateOrderImagesLoading ? <Preloader /> : null}

            <StyledOrderBlockTitle>
              {locale.photoCompany.title}
            </StyledOrderBlockTitle>
            {isCreate ? (
              <ImageInputMultiple files={order.companyImages} actions={false} />
            ) : (
              <ImageInputMultiple
                remoteFiles={order.companyImages}
                actions={false}
              />
            )}
            {canEdit && role !== roles.ADMIN ? (
              <StyledOrderBlockEdit
                onClick={openPatchModal('changePhotosOrderModal')}
              >
                <IconEdit />
              </StyledOrderBlockEdit>
            ) : null}
            {updateOrderImagesMessage.error ? (
              <StyledCustomError>
                {updateOrderImagesMessage.error}
              </StyledCustomError>
            ) : null}
          </StyledOrderBlock>
        </div>
        <div>
          <StyledOrderBlock>
            <StyledOrderBlockTitle>
              {locale.commentCompany.title}
            </StyledOrderBlockTitle>
            <StyledViewComment>{order.companyComment || '-'}</StyledViewComment>
            {canEdit && role !== roles.ADMIN ? (
              <StyledOrderBlockEdit
                onClick={openPatchModal('changeCommentOrderModal')}
              >
                <IconEdit />
              </StyledOrderBlockEdit>
            ) : null}
          </StyledOrderBlock>
        </div>
        {updateOrderMessage.error || createOrderMessage.error ? (
          <div>
            <StyledCustomError>
              {updateOrderMessage.error || createOrderMessage.error}
            </StyledCustomError>
          </div>
        ) : null}
        <div>
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <div>
              <StyledOrderTotal>
                {locale.total}:{' '}
                {isCreate
                  ? toPrice(totalPrice, lang)
                  : toPrice(order.price, lang)}
              </StyledOrderTotal>
            </div>
            {canEdit && role !== roles.ADMIN && !order.ended ? (
              <div>
                <Flex spacer={{ left: '8px', bottom: '8px' }}>
                  {isCreate ? (
                    <div>
                      <Button
                        kind={'green'}
                        text={locale.createBtn}
                        loading={createOrderLoading}
                        disabled={isLoading}
                        fn={handleCreateOrder}
                      />
                    </div>
                  ) : Date.parse(order.start) > Date.now() ? (
                    <>
                      <div>
                        <Button
                          text={locale.cancelBtn}
                          fn={handleCancelOrder}
                          loading={cancelOrderLoading}
                          disabled={isLoading}
                        />
                      </div>
                    </>
                  ) : (
                    <div>
                      <Button
                        kind={'green'}
                        text={locale.finishBtn}
                        fn={handleFinishOrder}
                        loading={finishOrderLoading}
                        disabled={isLoading}
                      />
                    </div>
                  )}
                </Flex>
              </div>
            ) : null}
          </Flex>
        </div>
      </Flex>
    </StyledOrderContainer>
  );
};

export const ViewOrderUser = ({
  orderData: order = {},
  isCreate,
  onChange,
}) => {
  const { _id: userId } = useSelector(getUserData);
  const canEdit = useAccess({ checkActive: true });
  const dispatch = useDispatch();
  const [locale, lang] = useLocale(L);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    messages: [
      updateOrderImagesMessage,
      updateOrderMessage,
      createOrderMessage,
    ],
    loading: [
      updateOrderImagesLoading,
      updateOrderLoading,
      createOrderLoading,
      deleteOrderLoading,
    ],
  } = useMessages([
    `updateOrderImages_${order._id}`,
    `updateOrder`,
    'createOrder',
    `deleteOrder_${order._id}`,
  ]);

  const { price: totalPrice, time: estimatedDurationOfWork } = useMemo(() => {
    return order.services?.length
      ? calculateTimeAndPriceServices(order.services)
      : { price: 0, time: 0 };
  }, [order]);

  const openPatchModal =
    (Comp, size = 'small') =>
    () => {
      dispatch(
        openModal({
          data: {
            order,
            isCreate,
            onChange,
          },
          size,
          Comp,
        })
      );
    };

  const handleCreateOrder = () => {
    const homePath =
      location.state?.from?.pathname + location.state?.from?.search ||
      '/orders';
    const { clientImages, ...restOrder } = order;
    fetchCreateOrderData(dispatch, { ...restOrder, client: userId }).then(
      (newOrder) => {
        if (clientImages?.length) {
          const formData = new FormData();
          clientImages.forEach((file) => {
            formData.append('images', file);
          });
          fetchUpdateOrderImages(
            dispatch,
            formData,
            () => {
              navigate(homePath);
            },
            newOrder._id
          );
        } else {
          navigate(homePath);
        }
      }
    );
  };

  const decideToCLose = () => {
    navigate('/orders');
  };

  const handleDeleteOrder = () =>
    fetchDeleteOrder(dispatch, order._id, decideToCLose);

  const isLoading =
    updateOrderLoading ||
    createOrderLoading ||
    updateOrderImagesLoading ||
    deleteOrderLoading;

  return (
    <StyledOrderContainer isLoading={isLoading}>
      <Flex
        flexDirection={'column'}
        spacer={{ left: '16px', bottom: '16px' }}
        full
      >
        <div>
          <StyledH2>
            {isCreate
              ? locale.titleOrder.new
              : `${locale.titleOrder.number} ${order.number || 0}`}
          </StyledH2>
        </div>

        <div>
          <StyledOrderBlock>
            <StyledOrderBlockTitle>
              {locale.generalInformation.title}
            </StyledOrderBlockTitle>
            {order.company ? (
              <>
                <OrderParams
                  title={locale.generalInformation.company}
                  value={order.company.companyName}
                  user={order.company}
                />
                <OrderParams
                  title={locale.generalInformation.phone}
                  value={order.company.phone}
                  link={`tel:${order.company.phone}`}
                />
                {order.company.email ? (
                  <OrderParams
                    title={locale.generalInformation.email}
                    value={order.company.email}
                    link={`mailto:${order.company.email}`}
                  />
                ) : null}
                <StyledOrderParams>
                  <CompanyAddress
                    companyAddress={order.company.companyAddress}
                    coordinates={order.company.coordinates}
                    noWrap
                  />
                </StyledOrderParams>
              </>
            ) : null}
            <StyledOrderParams>
              <span>{locale.generalInformation.dateAndTime}: &nbsp;</span>
              <span>{toDateAndTime(order.start, lang)}</span>
            </StyledOrderParams>
            {order.employees?.length
              ? order.employees.map((employee) => {
                  const { _id, name, phone } = employee;
                  return (
                    <OrderParams
                      key={_id}
                      title={locale.generalInformation.executor}
                      value={name || phone}
                      user={employee}
                    />
                  );
                })
              : null}
            {isCreate && canEdit ? (
              <StyledOrderBlockEdit
                onClick={openPatchModal('changeOrderStartModal', 'car')}
              >
                <IconEdit />
              </StyledOrderBlockEdit>
            ) : null}
          </StyledOrderBlock>
        </div>
        <div>
          {order.car ? (
            <CarDetails
              {...order.car}
              isCreate={isCreate}
              canEdit={canEdit && Date.parse(order.start) > Date.now()}
              openPatchModal={openPatchModal('changeOrderCarModal')}
            />
          ) : null}
        </div>
        <div>
          <StyledOrderBlock>
            <StyledOrderBlockTitle>
              {locale.services.title}
            </StyledOrderBlockTitle>
            {order.services?.length
              ? order.services.map((service) => {
                  return (
                    <OrderParams
                      key={service.service._id}
                      title={
                        isCreate
                          ? service.service.name
                          : service.service.name[lang]
                      }
                      value={toPrice(service.price, lang)}
                      type="space-between"
                    />
                  );
                })
              : null}
            {canEdit && Date.parse(order.start) > Date.now() && false ? (
              <StyledOrderBlockEdit
                onClick={openPatchModal('changeServicesOrderModal', 'big')}
              >
                <IconEdit />
              </StyledOrderBlockEdit>
            ) : null}
          </StyledOrderBlock>
        </div>
        <div>
          <StyledOrderBlock>
            <StyledOrderBlockTitle>{`${locale.time.title}: ${getTimeString(
              (Math.floor(Date.parse(order.end) / 60000) -
                Math.floor(Date.parse(order.start) / 60000)) *
                60000 +
                order.timeShift,
              lang
            )}`}</StyledOrderBlockTitle>
            <OrderParams
              title={locale.time.estimatedDurationOfWork}
              value={getTimeString(estimatedDurationOfWork)}
              type="space-between"
            />
          </StyledOrderBlock>
        </div>
        <div>
          <StyledOrderBlock>
            {updateOrderImagesLoading ? <Preloader /> : null}
            <StyledOrderBlockTitle>
              {locale.photoClient.title}
            </StyledOrderBlockTitle>
            <MultipleImagePicker images={order.clientImages} viewOnly />
            {canEdit ? (
              <StyledOrderBlockEdit
                onClick={openPatchModal('changeOrderImagesModal')}
              >
                <IconEdit />
              </StyledOrderBlockEdit>
            ) : null}
            {updateOrderImagesMessage.error ? (
              <StyledCustomError>
                {updateOrderImagesMessage.error}
              </StyledCustomError>
            ) : null}
          </StyledOrderBlock>
        </div>
        <div>
          <StyledOrderBlock>
            <StyledOrderBlockTitle>
              {locale.commentClient.title}
            </StyledOrderBlockTitle>
            <StyledViewComment>{order.clientComment || '-'}</StyledViewComment>
            {canEdit ? (
              <StyledOrderBlockEdit
                onClick={openPatchModal('changeOrderCommentModal')}
              >
                <IconEdit />
              </StyledOrderBlockEdit>
            ) : null}
          </StyledOrderBlock>
        </div>
        {!isCreate ? (
          <>
            <div>
              <StyledOrderBlock>
                <StyledOrderBlockTitle>
                  {locale.photoCompany.title}
                </StyledOrderBlockTitle>
                <ImageInputMultiple
                  remoteFiles={order.companyImages || []}
                  actions={false}
                />
              </StyledOrderBlock>
            </div>
            <div>
              <StyledOrderBlock>
                <StyledOrderBlockTitle>
                  {locale.commentCompany.title}
                </StyledOrderBlockTitle>
                <StyledViewComment>
                  {order.companyComment || '-'}
                </StyledViewComment>
              </StyledOrderBlock>
            </div>
          </>
        ) : null}
        {updateOrderMessage.error || createOrderMessage.error ? (
          <div>
            <StyledCustomError>
              {updateOrderMessage.error || createOrderMessage.error}
            </StyledCustomError>
          </div>
        ) : null}
        <div>
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <div>
              <StyledOrderTotal>
                {locale.total}:{' '}
                {isCreate
                  ? toPrice(totalPrice, lang)
                  : toPrice(order.price, lang)}
              </StyledOrderTotal>
            </div>
            {canEdit && !order.ended ? (
              <div>
                <Flex spacer={{ left: '8px', bottom: '8px' }}>
                  {isCreate ? (
                    <div>
                      <Button
                        kind={'green'}
                        text={locale.createBtn}
                        loading={createOrderLoading}
                        disabled={isLoading}
                        fn={handleCreateOrder}
                      />
                    </div>
                  ) : Date.parse(order.start) > Date.now() ? (
                    <>
                      <div>
                        <Button
                          text={locale.cancelBtn}
                          fn={handleDeleteOrder}
                          loading={deleteOrderLoading}
                          disabled={isLoading}
                        />
                      </div>
                    </>
                  ) : null}
                </Flex>
              </div>
            ) : null}
          </Flex>
        </div>
      </Flex>
    </StyledOrderContainer>
  );
};
