import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { BASE_URL } from '../Configs/constants';
import { getUserData } from '../Redux/Selectors/AuthSelectors';
import {
  DEFAULT_EVENTS,
  DISCONNECT_REASONS,
  INCOMMING_MESSAGES,
} from './constants';

const socket = io(BASE_URL, {
  autoConnect: false,
  multiplex: false,
  secure: BASE_URL !== 'http://localhost:3008',
});

export const connectToNotifications = (tockenAccess) => {
  if (!tockenAccess) {
    return console.warn('SOCKET: NO CREDS');
  }
  if (socket.connected) {
    return console.warn('SOCKET: MULTIPPLE CONNECTIONS NOT ALLOWED');
  }
  socket.auth = { tockenAccess };

  try {
    socket.connect();
  } catch (err) {
    console.log('SOCKET NOT CONNECTED: ', err);
  }
};

const handlers = [];

const subscribeToNotifications = ({ event, id, cb }) => {
  const existingHandler = handlers.find((handler) => handler.id === id);
  if (existingHandler) existingHandler.cb = cb;
  else handlers.push({ event, id, cb });
};

const unsubscribeFromNotifications = (subscriptionId) => {
  handlers.splice(
    handlers.findIndex((handler) => handler.id === subscriptionId),
    1
  );
};

socket.onAny((name, data) => {
  //console.log('SOCKET EVENT: ', name);
});

socket.on(INCOMMING_MESSAGES.reauth, () => {
  handlers.forEach((handler) => {
    if (handler.event === INCOMMING_MESSAGES.reauth) handler.cb();
  });
});

socket.on(INCOMMING_MESSAGES.all, (data) => {
  console.log('SOCKET INCOMMING_MESSAGES.all', data);
  handlers.forEach((handler) => {
    if (handler.event === INCOMMING_MESSAGES.all) handler.cb(data);
  });
});

socket.on(INCOMMING_MESSAGES.single, (data) => {
  //console.log('SOCKET EVENT: INCOMMING_MESSAGES.single');
  handlers.forEach((handler) => {
    if (handler.event === INCOMMING_MESSAGES.single) handler.cb(data);
  });
});

socket.on(DEFAULT_EVENTS.disconnect, (reason) => {
  if (reason === DISCONNECT_REASONS.server) {
    //console.log('SOCKET EVENT: DISCONNECTED BY SERVER');
  }
});

socket.on(DEFAULT_EVENTS.connect, () => {
  //console.log('SOCKET EVENT: CONNECTED');
  handlers.forEach((handler) => {
    if (handler.event === DEFAULT_EVENTS.connect) handler.cb(socket);
  });
});

socket.on(DEFAULT_EVENTS.error, (reason) => {
  console.log('SOCKET EVENT: NOT CONNECTED: ', reason?.message || reason);
  setTimeout(() => {
    socket.connect();
  }, 1000);
});

//only for use in App to avoid multipple connections
export const useConnectNotifications = () => {
  const tokenRef = useRef();
  const { tokenAccess } = useSelector(getUserData);

  useEffect(() => {
    if (tokenRef.current !== tokenAccess) {
      if (socket.connected) {
        socket.close();
      }
      connectToNotifications(tokenAccess);
    }
    tokenRef.current = tokenAccess;
  }, [tokenAccess]);
};

// can be used anywhere, don`t forget to unsubscribe(subscriptionId) if you subscribe in React Component
export const useNotifications = () => {
  const emit = useCallback((messageType, data) => {
    if (socket.connected) {
      try {
        socket.emit(messageType, data);
      } catch (err) {
        console.log('SOCKET EMIT ERROR: ', err);
      }
    }
  }, []);

  return {
    subscribe: subscribeToNotifications,
    emit,
    connected: socket.connected,
    unsubscribe: unsubscribeFromNotifications,
  };
};
