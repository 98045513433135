import styled from 'styled-components';

export const StyledWrapper = styled.ul`
  display: flex;
  max-width: 100%;
  align-items: flex-start;
  gap: 8px;
`;

export const StyledParts = styled.li`
width: 100%;`