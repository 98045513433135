import styled, { css } from 'styled-components';

import S from '../../variables';

export const StyledTabsIndividual = styled.ul`
  z-index: 3;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ isMargin }) => (isMargin ? '16px' : 0)};
  width: fit-content;
  padding: 5px 10px;
  border-radius: 14px;
  background: ${S.lightGray};
`;

export const StyledTabIndividual = styled.li`
  height: 32px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const StyledTabIndividualLink = styled.a`
  display: inline-block;
  cursor: pointer;
  height: 100%;
  padding: 4px 12px;
  color: ${S.textSecondary};
  border-radius: 12px;
  line-height: 1.5em;
  transition: color ${S.trFast}, background ${S.trFast};
  white-space: nowrap;

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.6;
          pointer-events: none;
        `
      : ``}

  &:hover,
  &:focus {
    color: ${S.greenDark};
  }

  &.active {
    font-weight: 500;
    color: ${S.textPrimary};
    background: ${S.white};
    box-shadow: ${S.consoleTabShade};
  }
`;

export const StyledLabel = styled.span`
  display: inline-flex;
  position: relative;
  top: -6px;
  margin-left: 4px;
  & > span {
    color: ${S.white};
    font-weight: 500;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding: 0 4px;
    min-width: 18px;
    height: 18px;
    background-color: ${S.purpleDark};
  }
`;
