import axios from 'axios';

import { BASE_URL } from '../Configs/constants';

import store from '../Redux';
import { refreshRx } from '../Redux/Actions/AuthActions/thunk';
import { getUserData } from '../Redux/Selectors/AuthSelectors';
import { getLang } from '../Redux/Selectors/LanguagesSelectors';

let refreshing = false;

export const API = axios.create({
  baseURL: `${BASE_URL}/api`,
  headers: { 'content-type': 'application/json', accept: 'application/json' },
});

API.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (
      err.response &&
      err.response.status === 401 &&
      originalConfig.url !== '/user/refresh'
    ) {
      if (!refreshing) {
        refreshing = true;
        const { tokenRefresh } = getUserData(store.getState());
        try {
          const auth = await refreshRx(store.dispatch, tokenRefresh);
          if (auth) {
            originalConfig.headers = {
              ...originalConfig.headers,
              Authorization: 'Bearer ' + auth.tokenAccess,
            };
            return await API(originalConfig);
          }
          return Promise.reject(err);
        } catch (_error) {
          return Promise.reject(err);
        } finally {
          refreshing = false;
        }
      } else
        return new Promise((resolve) => {
          const int = setInterval(() => {
            if (!refreshing) {
              clearInterval(int);
              resolve(API({ ...originalConfig, intercepted: false }));
            }
          }, 500);
        });
    }
    return Promise.reject(err);
  }
);

API.interceptors.request.use((config) => {
  const { tokenAccess, tokenRefresh } = getUserData(store.getState());
  if (!config.intercepted && config.headers.Authorization) {
    config.intercepted = true;
    config.headers = {
      ...config.headers,
      Authorization:
        'Bearer ' +
        (config.url === '/user/refresh' ? tokenRefresh : tokenAccess),
    };
  }
  return config;
});

export const query = async (action, payload, type = {}, controls = {}) => {
  const lang = getLang(store.getState());
  const config = {
    headers: {
      ...type,
      'X-language': lang,
    },
    ...controls,
  };
  try {
    return await action(config, payload);
  } catch (err) {
    if (err.response) return err.response;
    else return { data: { message: 'Unhandled error.Try again later.' } };
  }
};

export const authQuery = async (
  action,
  payload = '',
  type = {},
  controls = {}
) => {
  const lang = getLang(store.getState());
  const config = {
    headers: {
      'X-language': lang,
      Authorization: `Bearer`,
      ...type,
    },
    ...controls,
  };
  try {
    return await action(config, payload);
  } catch (err) {
    if (err.response) return err.response;
    else
      return {
        data: { message: `Unhandled error: ${err.message}. Try again later.` },
      };
  }
};
