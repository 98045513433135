import { StyledFormGroup } from '../../UI/Form/styled';
import { useLocale } from '../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { useDependentState } from '../../../Utils/Hooks/useDependentState';
import { Textarea } from '../../UI/Form/Textarea';
import { isUser } from '../../../Configs/constants';
import { getUserData } from '../../../Redux/Selectors/AuthSelectors';
import { useSelector } from 'react-redux';

export const CommentForm = ({ onChange, orderData }) => {
  const { role } = useSelector(getUserData);
  const [locale] = useLocale(L);

  const commentProp = isUser(role) ? 'clientComment' : 'companyComment';

  const [comment, setComment] = useDependentState(
    orderData[commentProp],
    (value) => onChange(commentProp, value)
  );

  const setCommentValue = ({ target: { value } }) => {
    setComment(value);
  };

  return (
    <StyledFormGroup>
      <Textarea
        name={'clientComment'}
        value={comment}
        label={locale.placeholders.comment}
        placeholder={locale.placeholders.comment}
        fn={setCommentValue}
      />
    </StyledFormGroup>
  );
};
