import styled from 'styled-components';

export const StyledMapContainer = styled.div`
  border-radius: 7px;
  overflow: hidden;
  height: 470px;

  & > div {
    height: 100%;
  }
`;
