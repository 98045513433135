import { StyledH2 } from '../../Titles';
import { openModal } from '../../../../Redux/Actions/ModalActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import {
  addUserServiceItem,
  changeUserAdminServiceItem,
  changeUserServiceItem,
} from '../../../../Redux/Actions/AuthActions';
import { addServiceAuth, updateService } from '../../../../API/Services';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { rx } from '../../../../Redux/Actions';
import {
  getUserData,
  getUserPartnership,
} from '../../../../Redux/Selectors/AuthSelectors';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import {
  StyledCustomError,
  StyledFormButtonBlock,
  StyledFormGroup,
} from '../../Form/styled';
import { Input } from '../../Form/Input';
import { useMemo, useState } from 'react';
import { fieldDataCollection } from '../../../../Utils/Helpers/stypByStep';
import { Flex } from '../../Flex';
import { Button } from '../../Buttons';
import { Select } from '../../Form/Select';
import { CheckboxInput } from '../../Form/Checkbox';
import { Preloader } from '../../Preloader';
import {
  createServiceByPartner,
  createServiceOnSignup,
  editServicesByPartner,
} from './queries';
import { TimePriceChooser } from '../../TimePriceChooser';

export const AddService = ({ partnership, service, onClose }) => {
  const dispatch = useDispatch();
  const { services = [], _id, role, hourlyRate } = useSelector(getUserData);
  const partnershipStore = useSelector(getUserPartnership);
  const serviceType = service?.service?.type || partnership || partnershipStore;
  const [locale, lang] = useLocale(L);
  const {
    messages: [serviceMessage, updateUserDataMessage],
    loading: [serviceLoading, updateUserDataLoading],
  } = useMessages(['addService', 'updateUserServices']);

  const [{ price, time, name }, setData] = useState({
    price: service?.price || 0,
    time: service?.time || 0,
    name: service?.service?.name || '',
  });

  const handleSubmitAddService = () => {
    const payload = {
      serviceToAdd: {
        type: serviceType,
        name: {
          [lang]: name,
        },
      },
      time: time,
      price: price,
    };
    if (role) {
      if (service) {
        editServicesByPartner(
          dispatch,
          _id,
          service?._id
            ? services.map((serviceItem) => {
                if (serviceItem._id === service._id) {
                  return {
                    ...serviceItem,
                    time,
                    price,
                  };
                }
                return serviceItem;
              })
            : [...services, { ...service, time, price }]
        );
      } else {
        createServiceByPartner(dispatch, _id, payload, services, lang);
      }
    } else {
      if (service) {
        dispatch(
          changeUserServiceItem({
            ...service,
            time,
            price,
          })
        );
        onClose();
      } else {
        createServiceOnSignup(dispatch, payload, lang);
      }
    }
  };

  const handleDeleteService = () => {
    if (role) {
      editServicesByPartner(
        dispatch,
        _id,
        services.filter((s) => s._id !== service._id)
      );
    } else {
      dispatch(
        changeUserServiceItem({
          ...service,
          time: null,
          price: null,
        })
      );
      onClose();
    }
  };

  return (
    <>
      <StyledH2>
        {service?._id ? locale.title.editService : locale.title.addService}
      </StyledH2>
      <TimePriceChooser
        nameEditable={!service}
        hourlyRate={hourlyRate}
        name={name}
        time={time}
        price={price}
        onChange={setData}
      />
      {serviceMessage.error || updateUserDataMessage.error ? (
        <StyledCustomError>
          {serviceMessage.error || updateUserDataMessage.error}
        </StyledCustomError>
      ) : null}

      <StyledFormButtonBlock style={{ gap: '20px' }}>
        {service?._id || (!role && service?.time && service?.price) ? (
          <Button
            kind={'stroke'}
            text={locale.deleteServiceBtn}
            fn={handleDeleteService}
            disabled={
              serviceLoading ||
              updateUserDataLoading ||
              !name ||
              !time ||
              !price ||
              !hourlyRate
            }
          />
        ) : null}
        <Button
          kind={'green'}
          text={service?._id ? locale.saveServiceBtn : locale.addServiceBtn}
          fn={handleSubmitAddService}
          disabled={
            serviceLoading ||
            updateUserDataLoading ||
            !name ||
            !time ||
            !price ||
            !hourlyRate
          }
        />
      </StyledFormButtonBlock>
    </>
  );
};

export const ChangeAdminService = ({ service, onClose }) => {
  const [locale, lang] = useLocale(L);
  const dispatch = useDispatch();

  const {
    messages: [updateServiceMessages],
    loading: [updateServiceLoading],
  } = useMessages(['updateService']);

  const [inputs, setInputs] = useState({
    nameEn: service.name.en,
    nameLt: service.name.lt,
    approved: service.approved,
  });

  const [errors, setErrors] = useState({
    nameEn: service.name.en ? '' : null,
    nameLt: service.name.lt ? '' : null,
  });

  const handleChangeService = (e) => {
    e.preventDefault();

    dispatch(
      rx(
        authRx,
        'updateService',
        (dispatch, { data }) => {
          if (data.service) {
            dispatch(changeUserAdminServiceItem(data.service));
            onClose();
          }
        },
        updateService,
        {
          id: service._id,
          body: {
            type: service.type,
            approved: inputs.approved,
            name: {
              en: inputs.nameEn,
              lt: inputs.nameLt,
            },
          },
        }
      )
    );
  };

  const handleReOpenModal = () => {
    dispatch(
      openModal({
        data: {
          service,
        },
        Comp: 'changeAdminService',
      })
    );
  };

  const handleDeleteService = () => {
    onClose();

    setTimeout(() => {
      dispatch(
        openModal({
          data: {
            id: service._id,
            fnClose: handleReOpenModal,
          },
          Comp: 'confirmationDeleteService',
        })
      );
    }, 300);
  };

  const handleChangeInput = (e) => {
    const { error, name, value } = fieldDataCollection(e, lang);

    setInputs((inputs) => {
      return {
        ...inputs,
        [name]: value,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        [name]: error,
      };
    });
  };

  const handleChangeChecked = (e) => {
    const { checked, name } = e.currentTarget;

    setInputs((inputs) => {
      return {
        ...inputs,
        [name]: checked,
      };
    });
  };

  return (
    <>
      <StyledH2>{locale.title.changeService}</StyledH2>
      <form noValidate={true} onSubmit={handleChangeService}>
        {updateServiceLoading ? <Preloader /> : null}
        <StyledFormGroup>
          <Input
            name={'nameEn'}
            placeholder={locale.placeholders.nameEn}
            label={locale.placeholders.nameEn}
            required={true}
            value={inputs.nameEn}
            errors={errors.nameEn}
            fn={handleChangeInput}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <Input
            name={'nameLt'}
            placeholder={locale.placeholders.nameLt}
            label={locale.placeholders.nameLt}
            required={true}
            value={inputs.nameLt}
            errors={errors.nameLt}
            fn={handleChangeInput}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <CheckboxInput
            name={'approved'}
            type={'checkbox'}
            placeholder={locale.placeholders.approved}
            value={inputs.approved}
            checked={inputs.approved}
            fn={handleChangeChecked}
          />
        </StyledFormGroup>
        <StyledCustomError>{updateServiceMessages.error}</StyledCustomError>
        <StyledFormButtonBlock style={{ display: 'block' }}>
          <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
            <div>
              <Button
                kind={'stroke'}
                text={locale.deleteServiceBtn}
                fn={handleDeleteService}
              />
            </div>
            <div>
              <Button
                kind={'green'}
                text={locale.saveServiceBtn}
                type={'submit'}
                disabled={Object.values(errors).some(
                  (err) => err === null || err.length
                )}
                style={{
                  minWidth: '100%',
                }}
              />
            </div>
          </Flex>
        </StyledFormButtonBlock>
      </form>
    </>
  );
};

export const AddAdminService = ({ partnerships, onClose }) => {
  const [locale, lang] = useLocale(L);
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    nameEn: '',
    nameLt: '',
    type: 0,
  });

  const [errors, setErrors] = useState({
    nameEn: null,
    nameLt: null,
  });

  const {
    messages: [addNewServiceMessages],
    loading: [addNewServiceLoading],
  } = useMessages(['addNewService']);

  const [optionSelect, optionTypes] = useMemo(() => {
    return [
      partnerships.reduce((acc, p) => [...acc, `${p.name[lang]}`], []),
      partnerships.reduce((acc, p) => [...acc, `${p.type}`], []),
    ];
  }, [partnerships, lang]);

  const handleChangeInput = (e) => {
    const { error, name, value } = fieldDataCollection(e, lang);

    setInputs((inputs) => {
      return {
        ...inputs,
        [name]: value,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        [name]: error,
      };
    });
  };

  const handleAddNewService = (e) => {
    e.preventDefault();

    dispatch(
      rx(
        authRx,
        'addNewService',
        (_, { data: { service } }) => {
          if (service) {
            dispatch(addUserServiceItem(service));

            onClose();
          }
        },
        addServiceAuth,
        {
          type: optionTypes[inputs.type],
          name: {
            en: inputs.nameEn,
            lt: inputs.nameLt,
          },
        }
      )
    );
  };

  return (
    <>
      <StyledH2>{locale.title.addAdminService}</StyledH2>
      <form noValidate={true} onSubmit={handleAddNewService}>
        <StyledFormGroup>
          <Select
            name={'type'}
            label={'Service type'}
            value={inputs.type}
            options={optionSelect}
            fn={handleChangeInput}
            kind={'form'}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <Input
            name={'nameEn'}
            value={inputs.nameEn}
            errors={errors.nameEn}
            label={'English name'}
            placeholder={'English name'}
            fn={handleChangeInput}
            required={true}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <Input
            name={'nameLt'}
            value={inputs.nameLt}
            errors={errors.nameLt}
            label={'Lithuanian name'}
            placeholder={'Lithuanian name'}
            fn={handleChangeInput}
            required={true}
          />
        </StyledFormGroup>
        <StyledCustomError>{addNewServiceMessages.error}</StyledCustomError>
        <StyledFormButtonBlock>
          <Button
            kind={'green'}
            text={'Save'}
            type={'submit'}
            loading={addNewServiceLoading}
            disabled={Object.values(errors).some(
              (err) => err === null || err.length
            )}
          />
        </StyledFormButtonBlock>
      </form>
    </>
  );
};
