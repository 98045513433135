import { memo, useEffect, useRef, useState } from 'react';
import {
  StyledErrorsWrapper,
  StyledImageInputContainer,
  StyledImageLabel,
  StyledImageLabelDeleteImage,
  StyledImageLabelNote,
  StyledImageLabelWrap,
  StyledImageLabelWrapDefault,
  StyledImagesGroup,
  StyledImagesGroupItem,
  StyledImagesGroupRatio,
} from '../styled';

import { ReactComponent as AddPhoto } from '../../../../Static/icons/console/add.svg';
import { ReactComponent as DeletePhoto } from '../../../../Static/icons/console/actions/delete.svg';
import { Flex } from '../../Flex';
import { Button } from '../../Buttons';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { openModal } from '../../../../Redux/Actions/ModalActions';
import { useDispatch } from 'react-redux';
import { ImageView } from '../../ImageView';

const imageMimeType = /image\/(png|jpg|jpeg)/gm;

export const ImageInputMultiple = memo(
  ({
    files,
    disabled = false,
    note,
    fn = () => null,
    actions = true,
    remoteFiles,
    handleDeleteRemoteFiles = () => null,
  }) => {
    const [imageFiles, setImageFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [err, setErr] = useState(null);
    const [locale] = useLocale(L);
    const dispatch = useDispatch();

    useEffect(() => {
      files && files[0]?.type ? setImageFiles(files) : setImageFiles([]);
    }, [files]);

    const changeHandler = (e) => {
      const { files } = e.target;
      const validImageFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type.match(imageMimeType)) {
          validImageFiles.push(file);
        }
      }

      if (validImageFiles.length) {
        setImageFiles(validImageFiles);
        setErr(null);
        fn(validImageFiles);
        return;
      }

      setErr(locale.error);
    };

    const handleRemoveImg = (e, id) => {
      const newList = imageFiles.length
        ? imageFiles.filter((img, index) => index !== id)
        : [];

      setImageFiles(newList);

      fn(newList);
    };

    useEffect(() => {
      const images = [];
      const fileReaders = [];
      let isCancel = false;

      if (imageFiles.length && imageFiles[0]?.type) {
        imageFiles.forEach((file, index) => {
          const fileReader = new FileReader();

          fileReaders.push(fileReader);

          fileReader.onload = (e) => {
            const { result } = e.target;

            if (result) {
              images.push({ id: index, result });
            }

            if (images.length === imageFiles.length && !isCancel) {
              setImages(images);
            }
          };

          fileReader.readAsDataURL(file);
        });
      } else {
        setImages([]);
      }

      return () => {
        isCancel = true;

        fileReaders.forEach((fileReader) => {
          if (fileReader.readyState === 1) {
            fileReader.abort();
          }
        });
      };
    }, [imageFiles]);

    const handleOpenLightbox = (index) => {
      dispatch(
        openModal({
          Comp: 'lightbox',
          data: { images: remoteFiles, startIndex: index },
          size: 'lightbox',
          withClose: false,
        })
      );
    };

    return (
      <StyledImageInputContainer isDisabled={disabled}>
        {actions ? (
          <StyledImageLabel>
            <input
              type="file"
              hidden
              onChange={changeHandler}
              accept=".png, .jpg, .jpeg"
              multiple
            />
            <StyledImageLabelWrap>
              <StyledImageLabelWrapDefault>
                <AddPhoto />
                <span>{locale.label}</span>
              </StyledImageLabelWrapDefault>
            </StyledImageLabelWrap>
            {err ? (
              <StyledErrorsWrapper errors={err}>
                <span>{err}</span>
              </StyledErrorsWrapper>
            ) : null}
          </StyledImageLabel>
        ) : null}
        {images?.length || remoteFiles?.length ? (
          <StyledImagesGroup>
            <Flex spacer={{ left: '16px', bottom: '16px' }} flexWrap={'wrap'}>
              {images?.length
                ? images.map(({ result, id }, idx) => {
                    return (
                      <StyledImagesGroupItem key={idx} actions={actions}>
                        <StyledImagesGroupRatio>
                          <img src={result} alt="" />
                          {actions ? (
                            <StyledImageLabelDeleteImage>
                              <Button
                                kind={'circle'}
                                Icon={DeletePhoto}
                                fn={(e) => handleRemoveImg(e, id)}
                              />
                            </StyledImageLabelDeleteImage>
                          ) : null}
                        </StyledImagesGroupRatio>
                      </StyledImagesGroupItem>
                    );
                  })
                : null}
              {remoteFiles?.length
                ? remoteFiles.map((image, idx) => {
                    const openLightbox = () => {
                      !actions && handleOpenLightbox(idx);
                    };
                    return (
                      <StyledImagesGroupItem
                        key={image}
                        actions={actions}
                        onClick={openLightbox}
                      >
                        <StyledImagesGroupRatio>
                          <ImageView key={image} src={image} showThmbs />

                          {actions ? (
                            <StyledImageLabelDeleteImage>
                              <Button
                                kind={'circle'}
                                Icon={DeletePhoto}
                                fn={(e) => handleDeleteRemoteFiles(image)}
                              />
                            </StyledImageLabelDeleteImage>
                          ) : null}
                        </StyledImagesGroupRatio>
                      </StyledImagesGroupItem>
                    );
                  })
                : null}
            </Flex>
            {note ? <StyledImageLabelNote>{note}</StyledImageLabelNote> : null}
          </StyledImagesGroup>
        ) : null}
      </StyledImageInputContainer>
    );
  }
);

const EMPTY_ARRAY = [];

const prepareImages = (images) =>
  images.reduce(
    (acc, img) => {
      if (typeof img === 'string') {
        acc.imageUrls.push(img);
      }
      if (img instanceof File) {
        acc.imageFiles.push(img);
      }
      return acc;
    },
    { imageUrls: [], imageFiles: [] }
  );

export const MultipleImagePicker = ({
  images = EMPTY_ARRAY,
  disabled = false,
  viewOnly = false,
  onChange = () => null,
}) => {
  const dispatch = useDispatch();

  const [{ imageUrls, imageFiles }, setImageItems] = useState(() =>
    prepareImages(images)
  );

  useEffect(() => {
    viewOnly && setImageItems(prepareImages(images));
  }, [viewOnly, images]);

  const [imagePreviews, setImagePreviews] = useState(new Map());

  const [err, setErr] = useState(null);
  const [locale] = useLocale(L);

  const changeHandler = (e) => {
    const { files } = e.target;

    console.log('files', files.length);
    const validImageFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.match(imageMimeType)) {
        validImageFiles.push(file);
      }
    }

    if (validImageFiles.length) {
      setErr(null);
      setImageItems((prev) => ({
        ...prev,
        imageFiles: [...validImageFiles, ...prev.imageFiles],
      }));
    } else if (files.length) {
      setErr(locale.error);
    }
  };

  const handleRemoveImg = (prop, img) => {
    setImageItems((prev) => ({
      ...prev,
      [prop]: prev[prop].filter((prevImg) => prevImg !== img),
    }));
  };

  useEffect(() => {
    const images = new Map();
    const fileReaders = [];
    let isCancel = false;

    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        const fileReader = new FileReader();

        fileReaders.push(fileReader);

        fileReader.onload = (e) => {
          const { result } = e.target;

          if (result) {
            images.set(file, result);
          }

          if (images.size === imageFiles.length && !isCancel) {
            setImagePreviews(images);
          }
        };

        fileReader.readAsDataURL(file);
      });
    } else {
      setImagePreviews(new Map());
    }

    return () => {
      isCancel = true;

      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [imageFiles]);

  useEffect(() => {
    !viewOnly && onChange([...imageFiles, ...imageUrls]);
  }, [imageUrls, imageFiles, onChange, viewOnly]);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (fileInputRef.current) {
      const container = new DataTransfer();
      for (const file of imageFiles) {
        container.items.add(file);
      }
      fileInputRef.current.files = container.files;
    }
  }, [imageFiles]);

  const handleOpenLightbox = (index) => {
    dispatch(
      openModal({
        Comp: 'lightbox',
        data: { images: imageUrls, startIndex: index },
        size: 'lightbox',
        withClose: false,
      })
    );
  };

  return (
    <StyledImageInputContainer isDisabled={disabled}>
      {viewOnly ? null : (
        <StyledImageLabel>
          <input
            ref={fileInputRef}
            type="file"
            hidden
            onChange={changeHandler}
            accept=".png, .jpg, .jpeg"
            multiple
          />
          <StyledImageLabelWrap>
            <StyledImageLabelWrapDefault>
              <AddPhoto />
              <span>{locale.label}</span>
            </StyledImageLabelWrapDefault>
          </StyledImageLabelWrap>
          {err ? (
            <StyledErrorsWrapper errors={err}>
              <span>{err}</span>
            </StyledErrorsWrapper>
          ) : null}
        </StyledImageLabel>
      )}
      <StyledImagesGroup>
        <Flex spacer={{ left: '16px', bottom: '16px' }} flexWrap={'wrap'}>
          {imageFiles.map((file, idx) => {
            return imagePreviews.has(file) ? (
              <StyledImagesGroupItem key={idx} actions={!viewOnly}>
                <StyledImagesGroupRatio>
                  <img src={imagePreviews.get(file)} alt="" />
                  {viewOnly ? null : (
                    <StyledImageLabelDeleteImage>
                      <Button
                        kind={'circle'}
                        Icon={DeletePhoto}
                        fn={() => handleRemoveImg('imageFiles', file)}
                      />
                    </StyledImageLabelDeleteImage>
                  )}
                </StyledImagesGroupRatio>
              </StyledImagesGroupItem>
            ) : null;
          })}
          {imageUrls.map((image, idx) => {
            const openLightbox = () => {
              viewOnly && handleOpenLightbox(idx);
            };
            return (
              <StyledImagesGroupItem
                key={image}
                actions={!viewOnly}
                onClick={openLightbox}
              >
                <StyledImagesGroupRatio>
                  <ImageView key={image} src={image} showThmbs />
                  {viewOnly ? null : (
                    <StyledImageLabelDeleteImage>
                      <Button
                        kind={'circle'}
                        Icon={DeletePhoto}
                        fn={() => handleRemoveImg('imageUrls', image)}
                      />
                    </StyledImageLabelDeleteImage>
                  )}
                </StyledImagesGroupRatio>
              </StyledImagesGroupItem>
            );
          })}
        </Flex>
      </StyledImagesGroup>
    </StyledImageInputContainer>
  );
};
