import { createReducer } from '@reduxjs/toolkit';
import { setLanguage } from '../../Actions/LanguagesActions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { languages } from '../../../Configs/constants';

const persistConfig = {
  key: 'lang',
  storage,
};

const prevLang = window.navigator.languages[0];

const initialState = {
  lang: languages.find((lang) => prevLang.startsWith(lang)) || 'en',
};

const languagesReducer = createReducer(initialState, (builder) => {
  builder.addCase(setLanguage, (state, action) => ({ lang: action.payload }));
});

const languagesConfig = persistReducer(persistConfig, languagesReducer);

export default languagesConfig;
