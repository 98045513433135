import styled, { css } from 'styled-components';
import S from '../../UI/variables';
import { MaxContainerMd, MaxContainerXs } from '../../UI/Containers';

export const StyledLayoutAuth = styled.section`
  position: relative;
  background-color: ${S.lightGray};
  min-height: 100vh;
  transition: filter ${S.trFast};
  ${({ loaded }) =>
    loaded &&
    css`
      filter: blur(5px);
    `}
`;

export const StyledLayoutBody = styled.div`
  padding: 105px 20px;

  ${MaxContainerXs},
  ${MaxContainerMd} {
    margin: 0 auto;
  }
`;

export const StyledLayoutFooter = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  padding: 16px 20px;
  background-color: ${S.lightGray};
  box-shadow: 0px -1px 22px rgba(177, 177, 177, 0.25);
`;

export const StyledLayoutAuthHeader = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: 8px 20px;
  background-color: ${S.lightGray};

  @media (min-width: ${S.tablet}) {
    padding: 12px 20px;
  }

  @media (min-width: ${S.tabletLandscape}) {
    padding: 24px 20px;
  }

  & > div {
    max-width: 1280px;
    margin: 0 auto;
  }
`;

export const StyledLayoutAuthHeaderSteps = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 700;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media (min-width: ${S.tablet}) {
    font-size: 32px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

export const StyledLayoutAuthLoginBody = styled.div`
  margin-top: 56px;
`;
