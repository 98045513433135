import {
  StyledCarInfoGroup,
  StyledCarInfoHead,
  StyledCarInfoRow,
} from './styled';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { toDate } from '../../../../Utils/Helpers';
import { NumberPlate } from '../../../Vehicles/NumberPlate';

export const ShowCarInformation = ({ car }) => {
  const {
    vin,
    number,
    make: { name: makeName, image },
    model: { name: modelName },
    year,
    engineDisplacementCcm,
    enginePowerKw,
    transmission,
    fuelTypePrimary,
    odometerKm,
    periodic: { insurance, mot, oil, brakes, filter },
  } = car;
  const [locale] = useLocale(L);

  return (
    <>
      <StyledCarInfoHead>
        {image && makeName ? <img src={image} alt={makeName} /> : null}
        {makeName ? <span>{makeName}</span> : null}
      </StyledCarInfoHead>
      <StyledCarInfoGroup>
        {number ? (
          <StyledCarInfoRow>
            <div>
              <NumberPlate number={number} />
            </div>
          </StyledCarInfoRow>
        ) : null}
        {vin ? (
          <StyledCarInfoRow>
            <span>{locale.vin}:</span> {vin}
          </StyledCarInfoRow>
        ) : null}
        {modelName ? (
          <StyledCarInfoRow>
            <span>{locale.model}:</span> {modelName}
          </StyledCarInfoRow>
        ) : null}
        {year ? (
          <StyledCarInfoRow>
            <span>{locale.year}:</span> {year}
          </StyledCarInfoRow>
        ) : null}
        {engineDisplacementCcm ? (
          <StyledCarInfoRow>
            <span>{locale.engineDisplacement}:</span> {engineDisplacementCcm}
          </StyledCarInfoRow>
        ) : null}
        {enginePowerKw ? (
          <StyledCarInfoRow>
            <span>{locale.enginePower}:</span> {enginePowerKw}
          </StyledCarInfoRow>
        ) : null}
        {transmission ? (
          <StyledCarInfoRow>
            <span>{locale.gearboxType}:</span> {transmission}
          </StyledCarInfoRow>
        ) : null}
        {fuelTypePrimary ? (
          <StyledCarInfoRow>
            <span>{locale.fuelType}:</span> {fuelTypePrimary}
          </StyledCarInfoRow>
        ) : null}
        {odometerKm ? (
          <StyledCarInfoRow>
            <span>{locale.odometerKm}:</span> {odometerKm?.odometer}
          </StyledCarInfoRow>
        ) : null}
      </StyledCarInfoGroup>

      <StyledCarInfoGroup>
        {insurance ? (
          <StyledCarInfoRow>
            <span>{locale.insuranceExpires}:</span> {toDate(insurance)}
          </StyledCarInfoRow>
        ) : null}
        {mot ? (
          <StyledCarInfoRow>
            <span>{locale.motExpires}:</span> {toDate(mot)}
          </StyledCarInfoRow>
        ) : null}
        {oil ? (
          <StyledCarInfoRow>
            <span>{locale.changeOil}:</span> {oil}
          </StyledCarInfoRow>
        ) : null}
        {brakes ? (
          <StyledCarInfoRow>
            <span>{locale.changeBrakePads}:</span> {brakes}
          </StyledCarInfoRow>
        ) : null}
        {filter ? (
          <StyledCarInfoRow>
            <span>{locale.changeAirFilter}:</span> {filter}
          </StyledCarInfoRow>
        ) : null}
      </StyledCarInfoGroup>
    </>
  );
};
