import { CategoryList } from '../../../Auth/Onboarding/StepServices/CategoryList';
import { Button } from '../../Buttons';
import { getServiceList } from '../../../Auth/Onboarding/StepServices/helpers';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { useMemo } from 'react';
import { StyledH2 } from '../../Titles';
import { StyledFormButtonBlock } from '../../Form/styled';
import L from './locale.json';
import { getChosenServices } from '../../../../Redux/Selectors/UserOrderSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { toggleService } from '../../../../Redux/Actions/UserOrderActions';

export const ServicePickerModal = ({ services, onClose }) => {
  const [locale, lang] = useLocale(L);
  const chosenServices = useSelector(getChosenServices);

  const dispatch = useDispatch();
  const onChange = (serviceId) => dispatch(toggleService(serviceId));

  const serviceList = useMemo(
    () => getServiceList(services, lang),
    [services, lang]
  );

  const onSubmit = () => {
    onClose();
  };

  return (
    <>
      <StyledH2>{locale.title}</StyledH2>
      <CategoryList
        serviceList={serviceList}
        chosenServices={chosenServices}
        onChange={onChange}
      />
      <StyledFormButtonBlock>
        <Button text={locale.submit} fn={onSubmit} />
      </StyledFormButtonBlock>
    </>
  );
};
