import styled, { css } from 'styled-components';
import { StyledAddCard } from '../../Vehicles/styled';
import S from '../../UI/variables';

export const StyledCarRadio = styled(StyledAddCard)`
  position: relative;
  margin-bottom: 12px;
  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 50%;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    right: 15px;
    top: 50%;
    transform: translateY(-50%) scale(0);
    background: blue;
    transition: transform ${S.trFast};
  }
  ${({ selected }) =>
    selected
      ? css`
          &:after {
            transform: translateY(-50%) scale(1);
          }
        `
      : ''}
`;
