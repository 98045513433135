import { Flex } from '../../../UI/Flex';
import { Button } from '../../../UI/Buttons';
import { useState } from 'react';
import L from './locale.json';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { StyledH2 } from '../../../UI/Titles';
import { fieldDataCollection } from '../../../../Utils/Helpers/stypByStep';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { editPeriod, addPeriod, deletePeriod } from '../../queries';
import { useDispatch } from 'react-redux';
import { MaxContainerXs } from '../../../UI/Containers';
import { StyledFormGroup } from '../../../UI/Form/styled';
import { Input } from '../../../UI/Form/Input';

const initialState = {
  pricePerDay: 0.01,
  time: 1000,
};

const initialStateErrors = {
  pricePerDay: null,
  time: null,
};

export const PeriodForm = ({ fn, onClose, period }) => {
  const dispatch = useDispatch();
  const [locale, lang] = useLocale(L);

  const [inputs, setInputs] = useState(period || initialState);
  const [errors, setErrors] = useState(initialStateErrors);

  const handleChange = (event) => {
    const { error, name, value } = fieldDataCollection(event, lang);
    setInputs((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const handleResponse = (_, { data }) => {
    if (data?.conflict && data.conflict in initialStateErrors) {
      setErrors((prev) => ({ ...prev, [data.conflict]: data.message }));
    } else if (data?.bannersConfig) {
      fn(data);
      onClose();
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const handler = period ? editPeriod : addPeriod;
    handler(dispatch, inputs, handleResponse);
  };

  const handleSubmitDelete = (e) => {
    e.preventDefault();
    deletePeriod(dispatch, period?._id, handleResponse);
  };

  const {
    loading: [loadingEdit, loadingAdd, loadingDelete],
  } = useMessages([
    'updatePeriodInConfig',
    'addPeriodToConfig',
    'deletePeriodFromConfig',
  ]);

  return (
    <>
      <Flex
        alignItems={'stretch'}
        flexDirection={'column'}
        spacer={{ bottom: '24px' }}
      >
        <div>
          <StyledH2>{period ? locale.titleEdit : locale.titleAdd}</StyledH2>
        </div>
        <div>
          <form>
            <MaxContainerXs>
              <StyledFormGroup>
                <Input
                  type={'number'}
                  name={'pricePerDay'}
                  value={(inputs.pricePerDay || '').toString()}
                  fn={handleChange}
                  errors={errors.pricePerDay}
                  placeholder={'0'}
                  label={locale.price}
                  required={true}
                />
              </StyledFormGroup>
              <StyledFormGroup>
                <Input
                  type={'number'}
                  name={'time'}
                  value={(inputs.time || '').toString()}
                  min={1000}
                  fn={handleChange}
                  errors={errors.time}
                  placeholder={'0'}
                  label={locale.time}
                  required={true}
                />
              </StyledFormGroup>
            </MaxContainerXs>
          </form>
        </div>
        <div>
          <Flex
            justifyContent={'center'}
            spacer={{ left: '12px', bottom: '12px' }}
          >
            <div>
              <Button
                kind={'green'}
                disabled={
                  loadingAdd ||
                  loadingEdit ||
                  loadingDelete ||
                  Object.values(errors).some(Boolean)
                }
                loading={loadingEdit || loadingAdd}
                text={locale.save}
                fn={handleSubmitForm}
              />
            </div>
            {period ? (
              <div>
                <Button
                  disabled={loadingAdd || loadingEdit || loadingDelete}
                  loading={loadingDelete}
                  text={locale.delete}
                  fn={handleSubmitDelete}
                />
              </div>
            ) : null}
          </Flex>
        </div>
      </Flex>
    </>
  );
};
