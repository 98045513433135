import { NavLink } from 'react-router-dom';

import {
  StyledTabsIndividual,
  StyledTabIndividual,
  StyledTabIndividualLink,
  StyledLabel,
} from './styled';

import L from '../locale.json';
import { useLocale } from '../../../../Utils/Hooks/useLocale';

export const TabsIndividual = ({
  links,
  buttons,
  callbackChange,
  activeTab = '',
  isMargin = true,
}) => {
  const [locale] = useLocale(L);

  if (links) {
    return (
      <StyledTabsIndividual isMargin={isMargin}>
        {links.map(({ link, name, end }, i) =>
          typeof link !== 'undefined' ? (
            <StyledTabIndividual key={i}>
              <StyledTabIndividualLink as={NavLink} to={link} end={end}>
                {locale?.[name] || name}
              </StyledTabIndividualLink>
            </StyledTabIndividual>
          ) : null
        )}
      </StyledTabsIndividual>
    );
  }
  if (buttons) {
    return (
      <StyledTabsIndividual isMargin={isMargin}>
        {buttons.map(({ type, name, disabled = false, label }, i) =>
          typeof type !== 'undefined' ? (
            <StyledTabIndividual key={i}>
              <StyledTabIndividualLink
                as={'div'}
                className={activeTab === type ? 'active' : ''}
                disabled={disabled}
                onClick={(e) =>
                  callbackChange ? callbackChange(e, type, name) : null
                }
              >
                <span>{locale?.[name] || name}</span>
                {label ? (
                  <StyledLabel>
                    <span>{label}</span>
                  </StyledLabel>
                ) : null}
              </StyledTabIndividualLink>
            </StyledTabIndividual>
          ) : null
        )}
      </StyledTabsIndividual>
    );
  }
};
