import { rx } from '../../Redux/Actions';
import { authMediaRx, authRx } from '../../Redux/Actions/AuthActions/thunk';

import {
  getMarketingToolsConfig,
  getOwnMarketingTools,
  getAllMarketingTools,
  pauseBanner,
  orderGear,
  orderBanner,
  updatePeriodInConfig,
  addPeriodToConfig,
  deletePeriodFromConfig,
  updateBannersConfig,
  updateTemplateInConfig,
  deleteTemplateFromConfig,
  addTemplateToConfig,
  updateGearsConfig,
  addGearToConfig,
  deleteGearFromConfig,
  updateGearInConfig,
  approveBanner,
} from '../../API/MarketingTools';

export const getMarketingToolsConfiguration = (dispatch, fn) =>
  dispatch(rx(authRx, 'getMarketingToolsConfig', fn, getMarketingToolsConfig));

export const getMyMarketingTools = (dispatch, id, fn) =>
  dispatch(
    rx(authRx, 'getOwnMarketingTools', fn, getOwnMarketingTools, { id })
  );

export const getUsersMarketingTools = (dispatch, fn) =>
  dispatch(rx(authRx, 'getAllMarketingTools', fn, getAllMarketingTools));

export const pauseOrUnpauseBanner = (dispatch, id, paused, fn) =>
  dispatch(
    rx(authRx, 'pauseBanner' + id, fn, pauseBanner, {
      id,
      body: { paused },
    })
  );

export const orderSearchBoost = (dispatch, userId, body, fn) =>
  dispatch(
    rx(authRx, 'orderGear', fn, orderGear, {
      id: userId,
      body,
    })
  );

export const doOrderBanner = (dispatch, userId, body, fn) =>
  dispatch(
    rx(authRx, 'orderBanner', fn, orderBanner, {
      id: userId,
      body,
    })
  );

export const editBannersConfig = (dispatch, body, fn) =>
  dispatch(
    rx(authRx, 'updateConfig', fn, updateBannersConfig, {
      body,
    })
  );

export const editGearsConfig = (dispatch, body, fn) =>
  dispatch(
    rx(authRx, 'updateConfig', fn, updateGearsConfig, {
      body,
    })
  );

export const editPeriod = (dispatch, body, fn) =>
  dispatch(
    rx(authRx, 'updatePeriodInConfig', fn, updatePeriodInConfig, {
      body,
      id: body._id,
    })
  );

export const deletePeriod = (dispatch, id, fn) =>
  dispatch(
    rx(authRx, 'deletePeriodFromConfig', fn, deletePeriodFromConfig, {
      id,
    })
  );

export const addPeriod = (dispatch, body, fn) =>
  dispatch(
    rx(authRx, 'addPeriodToConfig', fn, addPeriodToConfig, {
      body,
    })
  );

export const editTemplate = (dispatch, body, fn, id) =>
  dispatch(
    rx(authMediaRx, 'updateTemplateInConfig', fn, updateTemplateInConfig, {
      body,
      id,
    })
  );

export const deleteTemplate = (dispatch, id, fn) =>
  dispatch(
    rx(authRx, 'deleteTemplateFromConfig', fn, deleteTemplateFromConfig, {
      id,
    })
  );

export const addTemplate = (dispatch, body, fn) =>
  dispatch(
    rx(authMediaRx, 'addTemplateToConfig', fn, addTemplateToConfig, {
      body,
    })
  );

export const editGear = (dispatch, body, fn) =>
  dispatch(
    rx(authRx, 'updateGearInConfig', fn, updateGearInConfig, {
      body,
      id: body._id,
    })
  );

export const deleteGear = (dispatch, id, fn) =>
  dispatch(
    rx(authRx, 'deleteGearFromConfig', fn, deleteGearFromConfig, {
      id,
    })
  );

export const addGear = (dispatch, body, fn) =>
  dispatch(
    rx(authRx, 'addGearToConfig', fn, addGearToConfig, {
      body,
    })
  );

export const getMarketingToolsRequests = (dispatch, fn) =>
  dispatch(rx(authRx, 'getAllMarketingTools', fn, getAllMarketingTools));

export const approveUserBanner = (dispatch, id, fn) =>
  dispatch(rx(authRx, 'approveBanner', fn, approveBanner, { id }));
