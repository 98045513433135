import { ReactComponent as DoneIcon } from '../../../Static/icons/console/statuses/done.svg';
import { ReactComponent as PendingIcon } from '../../../Static/icons/console/statuses/pending.svg';
import { ReactComponent as SettledIcon } from '../../../Static/icons/console/statuses/settled.svg';
import { ReactComponent as RejectedIcon } from '../../../Static/icons/console/statuses/rejected.svg';

import S from '../variables';

export const statusColors = {
  rejected: S.error,
  settled: S.green,
  pending: S.secondGray,
  done: S.purple,
};

export const statusIcons = {
  done: DoneIcon,
  pending: PendingIcon,
  settled: SettledIcon,
  rejected: RejectedIcon,
};
