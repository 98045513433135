import { roles } from '../../../Configs/constants';

export const getUserData = ({ auth }) => auth;

export const getNotifications = ({ notifications }) => notifications;

export const getUserDataFields = ({
  auth: {
    companyName,
    partnership,
    companyAddress,
    phone,
    name,
    email,
    registrationCode,
    instagram,
    facebook,
    vat,
  },
}) => ({
  companyName,
  partnership,
  companyAddress,
  phone,
  name,
  email,
  registrationCode,
  instagram,
  facebook,
  vat,
});

export const getCompanySchedule = ({ auth }) =>
  auth.role === roles.PARTNER ? auth.schedule : auth.company.schedule;

export const getUserPartnership = ({ auth }) => auth.partnership;

export const getUserServices = ({ auth }) => auth.services;

export const getPaymentReference = ({ auth }) =>
  auth.cards &&
  auth.cards.find(({ _id }) => _id === auth.activeCard)?.payment_reference;

export const getAuthState = ({ auth }) => auth.role;

export const getUserValues =
  (...values) =>
  ({ auth }) => {
    const result = {};
    for (let key of values) {
      result[key] = auth[key];
    }
    return result;
  };

export const checkEqual =
  (...values) =>
  (p, n) => {
    for (let key of values) {
      if (p[key] !== n[key]) return false;
    }
    return true;
  };
