import {
  StyledFormButtonBlock,
  StyledFormGroup,
} from '../../../UI/Form/styled';
import { Input } from '../../../UI/Form/Input';
import { useDispatch, useSelector } from 'react-redux';
import { getNewOrderData } from '../../../../Redux/Selectors/CreateOrderSelectors';
import { PhoneInput } from '../../../UI/Form/Phone';
import { Flex } from '../../../UI/Flex';
import { DatePickerButton } from '../../../UI/Buttons/DatePicker';
import { setNewOrderData } from '../../../../Redux/Actions/CreateOrderActions';
import { SelectUser } from '../../../UI/Form/SelectUser';
import { getLoading } from '../../../../Redux/Selectors/MessageSelectors';
import { useEffect, useMemo, useRef, useState } from 'react';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { getEmployees } from '../../../../API/User';
import { splitTimeShort } from '../../../Calendar/helpers';
import { validation } from '../../../../Utils/validation';
import { closeModal } from '../../../../Redux/Actions/ModalActions';
import { fetchUpdateOrderData } from '../../queries';
import { Button } from '../../../UI/Buttons';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from '../locale.json';

export const GeneraInformationForm = ({
  onChange,
  isCreate,
  isModal,
  order,
  globalErrors,
  setGlobalErrors,
}) => {
  const dispatch = useDispatch();
  const firstMount = useRef(true);
  const [employeesRemote, setEmployeesRemote] = useState([]);
  const loading = useSelector(getLoading('getEmployees'));
  const [locale, lang] = useLocale(L);

  const { client, phone, email, employees, start } =
    useSelector(getNewOrderData);

  const [inputs, setInputs] = useState({
    client: order?.client.name || order?.client || '',
    phone: order?.client.phone || order?.phone || '',
    email: order?.client.email || order?.email || '',
    employees: order?.employees || [],
    start: order?.start || new Date(),
  });

  const [errors, setErrors] = useState(
    globalErrors || {
      client: '',
      phone: '',
      email: '',
    }
  );

  useEffect(() => {
    if (isCreate && !isModal) {
      if (
        globalErrors.client !== errors.client ||
        globalErrors.phone !== errors.phone ||
        globalErrors.email !== errors.email
      ) {
        setErrors(globalErrors);
      }
    }
  }, [
    isCreate,
    isModal,
    setErrors,
    errors.client,
    errors.phone,
    errors.email,
    globalErrors,
  ]);

  const handleLoadEmployees = () => {
    if (firstMount.current) {
      dispatch(
        rx(
          authRx,
          'getEmployees',
          (_, { data }) =>
            data?.employees && setEmployeesRemote(data.employees),
          getEmployees
        )
      );

      firstMount.current = false;
    }
  };

  const onChangeDate = (date) => {
    if (isCreate && !isModal) {
      dispatch(
        setNewOrderData({
          start: date,
        })
      );
    } else {
      setInputs((prevState) => ({
        ...prevState,
        start: date,
      }));
    }
  };

  const onChangeUser = (user) => {
    const employees = user ? [user] : [];
    if (isCreate && !isModal) {
      dispatch(
        setNewOrderData({
          employees,
        })
      );
    } else {
      setInputs((prevState) => ({
        ...prevState,
        employees,
      }));
    }
  };

  const handleChangeInput = (event) => {
    const { value, error, name } = validation(event.target, lang);
    const { valueAsDate } = event.target;

    switch (name) {
      case 'time':
        const ISODateTime = valueAsDate
          ? new Date(
              new Date(isModal ? inputs.start : start).setHours(
                valueAsDate.getUTCHours(),
                !valueAsDate.getUTCHours()
                  ? valueAsDate.getUTCMinutes() || 1
                  : valueAsDate.getUTCMinutes(),
                0,
                0
              )
            ).toISOString()
          : new Date().toLocaleTimeString('en', {
              hour12: false,
              hour: '2-digit',
              minute: '2-digit',
              timeZone: 'UTC',
            });

        if (isCreate && !isModal) {
          dispatch(
            setNewOrderData({
              start: ISODateTime,
            })
          );
          setGlobalErrors((prevState) => ({ ...prevState, [name]: error }));
        } else {
          setInputs((prevState) => ({
            ...prevState,
            start: ISODateTime,
          }));
        }
        break;
      default:
        if (isCreate && !isModal) {
          dispatch(
            setNewOrderData({
              [name]: value,
            })
          );
          setGlobalErrors((prevState) => ({ ...prevState, [name]: error }));
        } else {
          setInputs((prevState) => ({ ...prevState, [name]: value }));
          setErrors((prevState) => ({ ...prevState, [name]: error }));
        }
    }
  };

  const handleSubmitForm = () => {
    if (isCreate) {
      dispatch(
        setNewOrderData({
          ...inputs,
        })
      );
      dispatch(closeModal({}));
    } else {
      const body = {};

      if (order.employees !== inputs.employees) {
        body.employees = inputs.employees;
      }

      if (order.start !== inputs.start) {
        body.start = inputs.start;
      }

      fetchUpdateOrderData(
        dispatch,
        body,
        (newOrder) => {
          onChange(newOrder);
          dispatch(closeModal({}));
        },
        order._id
      );
    }
  };

  const disabledSubmit = useMemo(() => {
    return Object.values(errors).some((err) => err && err.length) || isCreate
      ? order === inputs
      : order.employees === inputs.employees && order.start === inputs.start;
  }, [errors, inputs, order, isCreate]);

  return (
    <>
      <div>
        <StyledFormGroup>
          <Input
            name={'client'}
            placeholder={locale.placeholders.client}
            label={locale.placeholders.client}
            value={isModal ? inputs.client || '' : client || ''}
            errors={errors?.client || null}
            required={true}
            fn={handleChangeInput}
            disabled={!isCreate}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <PhoneInput
            name={'phone'}
            placeholder={locale.placeholders.phone}
            label={locale.placeholders.phone}
            value={(isModal ? inputs.phone || '' : phone || '').replace(
              /^\+370/,
              ''
            )}
            errors={errors?.phone || null}
            required={true}
            fn={handleChangeInput}
            disabled={!isCreate}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <Input
            type={'email'}
            name={'email'}
            placeholder={locale.placeholders.email}
            label={locale.placeholders.email}
            value={isModal ? inputs.email || '' : email || ''}
            errors={errors?.email || null}
            required={false}
            fn={handleChangeInput}
            disabled={!isCreate}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <SelectUser
            list={employeesRemote}
            kind={'form'}
            loadingList={loading}
            triggerOpen={handleLoadEmployees}
            name={'employees'}
            defaultSelected={isModal ? inputs.employees[0] : employees[0]}
            fn={onChangeUser}
            label={locale.placeholders.employees}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <Flex
            spacer={{ left: '8px', bottom: '8px' }}
            full={true}
            flexWrap={'no-wrap'}
          >
            <div>
              <DatePickerButton
                kind={'form'}
                placeholder={locale.placeholders.date}
                label={locale.placeholders.date}
                showDoubleView={false}
                fn={onChangeDate}
                minDate={new Date()}
                selectedDate={new Date(isModal ? inputs.start : start)}
              />
            </div>
            <div>
              <Input
                type={'time'}
                name={'time'}
                placeholder={locale.placeholders.time}
                label={locale.placeholders.time}
                value={splitTimeShort(isModal ? inputs.start : start)}
                errors={errors?.time || null}
                required={true}
                fn={handleChangeInput}
              />
            </div>
          </Flex>
        </StyledFormGroup>
        {isModal ? (
          <StyledFormButtonBlock>
            <Button
              kind={'green'}
              fn={handleSubmitForm}
              text={locale.saveBtn}
              disabled={disabledSubmit}
            />
          </StyledFormButtonBlock>
        ) : null}
      </div>
    </>
  );
};
