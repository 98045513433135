import styled from 'styled-components';
import S from '../../UI/variables';

export const StyledBannerPreviewOfferDescription = styled.p`
  color: ${S.textSecondary};
`;

export const StyledBannerPreview = styled.div`
  overflow: hidden;
  background-color: ${S.lightGray};
  width: 40px;
  height: 40px;
`;

export const StyledBannerImageWrapper = styled.div`
  margin-bottom: 24px;
`;
