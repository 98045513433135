import { configureStore, combineReducers } from '@reduxjs/toolkit';

import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import select from './Reducers/SelectReducer';
import modal from './Reducers/ModalReducer';
import users from './Reducers/UsersReducer';
import auth from './Reducers/AuthReducer';
import message from './Reducers/MessageReducer';
import lang from './Reducers/LanguagesReducer';
import promoCodes from './Reducers/PromoCodeReducer';
import newOrder from './Reducers/CreateOrderReducer';
import userOrder from './Reducers/UserOrderReducer';
import webpush from './Reducers/WebPushReducer';
import types from './Actions/actionTypes';

const reducer = combineReducers({
  select,
  modal,
  users,
  auth,
  message,
  lang,
  promoCodes,
  newOrder,
  userOrder,
  webpush,
});

const thunk =
  ({ dispatch, getState }) =>
  (next) =>
  (action) =>
    typeof action === 'function' ? action(dispatch, getState) : next(action);

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          types.SET_IMAGES,
        ],
        ignoredActionPaths: ['payload.data', 'payload.companyImages'],
        ignoredPaths: [
          'modal.data',
          'newOrder.companyImages',
          'userOrder.clientImages',
        ],
      },
    }).concat(thunk),
});

export default store;
