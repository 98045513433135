import { StyledCheckInputWrapper } from '../styled';

export const CheckboxInput = ({
  name,
  value,
  fn = () => null,
  errors = '',
  placeholder = '',
  ...props
}) => {
  return (
    <StyledCheckInputWrapper checkedType={true}>
      <input
        {...props}
        name={name}
        value={value}
        type="checkbox"
        hidden={true}
        onChange={fn}
      />
      <span>{placeholder}</span>
    </StyledCheckInputWrapper>
  );
};
