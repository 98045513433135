import styled from 'styled-components';
import S from '../../variables';

export const StyledInfoModalGroup = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: calc(100% + 40px);
      border-top: 1px solid ${S.strokeGray};
      transform: translateX(-50%);

      @media screen and (min-width: ${S.tablet}) {
        width: calc(100% + 124px);
      }
    }
  }
`;
