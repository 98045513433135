import { logout, refresh } from '../../../API/User';
import { userLogout, setUserData } from './index';
import { query, authQuery } from '../../../API';
import { getDeviceInfo } from '../../../Utils/Helpers';
import { resetNewOrderData } from '../CreateOrderActions';
import { resetPromocodes } from '../PromoCodeActions';

export const refreshRx = async (dispatch) => {
  const result = await authQuery(refresh, getDeviceInfo());
  if (result.data.message) {
    dispatch(userLogout());
    return {};
  }
  if (result.data) {
    const { tokenAccess, tokenRefresh } = result.data;
    dispatch(setUserData({ tokenAccess, tokenRefresh }));
  }
  return result.data;
};

export const logoutRx = async (dispatch, fn) => {
  let result = await authQuery(logout);
  dispatch(userLogout());
  dispatch(resetNewOrderData());
  dispatch(resetPromocodes());
  fn(dispatch, result);
  return result;
};

export const authRx = async (dispatch, fn, action, payload = {}, ...args) => {
  const type = {};
  if (payload.content) type['content-type'] = payload.content;
  let result = await authQuery(action, payload, type, payload.controls);
  fn(dispatch, result, ...args);
  return result;
};

export const unauthRx = async (dispatch, fn, action, payload = {}, ...args) => {
  const type = {};
  if (payload.content) type['content-type'] = payload.content;
  let result = await query(action, payload, type, payload.controls);
  fn(dispatch, result, ...args);
  return result;
};

export const authMediaRx = async (
  dispatch,
  fn,
  action,
  payload = {},
  ...args
) => {
  let type = {};
  let controls;
  type['content-type'] = payload?.content || 'multipart/form-data';
  if (payload.controls) controls = payload.controls;
  let result;
  await refreshRx(dispatch);
  result = await authQuery(action, payload, type, controls);
  fn(dispatch, result, ...args);
  return result;
};

export const loginRx = (dispatch, result) => {
  if (result.data.user) {
    const { user, ...rest } = result.data.user;
    const res = { ...user, ...rest };
    dispatch(setUserData(res));
    return res;
  }
  return false;
};
