import { useDispatch, useSelector } from 'react-redux';
import {
  checkToUpdateSelect,
  getSelect,
} from '../../../../Redux/Selectors/SelectSelectors';
import {
  closeSelect,
  openSelect,
} from '../../../../Redux/Actions/SelectActions';
import { Avatar } from '../../Avatar';
import {
  StyledSelect,
  StyledSelectArrow,
  StyledSelectDropdown,
  StyledSelectLabel,
  StyledSelectUser,
} from '../Select/styled';
import { ReactComponent as SelectArrow } from '../../../../Static/icons/chevron-down.svg';
import { useEffect, useState } from 'react';
import { StyledInputLabel, StyledInputName } from '../styled';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { Preloader } from '../../Preloader';

export const SelectUser = ({
  fn,
  list,
  name,
  kind = 'default',
  label,
  defaultSelected = null,
  loadingList,
  triggerOpen,
  emptyOption = true,
}) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const open = useSelector(getSelect, checkToUpdateSelect([name]));
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  useEffect(() => {
    setSelectedUser(defaultSelected);
  }, [defaultSelected]);

  const handleOpen = (e) => {
    e.stopPropagation();
    open === name ? dispatch(closeSelect()) : dispatch(openSelect(name));

    triggerOpen && triggerOpen();
  };

  const handleSelected = (e, user) => {
    setSelectedUser(user);

    fn(user);
  };

  return (
    <StyledInputLabel onClick={handleOpen}>
      {label ? <StyledInputName>{label}</StyledInputName> : null}
      <StyledSelect className={open === name ? 'open' : ''}>
        <StyledSelectLabel kind={kind}>
          {selectedUser ? (
            <span className={'user-label'}>
              <Avatar {...selectedUser} wd={20} />
              <span>
                {selectedUser.companyName ||
                  selectedUser.name ||
                  selectedUser.phone}
              </span>
            </span>
          ) : (
            <span>{locale.notSelected}</span>
          )}
          <StyledSelectArrow
            as={SelectArrow}
            className={open === name ? 'open' : ''}
          />
        </StyledSelectLabel>
        <StyledSelectDropdown className={open === name ? 'open' : ''}>
          {loadingList ? (
            <Preloader />
          ) : list?.length ? (
            <ul>
              {emptyOption ? (
                <StyledSelectUser onClick={() => handleSelected({})}>
                  {locale.notSelected}
                </StyledSelectUser>
              ) : null}
              {list.map((user) => {
                return (
                  <StyledSelectUser
                    key={user._id}
                    onClick={(e) => handleSelected(e, user)}
                  >
                    <div>
                      <Avatar {...user} wd={20} />
                    </div>
                    <div>
                      <span>{user.companyName || user.name || user.phone}</span>
                      <span></span>
                    </div>
                  </StyledSelectUser>
                );
              })}
            </ul>
          ) : null}
        </StyledSelectDropdown>
      </StyledSelect>
    </StyledInputLabel>
  );
};
