import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../../Redux/Selectors/AuthSelectors';
import { StyledH2 } from '../../Titles';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import {
  StyledCustomError,
  StyledFormButtonBlock,
  StyledFormGroup,
} from '../../Form/styled';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { Button } from '../../Buttons';
import { Input } from '../../Form/Input';
import { useState } from 'react';
import { DatePickerButton } from '../../Buttons/DatePicker';
import { updateCar } from '../../../queries';
import { setUserData } from '../../../../Redux/Actions/AuthActions';

const inputs = ['odometerKm', 'insurance', 'mot', 'oil', 'brakes', 'filter'];

export const UpdateCarModal = ({ data, carId, onClose }) => {
  const { cars } = useSelector(getUserData);
  const [locale] = useLocale(L);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(data);

  const handleChange = ({ target }) => {
    const numberValue = target.valueAsNumber;
    setFormData((prev) => ({
      ...prev,
      [target.name]: isNaN(numberValue) ? undefined : numberValue,
    }));
  };

  const {
    messages: [{ error, conflict }],
    loading: [loading],
  } = useMessages(['patchCar' + carId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { odometerKm, ...periodic } = formData;
    updateCar(dispatch, carId, { periodic, odometerKm }, ({ car }) => {
      console.log('car', car);
      if (car) {
        dispatch(
          setUserData({
            cars: cars.map((el) => (el._id === car._id ? car : el)),
          })
        );
        onClose();
      }
    });
  };

  return (
    <>
      <StyledH2 align={'center'}>{locale.title}</StyledH2>
      <form noValidate={true} onSubmit={handleSubmit}>
        {inputs.map((type) => (
          <StyledFormGroup key={type}>
            {type === 'insurance' || type === 'mot' ? (
              <DatePickerButton
                showDoubleView={false}
                id={type}
                label={locale.labels[type]}
                selectedDate={formData[type] && new Date(formData[type])}
                fn={(date) =>
                  setFormData((prev) => ({ ...prev, [type]: date }))
                }
              />
            ) : (
              <Input
                type="number"
                min="1"
                step="1"
                label={locale.labels[type]}
                name={type}
                value={formData[type]}
                fn={handleChange}
                errors={conflict === type || !formData[type]}
              />
            )}
          </StyledFormGroup>
        ))}

        {error ? <StyledCustomError>{error}</StyledCustomError> : null}
        <StyledFormButtonBlock>
          <Button
            type={'submit'}
            kind={'green'}
            text={locale.buttons.save}
            loading={loading}
            disabled={loading || Object.values(formData).some((el) => !el)}
          />
        </StyledFormButtonBlock>
      </form>
    </>
  );
};
