export const START_TIME = 7;
export const END_TIME = 22;
export const DURATION_DAY = 3_600_000 * (END_TIME - START_TIME);

export const dashesConfig = [
  {
    color: '#39626D',
  },
  {
    color: '#B4EA33',
  },
];
