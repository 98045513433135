import { ReactComponent as PinIcon } from '../../Static/icons/console/pin.svg';
import { WithTooltip } from '../UI/Tooltip';
import { StyledLocationLink } from './styled';

export const CompanyAddress = ({
  companyAddress,
  coordinates,
  className,
  noWrap,
}) => {
  const { latitude, longitude } = coordinates || {};
  return companyAddress ? (
    <div className={className}>
      <StyledLocationLink
        href={`https://www.google.com/maps/place/${companyAddress}${
          latitude && longitude ? `/@${latitude},${longitude},20z` : ''
        }`}
        target="_blank"
        rel="noreferrer"
        noWrap={noWrap}
        onClick={(e) => e.stopPropagation()}
      >
        <PinIcon />
        <WithTooltip text={companyAddress} showOnOverflow>
          <span>{companyAddress}</span>
        </WithTooltip>
      </StyledLocationLink>
    </div>
  ) : null;
};
