import { useRef, useState, useEffect, useCallback, memo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTooltip } from '../Tooltip';

import { closeModal } from '../../../Redux/Actions/ModalActions';

import {
  StyledModalWrapper,
  StyledModalBody,
  StyledButtonWrapper,
  StyledInfoBlock,
  StyledComponentWrapper,
} from './styled';

import { measureScrollRails } from '../../../Utils/Helpers';
import { CloseButton } from '../Buttons/CloseButton';
import { useLocale } from '../../../Utils/Hooks/useLocale';
import { QAModal } from './QA';
import { PromoCodeModal } from './PromoCode';
import {
  AddNewContacts,
  AddSocialLink,
  EditEmail,
  EditEmailEmployee,
  EditPhone,
  EditPhoneEmployee,
  EditRegistrationCode,
  EditSocialLink,
  EditVatNumber,
} from './Contacts';
import { EditTCA } from './TCA';
import {
  AddBanner,
  AddMarketingTool,
  AddSearchBoost,
  ApproveBanner,
  ViewBanner,
  BannerPeriod,
  MarketingToolsConfig,
  BannerTemplate,
  UpdateGear,
} from './MarketingTools';
import { AddAdminService, AddService, ChangeAdminService } from './Service';
import { LogoutModal } from './Logout';
import { EditCompany } from './EditCompany';
import { EditSchedule } from './Schedule';
import {
  ConfirmDeleteCarModal,
  ConfirmRestoreCarModal,
  ConfirmationChangeActiveStatusUser,
  ConfirmationDeleteContact,
  ConfirmationDeleteImagePartner,
  ConfirmationDeleteLocation,
  ConfirmationDeleteModerationList,
  ConfirmationDeletePaymentCard,
  ConfirmationDeletePromoCode,
  ConfirmationDeleteReview,
  ConfirmationDeleteService,
  ConfirmationDeleteSubscription,
  ConfirmationDeleteUser,
} from './Confirmation';
import { EditUserName } from './EditUser';
import {
  InformationPaymentModal,
  PaymentCardModal,
  PaymentModal,
} from './PaymentCard';
import { AddLocationMap } from './AddLocationMap';
import { AddNewEmployee, AddNewUser, EditEmployeeColor } from './Users';
import { AddComment, EditReview, PartnerReviews } from './Review';
import { ShowCarInformation } from './Car';
import { SubscriptionModal } from './Subscriptions';
import { AddModerationListModal } from './AddModerationListModal';
import {
  ChangeCarInformationOrderModal,
  ChangeCommentOrderModal,
  ChangeGeneralInformationOrderModal,
  ChangeOrderCarModal,
  ChangeOrderCommentModal,
  ChangeOrderImagesModal,
  ChangeOrderStartModal,
  ChangePhotosOrderModal,
  ChangeServicesOrderModal,
  ChangeTimeOrderModal,
  OrderInfoModal,
} from './Orders';
import { ServicePickerModal } from './ServicePickerModal';
import { Lightbox } from './Ligtbox';
import { AddCarModal } from './AddCarModal';
import { UpdateCarModal } from './UpdateCarModal';

const components = {
  editUserName: EditUserName,
  QAModal: QAModal,

  addLocationMap: AddLocationMap,

  logoutModal: LogoutModal,

  addNewContacts: AddNewContacts,
  addSocialLink: AddSocialLink,
  editPhone: EditPhone,
  editEmail: EditEmail,
  editPhoneEmployee: EditPhoneEmployee,
  editEmailEmployee: EditEmailEmployee,
  editSocialLink: EditSocialLink,
  editVatNumber: EditVatNumber,
  editRegistrationCode: EditRegistrationCode,

  showCarInformation: ShowCarInformation,

  editSchedule: EditSchedule,

  paymentCardModal: PaymentCardModal,
  paymentModal: PaymentModal,
  informationPaymentModal: InformationPaymentModal,

  addMarketingTool: AddMarketingTool,
  addBanner: AddBanner,
  bannerPeriod: BannerPeriod,
  marketingToolsConfig: MarketingToolsConfig,
  bannerTemplate: BannerTemplate,
  updateGear: UpdateGear,
  approveBanner: ApproveBanner,
  viewBanner: ViewBanner,
  addSearchBoost: AddSearchBoost,

  editTCA: EditTCA,

  orderInfoModal: OrderInfoModal,
  changeGeneralInformationOrderModal: ChangeGeneralInformationOrderModal,
  changeCarInformationOrderModal: ChangeCarInformationOrderModal,
  changeServicesOrderModal: ChangeServicesOrderModal,
  changePhotosOrderModal: ChangePhotosOrderModal,
  changeTimeOrderModal: ChangeTimeOrderModal,
  changeCommentOrderModal: ChangeCommentOrderModal,

  changeOrderCommentModal: ChangeOrderCommentModal,
  changeOrderStartModal: ChangeOrderStartModal,
  changeOrderCarModal: ChangeOrderCarModal,
  changeOrderImagesModal: ChangeOrderImagesModal,

  promoCodeModal: PromoCodeModal,

  addService: AddService,
  addAdminService: AddAdminService,
  changeAdminService: ChangeAdminService,

  addNewUser: AddNewUser,
  addNewEmployee: AddNewEmployee,
  editEmployeeColor: EditEmployeeColor,

  editCompany: EditCompany,

  confirmationDeleteContact: ConfirmationDeleteContact,
  confirmationDeletePromoCode: ConfirmationDeletePromoCode,
  confirmationDeleteService: ConfirmationDeleteService,
  confirmationDeleteLocation: ConfirmationDeleteLocation,
  confirmationDeletePaymentCard: ConfirmationDeletePaymentCard,
  confirmationDeleteReview: ConfirmationDeleteReview,
  confirmationChangeActiveStatusUser: ConfirmationChangeActiveStatusUser,
  confirmationDeleteSubscription: ConfirmationDeleteSubscription,
  confirmationDeleteModerationList: ConfirmationDeleteModerationList,

  confirmationDeleteImagePartner: ConfirmationDeleteImagePartner,

  editReview: EditReview,
  addComment: AddComment,
  partnerReviews: PartnerReviews,

  subscriptionModal: SubscriptionModal,

  addModerationListModal: AddModerationListModal,
  confirmationDeleteUser: ConfirmationDeleteUser,
  servicePickerModal: ServicePickerModal,
  lightbox: Lightbox,
  addCarModal: AddCarModal,
  updateCarModal: UpdateCarModal,
  confirmDeleteCarModal: ConfirmDeleteCarModal,
  confirmRestoreCarModal: ConfirmRestoreCarModal,
};

export const Modal = memo(({ addPad }) => {
  const {
    data = {},
    size = 'small',
    Comp,
    preserve,
    withClose = true,
    closable = true,
  } = useSelector(({ modal }) => modal);
  const [, lang] = useLocale();

  const [Component, setComponent] = useState(null);
  const [elementData, setElementData] = useState({});

  const dispatch = useDispatch();

  const timeoutRef = useRef(null);

  const close = useCallback(() => {
    closable && dispatch(closeModal({ preserve, size }));
  }, [dispatch, preserve, closable, size]);

  const handleClose = (e) => e.target === e.currentTarget && close();

  const handleKeyDown = useCallback(
    (e) => e.key === 'Escape' && close(),
    [close]
  );

  useEffect(() => {
    if (Comp) {
      document.addEventListener('keydown', handleKeyDown, false);
      document.body.style = `height: 100vh; overflow: hidden; padding-right: ${
        addPad ? measureScrollRails() : 0
      }px`;
      setComponent(Comp);
      setElementData(data);
    } else {
      clearTimeout(timeoutRef.current);
      document.removeEventListener('keydown', handleKeyDown);
      document.body.style = '';
      if (!preserve && Component) {
        timeoutRef.current = setTimeout(() => {
          setComponent(null);
          setElementData({});
        }, 1000);
      }
    }
  }, [
    Comp,
    size,
    Component,
    data,
    handleKeyDown,
    preserve,
    setComponent,
    setElementData,
    addPad,
  ]);

  const Element = components[Component];

  return (
    <StyledModalWrapper opened={Comp in components} onClick={handleClose}>
      <StyledModalBody size={size}>
        {withClose && closable ? (
          <StyledButtonWrapper>
            <CloseButton fn={close} />
          </StyledButtonWrapper>
        ) : null}
        <StyledComponentWrapper>
          {Component ? (
            <Element
              {...elementData}
              onClose={close}
              isOpen={Component in components}
              lang={lang}
            />
          ) : null}
        </StyledComponentWrapper>
      </StyledModalBody>
    </StyledModalWrapper>
  );
});

export const InfoBlock = ({ message, isError }) => {
  const { tooltip, tooltipProps } = useTooltip({
    text: message,
    showOnOverflow: true,
  });
  return (
    <>
      {tooltip}
      <StyledInfoBlock isError={isError} key={message} {...tooltipProps}>
        {message}
      </StyledInfoBlock>
    </>
  );
};
