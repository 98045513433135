import styled, { css } from 'styled-components';
import S from '../../variables';
import { StyledPersonWrapper } from '../../Avatar/styled';

export const StyledSelect = styled.div`
  display: flex;
  max-width: 100%;
  position: relative;
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'auto')};
`;

export const StyledSelectLabel = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 100%;
  font-size: 12px;
  background-color: transparent;
  color: ${S.textPrimary};
  border: 1px solid ${S.strokeGray};
  transition: color ${S.trFast}, background-color ${S.trFast},
    border-color ${S.trFast};

  .user-label {
    display: flex;
    align-items: center;

    ${StyledPersonWrapper} {
      margin-right: 12px;
    }
  }

  @media screen and (min-width: ${S.tablet}) {
    font-size: 14px;
  }

  @media screen and (min-width: ${S.tabletLandscape}) {
    font-size: 16px;
  }

  & > .user-name {
    display: none;
  }

  @media screen and (min-width: ${S.tablet}) {
    & > .user-name {
      display: initial;
    }
  }

  ${({ kind }) => {
    switch (kind) {
      case 'form':
        return css`
          width: 100%;
          padding: 13px 16px;
          border-radius: 15px;
          justify-content: space-between;
        `;
      case 'button':
        return css`
          width: 100%;
          height: 52px;
          padding: 13px 16px;
          background-color: ${S.white};
          border-radius: 15px;
          justify-content: space-between;
        `;
      default:
        return css`
          border-radius: 4px;
          line-height: 1.125;
          padding: 5px 16px;
        `;
    }
  }}

  &.open,
  &:hover {
    border-color: ${S.greenDark};
  }

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
  }

  &::placeholder {
    color: ${S.strokeGray};
  }

  &.has-error {
    border-color: ${S.error};
  }
`;

export const StyledSelectIcon = styled.svg`
  margin-right: 16px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  fill: currentColor;

  @media screen and (min-width: ${S.tablet}) {
    width: 22px;
    height: 22px;
  }

  @media screen and (min-width: ${S.tabletLandscape}) {
    width: 24px;
    height: 24px;
  }
`;

export const StyledSelectArrow = styled.svg`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-left: 16px;
  transition: transform ${S.trFast};
  fill: currentColor;

  &.open {
    transform: scale(-1);
  }
`;

export const StyledSelectDropdownItem = styled.li`
  height: 32px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: color ${S.trFast}, opacity ${S.trFast};
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;

  &:hover,
  &.selected {
    color: ${S.purpleDark};
  }

  & > span {
    white-space: nowrap;
  }
`;

export const StyledSelectDropdown = styled.div`
  min-width: 100%;
  padding: 10px 0;
  position: absolute;
  z-index: 12;
  ${({ openTop, align = 'right' }) =>
    openTop
      ? css`
          bottom: calc(100% + 4px);
          transform-origin: bottom center;
          left: ${align === 'left' ? '0' : 'initial'};
          right: ${align === 'right' ? '0' : 'initial'};
        `
      : css`
          top: calc(100% + 4px);
          transform-origin: top center;
          left: ${align === 'left' ? '0' : 'initial'};
          right: ${align === 'right' ? '0' : 'initial'};
        `}

  border-radius: 12px;
  background: ${S.white};
  box-shadow: ${S.dropdownShade};
  transition: transform ${S.trFast}, box-shadow ${S.trFast};
  transform: scale(0);
  max-height: 300px;
  overflow: hidden;

  & > ul {
    max-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: auto;
    & > li {
      flex-shrink: 0;
      width: 100%;
    }
  }

  &.open {
    box-shadow: 0 0 8px rgba(99, 99, 99, 0.15);
    transform: scale(1);

    & ${StyledSelectDropdownItem} {
      opacity: 1;
    }
  }
`;

export const StyledSelectUser = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px;
  transition: background-color ${S.trFast};
  cursor: pointer;

  & > div {
    &:first-child {
      margin-right: 12px;
    }
  }

  &:hover {
    background-color: ${S.purple};
  }
`;

export const StyledSelectDropdownDate = styled(StyledSelectDropdown)`
  padding: 0;
  max-height: none;
  justify-content: center;
`;

export const StyledPlaceholder = styled.span`
  color: ${S.greenDark};
`;
