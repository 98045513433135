const types = {
  OPEN: 'select/open',
  CLOSE: 'select/close',
  AUTH: 'auth/set',
  AUTH_SET_NOTIFICATIONS: 'auth/set/notifications',
  AUTH_ADD_NOTIFICATIONS: 'auth/add/notifications',
  AUTH_SERVICES: 'auth/services',
  AUTH_SERVICE_CHANGE: 'auth/service/change',
  AUTH_SERVICE_ADMIN_CHANGE: 'auth/service/admin/change',
  AUTH_SERVICE_REMOVE: 'auth/service/remove',
  AUTH_SERVICE_ADMIN_REMOVE: 'auth/service/admin/remove',
  AUTH_SERVICE_ADD: 'auth/service/add',
  AUTH_SERVICE_ADMIN_ADD: 'auth/service/admin/add',
  ADDRESS: 'auth/address',
  LOGOUT: 'auth/clear',
  OPEN_MODAL: 'modal/open',
  CLOSE_MODAL: 'modal/close',
  USERS: 'users/all',
  SET_MESSAGE: 'message/set-message',
  CLEAR_MESSAGE: 'message/clear-message',
  SET_LOADING: 'message/set-loading',
  SET_LANG: 'lang/set',
  GET_PROMOCODES: 'get/promocodes',
  RESET_PROMOCODES: 'reset/promocodes',
  ADD_PROMOCODE: 'add/promocode',
  UPDATE_PROMOCODE: 'update/promocode',
  DELETE_PROMOCODE: 'delete/promocode',
  SET_NEW_ORDER: 'newOrder/set',
  SET_NEW_ORDER_STEP: 'newOrder/step',
  RESET_NEW_ORDER: 'newOrder/reset',
  ADD_SERVICE_NEW_ORDER: 'newOrder/add-service',
  REMOVE_SERVICE_NEW_ORDER: 'newOrder/remove-service',
  WEBPUSH_GRANTED: 'webpush/set-granted',
  WEBPUSH_MODIFIED: 'webpush/set-modified',
  INIT_ORDER_STATE: 'userOrder/init',
  SET_VEHICLE: 'userOrder/set-vehicle',
  SET_START: 'userOrder/set-start',
  SET_COMMENT: 'userOrder/set-comment',
  SET_IMAGES: 'userOrder/set-images',
  SELECT_SERVICE: 'userOrder/select-service',
  DESELECT_SERVICE: 'userOrder/deselect-service',
  SET_SERVICES: 'userOrder/set-services',
  TOGGLE_SERVICE: 'userOrder/toggle-service',
  SET_COMPANY: 'userOrder/set-company',
};

export default types;
