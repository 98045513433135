import { rx } from '../Redux/Actions';
import { authRx, unauthRx } from '../Redux/Actions/AuthActions/thunk';
import { setUserData } from '../Redux/Actions/AuthActions';
import { setBroadcastOnOrder } from '../Utils/Helpers';
import {
  addFavouritePartner,
  addNewCard,
  confirmNewCard,
  confirmOtherMethod,
  deleteAccount,
  deleteEmployee,
  getPartner,
  getPartnersOnboarding,
  getPartnersShort,
  getPaymentLinkToOtherMethod,
  getUser,
  removeFavouritePartner,
  updateUser,
} from '../API/User';
import { getPartnershipServices, getPartnerships } from '../API/Partnership';
import { getOrders } from '../API/Orders';
import { closeModal, openModal } from '../Redux/Actions/ModalActions';
import { getPopularServices } from '../API/Services';
import { checkVIN, deleteCar, getCars, patchCar, restoreCar } from '../API/Car';
import { getBanners } from '../API/MarketingTools';

export const refetchUser = (dispatch, _id) => {
  dispatch(
    rx(
      authRx,
      'initialFetch',
      (_, { data }) => data?.user && dispatch(setUserData(data.user)),
      getUser,
      _id
    )
  );
};

export const confirmAddCard = (dispatch, { id, paymentReference }) =>
  dispatch(
    rx(
      authRx,
      'confirmAddCard',
      (_, { data }) => {
        if (data.data) {
          dispatch(setUserData(data.user));
        }
      },
      confirmNewCard,
      { id, paymentReference }
    )
  );

export const confirmPaySubscription = (dispatch, { id, paymentReference }) =>
  dispatch(
    rx(
      authRx,
      'confirmPaySubscription',
      (_, { data }) => {
        if (data?.data) {
          dispatch(setUserData(data.user));
        }

        dispatch(closeModal());

        setTimeout(() => {
          dispatch(
            openModal({
              Comp: 'informationPaymentModal',
              data: {
                data,
              },
            })
          );
        }, 300);
      },
      confirmOtherMethod,
      { id, paymentReference }
    )
  );

export const addCard = (
  dispatch,
  id,
  dataCard,
  onClose,
  popup = window.open('', 'payment')
) =>
  dispatch(
    rx(
      authRx,
      'addCard',
      (_, { data }) => {
        if (data?.data) {
          const { paymentLink, paymentReference } = data;
          onClose();
          popup.location.href = paymentLink;

          setBroadcastOnOrder(paymentReference, () => {
            popup?.close();
            confirmAddCard(dispatch, { id, paymentReference });
          });
        } else {
          popup?.close();
        }
      },
      addNewCard,
      {
        id,
        body: { ...dataCard },
      }
    )
  );

export const payOtherMethod = (
  dispatch,
  id,
  onClose,
  popup = window.open('', 'subscription')
) =>
  dispatch(
    rx(
      authRx,
      'paySubscription',
      (_, { data }) => {
        if (data?.data) {
          const { paymentLink, paymentReference } = data;
          popup.location.href = paymentLink;

          setBroadcastOnOrder(paymentReference, () => {
            popup?.close();
            confirmPaySubscription(dispatch, { id, paymentReference });
          });
        } else {
          popup?.close();
        }

        onClose();

        setTimeout(() => {
          dispatch(
            openModal({
              Comp: 'informationPaymentModal',
              data: {
                data,
              },
            })
          );
        }, 300);
      },
      getPaymentLinkToOtherMethod,
      {
        id,
      }
    )
  );

export const fetchPartnerships = (dispatch, params, fn) =>
  dispatch(
    rx(
      unauthRx,
      'getPartnerships',
      (_, { data }) => {
        if (data.partnerships) {
          fn(data.partnerships);
        }
      },
      getPartnerships,
      {
        controls: { params },
      }
    )
  );

export const fetchPartnershipsServices = (dispatch, params, fn, auth = false) =>
  dispatch(
    rx(
      auth ? authRx : unauthRx,
      'getPartnershipServices',
      (_, { data }) =>
        data.partnershipsWithServices && fn(data.partnershipsWithServices),
      getPartnershipServices,
      {
        controls: { params },
      }
    )
  );

export const fetchPopularServices = (dispatch, fn) =>
  dispatch(
    rx(
      unauthRx,
      'getPopularServices',
      (_, { data }) => fn(data.services || []),
      getPopularServices
    )
  );

export const fetchCarByVIN = (dispatch, { vin, number }, fn) =>
  dispatch(
    rx(
      unauthRx,
      'checkVIN',
      (_, { data }) => {
        fn(data);
      },
      checkVIN,
      { body: { vin, number } }
    )
  );
export const fetchCars = (dispatch, fn) =>
  dispatch(
    rx(
      authRx,
      'getCars',
      (_, { data }) => {
        fn(data);
      },
      getCars
    )
  );

export const submitAddCar = (dispatch, carId, fn) =>
  dispatch(
    rx(
      authRx,
      'addCar',
      (_, { data }) => {
        fn(data);
      },
      restoreCar,
      { carId }
    )
  );

export const removeCar = (dispatch, carId, fn) =>
  dispatch(
    rx(
      authRx,
      'deleteCar' + carId,
      (_, { data }) => {
        fn(data);
      },
      deleteCar,
      { carId }
    )
  );

export const undeleteCar = (dispatch, carId, fn) =>
  dispatch(
    rx(
      authRx,
      'restoreCar' + carId,
      (_, { data }) => {
        fn(data);
      },
      restoreCar,
      { carId }
    )
  );

export const updateCar = (dispatch, carId, body, fn) =>
  dispatch(
    rx(
      authRx,
      'patchCar' + carId,
      (_, { data }) => {
        fn(data);
      },
      patchCar,
      { carId, body }
    )
  );

export const fetchAdminOrders = (dispatch, params, fn) => {
  const { year, month, ...rest } = params;
  if (year && month) {
    const date = `${new Date(year, month, 1).toISOString()}--${new Date(
      year,
      month + 1,
      1
    ).toISOString()}`;
    rest.date = date;
  }

  dispatch(
    rx(
      authRx,
      'getOrders',
      (_, { data }) => data.orders && fn(data.orders),
      getOrders,
      {
        controls: {
          params: rest,
        },
      }
    )
  );
};

export const fetchClients = (dispatch, fn) => {
  return fn([]);
  // dispatch(
  //   rx(authRx, 'getClients', (_, { data }) => fn(data.users), getUsers, {
  //     controls: { params: { role: 'client' } },
  //   })
  // );
};

export const deletePartnersEmployee = (dispatch, employeeId, fn) => {
  dispatch(
    rx(
      authRx,
      'deleteUser/' + employeeId,
      (_, { data }) => data?.code === 200 && fn(),
      deleteEmployee,
      {
        id: employeeId,
      }
    )
  );
};

export const deleteUserAccount = (dispatch, userId, fn) => {
  dispatch(
    rx(
      authRx,
      'deleteUser/' + userId,
      (_, { data }) => data?.code === 200 && fn(),
      deleteAccount,
      {
        id: userId,
      }
    )
  );
};

export const fetchBanners = (dispatch, fn) => {
  dispatch(
    rx(
      authRx,
      'getBanners',
      (_, { data }) => fn(data?.banners || []),
      getBanners
    )
  );
};

export const fetchPartnersShort = (dispatch, fn, params, auth = true) => {
  dispatch(
    rx(
      auth ? authRx : unauthRx,
      'getPartnersShort',
      (_, { data }) => fn(data?.partners || []),
      auth ? getPartnersShort : getPartnersOnboarding,
      { controls: { params } }
    )
  );
};

export const setFavouritePartner = (dispatch, id, partnerId, fn) => {
  dispatch(
    rx(
      authRx,
      'addFavouritePartner' + partnerId,
      (_, { data }) => {
        if (data.user) {
          dispatch(setUserData(data.user));
          fn(true);
        } else {
          fn(false);
        }
      },
      addFavouritePartner,
      {
        id,
        partnerId,
      }
    )
  );
};

export const deleteFavouritePartner = (dispatch, id, partnerId, fn) => {
  dispatch(
    rx(
      authRx,
      'removeFavouritePartner' + partnerId,
      (_, { data }) => {
        if (data.user) {
          dispatch(setUserData(data.user));
          fn(false);
        } else {
          fn(true);
        }
      },
      removeFavouritePartner,
      {
        id,
        partnerId,
      }
    )
  );
};

export const patchHourlyRate = (dispatch, userId, hourlyRate) => {
  dispatch(
    rx(
      authRx,
      'updateHourlyRate',
      (dispatch, { data }) => {
        dispatch(setUserData(data.user));
      },
      updateUser,
      {
        id: userId,
        body: {
          hourlyRate,
        },
      }
    )
  );
};

export const getUserCars = (dispatch, fn) => {
  dispatch(
    rx(
      authRx,
      'getUserCars',
      (_, { data }) => {
        fn(data.cars);
      },
      updateUser
    )
  );
};

export const getWorkshop = (dispatch, id, fn) => {
  dispatch(
    rx(
      authRx,
      `getWorkshop_${id}`,
      (_, { data }) => {
        fn(data.partner);
      },
      getPartner,
      { id }
    )
  );
};
