import { API } from '../index';

export const endpoints = {
  GET_CONFIG: '/config',
  PUT_TERMS: '/config/terms',
  UPDATE_CONFIG: '/config',
  DELETE_CONFIG: '/config',
};

export const getConfigs = async (config) =>
  await API.get(endpoints.GET_CONFIG, config);

export const putTerms = async (config, { body }) =>
  await API.put(endpoints.PUT_TERMS, body, config);

export const updateConfigs = async (config, { body, key }) =>
  await API.patch(endpoints.UPDATE_CONFIG + '/' + key, body, config);

export const deleteConfigs = async (config, { key, id }) =>
  await API.delete(endpoints.DELETE_CONFIG + '/' + key + '/' + id, config);
