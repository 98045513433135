import { createReducer } from '@reduxjs/toolkit';
import {
  deselectService,
  initOrderState,
  selectService,
  setClientComment,
  setClientImages,
  setOrderCompany,
  setOrderStart,
  setSelectedServices,
  setVehicle,
  toggleService,
} from '../../Actions/UserOrderActions';

export const defaultOrderState = {
  car: null,
  chosenServices: [],
  services: [],
  company: null,
  start: null,
  clientComment: '',
  banner: undefined,
};

const userOrderReducer = createReducer(defaultOrderState, (builder) => {
  builder
    .addCase(initOrderState, (state, action) => {
      const {
        services = [],
        chosenServices = [],
        company = null,
        start = null,
        car = null,
        clientComment = '',
        clientImages = [],
        banner,
      } = { ...state, ...action.payload };
      return {
        services,
        chosenServices,
        company,
        start,
        clientComment,
        clientImages,
        car,
        banner,
      };
    })
    .addCase(setVehicle, (state, action) => {
      state.car = action.payload;
    })
    .addCase(setOrderStart, (state, action) => {
      state.start = action.payload;
    })
    .addCase(selectService, (state, action) => {
      return {
        ...state,
        chosenServices: [...state.chosenServices, action.payload],
      };
    })
    .addCase(deselectService, (state, action) => {
      return {
        ...state,
        chosenServices: state.chosenServices.filter(
          (serviceId) => serviceId !== action.payload
        ),
      };
    })
    .addCase(setSelectedServices, (state, action) => {
      state.chosenServices = action.payload;
    })
    .addCase(toggleService, (state, action) => {
      const isServiceSelected = state.chosenServices?.includes(action.payload);
      return {
        ...state,
        chosenServices: isServiceSelected
          ? state.chosenServices?.filter((id) => id !== action.payload)
          : [...state.chosenServices, action.payload],
      };
    })
    .addCase(setOrderCompany, (state, action) => {
      state.company = action.payload;
    })
    .addCase(setClientComment, (state, action) => {
      state.clientComment = action.payload;
    })
    .addCase(setClientImages, (state, action) => {
      state.clientImages = action.payload;
    });
});

export default userOrderReducer;
