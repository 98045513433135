import styled from 'styled-components';
import S from '../../variables';
import { StyledCheckInputWrapper } from '../../Form/styled';

export const StyledCardFormWrapper = styled.div`
  padding: 24px 16px;
  border-radius: 15px;
  background-color: ${S.lightGray};
`;

export const StyledPaymentCardsWrapper = styled.div`
  max-width: 244px;
  margin: 0 auto;
`;

export const StyledPaymentVariant = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color ${S.trFast};

  &:hover {
    color: ${S.purpleDark};
  }

  ${StyledCheckInputWrapper} {
    margin-left: auto;
  }
`;

export const StyledPaymentVariantIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 24px;
  background-color: ${S.lightGray};
  border-radius: 2px;
  flex-shrink: 0;

  svg {
    width: 24px;
    height: 18px;
  }
`;

export const StyledPaymentVariantName = styled.span`
  padding-left: 8px;
`;

export const StyledPaymentVariantOtherIcon = styled.span`
  width: 14px;
  height: 14px;
  margin-right: 8px;
`;

export const StyledPaymentInformationIcon = styled.div`
  display: block;
  width: 56px;
  height: 56px;
  margin: 0 auto 20px;
`;
