import { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import { shallowEqual } from 'react-redux';

export const useDependentState = (state, updater) => {
  const [dependentState, setDependentState] = useState(state);
  useEffect(() => {
    setDependentState(state);
  }, [state]);

  const updaterRef = useRef(updater || (() => null));
  const stateRef = useRef(dependentState);

  const update = useCallback((next) => {
    if (typeof next === 'function') {
      setDependentState((prev) => {
        if (!shallowEqual(prev, stateRef.current)) {
          stateRef.current = prev;
          const nextValue = next(prev);
          updaterRef.current(nextValue);
          return nextValue;
        } else {
          return prev;
        }
      });
    } else {
      if (!shallowEqual(next, stateRef.current)) {
        stateRef.current = next;
        setDependentState(next);
        updaterRef.current(next);
      }
    }
  }, []);
  return [dependentState, update];
};
