import styled from 'styled-components';
import S from '../../../UI/variables';

export const StyledAdd = styled.div`
  position: absolute;
  color: #fff;
  font-size: 24px;
  opacity: 0;
  transition: opacity ${S.trFast};
`;

export const StyledTemplate = styled.div`
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1;
  background-color: ${({ empty }) => (empty ? S.strokeGray : 'transparent')};
  cursor: pointer;
  &:hover ${StyledAdd} {
    opacity: 1;
  }
  & input {
    display: none;
  }
`;
