import { LayoutAuth } from '../Layout';
import { TabsPurple } from '../../UI/Tabs/TabsPurple';
import { authRoutes } from '../../../Routes';
import { Flex } from '../../UI/Flex';
import { MaxContainerXs } from '../../UI/Containers';
import {
  StyledLayoutAuthHeaderSteps,
  StyledLayoutAuthLoginBody,
  StyledLayoutBody,
} from '../Layout/styled';
import { Form } from '../../UI/Form';
import { phoneFormConfig } from '../../../Configs/formConfigs/phone';
import { useMessages } from '../../../Redux/Selectors/MessageSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from '../../../Utils/Hooks/useLocale';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  clearMessage,
  setMessage,
} from '../../../Redux/Actions/MessageActions';
import { rx } from '../../../Redux/Actions';
import {
  authRx,
  loginRx,
  unauthRx,
} from '../../../Redux/Actions/AuthActions/thunk';
import {
  login,
  signin,
  signup,
  updateUserPhone,
  verifyNewUserPhone,
} from '../../../API/User';
import { codeFormConfig } from '../../../Configs/formConfigs/code';
import L from './locale.json';
import { StyledEditNumber, StyledSendAgainBtn } from './styled';
import { getDeviceInfo } from '../../../Utils/Helpers';
import { getUserData } from '../../../Redux/Selectors/AuthSelectors';
import { filterServiceForRequest } from '../../../Utils/Helpers/services';
import { closeModal } from '../../../Redux/Actions/ModalActions';
import { setUserData } from '../../../Redux/Actions/AuthActions';
import { roles } from '../../../Configs/constants';

const Timer = ({ timeout, fn }) => {
  const [sec, setSec] = useState(timeout);
  const m = Math.floor(sec / 60);
  const s = sec - m * 60;
  const ss = ('00' + s).slice(-2);
  const mm = ('00' + m).slice(-2);
  useEffect(() => {
    let t;
    if (sec > 0) {
      t = setTimeout(() => setSec((s) => (s >= 1 ? s - 1 : s)), 1000);
    } else {
      fn();
    }
    return () => clearTimeout(t);
  }, [sec, fn]);
  return <span>&nbsp;{mm + ':' + ss}</span>;
};

const CodeForm = (props) => {
  return <Form {...props} />;
};

const PhoneForm = (props) => {
  return <Form {...props} />;
};

const FormPhoneCode = ({
  outerPhone,
  canResend,
  phoneSent,
  locale,
  handleSubmitCode,
  codeMessage,
  phoneMessage,
  loadingCode,
  setSentOnceMore,
  decideToResend,
  handleSubmitPhone,
  loadingPhone,
}) => {
  return (
    <MaxContainerXs>
      {phoneSent ? (
        <>
          <CodeForm
            {...codeFormConfig}
            buttonProps={{
              kind: 'green',
              text: locale.submitCode,
              size: 'big',
              style: {
                width: '100%',
                marginTop: '16px',
              },
            }}
            onSubmit={handleSubmitCode}
            customError={codeMessage.error || phoneMessage.error}
            loading={loadingCode}
            locale={locale}
          />
          <div>
            <StyledSendAgainBtn
              disabled={!codeMessage.error && !canResend}
              onClick={setSentOnceMore}
            >
              {locale.resendCode}
              {!canResend && !codeMessage.error ? (
                <Timer timeout={60} fn={decideToResend} />
              ) : null}
            </StyledSendAgainBtn>
          </div>
        </>
      ) : (
        <PhoneForm
          {...{
            ...phoneFormConfig,
            initialState: {
              ...phoneFormConfig.initialState,
              formData: {
                ...phoneFormConfig.initialState.formData,
                phone: outerPhone || '',
              },
            },
          }}
          buttonProps={{
            kind: 'green',
            text: locale.submitPhone,
            size: 'big',
            style: {
              width: '100%',
              marginTop: '16px',
            },
          }}
          locale={locale}
          onSubmit={handleSubmitPhone}
          customError={
            codeMessage.error ||
            phoneMessage.error ||
            codeMessage.success ||
            phoneMessage.success
          }
          loading={loadingPhone}
        />
      )}
    </MaxContainerXs>
  );
};

export const Login = ({
  outerPhone,
  switchStep = () => null,
  setErrors = () => null,
  registration = false,
  updatingUserPhone = false,
  config,
}) => {
  const formData = useSelector(getUserData) || null;
  const [phoneSent, setPhoneSent] = useState(
    registration ? formData.phone : null
  );
  const [canResend, setCanResend] = useState(registration);
  const firstFetch = useRef(registration);

  const {
    messages: [phoneMessage, codeMessage],
    loading: [loadingPhone, loadingCode],
  } = useMessages(['phoneLogin', 'codeLogin']);

  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  useEffect(() => {
    if (registration) {
      if (phoneMessage.conflict && phoneMessage.code === 409) {
        setErrors((prevState) => ({
          ...prevState,
          [phoneMessage.conflict]: phoneMessage.error,
        }));
        const configErrorIndex = (config || []).findIndex((fields = []) =>
          fields.some(({ name }) => name === phoneMessage.conflict)
        );
        switchStep(configErrorIndex >= 0 ? configErrorIndex : 0, null);
      }
    }
  }, [registration, phoneMessage, setErrors, switchStep, config]);

  const decideToShowCode =
    (phone) =>
    (_, { data }) => {
      setPhoneSent(!!data?.data ? phone : null);
    };

  const decideToResend = useCallback(() => {
    setCanResend(true);
    dispatch(
      setMessage({
        phoneLogin: {
          error: '',
          success: '',
        },
      })
    );
  }, [dispatch, setCanResend]);

  const setSentOnceMore = () => {
    setCanResend(false);
    setPhoneSent(null);
    dispatch(clearMessage());
  };

  const handleSubmitPhone = useCallback(
    (e, { phone: p }) => {
      dispatch(
        setMessage({
          phoneLogin: {
            error: '',
            success: '',
            code: '',
            conflict: '',
          },
        })
      );

      const phone = p === '993857931' ? '+380' + p : '+370' + p;

      if (registration) {
        dispatch(
          rx(unauthRx, 'phoneLogin', decideToShowCode(phone), signup, {
            ...formData,
            services: filterServiceForRequest(formData.services),
            role: roles.PARTNER,
            phone,
          })
        );
      } else if (updatingUserPhone) {
        dispatch(
          rx(authRx, 'phoneLogin', decideToShowCode(phone), updateUserPhone, {
            id: formData._id,
            body: {
              phone,
            },
          })
        );
      } else {
        dispatch(
          rx(unauthRx, 'phoneLogin', decideToShowCode(phone), signin, {
            phone,
          })
        );
      }

      setCanResend(false);
    },
    [dispatch, formData, registration, updatingUserPhone]
  );

  useEffect(() => {
    if (firstFetch.current) {
      if (registration) {
        handleSubmitPhone(null, { phone: formData.phone });
        firstFetch.current = false;
      }
    }
  }, [registration, formData, handleSubmitPhone]);

  const decideToLogin = (dispatch, data) => {
    const user = loginRx(dispatch, data);
    if (user) {
      setCanResend(false);
      setPhoneSent(null);
      dispatch(closeModal({}));
    }
  };

  const decideToCloseModal = (dispatch, response) => {
    dispatch(setUserData(response.data.user));
    dispatch(closeModal());
  };

  const handleSubmitCode = (e, { code }) => {
    if (updatingUserPhone) {
      dispatch(
        rx(authRx, 'codeLogin', decideToCloseModal, verifyNewUserPhone, {
          id: formData._id,
          body: {
            code,
            ...getDeviceInfo(),
          },
        })
      );
    } else {
      dispatch(
        rx(unauthRx, 'codeLogin', decideToLogin, login, {
          code,
          phone: phoneSent,
          ...getDeviceInfo(),
        })
      );
    }
  };

  if (updatingUserPhone) {
    return (
      <FormPhoneCode
        phoneSent={phoneSent}
        locale={locale}
        outerPhone={outerPhone}
        handleSubmitCode={handleSubmitCode}
        codeMessage={codeMessage}
        phoneMessage={phoneMessage}
        loadingCode={loadingCode}
        setSentOnceMore={setSentOnceMore}
        decideToResend={decideToResend}
        handleSubmitPhone={handleSubmitPhone}
        loadingPhone={loadingPhone}
        canResend={canResend}
      />
    );
  } else {
    return (
      <>
        <LayoutAuth>
          <StyledLayoutBody>
            <Flex
              justifyContent={'center'}
              flexDirection={'column'}
              spacer={{ bottom: '32px' }}
            >
              {!registration && (
                <div>
                  <TabsPurple links={authRoutes} />
                </div>
              )}
              <div>
                <StyledLayoutAuthHeaderSteps>
                  {phoneSent ? locale.codeTitle : locale.phoneTitle}
                </StyledLayoutAuthHeaderSteps>
                {phoneSent ? (
                  <StyledEditNumber>
                    {locale.editPhoneTitle} {phoneSent}
                  </StyledEditNumber>
                ) : null}
              </div>
            </Flex>
            <StyledLayoutAuthLoginBody>
              <FormPhoneCode
                phoneSent={phoneSent}
                locale={locale}
                handleSubmitCode={handleSubmitCode}
                codeMessage={codeMessage}
                phoneMessage={phoneMessage}
                loadingCode={loadingCode}
                setSentOnceMore={setSentOnceMore}
                decideToResend={decideToResend}
                handleSubmitPhone={handleSubmitPhone}
                loadingPhone={loadingPhone}
                canResend={canResend}
              />
            </StyledLayoutAuthLoginBody>
          </StyledLayoutBody>
        </LayoutAuth>
      </>
    );
  }
};

export const UserLogin = ({ formData, switchStep, setErrors, config }) => {
  const [phoneSent, setPhoneSent] = useState(null);
  const [canResend, setCanResend] = useState(true);

  const {
    messages: [phoneMessage, codeMessage],
    loading: [loadingPhone, loadingCode],
  } = useMessages(['phoneLogin', 'codeLogin']);

  useEffect(() => {
    if (phoneMessage.conflict && phoneMessage.code === 409) {
      if (phoneMessage.conflict !== 'phone') {
        setErrors((prevState) => ({
          ...prevState,
          [phoneMessage.conflict]: phoneMessage.error,
        }));
        const configErrorIndex = (config || []).findIndex((fields = []) =>
          fields.some(({ name }) => name === phoneMessage.conflict)
        );
        if (configErrorIndex >= 0) {
          switchStep(configErrorIndex, null);
        }
        switchStep(configErrorIndex >= 0 ? configErrorIndex : 0, null);
      }
    }
  }, [phoneMessage, switchStep, config, setErrors]);

  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const decideToShowCode =
    (phone) =>
    (_, { data }) => {
      setPhoneSent(!!data?.data ? phone : null);
    };

  const decideToResend = useCallback(() => {
    setCanResend(true);
    dispatch(
      setMessage({
        phoneLogin: {
          error: '',
          success: '',
        },
      })
    );
  }, [dispatch, setCanResend]);

  const setSentOnceMore = () => {
    setCanResend(false);
    setPhoneSent(null);
    dispatch(clearMessage());
  };

  const handleSubmitPhone = useCallback(
    (e, { phone: p }) => {
      dispatch(
        setMessage({
          phoneLogin: {
            error: '',
            success: '',
            code: '',
            conflict: '',
          },
        })
      );

      const phone = '+370' + p;

      dispatch(
        rx(unauthRx, 'phoneLogin', decideToShowCode(phone), signup, {
          onboarding: formData?.car?._id
            ? { car: formData.car._id }
            : undefined,
          role: roles.PRIVATE_USER,
          phone,
          name: formData?.name,
          email: formData?.email,
        })
      );

      setCanResend(false);
    },
    [dispatch, formData]
  );

  const decideToLogin = (dispatch, data) => {
    const user = loginRx(dispatch, data);
    if (user) {
      setCanResend(false);
      setPhoneSent(null);
    }
  };

  const handleSubmitCode = (e, { code }) => {
    dispatch(
      rx(unauthRx, 'codeLogin', decideToLogin, login, {
        code,
        phone: phoneSent,
        ...getDeviceInfo(),
      })
    );
  };

  return (
    <>
      <LayoutAuth>
        <StyledLayoutBody>
          <Flex
            justifyContent={'center'}
            flexDirection={'column'}
            spacer={{ bottom: '32px' }}
          >
            <div>
              <StyledLayoutAuthHeaderSteps>
                {phoneSent ? locale.codeTitle : locale.phoneTitle}
              </StyledLayoutAuthHeaderSteps>
              {phoneSent ? (
                <StyledEditNumber>
                  {locale.editPhoneTitle} {phoneSent}
                </StyledEditNumber>
              ) : null}
            </div>
          </Flex>
          <StyledLayoutAuthLoginBody>
            <FormPhoneCode
              phoneSent={phoneSent}
              locale={locale}
              handleSubmitCode={handleSubmitCode}
              codeMessage={codeMessage}
              phoneMessage={phoneMessage}
              loadingCode={loadingCode}
              setSentOnceMore={setSentOnceMore}
              decideToResend={decideToResend}
              handleSubmitPhone={handleSubmitPhone}
              loadingPhone={loadingPhone}
              canResend={canResend}
            />
          </StyledLayoutAuthLoginBody>
        </StyledLayoutBody>
      </LayoutAuth>
    </>
  );
};
