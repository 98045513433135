import { API } from '../index';

export const endpoints = {
  SIGN_UP: '/user/signup',
  SIGN_IN: '/user/signin',
  VERIFY_CODE: '/user/verify',
  ADD_TOKEN: '/user/webtoken',
  DELETE_TOKEN: '/user/webtoken',
  REFRESH: '/user/refresh',
  LOGOUT: '/user/logout',
  DELETE_ACCOUNT: '/user/account',
  INCOMPLETE: '/user/incomplete',
  GET_USER: '/user',
  GET_USERS: '/user',
  GET_PARTNERS: '/user/partners',
  GET_PARTNERS_ONBOARDING: '/user/partners/public',
  GET_PARTNER: '/user/partner',
  GET_AVARAGE_HORLY_RATE: '/user/partners/hourly-rate',
  FAVOURITES: '/user/favourites',
  GET_EMPLOYEE: '/user/employee',
  ADD_EMPLOYEE: '/user/employee',
  DELETE_EMPLOYEE: '/user/employee',
  ADD_PARTNER_IMAGES: '/user/partner',
  UPDATE_EMPLOYEE: '/user/employee',
  UPDATE_EMPLOYEE_AVATAR: '/user/employee/avatar',
  UPDATE_USER: '/user',
  UPDATE_USER_AVATAR: '/user/avatar',
  DELETE_USER_AVATAR: '/user/avatar',
  UPDATE_USER_PHONE: '/user/phone',
  VERIFY_NEW_USER_PHONE: '/user/phone',
  ADD_CARD: '/user/card',
  CONFIRM_CARD: '/user/card',
  DELETE_CARD: '/user/card',
  PAY_CARD: '/user/payment/subscription',
  PAY_OTHER_METHOD: '/user/payment/subscription',
  CONFIRM_OTHER_METHOD: '/user/payment/subscription',
  GET_TRANSACTIONS: '/user/transactions',
  GET_TRANSACTIONS_ID: '/user/transactions',
};

export const login = async (config, body) =>
  await API.post(endpoints.VERIFY_CODE, body, config);

export const addFCMToken = async (config, body) =>
  await API.put(endpoints.ADD_TOKEN, body, config);

export const deleteFCMToken = async (config, token) =>
  await API.delete(endpoints.DELETE_TOKEN + '/' + token, config);

export const signup = async (config, body) =>
  await API.post(endpoints.SIGN_UP, body, config);

export const signin = async (config, body) =>
  await API.post(endpoints.SIGN_IN, body, config);

export const logout = async (config) =>
  await API.post(endpoints.LOGOUT, {}, config);

export const refresh = async (config, body) =>
  await API.post(endpoints.REFRESH, body, config);

export const incomplete = async (config, body) =>
  await API.post(endpoints.INCOMPLETE, body, config);

export const getUser = async (config, id) => {
  return await API.get(endpoints.GET_USER + '/' + id, config);
};

export const getUsers = async (config) =>
  await API.get(endpoints.GET_USERS, config);

export const getEmployees = async (config) =>
  await API.get(endpoints.GET_EMPLOYEE, config);

export const getPartners = async (config) =>
  await API.get(endpoints.GET_PARTNERS, config);

export const getAvarageHourlyRate = async (config) =>
  await API.get(endpoints.GET_AVARAGE_HORLY_RATE, config);

export const updateUser = async (config, { body, id }) =>
  await API.patch(endpoints.UPDATE_USER + '/' + id, body, config);

export const addPartnerImages = async (config, { id, body }) =>
  await API.patch(endpoints.ADD_PARTNER_IMAGES + '/' + id, body, config);

export const updateEmployee = async (config, { body, id }) =>
  await API.patch(endpoints.UPDATE_EMPLOYEE + '/' + id, body, config);

export const updateEmployeeAvatar = async (config, { body, id }) =>
  await API.patch(endpoints.UPDATE_EMPLOYEE_AVATAR + '/' + id, body, config);

export const updateAvatar = async (config, { body, id }) => {
  return await API.patch(endpoints.UPDATE_USER_AVATAR + '/' + id, body, config);
};

export const deleteAvatar = async (config, { id }) => {
  return await API.delete(endpoints.DELETE_USER_AVATAR + '/' + id, config);
};

export const updateUserPhone = async (config, { body, id }) =>
  await API.post(endpoints.UPDATE_USER_PHONE + '/' + id, body, config);

export const verifyNewUserPhone = async (config, { body, id }) =>
  await API.patch(endpoints.VERIFY_NEW_USER_PHONE + '/' + id, body, config);

export const addNewCard = async (config, { id, body }) => {
  return await API.post(endpoints.ADD_CARD + '/' + id, body, config);
};

export const paySubscription = async (config, { id, body }) => {
  return await API.post(endpoints.PAY_CARD + '/' + id + '/card', body, config);
};

export const getPaymentLinkToOtherMethod = async (config, { id }) =>
  await API.get(endpoints.PAY_OTHER_METHOD + '/' + id, config);

export const addNewEmployee = async (config, { body }) => {
  return await API.post(endpoints.ADD_EMPLOYEE, body, config);
};

export const deleteEmployee = async (config, { id }) => {
  return await API.delete(endpoints.DELETE_EMPLOYEE + '/' + id, config);
};

export const deleteAccount = async (config, { id }) => {
  return await API.delete(endpoints.DELETE_ACCOUNT + '/' + id, config);
};

export const confirmNewCard = async (config, { id, paymentReference }) => {
  return await API.post(
    endpoints.CONFIRM_CARD + '/' + id + '/' + paymentReference,
    {},
    config
  );
};

export const confirmOtherMethod = async (config, { id, paymentReference }) => {
  return await API.post(
    endpoints.CONFIRM_OTHER_METHOD + '/' + id + '/' + paymentReference,
    {},
    config
  );
};

export const deleteCard = async (config, { userId, cardId }) => {
  return await API.delete(
    endpoints.DELETE_CARD + '/' + userId + '/' + cardId,
    config
  );
};

export const getTransactions = async (config) =>
  await API.get(endpoints.GET_TRANSACTIONS, config);

export const getTransactionsId = async (config, { id }) =>
  await API.get(endpoints.GET_TRANSACTIONS_ID + '/' + id, config);

export const getPartnersShort = async (config) =>
  await API.get(endpoints.GET_PARTNERS, config);

export const getPartnersOnboarding = async (config) =>
  await API.get(endpoints.GET_PARTNERS_ONBOARDING, config);

export const addFavouritePartner = async (config, { id, partnerId }) =>
  await API.patch(`${endpoints.FAVOURITES}/${id}/add/${partnerId}`, {}, config);

export const removeFavouritePartner = async (config, { id, partnerId }) =>
  await API.patch(
    `${endpoints.FAVOURITES}/${id}/remove/${partnerId}`,
    {},
    config
  );

export const getPartner = async (config, { id }) => {
  return API.get(`${endpoints.GET_PARTNER}/${id}`, config);
};
