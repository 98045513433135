import { useMemo } from 'react';
import { StyledNumberPlate } from './styled';

const setNumber = (number) =>
  number ? `${number.slice(0, 3)}   ${number.slice(3, 6)}`.toUpperCase() : '';

export const NumberPlate = ({ number }) => {
  const numberValue = useMemo(() => setNumber(number), [number]);
  return <StyledNumberPlate>{numberValue}</StyledNumberPlate>;
};
