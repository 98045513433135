import { StyledCloseButton } from './styled';
import { ReactComponent as CloseIcon } from '../../../../Static/icons/close.svg';
import PropTypes from 'prop-types';

export const CloseButton = ({ fn }) => {
  return (
    <StyledCloseButton onClick={fn}>
      <CloseIcon />
    </StyledCloseButton>
  );
};
CloseButton.propTypes = {
  fn: PropTypes.func,
};
