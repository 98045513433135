import styled from 'styled-components';
import S from '../variables';

export const StyledH1 = styled.div`
  font-size: 26px;
  font-weight: 500;
  text-align: ${({ align }) => align || 'left'};

  @media screen and (min-width: ${S.tablet}) {
    font-size: 28px;
  }

  @media screen and (min-width: ${S.tabletLandscape}) {
    font-size: 32px;
  }
`;

export const StyledH2 = styled.div`
  font-size: 20px;
  font-weight: 500;
  text-align: ${({ align }) => align || 'left'};

  @media screen and (min-width: ${S.tablet}) {
    font-size: 22px;
  }

  @media screen and (min-width: ${S.tabletLandscape}) {
    font-size: 24px;
  }
`;

export const StyledH3 = styled.div`
  font-size: 18px;
  font-weight: 500;

  @media screen and (min-width: ${S.tabletLandscape}) {
    font-size: 20px;
  }
`;
