import { StyledH1, StyledH2 } from '../../Titles';
import { Form } from '../../Form';
import { addUserFormConfig } from '../../../../Configs/formConfigs/addUser';
import { MaxContainerXs } from '../../Containers';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { Flex } from '../../Flex';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { addNewEmployee, updateEmployee } from '../../../../API/User';
import { useDispatch } from 'react-redux';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import {
  StyledCustomError,
  StyledFormButtonBlock,
  StyledFormGroup,
  StyledInputName,
} from '../../Form/styled';
import { RadioInput } from '../../Form/Radio';
import { useState } from 'react';
import { Button } from '../../Buttons';
import { validation } from '../../../../Utils/validation';
import { Input } from '../../Form/Input';
import { PhoneInput } from '../../Form/Phone';
import { SketchPicker } from 'react-color';
import { StyledColourPicker, StyledColourPickerInput } from './styled';

export const EditEmployeeColor = ({ fn, employee, onClose }) => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    color: employee?.color || '#C4C1D2',
  });
  const [errors, setErrors] = useState({
    color: null,
  });

  const [locale] = useLocale(L);

  const {
    messages: [editEmployeeMessages],
    loading: [editEmployeeLoading],
  } = useMessages(['editEmployee']);

  const handleAddEmployee = (e) => {
    e.preventDefault();

    dispatch(
      rx(
        authRx,
        'editEmployee',
        (_, { data }) => {
          if (data?.employee) {
            fn && fn(data.employee);
            onClose();
          }
        },
        updateEmployee,
        {
          id: employee._id,
          body: {
            ...inputs,
          },
        }
      )
    );
  };

  const handleOnChangeField = (e) => {
    const { name, value, error } = validation(e.target);
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({ ...prevState, [name]: error }));
  };

  const handleChangePicker = (color) => {
    setInputs((prevState) => ({ ...prevState, color: color.hex }));
    setErrors((prevState) => ({ ...prevState, color: '' }));
  };

  return (
    <Flex
      flexDirection={'column'}
      spacer={{ left: '0', bottom: '24px' }}
      full={true}
    >
      <StyledH1 align={'center'}>{locale.titles.editEmployee}</StyledH1>

      <MaxContainerXs>
        <form noValidate={true} onSubmit={handleAddEmployee}>
          <StyledFormGroup>
            <StyledInputName>{locale.placeholders.selectColor}</StyledInputName>
            <StyledColourPicker>
              <SketchPicker
                width={'100%'}
                disableAlpha={true}
                color={inputs.color}
                onChange={handleChangePicker}
                styles={{ boxSizing: 'border-box' }}
              />
            </StyledColourPicker>
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledInputName>HEX</StyledInputName>
            <StyledColourPickerInput>
              <div
                className={'preview'}
                style={{ backgroundColor: inputs.color }}
              ></div>
              <Input
                type={'text'}
                name={'color'}
                placeholder={'HEX'}
                errors={errors.color}
                required={true}
                value={inputs.color}
                fn={handleOnChangeField}
              />
            </StyledColourPickerInput>
          </StyledFormGroup>
          {editEmployeeMessages.error ? (
            <StyledCustomError>{editEmployeeMessages.error}</StyledCustomError>
          ) : null}
          <StyledFormButtonBlock>
            <Button
              kind={'green'}
              text={locale.submits.editEmployee}
              type={'submit'}
              loading={editEmployeeLoading}
              disabled={Object.values(errors).some(
                (err) => err === null || err.length
              )}
            />
          </StyledFormButtonBlock>
        </form>
      </MaxContainerXs>
    </Flex>
  );
};

export const AddNewEmployee = ({ setAllUsers, onClose }) => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    name: '',
    phone: '',
    email: '',
    access: 'limited',
    color: '',
  });
  const [errors, setErrors] = useState({
    name: null,
    phone: null,
    email: '',
    color: null,
  });

  const [locale] = useLocale(L);

  const {
    messages: [addNewEmployeeMessages],
    loading: [addNewEmployeeLoading],
  } = useMessages(['addNewEmployee']);

  const handleAddEmployee = (e) => {
    e.preventDefault();

    dispatch(
      rx(
        authRx,
        'addNewEmployee',
        (_, { data }) => {
          if (data?.employee) {
            setAllUsers((prevState) => [...prevState, data.employee]);
            onClose();
          }
        },
        addNewEmployee,
        {
          body: {
            ...inputs,
            phone: `+370${inputs.phone}`,
            email: inputs.email || undefined,
          },
        }
      )
    );
  };

  const handleOnChangeField = (e) => {
    const { name, value, error } = validation(e.target);

    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({ ...prevState, [name]: error }));
  };

  const handleChangePicker = (color) => {
    setInputs((prevState) => ({ ...prevState, color: color.hex }));
    setErrors((prevState) => ({ ...prevState, color: '' }));
  };

  return (
    <Flex
      flexDirection={'column'}
      spacer={{ left: '0', bottom: '24px' }}
      full={true}
    >
      <StyledH1 align={'center'}>{locale.titles.addEmployee}</StyledH1>

      <MaxContainerXs>
        <form noValidate={true} onSubmit={handleAddEmployee}>
          <StyledFormGroup>
            <Input
              name={'name'}
              type={'text'}
              value={inputs.name}
              fn={handleOnChangeField}
              required={true}
              errors={errors.name}
              label={locale.placeholders.name}
              placeholder={locale.placeholders.name}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <PhoneInput
              name={'phone'}
              type={'tel'}
              value={inputs.phone}
              fn={handleOnChangeField}
              required={true}
              errors={errors.phone}
              label={locale.placeholders.phone}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <Input
              name={'email'}
              type={'email'}
              value={inputs.email}
              fn={handleOnChangeField}
              errors={errors.email}
              label={locale.placeholders.email}
              placeholder={locale.placeholders.email}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledInputName>{locale.placeholders.access}</StyledInputName>
            <div>
              <Flex spacer={{ left: '16px', bottom: '0' }}>
                <div>
                  <RadioInput
                    name={'access'}
                    placeholder={locale.placeholders.limited}
                    checked={inputs.access === 'limited'}
                    value={'limited'}
                    fn={handleOnChangeField}
                  />
                </div>
                <div>
                  <RadioInput
                    name={'access'}
                    placeholder={locale.placeholders.full}
                    checked={inputs.access === 'full'}
                    value={'full'}
                    fn={handleOnChangeField}
                  />
                </div>
              </Flex>
            </div>
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledInputName>{locale.placeholders.selectColor}</StyledInputName>
            <StyledColourPicker>
              <SketchPicker
                width={'100%'}
                disableAlpha={true}
                color={inputs.color}
                onChange={handleChangePicker}
                styles={{ boxSizing: 'border-box' }}
              />
            </StyledColourPicker>
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledInputName>HEX</StyledInputName>
            <StyledColourPickerInput>
              <div
                className={'preview'}
                style={{ backgroundColor: inputs.color }}
              ></div>
              <Input
                type={'text'}
                name={'color'}
                placeholder={'HEX'}
                errors={errors.color}
                required={true}
                value={inputs.color}
                fn={handleOnChangeField}
              />
            </StyledColourPickerInput>
          </StyledFormGroup>
          {addNewEmployeeMessages.error ? (
            <StyledCustomError>
              {addNewEmployeeMessages.error}
            </StyledCustomError>
          ) : null}
          <StyledFormButtonBlock>
            <Button
              kind={'green'}
              text={locale.submits.addEmployee}
              type={'submit'}
              loading={addNewEmployeeLoading}
              disabled={Object.values(errors).some(
                (err) => err === null || err.length
              )}
            />
          </StyledFormButtonBlock>
        </form>
      </MaxContainerXs>
    </Flex>
  );
};

export const AddNewUser = ({ onClose }) => {
  const handleSubmit = (e, formData) => {};

  return (
    <>
      <StyledH2>Add new user</StyledH2>
      <MaxContainerXs>
        <Form
          {...addUserFormConfig}
          onSubmit={handleSubmit}
          // loading={updateConfigurationLoading}
          // customError={updateConfigurationMassages.error}
          buttonProps={{
            kind: 'green',
            text: 'Save',
          }}
        />
      </MaxContainerXs>
    </>
  );
};
