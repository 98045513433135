import styled from 'styled-components';
import S from '../UI/variables';

export const StyledHeader = styled.header`
  position: relative;
`;

export const StyledHeaderRow = styled.div`
  background: ${S.white};
  height: 70px;
  padding: 0 12px;
  max-width: 100vw;

  @media screen and (min-width: ${S.tablet}) {
    height: 85px;
    padding: 0 24px;
  }
`;

export const StyledHeaderContent = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  & > li {
    height: 100%;
    align-items: center;

    &.nav {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
    }

    &.logo {
      justify-content: flex-start;

      & svg {
        width: 80px;

        @media screen and (min-width: ${S.tablet}) {
          width: 122px;
        }
      }
    }
  }
`;

export const StyledSearchBar = styled.li`
  position: relative;
  flex-shrink: 0;
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 40px;
  height: 100%;

  @media screen and (min-width: ${S.phone}) {
    margin: 0 20px 0 auto;
  }

  @media screen and (min-width: ${S.tablet}) {
    max-width: 200px;
    flex-shrink: 1;
    margin: 0 auto;
  }

  @media screen and (min-width: ${S.tabletLandscape}) {
    max-width: 420px;
    flex-shrink: 1;
  }

  @media screen and (min-width: 1200px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 320px;
    flex-shrink: 1;
  }

  @media screen and (min-width: 1440px) {
    max-width: 467px;
    flex-shrink: 1;
  }
`;

export const StyledSearchForm = styled.div`
  display: flex;
  align-items: center;
  background-color: ${S.white};
  width: 200px;
  height: 100%;
  max-width: ${({ active }) => (active ? 200 : 40)}px;
  transition: max-width ${S.trFast};
  position: absolute;
  z-index: 1;

  @media screen and (min-width: ${S.phone}) {
    width: 220px;
    max-width: ${({ active }) => (active ? 220 : 40)}px;
  }

  @media screen and (min-width: ${S.tablet}) {
    width: 100%;
    max-width: 100%;
    flex-shrink: 1;
  }
`;

export const StyledSearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  display: inline-block;
  border-radius: 8px;
  background: ${S.lightGray};
  height: 36px;
  color: ${S.textPrimary};
  padding-left: 40px;

  &::placeholder {
    color: ${S.textSecondary};
  }
`;

export const StyledSearchIcon = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 40px;
  padding: 0 12px;
  height: 100%;
  stroke: ${S.textDark};
  pointer-events: none;
`;
