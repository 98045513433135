import { Textarea } from '../../../Components/UI/Form/Textarea';

export const defaultTCAFormData = { termsEn: '', termsLt: '' };

export const defaultTCAFormErrors = { termsEn: '', termsLt: '' };

export const initialTCAFormState = {
  formData: defaultTCAFormData,
  errors: defaultTCAFormErrors,
};

export const setTCAFormConfig = [
  {
    Element: Textarea,
    props: {
      name: 'termsLt',
      placeholder: (l) => l.placeholders.termsLt,
      label: (l) => l.placeholders.termsLt,
      required: true,
    },
  },
  {
    Element: Textarea,
    props: {
      name: 'termsEn',
      placeholder: (l) => l.placeholders.termsEn,
      label: (l) => l.placeholders.termsEn,
      required: true,
    },
  },
];
