import { API } from '../index';

export const endpoints = {
  GET_PARTNERSHIP_SERVICES: '/partnership/services',
  GET_PARTNERSHIP: '/partnership',
  ADD_PARTNERSHIP: '/partnership',
  UPDATE_PARTNERSHIP: '/partnership',
  DELETE_PARTNERSHIP: '/partnership',
};

export const getPartnershipServices = async (config) =>
  await API(endpoints.GET_PARTNERSHIP_SERVICES, config);

export const getPartnerships = async (config) =>
  await API(endpoints.GET_PARTNERSHIP, config);

export const addPartnership = async (config, { body }) =>
  await API.post(endpoints.ADD_PARTNERSHIP, body, config);

export const updatePartnership = async (config, { body, id }) =>
  await API.patch(endpoints.UPDATE_PARTNERSHIP + '/' + id, body, config);

export const deletePartnership = async (config, { id }) =>
  await API.delete(endpoints.DELETE_PARTNERSHIP + '/' + id, config);
