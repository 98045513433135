import { PhoneInput } from '../../../Components/UI/Form/Phone';

export const defaultPhoneFormData = { phone: '' };

export const defaultPhoneFormErrors = { phone: '' };

export const initialPhoneFormState = {
  formData: defaultPhoneFormData,
  errors: defaultPhoneFormErrors,
};

export const setPhoneFormConfig = [
  {
    Element: PhoneInput,
    props: {
      name: 'phone',
      type: 'tel',
      required: true,
    },
  },
];
