import styled from 'styled-components';
import S from '../variables';

export const StyledWrapper = styled.div`
  padding: 20px;
  border: 1px solid ${S.purpleDark};
  border-radius: 12px;
  background-color: ${S.purpleLight};

  @media screen and (min-width: ${S.tablet}) {
    padding: 20px;
    border-radius: 24px;
  }
`;

export const StyledNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  gap: 24px;
  flex-wrap: wrap;
`;

export const InputWrapper = styled.div`
  width: 100%;
  max-width: 550px;
`;

export const StyledPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 220px;
  & input {
    max-width: 140px;
  }
`;

export const StyledTimeWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  cursor: pointer;
  & > li {
    position: relative;
    width: 50%;
    min-width: 104px;
    padding-top: 24px;
    &:first-child > ul {
      &:before {
        content: '';
        position: absolute;
        top: -5px;
        left: -4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${S.textDark};
      }
    }
    @media screen and (min-width: 498px) {
      min-width: 128px;
      width: calc(100% / 3);
    }

    @media screen and (min-width: 626px) {
      width: 25%;
    }

    @media screen and (min-width: 852px) {
      width: 20%;
    }
  }
`;

export const StyledHour = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%);
`;

export const StyledIntervalWrapper = styled.ul`
  position: relative;
  display: flex;
  gap: 4px;
  padding: 4px 2px;
  width: 100%;
  border-top: 2px solid
    ${({ selected }) => (selected ? S.textDark : S.purpleDark)};
  &:after {
    content: '';
    position: absolute;
    top: -5px;
    right: -4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    z-index: ${({ selected }) => (selected ? 1 : 0)};
    background-color: ${({ selected }) =>
      selected ? S.textDark : S.purpleDark};
  }
`;

export const StyledIntervalPad = styled.li`
  width: 25%;
  height: 25px;
  min-width: 25px;
  border: 1px solid ${S.purpleDark};
  border-radius: 8px;
  background-color: ${({ selected, hovered }) =>
    selected ? S.greenLight : hovered ? '#d9f19d' : S.strokeGray};

  @media screen and (min-width: 498px) {
    height: 28px;
    min-width: 28px;
  }
`;
