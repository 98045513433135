import {
  StyledErrorsWrapper,
  StyledInputLabel,
  StyledInputName,
  StyledInputWrapper,
  StyledPhoneInput,
  StyledPhonePrefix,
} from '../styled';

export const PhoneInput = ({
  required,
  name,
  type = 'tel',
  fn = () => null,
  errors = '',
  placeholder,
  disabled = false,
  label,
  locale,
  value,
}) => {
  const handleInput = (e) => {
    fn(e);
  };

  return (
    <StyledInputLabel>
      {label ? (
        typeof label === 'function' ? (
          <StyledInputName>
            {label(locale)}
            {required && '*'}
          </StyledInputName>
        ) : (
          <StyledInputName>
            {label}
            {required && '*'}
          </StyledInputName>
        )
      ) : null}
      <StyledInputWrapper>
        <StyledPhoneInput
          className={errors ? 'has-error' : ''}
          name={name}
          type={type}
          required={required}
          onInput={handleInput}
          errors={errors}
          value={value}
          maxLength={9}
          minLength={8}
          disabled={disabled}
          placeholder={
            typeof placeholder === 'function'
              ? placeholder(locale)
              : placeholder || ''
          }
        />
        <StyledPhonePrefix>+370{required ? ' *' : ''}</StyledPhonePrefix>
        <StyledErrorsWrapper errors={errors}>
          <span>{errors}</span>
        </StyledErrorsWrapper>
      </StyledInputWrapper>
    </StyledInputLabel>
  );
};
