import { createReducer } from '@reduxjs/toolkit';

import {
  setLoading,
  setMessage,
  clearMessage,
} from '../../Actions/MessageActions';

export const defaultMessageState = {
  error: '',
  message: '',
  code: null,
  conflict: null,
};

const initialMessagesState = {
  all: defaultMessageState,
  last: defaultMessageState,
};

const initialState = {
  loading: [],
  message: initialMessagesState,
};

const message = createReducer(initialState, (builder) => {
  builder
    .addCase(setLoading, (state, action) => {
      return {
        ...state,
        loading: state.loading.some((l) => l === action.payload)
          ? [...state.loading.filter((l) => l !== action.payload)]
          : [...state.loading, action.payload],
      };
    })
    .addCase(setMessage, (state, action) => {
      const lastMessage = Object.values(action.payload)?.[0];
      return {
        ...state,
        message: {
          ...state.message,
          ...action.payload,
          last:
            lastMessage?.error || lastMessage?.success
              ? lastMessage
              : state.message.last || defaultMessageState,
        },
      };
    })
    .addCase(clearMessage, (state, action) => {
      if (action.payload) {
        const toClear =
          typeof action.payload === 'string'
            ? [action.payload]
            : Array.isArray(action.payload)
            ? action.payload
            : null;
        if (toClear) {
          return {
            ...state,
            message: Object.entries(state.message)
              .filter(
                ([key]) => !toClear.some((messageName) => messageName === key)
              )
              .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
              }, {}),
          };
        }
      }
      return {
        ...state,
        message: { ...initialMessagesState, last: state.message.last },
      };
    });
});

export default message;
