import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';

const setUserData = createAction(types.AUTH);

const setUserServices = createAction(types.AUTH_SERVICES);

const changeUserServiceItem = createAction(types.AUTH_SERVICE_CHANGE);
const changeUserAdminServiceItem = createAction(
  types.AUTH_SERVICE_ADMIN_CHANGE
);
const addUserServiceItem = createAction(types.AUTH_SERVICE_ADD);
const addUserServiceItemAdmin = createAction(types.AUTH_SERVICE_ADMIN_ADD);
const removeUserServiceItem = createAction(types.AUTH_SERVICE_REMOVE);
const removeUserAdminServiceItem = createAction(
  types.AUTH_SERVICE_ADMIN_REMOVE
);

const userLogout = createAction(types.LOGOUT);

const userSetNotifications = createAction(types.AUTH_SET_NOTIFICATIONS);
const userAddNotifications = createAction(types.AUTH_ADD_NOTIFICATIONS);

export {
  setUserData,
  userLogout,
  setUserServices,
  changeUserServiceItem,
  removeUserServiceItem,
  addUserServiceItem,
  changeUserAdminServiceItem,
  removeUserAdminServiceItem,
  addUserServiceItemAdmin,
  userSetNotifications,
  userAddNotifications,
};
