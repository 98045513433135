const S = {
  //breackpoints
  desktop: '1280px',
  tabletLandscape: '1024px',
  tablet: '768px',
  phone: '480px',

  //fonts

  heading: 'font-size: 36px; font-weight: regular;',

  //colors
  white: '#fff',
  black: '#000',

  error: '#FF4747',

  yellow: '#FAF3EB',

  textDark: '#262626',
  textPrimary: '#1D2027',
  textSecondary: '#A1A6BD',

  purple: '#D2C2F8',
  purpleLight: '#EEE7FF',
  purpleSecond: '#D2C2F8',
  purpleLanding: '#AE9DF4',
  purpleDark: '#8F7DBB',

  red: '#FF3A3A',

  green: '#B4EA33',
  greenDark: '#1A434E',
  greenLight: '#C3F44D',
  greenSecond: '#326370',

  pink: '#FF7979',

  lightGray: '#F5F6FA',
  strokeGray: '#D2D6E4',
  stroke: '#A1A6BD',
  secondGray: '#C4C1D2',

  blueDark: '#4C628C',

  //statuses

  done: '#8F7DBB',
  pending: '#262626',
  settled: '#8CBE14',
  rejected: '#FF4646',

  //shadows
  calendarShade: '0 0 15px rgba(133, 133, 133, 0.15)',
  dropdownShade: '0px 4px 9px rgba(203, 203, 203, 0.25);',
  mainShade: '0px 0px 7px rgba(122, 122, 122, 0.25)',
  consoleTabShade:
    '0px 1px 2px rgba(97, 97, 97, 0.2), 0px 2px 4px rgba(97, 97, 97, 0.2)',

  //transition properties
  trFast: '250ms cubic-bezier(0.4, 0, 0.2, 1)',
  trMid: '750ms cubic-bezier(0.4, 0, 0.2, 1)',
  trSlow: '1s cubic-bezier(0.4, 0, 0.2, 1)',
  trBounce: '250ms cubic-bezier(0.4, 2.08, 0.55, 0.44)',
  trOverflowFast: '250ms cubic-bezier(0.53, 1.79, 0.68, 1.68)',
  trOverflowSlow: '500ms cubic-bezier(0.53, 1.79, 0.68, 1.68)',
};

export default S;
