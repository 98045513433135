import { useSelector } from 'react-redux';
import { getUserData } from '../../Redux/Selectors/AuthSelectors';
import { roles } from '../../Configs/constants';

export const useActiveSubscribe = () => {
  const userData = useSelector(getUserData);

  switch (userData.role) {
    case roles.EMPLOYEE:
      return userData.company?.active;
    case roles.PARTNER:
    case roles.PRIVATE_USER:
    case roles.BUSINESS_USER:
      return userData.active;
    case roles.ADMIN:
      return true;
    default:
      return false;
  }
};
