import {
  StyledFormButtonBlock,
  StyledFormGroup,
} from '../../../UI/Form/styled';
import { Input } from '../../../UI/Form/Input';
import { useDispatch, useSelector } from 'react-redux';
import { getNewOrderData } from '../../../../Redux/Selectors/CreateOrderSelectors';
import { calculateTimeAndPriceServices } from '../../../Calendar/helpers';
import { useMemo, useState } from 'react';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from '../locale.json';
import { validation } from '../../../../Utils/validation';
import { setNewOrderData } from '../../../../Redux/Actions/CreateOrderActions';
import { closeModal } from '../../../../Redux/Actions/ModalActions';
import { fetchUpdateOrderData } from '../../queries';
import { Button } from '../../../UI/Buttons';
import { useCarete } from '../../../../Utils/Hooks/useCarete';
import { useRef } from 'react';
import { useCallback } from 'react';

const shift = { 2: 1 };

export const TimeForm = ({ onChange, isCreate, order, isModal }) => {
  const dispatch = useDispatch();
  const { timeShift, services } = useSelector(getNewOrderData);
  const inputRef = useRef();

  const [locale, lang] = useLocale(L);

  const [inputs, setInputs] = useState({
    timeShift: order?.timeShift || 0,
  });

  const servicesTime = useMemo(
    () =>
      calculateTimeAndPriceServices(isCreate ? services : order?.services || [])
        .time,
    [services, isCreate, order]
  );

  const handleChangeInput = useCallback(
    (event) => {
      let { value, name } = validation(event.target, lang);
      value = value.replace(/[^\d|^:]+/g, '');

      let [hours = '00', minutes = '00'] = value.split(':');
      hours = (hours + '0').slice(0, 2);
      minutes = (minutes + '0').slice(0, 2);
      if (+hours > 23) hours = '23';
      if (+minutes > 59) minutes = '59';
      hours = (hours + '0').slice(0, 2);
      minutes = (minutes + '0').slice(0, 2);
      const time = Date.parse(`1970-01-01T${hours}:${minutes}:00.000Z`);

      if (isCreate && !isModal) {
        dispatch(
          setNewOrderData({
            [name]: time - servicesTime,
          })
        );
      } else {
        setInputs((prevState) => ({
          ...prevState,
          [name]: time - servicesTime,
        }));
      }
    },
    [dispatch, isCreate, isModal, lang, servicesTime]
  );

  const onChangeInput = useCarete(inputRef, handleChangeInput, shift);

  const handleSubmitForm = () => {
    if (isCreate) {
      dispatch(setNewOrderData(inputs));
      dispatch(closeModal({}));
    } else {
      fetchUpdateOrderData(
        dispatch,
        { ...inputs },
        (newOrder) => {
          onChange(newOrder);
          dispatch(closeModal({}));
        },
        order._id
      );
    }
    onChange && onChange((prevState) => ({ ...prevState, ...inputs }));
  };

  return (
    <>
      <StyledFormGroup>
        <Input
          innerRef={inputRef}
          type={'text'}
          name={'timeShift'}
          placeholder={locale.placeholders.estimated}
          label={locale.placeholders.estimated}
          value={new Date(
            (isModal ? inputs.timeShift : timeShift) + servicesTime
          ).toLocaleTimeString('ru', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'UTC',
          })}
          fn={onChangeInput}
        />
      </StyledFormGroup>
      {isModal ? (
        <StyledFormButtonBlock>
          <Button kind={'green'} fn={handleSubmitForm} text={locale.saveBtn} />
        </StyledFormButtonBlock>
      ) : null}
    </>
  );
};
