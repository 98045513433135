import {
  AddCarButton,
  StyledAddCarSubtitle,
  StyledAddCard,
  StyledAddCardTitle,
} from './styled';
import L from './locale.json';
import { useLocale } from '../../Utils/Hooks/useLocale';
import { getUserData } from '../../Redux/Selectors/AuthSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { roles } from '../../Configs/constants';
import { toPrice } from '../../Utils/Helpers';
import { ReactComponent as AddCarIcon } from '../../Static/icons/addCircle.svg';
import { openModal } from '../../Redux/Actions/ModalActions';

export const AddCarCard = ({ disabled }) => {
  const [locale] = useLocale(L);
  const dispatch = useDispatch();

  const {
    role,
    numCars = 0,
    subscription: { freeUnitsAmount = 0, pricePerUnit = 0 } = {},
  } = useSelector(getUserData);

  const freeMoreAvailable = freeUnitsAmount - numCars;
  const disabledCard =
    (role === roles.PRIVATE_USER && numCars >= 4) || disabled;

  const handleClick = () => {
    if (!disabledCard) {
      dispatch(openModal({ Comp: 'addCarModal' }));
    }
  };
  return (
    <StyledAddCard onClick={handleClick} disabled={disabledCard}>
      <StyledAddCardTitle>
        {locale.addCard.total}: {numCars}
        {role === roles.PRIVATE_USER ? `/${4}` : ''}
      </StyledAddCardTitle>
      {!disabledCard ? (
        <>
          <StyledAddCarSubtitle>
            {freeMoreAvailable > 0
              ? locale.addCard.freeTitle.replace(
                  '{{amount}}',
                  freeMoreAvailable
                )
              : locale.addCard.paidTitle.replace(
                  '{{cost}}',
                  toPrice(pricePerUnit)
                )}
          </StyledAddCarSubtitle>
          <AddCarButton
            text={locale.addCard.addButton}
            kind="icon"
            Icon={AddCarIcon}
          />
        </>
      ) : null}
    </StyledAddCard>
  );
};
