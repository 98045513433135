import { Input } from '../../../Components/UI/Form/Input';
import { Textarea } from '../../../Components/UI/Form/Textarea';

const defaultFormData = {
  Name: '',
  Description: '',
};

const initialListFormState = {
  formData: defaultFormData,
  errors: defaultFormData,
};

const getFormConfig = (l) => [
  {
    Element: Input,
    props: {
      name: 'Name',
      label: (l) => l.labels.name,
      placeholder: (l) => l.placeholders.name,
      type: 'text',
      required: true,
    },
  },
  {
    Element: Textarea,
    props: {
      name: 'Description',
      label: (l) => l.labels.description,
      placeholder: (l) => l.placeholders.description,
      required: true,
    },
  },
];

export const getModerationListFormConfig = (l, initialData = {}) => ({
  initialState: {
    ...initialListFormState,
    formData: { ...initialListFormState.formData, ...initialData },
  },
  formConfig: getFormConfig(l),
});
