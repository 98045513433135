import { StyledH2, StyledH3 } from '../../Titles';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from '../../../Orders/ViewOrder/locale.json';
import { StyledInfoModalGroup } from './styled';
import {
  StyledOrderParams,
  StyledOrderTotal,
} from '../../../Orders/ViewOrder/styled';
import { getTimeString } from '../../../Calendar/helpers';
import { Flex } from '../../Flex';
import { Button } from '../../Buttons';
import {
  fetchCancelOrder,
  fetchDeleteOrder,
  fetchFinishOrder,
  fetchUpdateOrderData,
  fetchUpdateOrderImages,
} from '../../../Orders/queries';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { closeModal } from '../../../../Redux/Actions/ModalActions';
import { ServicesForm } from '../../../Orders/CreateOrder/Forms/ServicesForm';
import { AboutCarForm } from '../../../Orders/CreateOrder/Forms/AboutCarForm';
import { GeneraInformationForm } from '../../../Orders/CreateOrder/Forms/GeneraInformationForm';
import { TimeForm } from '../../../Orders/CreateOrder/Forms/TimeForm';
import { CommentForm } from '../../../Orders/CreateOrder/Forms/CommentForm';
import { CommentForm as ClientCommentForm } from '../../../Orders/OrderForms/CommentForm';
import { PhotoForm } from '../../../Orders/CreateOrder/Forms/PhotoForm';
import { isUser, roles } from '../../../../Configs/constants';
import { getUserData } from '../../../../Redux/Selectors/AuthSelectors';
import { haveAccess } from '../../../../Utils/Helpers/isEmployeeAccessRoute';
import { StyledCustomError, StyledFormButtonBlock } from '../../Form/styled';
import { Preloader } from '../../Preloader';
import { toPrice } from '../../../../Utils/Helpers';
import { useState } from 'react';
import { OrderDateForm } from '../../../Orders/OrderForms/OrderDateForm';
import { SelectCarForm } from '../../../Orders/OrderForms/SelectCarForm';
import { ImagesForm } from '../../../Orders/OrderForms/ImagesForm';

export const OrderInfoModal = ({ order, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locations = useLocation();
  const { role, active, company, access } = useSelector(getUserData);
  const [locale, lang] = useLocale(L);

  const {
    messages: [cancelOrderMessages, finishOrderMessages],
    loading: [cancelOrderLoading, finishOrderLoading, deleteLoading],
  } = useMessages([
    `cancelOrder_${order._id}`,
    `finishOrder_${order._id}`,
    `deleteOrder_${order._id}`,
  ]);

  const decideToCLose = () => {
    navigate('/orders');
    onClose();
  };

  const handleCancelOrder = () =>
    fetchCancelOrder(dispatch, order._id, decideToCLose);

  const handleDeleteOrder = () =>
    fetchDeleteOrder(dispatch, order._id, decideToCLose);

  const handleFinishOrder = () =>
    fetchFinishOrder(dispatch, order._id, decideToCLose);

  const handleViewOrder = () => {
    navigate(`/orders/view/${order._id}`, { state: { from: locations } });
    dispatch(closeModal({}));
  };

  return (
    <Flex
      spacer={{ left: '26px', bottom: '26px' }}
      flexDirection={'column'}
      full={true}
    >
      <div>
        <StyledH3>{`${locale.titleOrder.number} ${
          order.number || 0
        }`}</StyledH3>
      </div>
      <div>
        <StyledInfoModalGroup>
          <StyledOrderParams>
            {isUser(role) ? (
              <>
                <span>{locale.generalInformation.company}:&nbsp;</span>
                <span>{order.company.companyName || '-'}</span>
              </>
            ) : (
              <>
                <span>{locale.generalInformation.client}:&nbsp;</span>
                <span>{order.client.name || '-'}</span>
              </>
            )}
          </StyledOrderParams>

          <StyledOrderParams>
            <span>
              {isUser(role)
                ? locale.generalInformation.address
                : locale.generalInformation.phone}
              :&nbsp;
            </span>
            <span>
              {(isUser(role)
                ? order.company.companyAddress
                : order.client.phone) || '-'}
            </span>
          </StyledOrderParams>
          <StyledOrderParams>
            <span>{locale.generalInformation.dateAndTime}: &nbsp;</span>
            <span>
              {`${new Date(order.start).toLocaleDateString(lang, {
                day: '2-digit',
                month: 'long',
              })}, ${new Date(order.start).toLocaleTimeString('ru', {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
              })}` || '-'}
            </span>
          </StyledOrderParams>
        </StyledInfoModalGroup>
        <StyledInfoModalGroup>
          <StyledOrderParams>
            <span>Car:&nbsp;</span>
            <span>{`${order.car?.make?.name || ''} ${
              order.car?.model?.name || ''
            }, ${order.car?.number || ''}`}</span>
          </StyledOrderParams>
        </StyledInfoModalGroup>
        {order.services?.length ? (
          <StyledInfoModalGroup>
            <StyledOrderParams>
              <span></span>
              <span>{locale.services.title}</span>
            </StyledOrderParams>
            {order.services.map((service) => {
              return (
                <StyledOrderParams
                  type={'space-between'}
                  key={service.service._id}
                >
                  <span>
                    {service.service.name[lang]}
                    :&nbsp;
                  </span>
                  <span>€{service.price}</span>
                </StyledOrderParams>
              );
            })}
          </StyledInfoModalGroup>
        ) : null}
        <StyledInfoModalGroup>
          {isUser(role) ? (
            <StyledOrderParams type={'space-between'}>
              <span>{locale.time.estimatedDurationOfWork}:&nbsp;</span>
              <span>
                {getTimeString(
                  new Date(order.end) - new Date(order.start),
                  lang
                )}
              </span>
            </StyledOrderParams>
          ) : (
            <>
              <StyledOrderParams type={'space-between'}>
                <span>{locale.time.estimatedDurationOfWork}:&nbsp;</span>
                <span>
                  {getTimeString(
                    new Date(order.end) - new Date(order.start),
                    lang
                  )}
                </span>
              </StyledOrderParams>
              <StyledOrderParams type={'space-between'}>
                <span>{locale.time.editDurationOfWorkTo}:&nbsp;</span>
                <span>
                  {order.timeShift ? getTimeString(order.timeShift, lang) : '-'}
                </span>
              </StyledOrderParams>
            </>
          )}
        </StyledInfoModalGroup>
      </div>
      <div>
        <StyledOrderTotal>
          {locale.total}: {toPrice(order.price, lang)}
        </StyledOrderTotal>
      </div>
      {cancelOrderMessages.error || finishOrderMessages.error ? (
        <div>
          <StyledCustomError>
            {cancelOrderMessages.error || finishOrderMessages.error}
          </StyledCustomError>
        </div>
      ) : null}
      <div>
        {cancelOrderLoading || finishOrderLoading ? (
          <Preloader />
        ) : (
          <Flex spacer={{ left: '8px', bottom: '8px' }} full={true}>
            {isUser(role) && Date.parse(order.start) > Date.now() ? (
              <div>
                <Button
                  text={locale.cancelBtn}
                  fn={handleDeleteOrder}
                  full
                  loading={deleteLoading}
                  disabled={deleteLoading}
                />
              </div>
            ) : (role === roles.PARTNER ||
                (haveAccess(role, access) && role !== roles.ADMIN)) &&
              !order.ended ? (
              <div>
                {Date.parse(order.start) > Date.now() ? (
                  <Button
                    text={locale.cancelBtn}
                    fn={handleCancelOrder}
                    full
                    loading={cancelOrderLoading}
                    disabled={cancelOrderLoading}
                  />
                ) : (
                  <Button
                    text={locale.finishBtn}
                    full
                    fn={handleFinishOrder}
                    loading={finishOrderLoading}
                    disabled={finishOrderLoading}
                  />
                )}
              </div>
            ) : null}
            <div>
              <Button
                kind={'green'}
                text={locale.moreBtn}
                fn={handleViewOrder}
                disabled={
                  !role === roles.ADMIN
                    ? role === roles.EMPLOYEE
                      ? !company?.active
                      : !active
                    : false
                }
                full={true}
              />
            </div>
          </Flex>
        )}
      </div>
    </Flex>
  );
};

export const ChangeGeneralInformationOrderModal = ({
  order,
  isCreate,
  onChange,
}) => {
  const [locale] = useLocale(L);

  return (
    <>
      <StyledH2>{locale.generalInformation.title}</StyledH2>
      <GeneraInformationForm
        order={order}
        isModal={true}
        isCreate={isCreate}
        onChange={onChange}
      />
    </>
  );
};

export const ChangeCarInformationOrderModal = ({
  order,
  isCreate,
  onChange,
}) => {
  const [locale] = useLocale(L);

  return (
    <>
      <StyledH2>{locale.aboutCar.title}</StyledH2>
      <AboutCarForm
        order={order?.car || {}}
        isCreate={isCreate}
        isModal={true}
        onChange={onChange}
      />
    </>
  );
};

export const ChangeServicesOrderModal = ({ order, isCreate, onChange }) => {
  const [locale] = useLocale(L);

  return (
    <>
      <StyledH2>{locale.services.title}</StyledH2>
      <ServicesForm
        order={order}
        isCreate={isCreate}
        isModal={true}
        onChange={onChange}
      />
    </>
  );
};

export const ChangePhotosOrderModal = ({ order, isCreate, onChange }) => {
  const [locale] = useLocale(L);

  return (
    <>
      <StyledH2>{locale.photoCompany.title}</StyledH2>
      <PhotoForm
        order={order}
        isModal={true}
        onChange={onChange}
        isCreate={isCreate}
      />
    </>
  );
};

export const ChangeTimeOrderModal = ({ order, isCreate, onChange }) => {
  const [locale] = useLocale(L);

  return (
    <>
      <StyledH2>{locale.time.title}</StyledH2>
      <TimeForm
        order={order}
        isModal={true}
        isCreate={isCreate}
        onChange={onChange}
      />
    </>
  );
};

export const ChangeCommentOrderModal = ({ order, isCreate, onChange }) => {
  const [locale] = useLocale(L);

  return (
    <>
      <StyledH2>{locale.commentCompany.title}</StyledH2>
      <CommentForm
        order={order}
        isModal={true}
        isCreate={isCreate}
        onChange={onChange}
      />
    </>
  );
};

export const ChangeOrderCarModal = ({ order, isCreate, onChange, onClose }) => {
  const [locale] = useLocale(L);

  const dispatch = useDispatch();

  const [car, setCar] = useState(order.car);

  const {
    loading: [loading],
    messages: [{ error }],
  } = useMessages([`updateOrder`]);

  const handleSubmitForm = () => {
    if (isCreate) {
      onChange({ car });
      onClose();
    } else {
      fetchUpdateOrderData(
        dispatch,
        { car },
        (newOrder) => {
          onChange(newOrder);
          onClose();
        },
        order._id
      );
    }
  };

  const handleChange = (_, value) => {
    setCar(value);
  };

  return (
    <>
      <StyledH2>{locale.generalInformation.car}</StyledH2>
      <SelectCarForm
        orderData={order}
        onChange={handleChange}
        errors={{ car: error }}
      />
      <StyledFormButtonBlock>
        <Button
          kind={'green'}
          fn={handleSubmitForm}
          text={locale.saveBtn}
          disabled={loading}
          loading={loading}
        />
      </StyledFormButtonBlock>
    </>
  );
};

export const ChangeOrderStartModal = ({
  order,
  isCreate,
  onChange,
  onClose,
}) => {
  const [locale] = useLocale(L);

  const dispatch = useDispatch();

  const [start, setStart] = useState(order.start);

  const {
    loading: [loading],
    messages: [{ error }],
  } = useMessages([`updateOrder`]);

  const handleSubmitForm = () => {
    if (isCreate) {
      onChange({ start });
      onClose();
    } else {
      fetchUpdateOrderData(
        dispatch,
        { start },
        (newOrder) => {
          onChange(newOrder);
          onClose();
        },
        order._id
      );
    }
  };

  const handleChange = (_, value) => {
    setStart(value);
  };

  return (
    <>
      <StyledH2>{locale.generalInformation.dateAndTime}</StyledH2>
      <OrderDateForm
        orderData={order}
        onChange={handleChange}
        errors={{ start: error }}
      />
      <StyledFormButtonBlock>
        <Button
          kind={'green'}
          fn={handleSubmitForm}
          text={locale.saveBtn}
          disabled={loading}
          loading={loading}
        />
      </StyledFormButtonBlock>
    </>
  );
};

export const ChangeOrderCommentModal = ({
  order,
  prop = 'clientComment',
  isCreate,
  onChange,
  onClose,
}) => {
  const [locale] = useLocale(L);

  const dispatch = useDispatch();

  const [comment, setComment] = useState(order[prop] || '');

  const {
    loading: [loading],
  } = useMessages([`updateOrder`]);

  const handleSubmitForm = () => {
    if (isCreate) {
      onChange({ [prop]: comment });
      onClose();
    } else {
      fetchUpdateOrderData(
        dispatch,
        { [prop]: comment },
        (newOrder) => {
          onChange(newOrder);
          onClose();
        },
        order._id
      );
    }
  };

  const handleChange = (_, value) => {
    setComment(value);
  };

  return (
    <>
      <StyledH2>{locale.commentClient.title}</StyledH2>
      <ClientCommentForm orderData={order} onChange={handleChange} />
      <StyledFormButtonBlock>
        <Button
          kind={'green'}
          fn={handleSubmitForm}
          text={locale.saveBtn}
          disabled={loading}
          loading={loading}
        />
      </StyledFormButtonBlock>
    </>
  );
};

export const ChangeOrderImagesModal = ({
  order,
  prop = 'clientImages',
  isCreate,
  onChange,
  onClose,
}) => {
  const [locale] = useLocale(L);

  const dispatch = useDispatch();

  const [images, setImages] = useState(order[prop] || '');

  const {
    messages: [updateOrderImagesMessage],
    loading: [loading],
  } = useMessages([`updateOrderImages_${order._id}`]);

  const handleSubmitForm = () => {
    if (isCreate) {
      onChange({ [prop]: images });
      onClose();
    } else {
      const imagesToDelete = order[prop].filter((img) => !images.includes(img));

      const formData = new FormData();

      imagesToDelete.forEach((file) => {
        formData.append('imagesToDelete', file);
      });

      images.forEach((file) => {
        if (file instanceof File) {
          formData.append('images', file);
        }
      });

      fetchUpdateOrderImages(
        dispatch,
        formData,
        (newOrder) => {
          onChange(newOrder);
          onClose();
        },
        order._id
      );
    }
  };

  const handleChange = (_, value) => {
    setImages(value);
  };

  return (
    <>
      <StyledH2>{locale.photoClient.title}</StyledH2>
      <ImagesForm orderData={order} onChange={handleChange} />
      <StyledFormButtonBlock>
        <Button
          kind={'green'}
          fn={handleSubmitForm}
          text={locale.saveBtn}
          disabled={loading}
          loading={loading}
        />
      </StyledFormButtonBlock>
      {updateOrderImagesMessage.error ? (
        <StyledCustomError>{updateOrderImagesMessage.error}</StyledCustomError>
      ) : null}
    </>
  );
};
