export const getDaysInMonth = (dateObject) => {
  return (
    33 - new Date(dateObject.getFullYear(), dateObject.getMonth(), 33).getDate()
  );
};

export const getFirstDayOfTheMonth = (dateObject) => {
  const date = new Date(dateObject.toUTCString());
  date.setDate(1);
  return (date.getDay() || 7) - 1;
};

const setDateAtDayStart = (dateObject = null) => {
  const date = new Date(dateObject);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const setDateObject = (dateObject, day, month) => {
  const date = setDateAtDayStart(dateObject);
  if (Number.isInteger(month)) {
    const d = Number.isInteger(day) ? day : 1;
    date.setMonth(month, d);
  } else if (Number.isInteger(day)) date.setDate(day);
  return date;
};

export const setDateOfMonth = (dateObject, day, month) => {
  const date = setDateObject(dateObject, day, month);
  return date.toISOString();
};

export const clearDate = (iso) => {
  const date = new Date(iso);
  const offset = date.getTimezoneOffset();
  const hours = date.getHours();
  date.setHours(hours - offset / 60, 0, 0, 0);
  return date.toISOString();
};

export const isTodayDate = (date) => {
  const inputDate = new Date(date);
  return inputDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);
};
