import { StyledGrade, StyledGradeStar } from './styled';

export const Grade = ({
  grade,
  value,
  showValue = false,
  fn = null,
  ht = 16,
  isShortMode = false,
}) => {
  const handleClick = (val) => () => fn && fn(val);
  return (
    <StyledGrade active={!!fn}>
      <ul>
        {isShortMode ? (
          <StyledGradeStar grade={1} ht={ht} />
        ) : (
          [5, 4, 3, 2, 1].map((v) => (
            <StyledGradeStar
              active={!!fn}
              key={v}
              grade={grade >= v ? 1 : grade - v < -1 ? 0 : grade - v + 1}
              ht={ht}
              onClick={handleClick(v)}
            />
          ))
        )}
      </ul>
      {isShortMode ? <span>{grade}&nbsp;&nbsp;</span> : null}
      {showValue ? (
        <span className="isGray">{`(${
          value !== undefined ? value : grade
        })`}</span>
      ) : null}
    </StyledGrade>
  );
};
