import styled from 'styled-components';

import S from '../UI/variables';
import { StyledSelectArrow } from '../UI/Form/Select/styled';

export const StyledPanel = styled.li`
  height: 100%;
  flex-shrink: 0;
  margin-left: 12px;
  cursor: pointer;
  display: flex;

  ${StyledSelectArrow} {
    margin-left: 0;

    @media screen and (min-width: ${S.tablet}) {
      margin-left: 16px;
    }
  }

  @media screen and (min-width: ${S.tablet}) {
    margin-left: 36px;
  }
`;
