import { StyledH2 } from '../../Titles';
import { Form } from '../../Form';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { useDispatch } from 'react-redux';
import { addList, updateList } from '../../../Settings/Moderation/queries';
import { getModerationListFormConfig } from '../../../../Configs/formConfigs/moderationList';
import { InfoBlock } from '..';

export const AddModerationListModal = ({ onClose, onAdd, listData }) => {
  const [locale] = useLocale(L);
  const dispatch = useDispatch();

  const {
    messages: [addListMessage, updateListMessage],
    loading: [loadingAddList, loadingUpdateList],
  } = useMessages(['addModerationList', 'updateModerationList']);

  const handleAdd = (e, formData) => {
    addList(dispatch, formData, (...data) => {
      onAdd(...data);
      onClose();
    });
  };

  const handlePatch = (e, formData) => {
    updateList(dispatch, listData.Id, formData, (...data) => {
      onAdd(...data);
      onClose();
    });
  };

  return (
    <>
      <StyledH2>{listData ? locale.titleEdit : locale.title}</StyledH2>
      <Form
        {...getModerationListFormConfig(
          locale,
          listData
            ? { Name: listData.Name, Description: listData.Description }
            : {}
        )}
        onSubmit={listData ? handlePatch : handleAdd}
        loading={loadingAddList || loadingUpdateList}
        locale={locale}
        customError={addListMessage.error}
        buttonProps={{
          kind: 'green',
          text: listData ? locale.submitEdit : locale.submit,
        }}
      />
      <InfoBlock
        isError={true}
        message={addListMessage.error || updateListMessage.error}
      />
    </>
  );
};
