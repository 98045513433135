import { useState, useRef, useEffect } from 'react';

import {
  StyledAvatarInput,
  StyledPersonLogo,
  StyledPersonWrapper,
  StyledPicture,
} from './styled';
import { BASE_URL } from '../../../Configs/constants';

import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getLoading } from '../../../Redux/Selectors/MessageSelectors';
import { Preloader } from '../Preloader';
import { Button } from '../Buttons';
import { ReactComponent as TrashIcon } from '../../../Static/icons/console/actions/delete.svg';
import { rx } from '../../../Redux/Actions';
import { authMediaRx } from '../../../Redux/Actions/AuthActions/thunk';
import { setUserData } from '../../../Redux/Actions/AuthActions';
import {
  deleteAvatar,
  updateAvatar,
  updateEmployeeAvatar,
} from '../../../API/User';
import { useLocale } from '../../../Utils/Hooks/useLocale';
import L from './locale.json';

export const Avatar = ({
  gravatar = 'https://s.gravatar.com/avatar/9e2c8f8c4ffc6f67f5b183adc4d9feca?s=250&d=identicon',
  avatarURL,
  wd = 48,
  file = null,
  outline = false,
  color,
  customImage = false,
}) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [errorAvatar, setErrorAvatar] = useState(false);

  const firstError = useRef(avatarURL);

  const url = new URL(gravatar);
  url.searchParams.set('s', wd);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setUploadedFile(e.target.result);
      reader.readAsDataURL(file);
    } else setUploadedFile(null);
  }, [file]);

  const handleError = (err) => {
    setErrorAvatar(true);
  };

  useEffect(() => {
    if (avatarURL && errorAvatar && firstError.current !== avatarURL) {
      firstError.current = avatarURL;
      setErrorAvatar(false);
    }
  }, [avatarURL, errorAvatar, setErrorAvatar]);

  return (
    <StyledPersonWrapper>
      <StyledPersonLogo wd={wd} outline={outline} color={color}>
        {uploadedFile ? (
          <picture>
            <StyledPicture src={uploadedFile} alt="" />
          </picture>
        ) : (
          <picture>
            {avatarURL && !errorAvatar
              ? ['webp', 'png'].map((mime) => (
                  <source
                    srcSet={`${BASE_URL}/${avatarURL}.${mime}`}
                    type={`image/${mime}`}
                    key={mime}
                  />
                ))
              : null}
            <StyledPicture
              src={customImage || url.href}
              alt=""
              onError={handleError}
            />
          </picture>
        )}
      </StyledPersonLogo>
    </StyledPersonWrapper>
  );
};

export const AvatarLoader = ({
  _id,
  avatarURL,
  gravatar,
  toEmployee,
  onChange = () => null,
}) => {
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const [locale] = useLocale(L);

  const loading = useSelector(getLoading('updateAvatar'), shallowEqual);

  const handleDelete = () => {
    file && setFile(null);

    dispatch(
      rx(
        authMediaRx,
        'updateAvatar',
        (dispatch, { data }) => {
          data.data &&
            dispatch(setUserData({ avatarURL: data.user.avatarURL }));
        },
        deleteAvatar,
        {
          id: _id,
        }
      )
    );
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    setFile(file || null);

    if (toEmployee) {
      dispatch(
        rx(
          authMediaRx,
          'updateAvatar',
          (dispatch, { data }) => {
            if (data?.user) {
              onChange(data.user);
            }
          },
          updateEmployeeAvatar,
          {
            id: _id,
            body: {
              avatarURL: file,
            },
          }
        )
      );
    } else {
      dispatch(
        rx(
          authMediaRx,
          'updateAvatar',
          (dispatch, { data }) => {
            if (data?.user) {
              dispatch(setUserData({ avatarURL: data.user.avatarURL }));
            }
          },
          updateAvatar,
          {
            id: _id,
            body: {
              avatarURL: file,
            },
          }
        )
      );
    }
  };

  useEffect(() => () => setFile(null), []);

  return (
    <StyledPersonWrapper>
      <Avatar avatarURL={avatarURL} file={file} gravatar={gravatar} wd={64} />
      {loading ? <Preloader /> : null}
      <label>
        {locale.changePhoto}
        <StyledAvatarInput
          key={file ? file.name : 'default'}
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleChange}
        />
      </label>
      {avatarURL && !toEmployee ? (
        <Button
          fn={handleDelete}
          disabled={loading}
          kind={'circle'}
          Icon={TrashIcon}
        />
      ) : null}
    </StyledPersonWrapper>
  );
};

Avatar.propTypes = {
  gravatar: PropTypes.string,
  avatarURL: PropTypes.string,
};
