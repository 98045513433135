import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setWebpushGranted } from '../../Actions/WebpushActions';

const persistConfig = {
  key: 'webpush',
  storage,
};

const initialState = {
  granted: false,
  modified: false,
};

const webpushReducer = createReducer(initialState, (builder) => {
  builder.addCase(setWebpushGranted, (state, action) => {
    if (state.granted !== !!action.payload) {
      state.modified = true;
    }
    state.granted = !!action.payload;
  });
});

const webpushConfig = persistReducer(persistConfig, webpushReducer);

export default webpushConfig;
