import { StyledLayoutAuth, StyledLayoutAuthHeader } from './styled';
import { Flex } from '../../UI/Flex';
import { ReactComponent as LogoIcon } from '../../../Static/icons/logo.svg';
import { LanguagePicker } from '../../LanguagePicker';
import { StyledLanguagePicker } from '../../LanguagePicker/styled';

export const LayoutAuth = ({ loaded, children }) => {
  return (
    <StyledLayoutAuth loaded={loaded}>
      <StyledLayoutAuthHeader>
        <Flex justifyContent={'space-between'}>
          <div>
            <LogoIcon />
          </div>
          <div>
            <StyledLanguagePicker>
              <LanguagePicker />
            </StyledLanguagePicker>
          </div>
        </Flex>
      </StyledLayoutAuthHeader>
      {children}
    </StyledLayoutAuth>
  );
};
