import { useState } from 'react';
import { Input } from '../../../UI/Form/Input';
import {
  StyledServiceListWrapper,
  StyledCategoryList,
  StyledCategoryListItem,
  StyledServiceName,
  StyledCategory,
  StyledListOfServices,
  StyledServiceItem,
  StyledCategoryCount,
  StyledCheck,
  StyledServiceNameSpan,
  StyledTime,
  StyledPrice,
} from './styled';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { useMemo } from 'react';
import { WithTooltip } from '../../../UI/Tooltip';
import L from './locale.json';
import { toPrice } from '../../../../Utils/Helpers';
import { getTimeString } from '../../../Calendar/helpers';

const ServiceItem = ({ service, chosenServices, onChange }) => {
  const [, lang] = useLocale();
  const handleChange = () => {
    onChange(service._id);
  };
  const selected = chosenServices.includes(service._id);
  return (
    <StyledServiceItem selected={selected} onClick={handleChange}>
      <WithTooltip text={service.name[lang]} showOnOverflow>
        {(tooltipProps) => (
          <StyledServiceName {...tooltipProps}>
            <StyledServiceNameSpan>{service.name[lang]}</StyledServiceNameSpan>
          </StyledServiceName>
        )}
      </WithTooltip>
      {service?.time ? (
        <>
          <StyledTime>{getTimeString(service.time * 60000, lang)}</StyledTime>
        </>
      ) : null}
      {service?.price ? (
        <>
          <StyledPrice>{toPrice(service.price, lang)}</StyledPrice>
        </>
      ) : null}
      <StyledCheck>{selected ? ' ✓' : '  '}</StyledCheck>
    </StyledServiceItem>
  );
};

const CategoryItem = ({
  name,
  serviceType,
  services,
  index,
  expanded,
  setExpanded,
  chosenServices,
  onChange,
}) => {
  const chosenWithinCategory = useMemo(
    () => services.filter(({ _id }) => chosenServices.includes(_id)),
    [chosenServices, services]
  );

  return (
    <StyledCategoryListItem key={serviceType} expanded={expanded}>
      <StyledCategory
        onClick={() => setExpanded(expanded ? null : index)}
        expanded={expanded}
      >
        <StyledServiceName>
          {name}
          <StyledCategoryCount>{`${chosenWithinCategory.length}/${services.length}`}</StyledCategoryCount>
        </StyledServiceName>
      </StyledCategory>
      {services.length ? (
        <StyledListOfServices>
          {services.map((service) => {
            return (
              <ServiceItem
                key={service._id}
                service={service}
                chosenServices={chosenServices}
                onChange={onChange}
              />
            );
          })}
        </StyledListOfServices>
      ) : null}
    </StyledCategoryListItem>
  );
};

export const CategoryList = ({
  serviceList,
  className,
  chosenServices,
  onChange,
}) => {
  const [{ search: searchPlaceholder }] = useLocale(L);
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState(null);

  const categories = useMemo(() => {
    return Object.values(serviceList).map(
      ({ services, serviceType, name }) => ({
        serviceType,
        name,
        services: search
          ? services.filter(({ name }) =>
              Object.values(name).some((v) =>
                v.toLowerCase().includes(search.toLowerCase())
              )
            )
          : services,
      }),
      {}
    );
  }, [serviceList, search]);

  return (
    <StyledServiceListWrapper className={className}>
      <Input
        type="text"
        name="search"
        value={search}
        fn={({ target }) => setSearch(target.value)}
        placeholder={searchPlaceholder}
      />
      <StyledCategoryList>
        <StyledCategoryList>
          {categories.map((category, i) => (
            <CategoryItem
              key={category.serviceType}
              {...category}
              expanded={expanded === i}
              index={i}
              setExpanded={setExpanded}
              chosenServices={chosenServices}
              onChange={onChange}
            />
          ))}
        </StyledCategoryList>
      </StyledCategoryList>
    </StyledServiceListWrapper>
  );
};
