import { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage, clearMessage } from '../../Actions/MessageActions';
import { defaultMessageState } from '../../Reducers/MessageReducer';

export const getLoading =
  (type) =>
  ({ message }) =>
    message.loading.some((l) => l === type);

export const getMessage =
  (type) =>
  ({ message }) =>
    message.message[type] || defaultMessageState;

export const getLastMessage = ({ message }) =>
  message.message.last || defaultMessageState;

export const getLoadingByType =
  (types) =>
  ({ message }) =>
    types.map((t) => message.loading.some((l) => l === t));

export const useMessages = (typesArr, clearOnMount = true) => {
  const types = useRef(typesArr);
  const dispatch = useDispatch();
  const messages = useSelector(
    (state) => types.current.map((type) => getMessage(type)(state)),
    (p, n) =>
      p.every((m, i) => m.error === n[i].error && m.success === n[i].success)
  );
  const loading = useSelector(getLoadingByType(types.current), (p, n) =>
    p.every((m, i) => m === n[i])
  );

  const clear = useCallback(
    (payload) => {
      dispatch(clearMessage(payload || types.current));
    },
    [dispatch]
  );

  useEffect(() => {
    clearOnMount &&
      dispatch(
        setMessage(
          types.current.reduce(
            (acc, type) => ({
              ...acc,
              [type]: defaultMessageState,
            }),
            {}
          )
        )
      );
    return () => clear();
  }, [dispatch, clear, clearOnMount]);

  return { messages, loading, clear };
};
