import { API } from '../index';

export const endpoints = {
  GET_PROMOCODE: '/promocode',
  POST_PROMOCODE: '/promocode',
  PATCH_PROMOCODE: '/promocode',
  DELETE_PROMOCODE: '/promocode',
  GET_PROMOCODE_USER: '/promocode',
};

export const getPromoCodes = async (config) =>
  await API.get(endpoints.GET_PROMOCODE, config);

export const getPromoCodeUser = async (config, { name }) =>
  await API.get(endpoints.GET_PROMOCODE_USER + '/' + name, config);

export const postPromoCodes = async (config, { body }) =>
  await API.post(endpoints.POST_PROMOCODE, body, config);

export const patchPromoCodes = async (config, { body, id }) =>
  await API.patch(endpoints.PATCH_PROMOCODE + '/' + id, body, config);

export const deletePromoCode = async (config, { id }) =>
  await API.delete(endpoints.DELETE_PROMOCODE + '/' + id, config);
