import { Flex } from '../../../UI/Flex';
import { Button } from '../../../UI/Buttons';
import { useCallback, useState } from 'react';
import L from './locale.json';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { StyledH2 } from '../../../UI/Titles';
import { fieldDataCollection } from '../../../../Utils/Helpers/stypByStep';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { editGear, addGear, deleteGear } from '../../queries';
import { useDispatch } from 'react-redux';
import { MaxContainerXs } from '../../../UI/Containers';
import { StyledFormGroup } from '../../../UI/Form/styled';
import { Input } from '../../../UI/Form/Input';
import { CalcGroup } from '../../../UI/CalcGroup';
import { StyledLabel } from './styled';

const initialState = {
  basePrice: 0,
  pricePerDay: 1,
  power: 1,
};

const initialStateErrors = {
  basePrice: null,
  pricePerDay: null,
  power: null,
};

export const GearForm = ({ fn, onClose, gear }) => {
  const dispatch = useDispatch();
  const [locale, lang] = useLocale(L);

  const [inputs, setInputs] = useState(gear || initialState);
  const [errors, setErrors] = useState(initialStateErrors);

  const handleChange = (event) => {
    const { error, name, value } = fieldDataCollection(event, lang);
    setInputs((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const handleChangePower = useCallback((value) => {
    setInputs((prev) => ({ ...prev, power: value }));
    setErrors((prev) => ({ ...prev, power: null }));
  }, []);

  const handleResponse = (_, { data }) => {
    if (data?.searchBoostConfig) {
      fn(data);
      onClose();
    }
    if (data?.conflict && data.conflict in initialStateErrors) {
      setErrors((prev) => ({ ...prev, [data.conflict]: data.message }));
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const handler = gear ? editGear : addGear;
    handler(dispatch, inputs, handleResponse);
  };

  const handleSubmitDelete = (e) => {
    e.preventDefault();
    deleteGear(dispatch, gear?._id, handleResponse);
  };

  const {
    loading: [loadingEdit, loadingAdd, loadingDelete],
  } = useMessages([
    'updateGearInConfig',
    'addGearToConfig',
    'deleteGearFromConfig',
  ]);

  return (
    <>
      <Flex
        alignItems={'stretch'}
        flexDirection={'column'}
        spacer={{ bottom: '24px' }}
      >
        <div>
          <StyledH2>{gear ? locale.titleEdit : locale.titleAdd}</StyledH2>
        </div>
        <div>
          <form>
            <MaxContainerXs>
              <>
                <StyledLabel>{locale.power}</StyledLabel>
                <CalcGroup
                  minValue={1}
                  fn={handleChangePower}
                  value={gear?.power || 1}
                />
              </>
              <StyledFormGroup>
                <Input
                  type={'number'}
                  name={'basePrice'}
                  value={String(inputs.basePrice)}
                  min={0}
                  fn={handleChange}
                  errors={errors.basePrice}
                  placeholder={'0'}
                  label={locale.basePrice}
                  required={true}
                />
              </StyledFormGroup>
              <StyledFormGroup>
                <Input
                  type={'number'}
                  name={'pricePerDay'}
                  value={String(inputs.pricePerDay)}
                  fn={handleChange}
                  errors={errors.pricePerDay}
                  placeholder={'0'}
                  label={locale.pricePerDay}
                  required={true}
                />
              </StyledFormGroup>
            </MaxContainerXs>
          </form>
        </div>
        <div>
          <Flex
            justifyContent={'center'}
            spacer={{ left: '12px', bottom: '12px' }}
          >
            <div>
              <Button
                kind={'green'}
                disabled={
                  loadingAdd ||
                  loadingEdit ||
                  loadingDelete ||
                  Object.values(errors).some(Boolean)
                }
                loading={loadingEdit || loadingAdd}
                text={locale.save}
                fn={handleSubmitForm}
              />
            </div>
            {gear ? (
              <div>
                <Button
                  disabled={loadingAdd || loadingEdit || loadingDelete}
                  loading={loadingDelete}
                  text={locale.delete}
                  fn={handleSubmitDelete}
                />
              </div>
            ) : null}
          </Flex>
        </div>
      </Flex>
    </>
  );
};
