import { Suspense } from 'react';
import { ErrorBoundary } from '../../Components/UI/Errors';

import { Preloader } from '../../Components/UI/Preloader';

const WithSuspense = (Component) => (props) =>
  (
    <Suspense fallback={<Preloader />}>
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    </Suspense>
  );

export default WithSuspense;
