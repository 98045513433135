import styled from 'styled-components';
import S from '../../variables';
import { rgba } from 'polished';

export const StyledTabsPurple = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${S.purpleLanding};
  padding: 7px;
  border-radius: 15px;

  & > li {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export const StyledTabsPurpleLink = styled.a`
  display: block;
  color: ${S.white};
  transition: background-color ${S.trFast}, color ${S.trFast};
  padding: 10px;
  border-radius: 12px;

  &:hover:not(.active) {
    background-color: ${rgba(S.white, 0.2)};
  }

  &.active {
    color: ${S.textPrimary};
    background-color: ${S.white};
  }

  @media screen and (min-width: ${S.tablet}) {
    padding: 10px 48px;
  }
`;
