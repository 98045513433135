import styled from 'styled-components';
import S from '../../UI/variables';

export const StyledBellItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  padding: 10px 12px;
  height: 100%;

  @media screen and (min-width: ${S.tablet}) {
    margin-left: auto;
    padding: 10px 30px;
  }
`;

export const StyledBell = styled.li`
  display: flex;
  color: ${S.textPrimary};
  transition: color ${S.trFast};

  &:hover {
    color: ${S.purpleDark};
  }

  svg {
    fill: currentColor;
  }

  & > span {
    display: block;
    position: relative;

    & > span {
      color: ${S.white};
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 100%;
      left: 100%;
      transform: translate(-50%, 50%);
      border-radius: 20px;
      padding: 0 5px;
      min-width: 22px;
      height: 22px;
      background-color: ${S.purpleDark};
      font-size: 10px;
    }
  }
`;
