import styled, { css } from 'styled-components';
import S from '../../UI/variables';
import { StyledCard } from '../../UI/Card/styled';
import { StyledImageLabelDeleteImage } from '../../UI/Form/styled';
import { StyledPreloader } from '../../UI/Preloader/styled';
import { rgba } from 'polished';

export const StyledOrderContainer = styled.div`
  max-width: 566px;

  ${({ isLoading }) =>
    isLoading
      ? css`
          opacity: 0.5;
          pointer-events: none;
        `
      : ``}
`;

export const StyledOrderBlock = styled(StyledCard)`
  position: relative;
  overflow: hidden;
  box-shadow: ${S.mainShade};
  background-color: ${S.white};

  ${StyledPreloader} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: ${rgba(S.white, 0.5)};
    border-radius: 16px;
  }
`;

export const StyledOrderBlockTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  padding-right: 40px;
`;

export const StyledOrderBlockEdit = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  color: ${S.secondGray};
  cursor: pointer;
  width: 16px;

  @media screen and (min-width: ${S.tabletLandscape}) {
    top: 24px;
    right: 24px;
    width: 18px;
  }

  &:hover {
    color: ${S.purpleDark};
  }

  svg {
    fill: currentColor;
    width: 100%;
  }
`;

export const StyledOrderTotal = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${S.purpleDark};

  @media screen and (min-width: ${S.tablet}) {
    font-size: 24px;
  }
`;

export const StyledOrderParams = styled.div`
  display: flex;
  justify-content: ${({ type = 'flex-start' }) => type};
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-start')};
  word-break: break-word;
  font-size: 12px;

  @media screen and (min-width: ${S.tablet}) {
    font-size: 16px;
  }

  & > span {
    flex-shrink: 0;
    &:first-child {
      font-weight: ${({ type = 'flex-start' }) =>
        type === 'flex-start' ? '500' : '400'};
    }
    &:last-child {
      flex-shrink: 2;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;

      padding-left: ${({ type = 'flex-start' }) =>
        type === 'flex-start' ? '0px' : '20px'};
    }
  }

  &:not(:last-child) {
    margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : 12)}px;
  }
`;

export const StyledOrderImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-bottom: -8px;
`;

export const StyledOrderImageItem = styled.div`
  padding-left: 8px;
  margin-bottom: 8px;
  width: 25%;
`;

export const StyledOrderImageItemDelete = styled(StyledImageLabelDeleteImage)``;

export const StyledOrderImageRatio = styled.div`
  position: relative;
  padding-top: 100%;
  border-radius: 10px;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledCarImage = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  border-radius: 50%;
  overflow: hidden;
`;

export const StyledViewComment = styled.span`
  white-space: pre-line;
`