import {
  StyledErrorsWrapper,
  StyledInput,
  StyledInputLabel,
  StyledInputName,
  StyledInputWrapper,
} from '../styled';
import PropTypes from 'prop-types';

export const Input = ({
  name,
  value,
  fn = () => null,
  errors = '',
  placeholder = '',
  label,
  locale,
  innerRef,
  ...props
}) => {
  return (
    <StyledInputLabel>
      {label ? (
        typeof label === 'function' ? (
          <StyledInputName>
            {label(locale)}
            {props.required && '*'}
          </StyledInputName>
        ) : (
          <StyledInputName>
            {label}
            {props.required && '*'}
          </StyledInputName>
        )
      ) : null}
      <StyledInputWrapper>
        <StyledInput
          {...props}
          name={name}
          className={errors ? 'has-error' : ''}
          value={value}
          ref={innerRef}
          onInput={fn}
          placeholder={`${
            typeof placeholder === 'function'
              ? placeholder(locale)
              : placeholder || ''
          } ${props.required && placeholder ? '*' : ''}`}
        />
        <StyledErrorsWrapper errors={errors}>
          <span>{errors}</span>
        </StyledErrorsWrapper>
      </StyledInputWrapper>
    </StyledInputLabel>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  fn: PropTypes.func,
};
