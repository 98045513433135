import { useEffect, useRef } from 'react';
import {
  StyledCodeInput,
  StyledCodeWrapper,
  StyledInputLabel,
  StyledInputWrapper,
} from '../styled';

export const CodeInput = ({
  codeLength = 4,
  name,
  label,
  value = '',
  fn = () => null,
  errors = '',
  onSubmit,
  ...props
}) => {
  const refs = useRef([]);

  useEffect(() => {
    refs.current.forEach((ref, i) =>
      value.length < i
        ? ref.setAttribute('disabled', '')
        : ref.removeAttribute('disabled')
    );
    const current = refs.current[value.length];

    if (current) {
      current.focus();
    }
  }, [value]);

  useEffect(() => {
    const onBackspace = (e) => {
      if (e.key === 'Backspace') {
        const next = refs.current.findIndex((el) => el === e.target) - 1;
        if (next < 0) return;
        refs.current[next].focus();
      }
    };

    window.addEventListener('keydown', onBackspace);

    return () => window.removeEventListener('keydown', onBackspace);
  }, [value]);

  const handleInput = (e) => {
    const target = {
      type: 'code',
      name: 'code',
      value: refs.current
        .reduce((acc, { value }) => acc + value, '')
        .slice(0, codeLength),
    };
    fn({ target });
    const next = refs.current.findIndex((el) => el === e.target);
    if (next === codeLength - 1) return;
    refs.current[Math.min(next, value.length)].focus();
  };

  return (
    <StyledInputWrapper>
      {label && <StyledInputLabel>{label}</StyledInputLabel>}
      <StyledCodeWrapper>
        {Array.from({ length: codeLength }, (_, i) => (
          <StyledCodeInput
            ref={(el) => (refs.current[i] = el)}
            key={i}
            {...props}
            name={name}
            className={errors ? 'error' : ''}
            type="text"
            value={value[i] || ''}
            onInput={handleInput}
          />
        ))}
      </StyledCodeWrapper>
    </StyledInputWrapper>
  );
};
