import { useCallback, useEffect, useState } from 'react';
import { getCaretPosition, setCaretPosition } from '../Helpers';

export const useCarete = (inputRef, onChange, shift = {}) => {
  const [pos, setPos] = useState(0);
  const handleInput = useCallback(
    (...args) => {
      let caretPos = getCaretPosition(inputRef.current);
      caretPos += shift[caretPos] || 0;
      setPos(Math.min(caretPos, inputRef.current.value.length));
      onChange(...args);
    },
    [onChange, inputRef, shift]
  );

  useEffect(() => {
    inputRef.current && setCaretPosition(inputRef.current, pos);
  }, [pos, inputRef]);

  return handleInput;
};
