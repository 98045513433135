import { Input } from '../../../Components/UI/Form/Input';

export const defaultEditCompanyFormData = {
  companyName: '',
  companyAddress: '',
};

export const defaultEditCompanyFormErrors = {
  companyName: '',
  companyAddress: '',
};

export const initialEditCompanyFormState = {
  formData: defaultEditCompanyFormData,
  errors: defaultEditCompanyFormErrors,
};

export const setEditCompanyFormConfig = [
  {
    Element: Input,
    props: {
      name: 'companyName',
      label: (l) => l.placeholders.companyName,
      placeholder: (l) => l.placeholders.companyName,
      type: 'text',
      required: true,
    },
  },
  {
    Element: Input,
    props: {
      name: 'companyAddress',
      label: (l) => l.placeholders.address,
      placeholder: (l) => l.placeholders.address,
      type: 'text',
      required: true,
    },
  },
];
