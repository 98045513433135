import { API } from '../index';

export const endpoints = {
  GET_REVIEWS: '/review',
  GET_PARTNER_REVIEWS: '/review/partner',
  DELETE_REVIEW: '/review',
  DELETE_COMMENT: '/review',
  EDIT_REVIEW: '/review',
  EDIT_COMMENT: '/review',
  ADD_COMMENT: '/review',
};

export const getReviews = async (config) =>
  await API.get(endpoints.GET_REVIEWS, config);

export const getPartnerReviews = async (config, { id }) =>
  await API.get(`${endpoints.GET_PARTNER_REVIEWS}/${id}`, config);

export const getReview = async (config, { id }) =>
  await API.get(`${endpoints.GET_REVIEWS}/${id}`, config);

export const deleteReview = async (config, { id }) =>
  await API.delete(endpoints.DELETE_REVIEW + '/' + id, config);

export const deleteComment = async (config, { id, commentId }) =>
  await API.delete(
    endpoints.DELETE_COMMENT + '/' + id + '/' + commentId,
    config
  );

export const addReview = async (config, { body, id }) =>
  await API.post(endpoints.EDIT_REVIEW, body, config);

export const editReview = async (config, { body, id }) =>
  await API.patch(endpoints.EDIT_REVIEW + '/' + id, body, config);

export const addCommentToReview = async (config, { body, id }) =>
  await API.post(endpoints.ADD_COMMENT + '/' + id, body, config);

export const editComment = async (config, { body, id, commentId }) =>
  await API.patch(
    endpoints.EDIT_COMMENT + '/' + id + '/' + commentId,
    body,
    config
  );
