import styled from 'styled-components';
import S from '../../UI/variables';
import { StyledH1 } from '../../UI/Titles';

export const StyledCreateOrderFlex = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (min-width: ${S.tabletLandscape}) {
    flex-direction: row;
  }

  & > div {
    &:last-child {
      width: 100%;
    }
  }
`;

export const StyledCreateOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${StyledH1} {
    margin-bottom: 24px;

    & + div {
      margin-bottom: 16px;
    }
  }
`;

export const StyledCreateOrderBox = styled.div`
  box-shadow: 0 0 9px #efefef;
  padding: 32px 16px;
  border-radius: 7px;
  max-width: ${({ large }) => (large ? '100%' : '448px')};
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: ${S.tabletLandscape}) {
    padding: 32px 64px;
  }
`;
