import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as DateIcon } from '../../../../Static/icons/console/calendar/calendar.svg';
import { ReactComponent as SelectArrow } from '../../../../Static/icons/chevron-down.svg';

import {
  openSelect,
  closeSelect,
} from '../../../../Redux/Actions/SelectActions';
import {
  getSelect,
  checkToUpdateSelect,
} from '../../../../Redux/Selectors/SelectSelectors';

import {
  StyledPlaceholder,
  StyledSelect,
  StyledSelectArrow,
  StyledSelectDropdown,
  StyledSelectDropdownItem,
  StyledSelectIcon,
  StyledSelectLabel,
} from './styled';

import {
  StyledErrorsWrapper,
  StyledInputLabel,
  StyledInputName,
} from '../styled';

export const Select = ({
  kind = 'default',
  icon = false,
  label,
  name,
  options,
  value = null,
  fn = () => null,
  errors = '',
  active = false,
  predefined = null,
  disabled = false,
  locale,
}) => {
  const [selected, setSelected] = useState(value);

  const refInput = useRef(null);

  const open = useSelector(getSelect, checkToUpdateSelect([name]));
  const dispatch = useDispatch();

  const handleOpen = (e) => {
    if (predefined === null) {
      e.stopPropagation();
      open === name ? dispatch(closeSelect()) : dispatch(openSelect(name));
    }
  };

  const handleSelect = (index) => () => {
    setSelected(index);
    fn({
      target: {
        value: index,
        name: name,
      },
    });
  };

  const inputValue =
    options[predefined === null ? (active ? selected : value) : predefined];

  return (
    <StyledInputLabel onClick={handleOpen} disabled={disabled}>
      {label ? (
        typeof label === 'function' ? (
          <StyledInputName>{label(locale)}</StyledInputName>
        ) : (
          <StyledInputName>{label}</StyledInputName>
        )
      ) : null}
      <StyledSelect
        className={open === name ? 'open' : ''}
        enabled={predefined === null}
      >
        <StyledSelectLabel
          kind={kind}
          enabled={predefined === null}
          className={open === name ? 'open' : ''}
        >
          <input
            id={name}
            ref={refInput}
            name={name}
            type={'hidden'}
            value={selected}
          />
          {icon ? <StyledSelectIcon as={DateIcon} /> : null}
          <span>{inputValue}</span>
          <StyledSelectArrow
            as={SelectArrow}
            className={open === name ? 'open' : ''}
          />
        </StyledSelectLabel>
        <StyledSelectDropdown className={open === name ? 'open' : ''}>
          <ul>
            {options.map((opt, i) => (
              <StyledSelectDropdownItem
                key={i}
                onClick={handleSelect(i)}
                className={
                  (active ? i === selected : i === value) ? 'selected' : ''
                }
              >
                <span>{opt}</span>
              </StyledSelectDropdownItem>
            ))}
          </ul>
        </StyledSelectDropdown>
      </StyledSelect>
      {errors && typeof errors === 'string' ? (
        <StyledErrorsWrapper errors={errors}>
          <span className={errors ? 'error' : ''}>{errors}</span>
        </StyledErrorsWrapper>
      ) : null}
    </StyledInputLabel>
  );
};

export const SelectComponent = ({
  kind = 'default',
  icon = false,
  label,
  name,
  options,
  value = null,
  placeholder = null,
  onChange,
  fn,
  errors = '',
  disabled = false,
  valueComponent = null,
  openTop = false,
  align = 'right',
}) => {
  const [selected, setSelected] = useState(
    () => options.find((opt) => opt.value === (value?.value || value)) || value
  );

  const valueRef = useRef(value);
  const optionsRef = useRef(options);

  useEffect(() => {
    if (valueRef.current !== value || optionsRef.current !== options) {
      valueRef.current = value;
      optionsRef.current = options;
      setSelected(options.find((opt) => opt.value === (value?.value || value)));
    }
  }, [value, options]);

  const open = useSelector(getSelect, checkToUpdateSelect([name]));
  const dispatch = useDispatch();

  const dropdownRef = useRef(null);

  const handleOpen = (e) => {
    e.stopPropagation();
    if (open === name) {
      dispatch(closeSelect());
    } else {
      dispatch(openSelect(name));
      setTimeout(
        () =>
          dropdownRef.current
            ?.querySelector('.selected')
            ?.scrollIntoView({ block: 'center' }),
        150
      );
    }
  };

  const handleSelect = (opt) => (e) => {
    if (opt.disabled) {
      e.stopPropagation();
      return;
    }
    setSelected(opt);
    onChange?.(opt);
    fn?.({
      target: {
        value: opt.value,
        name,
      },
    });
  };

  return (
    <StyledInputLabel onClick={handleOpen} disabled={disabled}>
      {label ? <StyledInputName>{label}</StyledInputName> : null}
      <StyledSelect className={open === name ? 'open' : ''} enabled={true}>
        <StyledSelectLabel
          kind={kind}
          enabled={true}
          className={`${open === name ? 'open' : ''} 
          ${errors ? 'has-error' : ''}`}
        >
          {icon ? <StyledSelectIcon as={icon} /> : null}
          {selected ? (
            typeof valueComponent === 'function' ? (
              valueComponent(selected)
            ) : (
              <span>{selected.label}</span>
            )
          ) : (
            <StyledPlaceholder>{placeholder}</StyledPlaceholder>
          )}
          <StyledSelectArrow
            as={SelectArrow}
            className={open === name ? 'open' : ''}
          />
        </StyledSelectLabel>
        <StyledSelectDropdown
          className={open === name ? 'open' : ''}
          openTop={openTop}
          align={align}
          ref={dropdownRef}
        >
          <ul>
            {options.map((opt, i) => (
              <StyledSelectDropdownItem
                key={opt.value}
                onClick={handleSelect(opt)}
                className={selected?.value === opt.value ? 'selected' : ''}
              >
                {typeof valueComponent === 'function' ? (
                  valueComponent(opt)
                ) : (
                  <span>{opt.label}</span>
                )}
              </StyledSelectDropdownItem>
            ))}
          </ul>
        </StyledSelectDropdown>
      </StyledSelect>
      {errors && typeof errors === 'string' ? (
        <StyledErrorsWrapper errors={errors}>
          <span className={errors ? 'error' : ''}>{errors}</span>
        </StyledErrorsWrapper>
      ) : null}
    </StyledInputLabel>
  );
};
