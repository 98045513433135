import { StyledBannerImageWrapper } from './styled';
import { Flex } from '../../UI/Flex';
import { Form } from '../../UI/Form';
import { Button } from '../../UI/Buttons';
import { useMemo } from 'react';
import { BannerImage } from '../Partner/ToolItem';
import { useLocale } from '../../../Utils/Hooks/useLocale';
import { useDispatch } from 'react-redux';
import { Textarea } from '../../UI/Form/Textarea';
import L from './locale.json';
import { approveUserBanner } from '../queries';
import { useMessages } from '../../../Redux/Selectors/MessageSelectors';
import { Avatar } from '../../UI/Avatar';
import { CompanyAddress } from '../../CompanyAddress';
import { useNavigate } from 'react-router-dom';

export const BannerView = ({ banner, onClose, fn }) => {
  const {
    company: { companyName, companyAddress } = {},
    start,
    expires,
    created,
    approved,
  } = banner;

  const [locale, lang] = useLocale(L);
  const dispatch = useDispatch();

  const dates = useMemo(() => {
    const startDate = Math.max(
      Date.parse(created),
      Date.parse(start || 0),
      approved ? Date.parse(approved) : 0
    );
    const endDate = Date.parse(expires);

    return new Intl.DateTimeFormat(lang, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).formatRange(startDate, endDate);
  }, [lang, approved, created, expires, start]);

  const handleApprove = () => {
    approveUserBanner(dispatch, banner._id, (_, { data }) => {
      if (data?.data) {
        fn(data.banner);
        onClose();
      }
    });
  };

  const {
    loading: [loading],
  } = useMessages(['approveBanner']);

  return (
    <>
      <StyledBannerImageWrapper>
        <BannerImage {...banner} full size={325} />
      </StyledBannerImageWrapper>
      <Flex
        flexDirection={'column'}
        full={true}
        spacer={{ left: '16px', bottom: '16px' }}
      >
        {companyName ? (
          <div>
            <span>
              {locale.company}:&nbsp;{companyName}
            </span>
          </div>
        ) : null}
        {companyAddress ? (
          <div>
            <span>
              {locale.address}:&nbsp;{companyAddress}
            </span>
          </div>
        ) : null}
        <div>
          <div>
            <Flex
              justifyContent={'space-between'}
              spacer={{ left: '5px', bottom: '8px' }}
            >
              <span>{locale.offerDuration}</span>
              <div>{dates}</div>
            </Flex>
          </div>
        </div>
        <div>
          <Form
            initialState={{
              formData: banner,
              errors: {},
            }}
            formConfig={[
              {
                Element: Textarea,
                props: {
                  name: 'description',
                  label: locale.description,
                  readOnly: true,
                  disabled: true,
                },
              },
              {
                Element: Textarea,
                props: {
                  name: 'note',
                  label: locale.note,
                  readOnly: true,
                  disabled: true,
                },
              },
            ]}
            buttonSubmit={false}
          />
        </div>
        {approved ? null : (
          <div>
            <Flex
              full={true}
              justifyContent={'center'}
              spacer={{ left: '8px', bottom: '8px' }}
            >
              <div>
                <Button
                  kind={'green'}
                  text={locale.approve}
                  style={{ width: '100%' }}
                  fn={handleApprove}
                  disabled={loading}
                  loading={loading}
                />
              </div>
            </Flex>
          </div>
        )}
      </Flex>
    </>
  );
};

export const BannerUserView = ({ banner, onClose }) => {
  const { company, description } = banner;

  const [locale] = useLocale(L);
  const navigate = useNavigate();

  const handleBook = () => {
    navigate(`workshop/${banner.company._id}`, {
      state: { banner: banner._id },
    });
    onClose();
  };

  return (
    <>
      <StyledBannerImageWrapper>
        <BannerImage {...banner} full size={325} />
      </StyledBannerImageWrapper>
      <Flex
        flexDirection={'column'}
        full={true}
        spacer={{ left: '16px', bottom: '16px' }}
      >
        {company ? (
          <>
            <Flex>
              <div>
                <Avatar {...company} wd={50} />
              </div>
              &nbsp; &nbsp;
              <div>
                <span>{company.companyName || company.name || ' - '}</span>
              </div>
            </Flex>
            <CompanyAddress {...company} />
          </>
        ) : null}
        <div>
          <p>{description}</p>
        </div>
        <div>
          <Flex
            full={true}
            justifyContent={'center'}
            spacer={{ left: '8px', bottom: '8px' }}
          >
            <div>
              <Button
                kind={'green'}
                text={locale.book}
                style={{ width: '100%' }}
                fn={handleBook}
              />
            </div>
          </Flex>
        </div>
      </Flex>
    </>
  );
};
