import { Button } from '../../Buttons';
import { checkStep } from '../../../../Utils/Helpers/stypByStep';
import { Flex } from '../../Flex';
import { StyledStepsWrapper } from './styled';

export const StepsForm = ({
  step,
  disabledStep,
  disabledSteps,
  switchStep,
  config,
}) => {
  return (
    <StyledStepsWrapper>
      <Flex
        spacer={{
          left: '12px',
          bottom: '12px',
        }}
        justifyContent={'center'}
      >
        {config.map((objParams, index) => {
          const disabled = disabledSteps
            ? disabledSteps.some((s, i) => i <= index && s)
            : disabledStep;
          const canNavigate =
            disabledSteps && !disabledSteps.some((s, i) => i < index && s);
          return (
            <div key={index}>
              <Button
                kind={'step'}
                text={`${index + 1}`}
                classes={[...checkStep(index, step, disabled, canNavigate)]}
                fn={(event) => switchStep(index, event)}
              />
            </div>
          );
        })}
      </Flex>
    </StyledStepsWrapper>
  );
};
