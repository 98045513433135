import { createSelector } from 'reselect';

export const getNewOrderData = ({ newOrder }) => newOrder;

export const getNewOrderReadyData = createSelector(
  ({ newOrder }) => newOrder,
  ({
    client,
    phone,
    email,
    employees,
    start,
    number,
    vin,
    year,
    services,
    timeShift,
    companyComment,
    companyImages,
    step,
  }) => {
    return {
      client: {
        name: client,
        phone: `+370${phone ? phone.replace(/^\+370/, '') : ''}`,
        email,
      },
      employees,
      start,
      car: {
        number,
        vin,
        year,
      },
      services,
      timeShift,
      companyComment,
      companyImages,
      step,
    };
  }
);
