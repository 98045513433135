import { StyledH1, StyledH3 } from '../../Titles';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../Redux/Actions/ModalActions';
import { Flex } from '../../Flex';
import { StyledModalContainer, StyledModalSubtitle } from '../styled';
import { SelectedTile } from '../../SelectedTile';
import L from './locale.json';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { MaxContainerXs } from '../../Containers';
import { SearchBoost } from '../../../MarketingTools/SearchBoost';
import { AddBannerForm } from '../../../MarketingTools/AddBannerForm';
import { StyledTilesMarketing } from './styled';
import { BannerUserView, BannerView } from '../../../MarketingTools/BannerView';
import { PeriodForm } from '../../../MarketingTools/Admin/PeriodForm';
import { ConfigForm } from '../../../MarketingTools/Admin/ConfigForm';
import { BannerTemplateForm } from '../../../MarketingTools/Admin/BannerTemplateForm';
import { GearForm } from '../../../MarketingTools/Admin/GearForm';

export const AddMarketingTool = ({
  onClose,
  config: { searchBoostConfig, bannersConfig } = {},
  isAdmin = false,
  fn,
}) => {
  const [{ addMarketingTools: locale }] = useLocale(L);
  const dispatch = useDispatch();

  const handelOpenBannerForm = () => {
    onClose();

    setTimeout(() => {
      dispatch(
        openModal({
          Comp: 'addBanner',
          size: 'medium',
          data: {
            isAdmin,
            config: bannersConfig,
            fn: (banner) => fn({ banner }),
          },
        })
      );
    }, 300);
  };

  const handelOpenBoostForm = () => {
    onClose();

    setTimeout(() => {
      dispatch(
        openModal({
          Comp: 'addSearchBoost',
          size: 'medium',
          data: {
            isAdmin,
            config: searchBoostConfig,
            fn: (searchBoost) => fn({ searchBoost }),
          },
        })
      );
    }, 300);
  };

  return (
    <>
      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        spacer={{ left: '16px', bottom: '16px' }}
      >
        <div>
          <StyledH1>{locale.title}</StyledH1>
        </div>
        <div>
          <StyledH3>{locale.subtitle}</StyledH3>
        </div>
        <div>
          <StyledModalContainer>
            <StyledTilesMarketing>
              <div>
                <SelectedTile
                  title={searchBoostConfig.name}
                  description={searchBoostConfig.description}
                  benefits={searchBoostConfig.benefits}
                  fn={handelOpenBoostForm}
                />
              </div>
              <div>
                <SelectedTile
                  title={bannersConfig.name}
                  description={bannersConfig.description}
                  benefits={bannersConfig.benefits}
                  fn={handelOpenBannerForm}
                />
              </div>
            </StyledTilesMarketing>
          </StyledModalContainer>
        </div>
      </Flex>
    </>
  );
};

export const AddBanner = ({ config, onClose, isAdmin, fn }) => {
  return (
    <AddBannerForm
      config={config}
      fn={fn}
      onClose={onClose}
      isAdmin={isAdmin}
    />
  );
};

export const ApproveBanner = (props) => {
  return <BannerView {...props} />;
};

export const ViewBanner = (props) => {
  return <BannerUserView {...props} />;
};

export const BannerTemplate = (props) => {
  return <BannerTemplateForm {...props} />;
};

export const AddSearchBoost = ({ config, onClose, isAdmin, fn }) => {
  const [, lang] = useLocale();

  return (
    <>
      <MaxContainerXs>
        <Flex
          flexDirection={'column'}
          full={true}
          alignItems={'center'}
          spacer={{ left: '16px', bottom: '16px' }}
        >
          <div>
            <Flex justifyContent={'center'}>
              <StyledH1>{config.name[lang]}</StyledH1>
            </Flex>
          </div>
          <div>
            <StyledModalSubtitle>
              {config.description[lang]}
            </StyledModalSubtitle>
          </div>
          <div>
            <StyledModalContainer>
              <SearchBoost
                onSubmit={(data) => {
                  fn(data);
                  onClose();
                }}
                config={config}
                isAdmin={isAdmin}
              />
            </StyledModalContainer>
          </div>
        </Flex>
      </MaxContainerXs>
    </>
  );
};

export const BannerPeriod = (props) => {
  return (
    <StyledModalContainer>
      <PeriodForm {...props} />
    </StyledModalContainer>
  );
};

export const MarketingToolsConfig = (props) => {
  return (
    <StyledModalContainer>
      <ConfigForm {...props} />
    </StyledModalContainer>
  );
};

export const UpdateGear = (props) => {
  return (
    <StyledModalContainer>
      <GearForm {...props} />
    </StyledModalContainer>
  );
};
