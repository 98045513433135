import { StyledImage } from './styled';
import { useState } from 'react';
import { BASE_URL } from '../../../Configs/constants';
import defaultImage from '../../../Static/icons/not-image.svg';

export const ImageView = ({
  src,
  onClick,
  ImageComponent,
  imageProps,
  showThmbs,
  className,
}) => {
  const [errorImage, setErrorImage] = useState(null);

  const srcBase = `${BASE_URL}/${src}${showThmbs ? '_thmb' : ''}`;

  return (
    <picture onClick={onClick} className={className}>
      {src && !errorImage ? (
        <>
          <source srcSet={`${srcBase}.webp`} type={`image/webp`} />
          <source srcSet={`${srcBase}.png`} type={`image/png`} />
        </>
      ) : null}
      <StyledImage
        as={ImageComponent}
        src={errorImage || `${srcBase}.png`}
        alt=""
        onError={() => setErrorImage(defaultImage)}
        {...imageProps}
      />
    </picture>
  );
};
