import { createReducer } from '@reduxjs/toolkit';

import { setUsers } from '../../Actions/UsersActions';

const initialUsers = [];

const users = createReducer(initialUsers, (builder) => {
  builder.addCase(setUsers, (state, action) => {
    return [
      ...state.filter(({ role }) => role !== 'client'),
      ...action.payload,
    ];
  });
});

export default users;
