import { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserData } from '../../Redux/Selectors/AuthSelectors';
import { refetchUser } from '../../Components/queries';
import { useMessages } from '../../Redux/Selectors/MessageSelectors';

export const useAuthChange = () => {
  const dispatch = useDispatch();

  const { _id } = useSelector(getUserData);

  const firstMount = useRef(true);

  const {
    loading: [loading],
  } = useMessages(['initialFetch']);

  const refetch = useCallback(() => {
    refetchUser(dispatch, _id);
  }, [_id, dispatch]);

  useEffect(() => {
    if (firstMount.current && _id && !loading) {
      refetch();
    }
    firstMount.current = false;
  }, [refetch, _id, loading]);

  return refetch;
};
