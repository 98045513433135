import { useSelector, useDispatch } from 'react-redux';

import { rx } from '../../../../Redux/Actions';

import {
  getLoading,
  useMessages,
} from '../../../../Redux/Selectors/MessageSelectors';
import { getUserData } from '../../../../Redux/Selectors/AuthSelectors';

import { Button } from '../../Buttons';

import { logoutRx } from '../../../../Redux/Actions/AuthActions/thunk';

import L from './locale.json';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { StyledH1, StyledH2 } from '../../Titles';
import { Flex } from '../../Flex';
import { roles } from '../../../../Configs/constants';

export const LogoutModal = ({ onClose }) => {
  const loading = useSelector(getLoading('user-logout'));
  const {
    // messages: [message],
    clear,
  } = useMessages(['user-logout']);

  const { tokenAccess, tokenRefresh, name, companyName, role } =
    useSelector(getUserData);

  const [locale] = useLocale(L);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(
      rx(
        logoutRx,
        'user-logout',
        () => {
          onClose();
          clear();
        },
        tokenAccess,
        tokenRefresh
      )
    );
  };

  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      spacer={{ left: '12px', bottom: '16px' }}
    >
      <StyledH1>{role === roles.PARTNER ? companyName : name},</StyledH1>
      <StyledH2>{locale.confirm}</StyledH2>
      <div>
        <Button
          disabled={!!loading}
          fn={handleSubmit}
          kind="green"
          text={locale.button}
        />
      </div>
    </Flex>
  );
};
