import styled from 'styled-components';

import { StyledCommonButton } from '../styled';
import S from '../../variables';

export const StyledCloseButton = styled(StyledCommonButton)`
  width: 24px;
  height: 24px;
  border: none;

  &:hover {
    color: ${S.error};
  }

  & > svg {
    fill: currentColor;
    margin: auto;
  }
`;
