import styled from 'styled-components';
import S from '../../variables';

export const StyledCarInfoRow = styled.div`
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  & > span {
    font-weight: 400;
    margin-right: 4px;
    flex-shrink: 0;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const StyledCarInfoHead = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 22px;
  font-size: 20px;
  margin-bottom: 34px;

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 8px;
  }
`;

export const StyledCarInfoGroup = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: calc(100% + 40px);
      border-top: 1px solid ${S.strokeGray};
      transform: translateX(-50%);

      @media screen and (min-width: ${S.tablet}) {
        width: calc(100% + 124px);
      }
    }
  }
`;
