import { createReducer } from '@reduxjs/toolkit';

import { openSelect, closeSelect } from '../../Actions/SelectActions';

const select = createReducer({ value: '' }, (builder) => {
  builder
    .addCase(openSelect, (state, action) => {
      state.value = action.payload;
    })
    .addCase(closeSelect, (state) => {
      state.value = '';
    });
});

export default select;
