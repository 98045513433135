import { StyledH2 } from '../../Titles';
import { Input } from '../../Form/Input';
import { StyledCustomError, StyledFormGroup } from '../../Form/styled';
import { Select } from '../../Form/Select';
import { MaxContainerXs } from '../../Containers';
import { useMemo, useState } from 'react';
import { validation } from '../../../../Utils/validation';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import { Flex } from '../../Flex';
import { Button } from '../../Buttons';
import { StyledPeriodRow } from './styled';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { patchPromoCodes, postPromoCodes } from '../../../../API/PromoCodes';
import { useDispatch } from 'react-redux';
import {
  addStorePromoCode,
  updateStorePromoCode,
} from '../../../../Redux/Actions/PromoCodeActions';
import { parseMilliseconds } from '../../../../Utils/Helpers/promocodes';
import { selectPeriods, selectApplyFor } from '../../../../Configs/promocodes';
import L from './locale.json';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import { getPlural } from '../../../../Utils/Helpers/plural';

const SubmitPromoCode = ({ errors, edit, loading }) => {
  const [{ submit }] = useLocale(L);

  const disabled = useMemo(() => {
    return Object.values(errors).some((err) => err === null || err.length);
  }, [errors]);

  return (
    <Flex justifyContent={'center'}>
      <div>
        <Button
          loading={loading}
          kind={'green'}
          type={'submit'}
          text={edit ? submit.edit : submit.add}
          disabled={disabled || loading}
        />
      </div>
    </Flex>
  );
};

export const PromoCodeModal = ({ onClose, promoCode, edit = false }) => {
  const dispatch = useDispatch();
  const expiresParse = parseMilliseconds(promoCode?.expires);

  const {
    messages: [changePromoCodeMessages, addPromoCodeMessages],
    loading: [changePromoCodeLoading, addPromoCodeLoading],
  } = useMessages(['changePromoCode', 'addPromoCode']);

  const [locale, lang] = useLocale(L);
  const [inputs, setInputs] = useState({
    applyFor: promoCode?.applyFor
      ? selectApplyFor.findIndex(
          (el) => promoCode?.applyFor === el.toLowerCase()
        )
      : 0,
    name: promoCode?.name || '',
    discount: -promoCode?.discount || '',
    maxUsers: promoCode?.maxUsers || '',
    expires: typeof promoCode?.expires !== 'undefined' ? expiresParse[0] : 0,
    typePeriod: typeof promoCode?.expires !== 'undefined' ? expiresParse[1] : 0,
  });

  const [errors, setErrors] = useState({
    name: promoCode?.name ? '' : null,
    discount: promoCode?.discount ? '' : null,
    maxUsers: promoCode?.maxUsers ? '' : null,
    expires: typeof promoCode?.expires !== 'undefined' ? '' : null,
  });

  const handleChange = (e) => {
    const { value, error, name } = validation(e.target, lang);

    setInputs((prevState) => ({ ...prevState, [name]: value }));

    setErrors((prevState) => ({ ...prevState, [name]: error }));
  };

  const handleChangePeriod = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      expires: '0',
    }));

    setErrors((prevState) => ({
      ...prevState,
      expires: '',
    }));
  };

  const handleAddPromoCode = (e) => {
    e.preventDefault();

    const discount = -Number(inputs.discount);
    const expires =
      inputs.typePeriod === 0
        ? Number(inputs.expires) * 60 * 60 * 1000
        : inputs.typePeriod === 1
        ? Number(inputs.expires) * 24 * 60 * 60 * 1000
        : inputs.typePeriod === 2
        ? Number(inputs.expires) * 30 * 24 * 60 * 60 * 1000
        : Number(inputs.expires) * 12 * 30 * 24 * 60 * 60 * 1000;

    if (edit) {
      dispatch(
        rx(
          authRx,
          'changePromoCode',
          (_, { data: { promoCode } }) => {
            if (promoCode) {
              dispatch(updateStorePromoCode(promoCode));
              onClose();
            }
          },
          patchPromoCodes,
          {
            id: promoCode._id,
            body: {
              maxUsers: Number(inputs.maxUsers),
              discount,
              expires,
            },
          }
        )
      );
    } else {
      dispatch(
        rx(
          authRx,
          'addPromoCode',
          (_, { data: { promoCode } }) => {
            if (promoCode) {
              dispatch(addStorePromoCode(promoCode));
              onClose();
            }
          },
          postPromoCodes,
          {
            body: {
              maxUsers: Number(inputs.maxUsers),
              discount,
              expires,
              name: inputs.name,
              applyFor: selectApplyFor[inputs.applyFor].toLowerCase(),
            },
          }
        )
      );
    }
  };

  return (
    <>
      <StyledH2>{edit ? locale.title.edit : locale.title.add}</StyledH2>
      <MaxContainerXs>
        <form onSubmit={handleAddPromoCode} noValidate={true}>
          <StyledFormGroup>
            <Select
              disabled={edit}
              kind={'form'}
              name={'applyFor'}
              label={locale.placeholders.selectApplyFor}
              fn={handleChange}
              value={inputs.applyFor}
              options={selectApplyFor.map((name) => locale.applyFor[name])}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <Input
              name={'name'}
              label={locale.placeholders.name}
              disabled={edit}
              placeholder={locale.placeholders.name}
              type={'text'}
              fn={handleChange}
              required={true}
              errors={errors.name}
              value={inputs.name}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <Input
              name={'discount'}
              label={locale.placeholders.discount}
              placeholder={locale.placeholders.discount}
              type={'number'}
              fn={handleChange}
              required={true}
              min={-100}
              max={-1}
              errors={errors.discount}
              value={String(inputs.discount)}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledPeriodRow>
              <Flex
                spacer={{ left: '12px', bottom: '12px' }}
                full={true}
                alignItems={'flex-end'}
              >
                <div>
                  <Input
                    name={'expires'}
                    label={locale.placeholders.expires}
                    placeholder={locale.placeholders.expires}
                    type={'number'}
                    fn={handleChange}
                    required={true}
                    min={0}
                    max={
                      inputs.typePeriod === 0
                        ? 24
                        : inputs.typePeriod === 1
                        ? 30
                        : 12
                    }
                    errors={errors.expires}
                    value={String(inputs.expires)}
                  />
                </div>
                <div>
                  <Select
                    kind={'form'}
                    name={'typePeriod'}
                    fn={handleChangePeriod}
                    value={inputs.typePeriod}
                    options={selectPeriods.map((period) =>
                      getPlural(inputs.expires, locale.expires[period])
                    )}
                  />
                </div>
              </Flex>
            </StyledPeriodRow>
          </StyledFormGroup>
          <StyledFormGroup>
            <Input
              name={'maxUsers'}
              label={locale.placeholders.maxUsers}
              placeholder={locale.placeholders.maxUsers}
              type={'number'}
              fn={handleChange}
              required={true}
              min={0}
              errors={errors.maxUsers}
              value={String(inputs.maxUsers)}
            />
          </StyledFormGroup>
          <StyledCustomError>
            {changePromoCodeMessages.error || addPromoCodeMessages.error}
          </StyledCustomError>
          <StyledFormGroup>
            <SubmitPromoCode
              errors={errors}
              edit={edit}
              loading={changePromoCodeLoading || addPromoCodeLoading}
            />
          </StyledFormGroup>
        </form>
      </MaxContainerXs>
    </>
  );
};
