import { useReducer, useRef } from 'react';

import { Button } from '../Buttons';

import {
  StyledCustomError,
  StyledFormButtonBlock,
  StyledFormGroup,
  StyledForm,
} from './styled';

import { memoize } from '../../../Utils/HOC/memoize';
import { validation } from '../../../Utils/validation';
import { FormReducer } from '../../../Configs/formConfigs';
import { useLocale } from '../../../Utils/Hooks/useLocale';

const Memoized = memoize((prev, next) => {
  return prev.value === next.value && prev.errors === next.errors;
});

export const Form = ({
  reducer = FormReducer,
  initialState,
  formConfig,
  as = 'form',
  buttonProps,
  buttonSubmit = true,
  onSubmit = () => null,
  loading = false,
  locale,
  fnOnChangField = () => null,
  customError = null,
  clearOrSubmit = false,
}) => {
  const [{ errors, formData }, dispatch] = useReducer(reducer, initialState);

  const [, lang] = useLocale();

  const formRef = useRef(null);

  const submitSelf = () => formRef.current.click();

  const handleChangeForm = (event) => {
    const type = event.target.name;

    const { value: payload = '', error = '' } = validation(event.target, lang);

    dispatch({ type, payload, name: type });

    dispatch({
      type: `error/${type}`,
      payload: error,
      name: type,
    });

    fnOnChangField(event, formData, errors);
  };

  const checkValidation = () => {
    let success = false;

    Object.keys(formData).forEach((key, index) => {
      const config = {
        value: formData[key],
        name: key,
        ...formConfig[index].props,
      };

      const { value: payload, error } = validation(config, lang);

      dispatch({
        type: key,
        payload,
        name: key,
      });

      dispatch({
        type: `error/${key}`,
        payload: error,
        name: key,
      });

      success = !error.length;
    });

    return success;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (checkValidation()) {
      onSubmit(e, formData);
      clearOrSubmit && dispatch({ type: 'clear', payload: initialState });
    }
  };

  return (
    <StyledForm as={as} onSubmit={handleSubmit} noValidate={true}>
      {formConfig.map(({ Element, props, style }, i) => (
        <StyledFormGroup key={i} style={style}>
          <Memoized value={formData[props.name]} errors={errors[props.name]}>
            <Element
              {...props}
              fn={handleChangeForm}
              value={formData[props.name]}
              errors={errors[props.name]}
              locale={locale}
              onSubmit={submitSelf}
            />
          </Memoized>
        </StyledFormGroup>
      ))}
      {customError ? (
        <StyledCustomError>{customError}</StyledCustomError>
      ) : null}

      {buttonSubmit ? (
        <StyledFormButtonBlock>
          <Button
            loading={loading}
            disabled={
              !!loading || Object.values(errors).some((err) => err.length)
            }
            type={'submit'}
            {...buttonProps}
            ref={formRef}
          />
        </StyledFormButtonBlock>
      ) : null}
    </StyledForm>
  );
};
