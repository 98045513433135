import styled from 'styled-components';
import { StyledCommonButton } from '../../UI/Buttons/styled';
import S from '../../UI/variables';

export const StyledEditNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -8px;
  line-height: 2.1;
`;

export const StyledSendAgainBtn = styled(StyledCommonButton)`
  border: 0;
  font-size: 20px;
  margin-top: 56px;
  text-align: center;
  display: block;
  width: 100%;
  font-weight: 600;
  color: ${S.greenDark};

  &[disabled] {
    color: ${S.secondGray};
  }

  &:hover {
    color: ${S.purpleLanding};
  }
`;
