import { MainContainer } from '../UI/Containers';
import {
  StyledMainWithSidebar,
  StyledConsoleMain,
  StyledMainShade,
} from './styled';

import { Sidebar } from '../Sidebar';
import { Outlet } from 'react-router-dom';
import { MessagePopUp } from '../UI/MessagePopUp';

export const MainWithSidebar = ({ Auth }) => {
  return (
    <StyledMainWithSidebar className="main-with-sidebar">
      <StyledMainShade></StyledMainShade>
      {Auth ? <Sidebar Auth={Auth} /> : null}
      <StyledConsoleMain>
        <MainContainer>
          <MessagePopUp />
          <Outlet />
        </MainContainer>
      </StyledConsoleMain>
    </StyledMainWithSidebar>
  );
};
