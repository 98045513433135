import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';

const getStorePromoCodes = createAction(types.GET_PROMOCODES);

const addStorePromoCode = createAction(types.ADD_PROMOCODE);

const updateStorePromoCode = createAction(types.UPDATE_PROMOCODE);

const deleteStorePromoCode = createAction(types.DELETE_PROMOCODE);

const resetPromocodes = createAction(types.RESET_PROMOCODES);

export {
  getStorePromoCodes,
  addStorePromoCode,
  updateStorePromoCode,
  deleteStorePromoCode,
  resetPromocodes,
};
