export const getServiceList = (services, lang, partnerServices = []) => {
  const lookup = partnerServices.reduce(
    (acc, service) =>
      service?.service?._id ? { ...acc, [service.service._id]: service } : acc,
    {}
  );
  return services.reduce((acc, service) => {
    if (partnerServices.length && !lookup[service._id]) {
      return acc;
    }
    acc[service.type] = {
      ...acc[service.type],
      serviceType: service.type,
      name: service.typeName[lang],
      services: [
        ...(acc[service.type]?.services || []),
        {
          ...service,
          price: lookup[service._id]?.price,
          time: lookup[service._id]?.time,
        },
      ],
    };
    return acc;
  }, {});
};
