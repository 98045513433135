import { useEffect, useRef, useCallback } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { addFCMToken, deleteFCMToken } from '../../API/User';
import { authQuery, query } from '../../API';
import { useDispatch, useSelector } from 'react-redux';
import { setWebpushGranted } from '../../Redux/Actions/WebpushActions';

const firebaseConfig = {
  apiKey: 'AIzaSyAakOpj7nzGsyktsJDBxQ6C1hQG_6-5MSE',
  authDomain: 'fairfix-357fe.firebaseapp.com',
  projectId: 'fairfix-357fe',
  storageBucket: 'fairfix-357fe.appspot.com',
  messagingSenderId: '509228188209',
  appId: '1:509228188209:web:88607c7a3df231b80a0b10',
  measurementId: 'G-KP068DE2ZH',
};

const app = initializeApp(firebaseConfig);

//const analytics = getAnalytics(app);
const messaging = getMessaging(app);

const handlers = new Map();

onMessage(messaging, (payload) => {
  handlers.forEach(([id, handler]) => handler(payload));
});

const VAPID_KEY =
  'BNps6O-RN4SIOo3RgMZy8uBd_Ho7VyazrCxEm-nG4OnQJaOghZ2xVwk_Vm5ZkVAEO4AjJq0EAajTA1NYtzCF5x0';

export const useFirebaseMessages = () => {
  const init = useRef(false);
  const dispatch = useDispatch();
  const initializeFirebaseMessaging = useCallback(async () => {
    if (!init.current) {
      init.current = true;
      navigator.permissions
        .query({ name: 'push', userVisibleOnly: true })
        .then(function (status) {
          dispatch(setWebpushGranted(status.state === 'granted'));
          status.onchange = function () {
            dispatch(setWebpushGranted(this.state === 'granted'));
          };
        });
    }

    try {
      const permission = await Notification.requestPermission();

      if (permission === 'granted') {
        const token = await getToken(messaging, {
          vapidKey: VAPID_KEY,
        });

        if (token) {
          await authQuery(addFCMToken, { FCMToken: token });
          return token;
        }
      }
    } catch (err) {
      console.log('ERROR initializeFirebaseMessaging', err);
      return null;
    }
  }, [dispatch]);

  const subscribe = useCallback((handler, id) => {
    if (typeof id === 'string' && typeof handler === 'function') {
      handlers.set(id, handler);
      return () => handlers.delete(id);
    }
  }, []);

  return { subscribe, initializeFirebaseMessaging };
};

export const FirebaseMessages = ({ Auth }) => {
  const tokenRef = useRef(null);
  const initializedRef = useRef(false);
  const { granted } = useSelector(({ webpush }) => webpush);

  const { initializeFirebaseMessaging } = useFirebaseMessages();

  useEffect(() => {
    if (!Auth) {
      tokenRef.current && query(deleteFCMToken, tokenRef.current);
      tokenRef.current = null;
      initializedRef.current = false;
      return;
    }
    if (!granted) {
      tokenRef.current && query(deleteFCMToken, tokenRef.current);
      tokenRef.current = null;
    }
    if (!initializedRef.current) {
      initializedRef.current = true;

      initializeFirebaseMessaging().then((token) => {
        tokenRef.current = token;
      });
    }
  }, [Auth, initializeFirebaseMessaging, granted]);

  return null;
};
