import { parsers } from '../Configs/parsers';
import L from './generalLocale.json';

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexURL =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&/=]*)/g;

const isEmail = (value) =>
  String(value).length ? !!String(value).toLowerCase().match(regexEmail) : true;

const isLink = (value) => (String(value).length ? value.match(regexURL) : true);

export const validation = (input, lang = 'lt') => {
  const { validation: LangError } = L[lang];

  const {
    type,
    value: v,
    required,
    name,
    min,
    max,
    maxLength,
    minLength,
  } = input;

  const minimum = min ? Number(min) : 0;
  const maximum = max ? Number(max) : null;

  const minimumLength = minLength !== -1 ? Number(minLength) : null;
  const maximumLength = maxLength !== -1 ? Number(maxLength) : 1000;

  const parse = parsers[name] || ((v) => v);

  const value = parse(v);

  let error = '';

  if (required && !String(value).length) {
    error = LangError.required;
  } else {
    switch (type) {
      case 'text': {
        if (name === 'vat' && value && !value.match(/^LT/)) {
          error = LangError.vat;
        }
        if (name === 'vin' && value.length !== 17) {
          error = LangError.vin;
        }
        if (name === 'number') {
          if (value.length !== 6) {
            error = LangError.numberPlateLength;
          }
          if (value.length === 6 && !value.match(/^[A-Z]{3}[0-9]{3}$/)) {
            error = LangError.numberPlateFormat;
          }
        }
        break;
      }
      case 'code':
        error = value.length < 4 ? LangError.code : '';
        break;
      case 'number':
        const n = Number(value);
        error =
          n > maximum && maximum
            ? `${LangError.numberMin} ${maximum}`
            : n < minimum
            ? `${LangError.numberMax} ${minimum}`
            : value.length > maximumLength
            ? `${LangError.numberLengthMax} ${maximumLength}`
            : value.length < minimumLength && minimumLength
            ? `${LangError.numberLengthMin} ${minimumLength}`
            : '';
        break;
      case 'email':
        error = !isEmail(value) ? LangError.format : '';
        break;
      case 'tel':
        error =
          value.length > maximumLength
            ? `${LangError.numberLengthMax} ${maximumLength}`
            : value.length < minimumLength && minimumLength
            ? `${LangError.numberLengthMin} ${minimumLength}`
            : '';
        break;
      case 'url':
        error = !isLink(value) ? LangError.url : '';
        break;
      case 'vin':
        error = LangError.vin;
        break;
      default:
        if (name === 'monthYear') {
          if (value.length === 4) {
            const [month, year] = v.split('/');
            const dateYear = new Date().getFullYear().toString().slice(-2);

            error =
              Number(month) < 1
                ? LangError.monthMin
                : Number(month) > 12
                ? LangError.monthMax
                : Number(year) < Number(dateYear)
                ? LangError.yearMin
                : Number(year) > 99
                ? LangError.yearMax
                : '';
          }
        }

        if (error.length === 0) {
          error =
            value.length > maximumLength
              ? `${LangError.numberLengthMax} ${maximumLength}`
              : value.length < minimumLength && minimumLength
              ? `${LangError.numberLengthMin} ${minimumLength}`
              : '';
        }
    }
  }

  return { value, error, name, v };
};
