import { createReducer } from '@reduxjs/toolkit';

import {
  getStorePromoCodes,
  addStorePromoCode,
  updateStorePromoCode,
  deleteStorePromoCode,
  resetPromocodes,
} from '../../Actions/PromoCodeActions';

const initialState = [];

const promoCodes = createReducer(initialState, (builder) => {
  builder
    .addCase(getStorePromoCodes, (state, action) => {
      return action.payload;
    })
    .addCase(addStorePromoCode, (state, action) => {
      return [action.payload, ...state];
    })
    .addCase(updateStorePromoCode, (state, action) => {
      return state.map((promocode) =>
        promocode._id === action.payload._id ? action.payload : promocode
      );
    })
    .addCase(deleteStorePromoCode, (state, action) => {
      return state.filter((promocode) => promocode._id !== action.payload);
    })
    .addCase(resetPromocodes, (state, action) => {
      return initialState;
    });
});

export default promoCodes;
