import { useDispatch } from 'react-redux';
import { SelectUser } from '../../../UI/Form/SelectUser';
import { useRef, useState } from 'react';
import { rx } from '../../../../Redux/Actions';
import { authRx } from '../../../../Redux/Actions/AuthActions/thunk';
import { getPartners } from '../../../../API/User';
import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from './locale.json';
import { useMessages } from '../../../../Redux/Selectors/MessageSelectors';
import LogoIcon from '../../../../Static/icons/logo2.svg';

const noPartnerOption = {
  _id: null,
  companyName: 'FairFix',
  customImage: LogoIcon,
};

export const PartnerSelect = ({ onChange }) => {
  const dispatch = useDispatch();
  const firstMount = useRef(true);
  const [partners, setPartners] = useState([noPartnerOption]);
  const {
    loading: [loading],
  } = useMessages(['getPartners']);
  const [locale] = useLocale(L);

  const handleLoadPartners = () => {
    if (firstMount.current) {
      dispatch(
        rx(
          authRx,
          'getPartners',
          (_, { data }) =>
            data?.partners && setPartners([noPartnerOption, ...data.partners]),
          getPartners
        )
      );
      firstMount.current = false;
    }
  };

  const onChangePartner = ({ _id }) => {
    onChange(_id);
  };

  return (
    <SelectUser
      list={partners}
      kind={'form'}
      loadingList={loading}
      triggerOpen={handleLoadPartners}
      name={'company'}
      defaultSelected={noPartnerOption}
      fn={onChangePartner}
      label={locale.select}
      emptyOption={false}
    />
  );
};
