import { DatePicker } from '../../../UI/DatePicker';
import { StyledErrorsWrapper } from '../../../UI/Form/styled';

export const Step2 = ({ inputs, handleChange, errors }) => {
  const handleChangeDate = ([dateFrom, dateTo]) => {
    handleChange({
      target: {
        value: dateFrom,
        name: 'start',
        type: 'date',
        required: true,
      },
    });
    handleChange({
      target: {
        value:
          dateFrom && dateTo
            ? Math.floor(
                (Date.parse(dateTo) - Date.parse(dateFrom)) /
                  (24 * 60 * 60 * 1000)
              )
            : 0,
        name: 'days',
        type: 'number',
        required: true,
      },
    });
  };

  return (
    <>
      <DatePicker
        fn={handleChangeDate}
        selectRange
        minDate={new Date()}
        selectedDate={[
          inputs.start ? new Date(inputs.start) : null,
          inputs.start
            ? new Date(
                Date.parse(inputs.start) +
                  (inputs.days || 0) * 24 * 60 * 60 * 1000
              )
            : null,
        ]}
      />
      <StyledErrorsWrapper errors={errors.start}>
        <span>{errors.start}</span>
      </StyledErrorsWrapper>
      <StyledErrorsWrapper errors={errors.days}>
        <span>{errors.days}</span>
      </StyledErrorsWrapper>
    </>
  );
};
