import { useLocale } from '../../../../Utils/Hooks/useLocale';
import L from '../../../Auth/locale.json';
import { NavLink } from 'react-router-dom';
import { StyledTabsPurple, StyledTabsPurpleLink } from './styled';

export const TabsPurple = ({ links }) => {
  const [locale] = useLocale(L);

  if (links) {
    return (
      <StyledTabsPurple>
        {links.map(({ link, name, end, skipTabs }, i) =>
          typeof link !== 'undefined' && !skipTabs ? (
            <li key={i}>
              <StyledTabsPurpleLink as={NavLink} to={link} end={end}>
                {locale?.[name] || name}
              </StyledTabsPurpleLink>
            </li>
          ) : null
        )}
      </StyledTabsPurple>
    );
  }
};
