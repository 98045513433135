import styled from 'styled-components';
import S from '../variables';

export const StyledCard = styled.div`
  position: relative;
  min-height: 100%;
  padding: 24px 24px 32px;
  border-radius: 7px;
  box-shadow: ${({ shadow }) => (shadow ? S.mainShade : 'none')};
  background-color: ${({ shadow }) => (shadow ? S.white : S.yellow)};
`;

export const StyledCardRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const StyledCardHead = styled.div`
  margin-left: -16px;
  margin-bottom: -16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @media screen and (min-width: ${S.tabletLandscape}) {
    flex-direction: row;
    align-items: flex-start;
  }

  & > div {
    padding-left: 16px;
    margin-bottom: 16px;
  }
`;

export const StyledCardText = styled.span`
  display: block;
  font-size: 15px;
  line-height: 1.34;
  max-width: ${({ maxWidth }) => (maxWidth ? '651px' : 'none')};
`;

export const StyledCardName = styled.a`
  display: block;
  font-size: 18px;
  line-height: 1.21;
  font-weight: 500;
  color: ${S.textPrimary};
  text-decoration: none;
  transition: color ${S.trFast};

  @media screen and (min-width: ${S.tablet}) {
    font-size: 20px;
  }

  @media screen and (min-width: ${S.tabletLandscape}) {
    font-size: 24px;
  }

  &[href] {
    &:hover {
      color: ${S.purpleDark};
    }
  }
`;
