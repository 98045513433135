import styled, { css } from 'styled-components';
import S from '../UI/variables';

export const StyledLocationLink = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${S.textPrimary};
  text-decoration: none;
  transition: color ${S.trFast};
  font-size: 14px;

  & > svg {
    flex-shrink: 0;
  }

  &:hover {
    color: ${S.purpleDark};
  }

  ${({ noWrap }) =>
    noWrap
      ? css`
          width: 100%;
          & > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        `
      : ''}

  @media screen and (min-width: ${S.tablet}) {
    font-size: 16px;
  }
`;
