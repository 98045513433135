import styled, { css } from 'styled-components';
import S from '../../UI/variables';
import {
  StyledPromoCodeItemDate,
  StyledPromoCodeItemInfo,
  StyledPromoCodeItemName,
} from '../../Settings/PromoCode/PromoCodeItem/styled';

export const StyledEmptyTools = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: ${S.lightGray};
  border-radius: 15px;
  font-size: 20px;
  width: fit-content;
  line-height: 1.8;

  & > span:last-child {
    font-weight: 500;
  }
`;

export const StyledToolsList = styled.ul`
  margin: 16px 0;
  & > li:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const StyledTool = styled.div`
  padding: 16px;
  border-radius: 16px;
  background-color: ${S.white};
  box-shadow: ${S.mainShade};
  max-width: 678px;
`;

export const StyledToolFlex = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (min-width: ${S.phone}) {
    flex-direction: row;
  }
`;

export const StyledToolPicture = styled.div`
  position: relative;
  flex-shrink: 0;
  max-width: ${({ full, size }) => size || (full ? 200 : 100)}px;
  max-height: ${({ full, size }) => size || (full ? 200 : 100)}px;
  background-color: ${S.lightGray};
  border-radius: ${({ fontSize }) => fontSize}px;
  overflow: hidden;
  will-change: filter;
  filter: hue-rotate(${({ hue }) => hue || 0}deg);

  ${({ clipPath, power = 0 }) =>
    clipPath
      ? css`
          clip-path: ${clipPath};
          background-image: conic-gradient(
            yellow,
            ${S.greenLight} ${power}%,
            transparent ${power}%,
            transparent
          );
        `
      : ''}

  ${({ margin = true }) =>
    margin
      ? css`
          margin: 0 0 12px 0;
          @media screen and (min-width: ${S.phone}) {
            margin: 0 12px 0 0;
          }
        `
      : ''}

  @media screen and (min-width: ${S.tablet}) {
    width: ${({ full, size }) => size || (full ? 379 : 142)}px;
    height: ${({ full, size }) => size || (full ? 379 : 142)}px;
  }

  & > span {
    caret-color: black;
    outline: none;
    border: none;
    white-space: pre-line;
    display: block;
    padding: ${({ fontSize }) => fontSize / 2}px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    font-size: ${({ fontSize }) => fontSize || 12}px;
    font-weight: 500;
    text-shadow: -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black,
      1px 1px 0px black;
  }
`;

export const StyledTemplateImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledToolData = styled.div`
  width: 100%;
  padding-right: 50px;
`;

export const StyledToolsWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 32px;
  }
`;

export const StyledStatusWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const StyledToolDate = styled(StyledPromoCodeItemDate)`
  line-height: 20px;
  margin-bottom: 8px;
  & > svg {
    margin-right: 12px;
  }
`;
export const StyledToolName = styled(StyledPromoCodeItemName)``;
export const StyledToolInfo = styled(StyledPromoCodeItemInfo)`
  word-break: break-word;
`;

export const StyledToolTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 20px;
  margin-bottom: 4px;
`;
