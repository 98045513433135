import styled, { css } from 'styled-components';

import star from '../../Static/icons/console/star.svg';
import grayStar from '../../Static/icons/console/star_gray.svg';
import hoverStar from '../../Static/icons/console/star_hover.svg';
import S from '../UI/variables';

export const StyledGrade = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  & > ul {
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
    margin-right: 8px;
    ${({ active }) =>
      active
        ? css`
            &:hover {
              & > li:after {
                opacity: 0;
              }
            }
          `
        : ''}
  }

  & > span {
    text-decoration: none;
    &.isGray {
      color: ${S.secondGray};
    }
  }
`;

export const StyledGradeStar = styled.li`
  position: relative;
  width: ${({ ht }) => ht || 16}px;
  height: ${({ ht }) => ht || 16}px;
  background: url(${grayStar});
  background-size: auto 100%;
  ${({ active }) =>
    active
      ? css`
          cursor: pointer;
          &:hover {
            background: url(${star});
            background-size: auto 100%;
            transform: scale(1.1);
          }
          &:hover ~ li {
            background: url(${hoverStar});
            background-size: auto 100%;
          }
        `
      : ''};

  &:not(:last-child) {
    padding-right: 3px;
  }

  &:after {
    content: '';
    position: absolute;
    background: url(${star});
    background-size: auto 100%;
    top: 0;
    left: 0;
    width: ${({ grade }) => 100 * grade}%;
    height: 100%;
  }
`;
