import { Input } from '../../../Components/UI/Form/Input';

export const defaultRegistrationCodeFormData = { registrationCode: '' };

export const defaultRegistrationCodeFormErrors = { registrationCode: '' };

export const initialRegistrationCodeFormState = {
  formData: defaultRegistrationCodeFormData,
  errors: defaultRegistrationCodeFormErrors,
};

export const setRegistrationCodeFormConfig = [
  {
    Element: Input,
    props: {
      name: 'registrationCode',
      placeholder: (l) => l.placeholders.registrationCode,
      type: 'number',
      max: 9999999999,
      required: true,
    },
  },
];
