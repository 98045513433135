import styled from 'styled-components';
import S from '../../UI/variables';

export const StyledGearIndicator = styled.ul`
  display: inline-flex;
  & > li {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 8px;
  }
`;

export const StyledGear = styled.svg`
  width: 24px;
  height: 24px;
  fill: ${({ $activeGear }) => ($activeGear ? S.purple : S.strokeGray)};
`;
