import styled from 'styled-components';

export const MaxContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin-right: auto;
`;

export const MaxContainerXs = styled.div`
  width: 100%;
  max-width: 355px;
  margin: 0 auto;
`;

export const MaxContainerSm = styled.div`
  width: 100%;
  max-width: 680px;
  margin: ${({ margin }) => margin || '0 auto 0 0'};
`;

export const MaxContainerMd = styled.div`
  width: 100%;
  max-width: 900px;
  margin: ${({ margin }) => margin || '0 auto 0 0'};
`;

export const MaxContainerDf = styled.div`
  width: 100%;
  max-width: 1038px;
  margin-right: auto;
`;

export const MaxContainerXl = styled.div`
  width: 100%;
  max-width: 1120px;
  margin-right: auto;
`;

export const MainContainer = styled.div`
  padding: 24px 16px 32px;
  min-height: 100%;
`;
