import styled from 'styled-components';
import S from '../../UI/variables';

export const StyledReviewItem = styled.div`
  padding: 24px;
  border-radius: 7px;
  background-color: ${S.white};
  box-shadow: ${S.mainShade};
  opacity: ${({ loadingReview }) => (loadingReview ? '0.6' : 1)};
  pointer-events: ${({ loadingReview }) => (loadingReview ? 'none' : 'auto')};
  transition: opacity ${S.trFast};

  & & {
    box-shadow: none;
    padding: 16px 0 0 0;
    margin-left: 16px;
    border-radius: 0;
    border-top: 1px solid ${S.strokeGray};

    &:not(:last-child) {
      padding-bottom: 16px;
    }
  }
`;

export const StyledExpandedReview = styled.div`
  overflow: hidden;
  transition: max-height ${S.trFast};
  max-height: ${({ active, bodyHeight }) => (active ? bodyHeight : 0)}px;
`;

export const StyledReviewItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const StyledReviewItemRowActions = styled(StyledReviewItemRow)`
  justify-content: flex-end;
`;

export const StyledReviewItemInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledReviewItemDate = styled.div`
  color: ${S.textSecondary};
  font-size: 12px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const StyledReviewItemUserName = styled.div`
  font-weight: 500;
  line-height: 1.563;
  color: ${S.textPrimary};
`;

export const StyledReviewItemText = styled.div`
  line-height: 1.563;
  color: ${S.textPrimary};
  white-space: pre;
`;

export const StyledReviewItemComment = styled(StyledReviewItemText)`
  color: ${S.greenDark};
  font-size: 14px;
`;
