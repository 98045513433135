import {
  StyledErrorsWrapper,
  StyledInput,
  StyledInputLabel,
  StyledInputName,
  StyledInputWrapper,
} from '../styled';

export const InputMonthYearCard = ({
  required,
  name,
  type = 'text',
  fn = () => null,
  errors = '',
  label,
  locale,
  placeholder,
  value,
}) => {
  const maskMonthYear = (value) => {
    const valueReplaceLetters = value.replace(/\D/g, '');

    const v = valueReplaceLetters.match(/(\d{0,2})(\d{0,2})/);

    return !v[2] ? v[1] : v[1] + '/' + v[2];
  };

  const handleInput = (e) => {
    e.target.value = maskMonthYear(e.target.value);
    fn(e);
  };

  return (
    <StyledInputLabel>
      {label ? (
        typeof label === 'function' ? (
          <StyledInputName>{label(locale)}</StyledInputName>
        ) : (
          <StyledInputName>{label.label}</StyledInputName>
        )
      ) : null}
      <StyledInputWrapper>
        <StyledInput
          className={errors ? 'has-error' : ''}
          name={name}
          type={type}
          required={required}
          onInput={handleInput}
          errors={errors}
          minLength={4}
          maxLength={5}
          value={maskMonthYear(value)}
          placeholder={`${
            typeof placeholder === 'function'
              ? placeholder(locale)
              : placeholder
          } ${required ? '*' : ''}`}
        />
        <StyledErrorsWrapper errors={errors}>
          <span>{errors}</span>
        </StyledErrorsWrapper>
      </StyledInputWrapper>
    </StyledInputLabel>
  );
};
