import { Input } from '../../../Components/UI/Form/Input';
import { Textarea } from '../../../Components/UI/Form/Textarea';

export const defaultQaFormData = {
  questionLt: '',
  answerLt: '',
  questionEn: '',
  answerEn: '',
};

export const defaultQaFormErrors = {
  questionLt: '',
  answerLt: '',
  questionEn: '',
  answerEn: '',
};

export const initialQAFormState = {
  formData: defaultQaFormData,
  errors: defaultQaFormErrors,
};

export const setAddQAFormConfig = [
  {
    Element: Input,
    props: {
      name: 'questionLt',
      type: 'text',
      placeholder: (l) => l.placeholders.questionLt,
      label: (l) => l.placeholders.questionLt,
      required: true,
    },
  },
  {
    Element: Textarea,
    props: {
      name: 'answerLt',
      placeholder: (l) => l.placeholders.answerLt,
      label: (l) => l.placeholders.answerLt,
      required: true,
    },
  },
  {
    Element: Input,
    props: {
      name: 'questionEn',
      type: 'text',
      placeholder: (l) => l.placeholders.questionEn,
      label: (l) => l.placeholders.questionEn,
      required: true,
    },
  },
  {
    Element: Textarea,
    props: {
      name: 'answerEn',
      placeholder: (l) => l.placeholders.answerEn,
      label: (l) => l.placeholders.answerEn,
      required: true,
    },
  },
];
