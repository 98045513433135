import styled, { css } from 'styled-components';

import S from '../variables';
import { StyledPreloader } from '../Preloader/styled';

export const StyledCommonButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  position: relative;
  font-size: 12px;
  appearance: none;
  background: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${S.strokeGray};
  transition: color ${S.trFast}, background-color ${S.trFast},
    border-color ${S.trFast}, box-shadow ${S.trFast}, transform ${S.trFast};

  &[disabled] {
    cursor: default;
    pointer-events: none;
  }

  ${StyledPreloader} {
    width: 20px;
    height: 20px;
    background-color: transparent;
    padding: 0;

    &:not(:first-child) {
      margin-left: 20px;
    }

    & > svg {
      display: none;
    }

    & > div {
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (min-width: ${S.tablet}) {
    font-size: 14px;
  }

  @media screen and (min-width: ${S.tabletLandscape}) {
    font-size: 16px;
  }
`;

const getButtonStyles = ({ kind, size, $showDisabled }) => {
  switch (kind) {
    case 'green':
      return css`
          color: ${S.textPrimary};
          background-color: ${S.greenLight};
          border-color: ${S.greenLight};

          line-height: 22px;
          font-weight: 500;
          border-radius: ${size === 'small' ? 4 : 16}px;
          padding: ${
            size === 'small'
              ? '6px 24px'
              : size === 'big'
              ? '16px 28px'
              : '12px 34px'
          }};

          & > .icon {
            & > svg {
              width: 12px;
              height: 12px;
            }
          }

          ${
            $showDisabled
              ? css`
                  &[disabled] {
                    background-color: ${S.strokeGray};
                    border-color: ${S.strokeGray};
                  }

                  &:hover {
                    &:not([disabled]) {
                      background-color: ${S.greenDark};
                      color: ${S.white};
                      border-color: ${S.white};
                    }
                  }
                `
              : css`
                  &:hover {
                    background-color: ${S.greenDark};
                    color: ${S.white};
                    border-color: ${S.white};
                  }
                `
          }
        `;
    case 'icon':
      return css`
        color: ${S.textSecondary};
        background-color: transparent;
        border: none;
        padding: 0;
        width: 24px;
        height: 24px;

        & > .icon {
          & > svg {
            width: 24px;
            height: 24px;
          }
        }

        &:hover {
          background-color: transparent;
          color: ${S.purpleDark};
        }
      `;
    case 'stroke':
      return css`
          color: ${S.textPrimary};
          background-color: transparent;
          border-color: ${S.textPrimary};

          font-weight: 500;
          line-height: 1.375;
          border-radius: ${size === 'small' ? 4 : 16}px;
          padding: ${size === 'small' ? '6px 24px' : '12px 34px'}};

          &:hover {
            background-color: ${S.greenDark};
            border-color: ${S.greenDark};
            color: ${S.white};
          }
        `;
    case 'pink':
      return css`
        color: ${S.textPrimary};
        background-color: transparent;
        border-color: ${S.strokeGray};

        line-height: 1.125;
        font-weight: 400;
        border-radius: 4px;
        padding: 8px 13px;

        &:hover,
        &.is-active {
          border-color: ${S.pink};
          background-color: ${S.pink};
          color: ${S.white};
        }
      `;
    case 'square':
      return css`
        color: ${S.greenDark};
        background-color: ${S.strokeGray};
        border-color: ${S.strokeGray};

        border-radius: 8px;
        padding: 12px;
        width: 48px;
        min-width: 48px !important;
        height: 48px;

        &:hover,
        &.is-active {
          border-color: ${S.purple};
          background-color: ${S.purple};
        }

        ${$showDisabled
          ? css`
              &[disabled] {
                background-color: transparent;
                color: ${S.strokeGray};
              }
            `
          : ''}
      `;
    case 'square-grey':
      return css`
        color: ${S.textSecondary};
        background-color: ${S.lightGray};
        border-color: ${S.lightGray};

        border-radius: 3px;
        padding: 5px;
        width: 36px;
        height: 36px;

        &:hover,
        &.is-active {
          color: ${S.textPrimary};
          border-color: ${S.purple};
          background-color: ${S.purple};
        }

        ${$showDisabled
          ? css`
              &[disabled] {
                background-color: transparent;
                color: ${S.strokeGray};
              }
            `
          : ''}
      `;
    case 'circle':
      return css`
        align-items: center;
        justify-content: center;
        color: ${S.purpleDark};
        background-color: ${S.purpleLight};
        border-color: ${S.purpleLight};
        font-size: 12px;
        line-height: 12px;

        border-radius: 30px;
        padding: 10px;
        min-width: 36px;
        height: 36px;

        & > .icon > svg {
          width: 14px;
          height: 14px;
        }

        &:hover,
        &.is-active {
          border-color: ${S.purpleDark};
          background-color: transparent;
        }

        ${$showDisabled
          ? css`
              &[disabled] {
                background-color: ${S.strokeGray};
                border-color: ${S.strokeGray};

                & > .icon > svg {
                  fill: #fff;
                }
              }
            `
          : ''}
      `;
    case 'step':
      return css`
        justify-content: center;
        padding: 0;
        font-size: 14px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: ${S.white};
        background-color: ${S.secondGray};
        pointer-events: none;

        &.is-ready {
          pointer-events: auto;
          background-color: ${S.green};
          color: ${S.green};

          &::after {
            transform: scale(1);
          }
        }

        &.is-enabled {
          pointer-events: auto;
          background-color: ${S.purpleLanding};
        }

        &:not(:last-child):hover {
          box-shadow: 0 0 0 2px ${S.purpleDark};
        }

        &.is-use {
          pointer-events: none;
          transform: scale(1.2);
        }

        &:not(:last-child) {
          margin-right: 12px;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          transition: transform ${S.trFast};
          transform: scale(0);
          background: ${S.green}
            url("data:image/svg+xml,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 3.4L5.25 7L11.5 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A")
            no-repeat center center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      `;
    default:
      return css`
        color: ${S.textPrimary};
        background-color: transparent;
        border-color: ${S.textPrimary};

        &:hover {
          background-color: ${S.textPrimary};
          color: ${S.white};
        }
      `;
  }
};

export const StyledMainButton = styled(StyledCommonButton)`
  width: ${({ full }) => (full ? '100%' : 'auto')};

  ${getButtonStyles}

  &.is-loading {
    pointer-events: none;
  }

  & > span {
    display: flex;
  }

  & > .badge {
    position: absolute;
    left: 100%;
    bottom: 100%;
    transform: translate(-50%, 50%);
  }

  & > .icon {
    display: flex;

    & > svg {
      fill: currentColor;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }

    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    background: initial;
  }
`;
